import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';
import {Batch} from '../../lane/store/types';
import {DumpData, LaneDump} from './types';

const prefix = 'production/info';
const prefixDump = 'production/control/dump';

export const DumpsServices = {
    getAllPaginated: ({params, args}: PaginatedServiceArgs) => {
        return guardedFetcher.get<PaginateResponse<Batch>>(`${prefix}/area/${args || 'NO_EXISTE_LINEA'}/dumps`, {params});
    },
    cancelDump: (dumpId: string) => guardedFetcher.get(`${prefix}/dump/cancel/${dumpId}`),
    reactivateBatchCheck: (dumpId: string) => guardedFetcher.get(`${prefix}/dump/reactivate-batch-check/${dumpId}`),
    reactivateBatchExecute: (dumpId: string) => guardedFetcher.patch(`${prefix}/dump/reactivate-batch/${dumpId}`),
    modifyDump: (dumpId: string, numBoxes: number, username: string) => guardedFetcher.post(`${prefix}/dump/modify-manually/${dumpId}/boxes/${numBoxes}`, {username}),
    createOrUpdate: (dump_barcode: string, origin_code: string, data: DumpData) =>
        guardedFetcher.post<LaneDump>(`${prefixDump}/create/${dump_barcode}/logisticunit/${origin_code.replace('/', '-')}`, data),

};
