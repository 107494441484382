export enum ParameterCPN {
    INSTALLATION_TYPE = 'INSTALLATION_TYPE',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    FRONT_APP_COLOR = 'FRONT_APP_COLOR',
    FRONT_APP_PRIMARY_COLOR = 'FRONT_APP_PRIMARY_COLOR',
    FRONT_APP_SECUNDARY_COLOR = 'FRONT_APP_SECUNDARY_COLOR',
    EMPLOYEE_CODE_MODE = 'EMPLOYEE_CODE_MODE',
    AREA_BARCODE_PREFIX = 'AREA_BARCODE_PREFIX',
    AREA_MODIFY_BARCODE_PREFIX = 'AREA_MODIFY_BARCODE_PREFIX',
    PTABLE_BARCODE_PREFIX = 'PTABLE_BARCODE_PREFIX',
    PALLET_BARCODE_PREFIX = 'PALLET_BARCODE_PREFIX',
    LOGISTIC_UNIT_BARCODE_PREFIX = 'LOGISTIC_UNIT_BARCODE_PREFIX',
    SHOW_ORDER_CODE = 'SHOW_ORDER_CODE',
    UNLOAD_BOXES_CORRECTION_FACTOR = 'UNLOAD_BOXES_CORRECTION_FACTOR',
}
