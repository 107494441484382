import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface Props {
    open: boolean;
    title: string;
    handle: () => void;
    handleClose: () => void;
    contentText?: string;
    disableButton?: boolean;
}


type State = {
    diabledButton: boolean;
};

const initialState: Readonly<State> = {
    diabledButton: false,
};

export class CustomDialog extends React.Component<Props, State> {

    readonly state = initialState;

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.open === false && this.props.open === true) {
            this.setState({diabledButton: false});
        }
    }

    handleClick() {
        this.setState({diabledButton: true});
        this.props.handle();
    }

    render() {
        return (<Dialog
            open={this.props.open}
            onClose={this.props.handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>
            <DialogContent>
                {this.props.contentText && (
                    <DialogContentText id='alert-dialog-description'>
                        {this.props.contentText}
                    </DialogContentText>
                )}
                {this.props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handleClose} style={{backgroundColor: '#c44'}}>
                    No
                </Button>
                <Button
                    disabled={this.state.diabledButton || (this.props.disableButton ? this.props.disableButton : false)}
                    onClick={() => this.handleClick()}
                    style={{backgroundColor: '#4c4'}}>
                    Sí
                </Button>
            </DialogActions>
        </Dialog>);
    }
}



