import * as React from 'react';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

/*** THEMED */
export const Wrap = styled.div`
    width: 100%;
`;
export const WrapDumpID = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 8px;
`;

export const WrapDumpIDItem = styled.div`
    flex: 1 0 23%;
    min-height: 80px;
    max-height: 80px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
`;

export const WrapDumpData = styled.div`
    margin-top: 15px;
    height: 100%;
    font-size: 0.7em;
    display: flex;
    flex-flow: column nowrap;
`;

const styleIcon = {
    icon: {
        marginLeft: 19,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
};
const styleTypo = {
    typo: {
        marginLeft: 25,
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#01ad0a',
    },
};
export const ThemedErrorIcon = withStyles(styleIcon)((props: any) => (
    <ErrorOutline data-name={'error'} className={props.classes.icon} color='error' />
));
export const ThemedSucessIcon = withStyles(styleIcon)((props: any) => (
    <Check
        data-name={'success'}
        className={props.classes.icon}
        color='primary'
        style={{color: '#01ad0a'}}
    />
));
export const ThemedStatus = withStyles(styleTypo)((props: any) => (
    <Typography data-name={props.dataName} className={props.classes.typo}>
        {props.children}
    </Typography>
));
