import * as React from 'react';
import {ColumnType} from 'src/types';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {ProdPartsServices} from '../../lanemanager/prodparts/store/prodParts.services';
import {PaginatedTableComponent} from '../../../shared/components/PaginatedTableComponent';
import {CustomButton, OCard} from '../../../shared/style';
import {TableModal} from '../../../shared/components/Modal';
import {Tooltip} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import {PalletInfoSubpallet} from './types';
import {customFormatNumber} from '../../../shared/functions/FormatHelpers';

export interface Props {
    open: boolean;
    handleClose: () => void;
    handleSelect: (selected: ProdPartGeneric) => void;
    title: string;
    subpallets: PalletInfoSubpallet[];
    erpOrderCode: string;
    areaId: string;
    newSubpallet: boolean;
}

type State = {};

export class SelectProdpartModal extends React.Component<Props, State> {
    columns: ColumnType<ProdPartGeneric> = [];

    componentDidMount() {
        this.columns = this.getColumnFormat();
        console.log(this.props.erpOrderCode);
    }

    //private alreadyPalletProdpartExist() {
    // Por la tarea #45179
    //    return false;
    //return this.props.subpallets.map((subpallet: PalletInfoSubpallet) => subpallet.prodpart_id).includes(prodpart.id);
    //}

    private alreadyCompleted(prodpart: ProdPartGeneric) {
        return prodpart.pallets_count >= prodpart.pallets_total;
    }

    private getColumnFormat = (): ColumnType<ProdPartGeneric> => {
        return [
            {
                Header: '',
                sortable: false,
                style: {textAlign: 'center'},
                width: 50,
                filterable: false,
                Cell: item => (
                    <>
                        <CustomButton
                            disabled={false}
                            width={'24px'}
                            minWidth={'24px'}
                            height={'24px'}
                            margin={'2px 2px 2px 2px'}
                            bgcolor={'#c44'}
                            onClick={() => {
                                this.props.handleSelect(item.original);
                            }}
                        >
                            <Tooltip title={'Seleccionar'} placement="top">
                                <CheckCircleOutline />
                            </Tooltip>
                        </CustomButton>
                    </>
                ),
            },
            {
                Header: 'Erp Code',
                headerClassName: 'header_cell_string',
                accessor: 'erp_prodpart_code',
                className: 'cell_string',
                filterable: true,
                sortable: true,
            },
            {
                Header: 'CLIENTE',
                headerClassName: 'header_cell_string',
                id: 'customer',
                accessor: b => b.customer.name,
                className: 'cell_string',
                filterable: true,
                sortable: false,
                minWidth: 160,
                style: {whiteSpace: 'unset'},
            },
            {
                Header: 'PALES',
                headerClassName: 'header_cell_number',
                width: 80,
                id: 'pallets',
                sortable: false,
                className: 'cell_number',
                Cell: item => (
                    <div
                        style={{
                            fontWeight: this.alreadyCompleted(item.original) ? 'bold' : 'normal',
                            color: this.alreadyCompleted(item.original) ? 'red' : '',
                        }}
                    >
                        {`${customFormatNumber(item.original.pallets_count)}/${customFormatNumber(item.original.pallets_total)}`}
                    </div>
                ),
            },
            {
                Header: 'PEND.',
                headerClassName: 'header_cell_number',
                id: 'pending',
                sortable: false,
                className: 'cell_number',
                width: 80,
                Cell: items => <>{`${customFormatNumber(items.original.pallets_total - items.original.pallets_count)}`}</>,
            },
            {
                id: 'merchandise',
                accessor: b => b.merchandise.name,
                Header: 'PRODUCTO',
                headerClassName: 'header_cell_string',
                className: 'cell_string',
                filterable: true,
                minWidth: 160,
                sortable: false,
                style: {whiteSpace: 'unset'},
            },
            {
                accessor: 'pallet_name', // b => b.making_type.name,
                Header: 'PALÉ',
                headerClassName: 'header_cell_string',
                className: 'cell_string',
                filterable: true,
                minWidth: 120,
                sortable: true,
            },
            {
                Header: 'CAJAS',
                headerClassName: 'header_cell_number',
                id: 'boxes',
                sortable: false,
                Cell: items => <>{`${customFormatNumber(items.original.boxes_count || 0)}/${customFormatNumber(items.original.boxes_total || 0)}`}</>,
                className: 'cell_number',
            },
            {
                Header: 'REFERENCIA',
                headerClassName: 'header_cell_string',
                accessor: 'order_reference',
                className: 'cell_string',
                filterable: true,
                minWidth: 160,
                style: {whiteSpace: 'unset'},
            },
            {
                accessor: 'packaging', // b => b.making_type.name,
                Header: 'ENVASE',
                headerClassName: 'header_cell_string',
                className: 'cell_string',
                filterable: true,
                minWidth: 160,
                sortable: true,
                style: {whiteSpace: 'unset'},
            },
            {
                accessor: 'boxes_per_pallet', // b => b.making_type.name,
                Header: 'ENV.X PALE',
                headerClassName: 'header_cell_number',
                className: 'cell_number',
                filterable: false,
                sortable: true,
            },
        ];
    };

    render() {
        return (
            <>
                <TableModal maxWidth={'100%'} title={this.props.title} handleClose={this.props.handleClose} open={this.props.open}>
                    <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                        <PaginatedTableComponent
                            filtered={
                                this.props.subpallets.length > 1 || this.props.newSubpallet
                                    ? [
                                          {
                                              id: 'order_id',
                                              value: this.props.erpOrderCode,
                                          },
                                          {
                                              id: 'area_id',
                                              value: this.props.areaId,
                                          },
                                      ]
                                    : [
                                          {
                                              id: 'area_id',
                                              value: this.props.areaId,
                                          },
                                      ]
                            }
                            columnFormat={this.columns}
                            defaultPageSize={20}
                            service={ProdPartsServices.getProdPartsPaginate}
                            loading={true}
                            defaultSortedProp={[{id: 'erp_prodpart_code', desc: true}]}
                        />
                    </OCard>
                </TableModal>
            </>
        );
    }
}
