import * as React from 'react';
import {connect, MapStateToProps} from 'react-redux';
import ReactTable from 'react-table';

import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store';

import {ChipTablesConnected} from './ChipTables';
import {CustomPaper} from './Wrap';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import { ParameterCPN } from 'src/types/app_types';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';

export interface SProps {
    prodParts: ReturnType<typeof AreaSelectors.getProdParts>;
    showOrderCodeParam?: string;
}
export class ProdPartsInfo extends React.Component<SProps> {
    public render() {
        const {prodParts} = this.props;
        const showOrderCode = (this.props.showOrderCodeParam === '1') ? true : false;
        return (
            <>
                <CustomPaper>
                    <ReactTable
                        style={{height: '100%'}}
                        data={prodParts}
                        resizable={false}
                        sortable={false}
                        minRows={5}
                        className="-striped -highlight"
                        defaultSorted={[{id: 'finished_at', desc: false}]}
                        defaultFilterMethod={(filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined
                                ? !!String(row[id])
                                      .toLowerCase()
                                      .match(('' + filter.value).toLowerCase())
                                : true;
                        }}
                        columns={[
                            {
                                Header: 'ENTRADA',
                                headerClassName: 'header_cell_string',
                                accessor: 'order_arrival_at',
                                className: 'cell_string',
                            },
                            {
                                Header: 'PEDIDO',
                                headerClassName: 'header_cell_string',
                                accessor: 'order_id',
                                className: 'cell_string',
                                show: showOrderCode,
                            },
                            {
                                Header: 'PI',
                                headerClassName: 'header_cell_string',
                                accessor: 'erp_prodpart_code',
                                className: 'cell_string',
                            },
                            {
                                Header: 'CLIENTE',
                                headerClassName: 'header_cell_string',
                                id: 'customer',
                                accessor: b => b.customer.name,
                                className: 'cell_string',
                            },
                            {
                                Header: 'PALÉS',
                                headerClassName: 'header_cell_number',
                                className: 'cell_number',
                                Cell: items => <>{`${customFormatNumber(items.original.pallets_count)}/${customFormatNumber(items.original.pallets_total)}`}</>,
                            },
                            {
                                Header: 'REFERENCIA',
                                headerClassName: 'header_cell_number',
                                accessor: 'order_reference',
                                className: 'cell_string',
                            },
                            {
                                Header: 'CAJAS',
                                headerClassName: 'header_cell_number',
                                Cell: items => <>{`${customFormatNumber(items.original.boxes_count || 0)}/${customFormatNumber(items.original.boxes_total || 0)}`}</>,
                                className: 'cell_number',
                            },
                            {
                                Header: 'MESAS',
                                Cell: items => <ChipTablesConnected prodpart={items.original} />,
                                minWidth: 260,
                                style: {whiteSpace: 'normal', display: 'flex'},
                            },
                            {
                                accessor: 'variety',
                                headerClassName: 'header_cell_string',
                                Header: 'PRODUCTO',
                                className: 'cell_string',
                            },
                            {
                                accessor: 'packaging',
                                headerClassName: 'header_cell_string',
                                Header: 'ENVASE',
                                className: 'cell_string',
                            },
                        ]}
                        previousText={'Anterior'}
                        nextText={'Siguiente'}
                        loadingText={'Obteniendo datos...'}
                        noDataText={'No hay datos en la tabla'}
                        showPageSizeOptions={false}
                        defaultPageSize={5}
                    />
                </CustomPaper>
            </>
        );
    }
}

const mapState2Props: MapStateToProps<SProps, any, AppStoreState> = state => ({
    prodParts: AreaSelectors.getProdParts(state),
    showOrderCodeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.SHOW_ORDER_CODE),
});

export const ProdPartsInfoConnected = connect(mapState2Props)(ProdPartsInfo);
