import * as React from 'react';
import {ColumnType} from 'src/types';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {CustomButton, OCard} from '../../../shared/style';
import {TableModal} from '../../../shared/components/Modal';
import {Tooltip} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import {TableComponent} from '../../../shared/components/TableComponent';
import {PalletsServices} from '../../lanemanager/pallets/store/services';
import {PalletInfoErpPackaging, PalletInfoSubpallet} from './types';

export interface Props {
    open: boolean;
    handleClose: () => void;
    handleSelect: (selected: any) => void;
    subpallet: PalletInfoSubpallet;
}

type State = {};

export class SelectErpPackagingModal extends React.Component<Props, State> {
    columns: ColumnType<any> = [];

    componentDidMount() {
        this.columns = this.getColumnFormat();
    }

    private alreadySubpalletPackagingExist(erpPallet: PalletInfoErpPackaging) {
        return this.props.subpallet.packaging_name === erpPallet.erpPackagingName;
    }

    private getColumnFormat = (): ColumnType<ProdPartGeneric> => {
        return [
            {
                Header: '',
                sortable: false,
                style: {textAlign: 'center'},
                filterable: false,
                Cell: item => (
                    <>
                        <CustomButton
                            disabled={this.alreadySubpalletPackagingExist(item.original)}
                            width={'24px'}
                            minWidth={'24px'}
                            height={'24px'}
                            margin={'2px 2px 2px 2px'}
                            bgcolor={'#c44'}
                            onClick={() => {
                                this.props.handleSelect(item.original);
                            }}
                        >
                            <Tooltip title={'Seleccionar'} placement="top">
                                <CheckCircleOutline />
                            </Tooltip>
                        </CustomButton>
                    </>
                ),
            },
            {
                Header: 'Nombre envase',
                headerClassName: 'header_cell_string',
                accessor: 'erpPackagingName',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 200,
            },
            {
                Header: 'Código envase',
                headerClassName: 'header_cell_string',
                accessor: 'erpPackagingCode',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Nombre familia',
                headerClassName: 'header_cell_string',
                accessor: 'erpFamilyName',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 200,
            },
            {
                Header: 'Código familia',
                headerClassName: 'header_cell_string',
                accessor: 'erpFamilyCode',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Tipo confección',
                headerClassName: 'header_cell_string',
                accessor: 'confectionType',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Tara',
                headerClassName: 'header_cell_string',
                accessor: 'erpTara',
                className: 'cell_string',
                width: 90,
            },
        ];
    };

    render() {
        return (
            <>
                <TableModal maxWidth={'100%'} title={'Seleccione un envase'} handleClose={this.props.handleClose} open={this.props.open}>
                    <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                        <TableComponent defaultPageSize={20} columnFormat={this.columns} service={PalletsServices.getErpPackagingList} />
                    </OCard>
                </TableModal>
            </>
        );
    }
}
