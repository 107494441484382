import * as React from 'react';
import {connect} from 'react-redux';

import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {changePtableAssign, CPA} from 'src/app/lanemanager/lane/store/thunks/changePtableAssign';
import {LaneProdPartState, LanePtableState, ProdPartGeneric} from 'src/app/lanemanager/lane/store/types';
import {AppStoreState} from 'src/store/interface';

import {ChooseTable} from './ChooseTable';
import {CustomButtonProdPartsInfo} from './Wrap';
import {ProdPartServices} from '../../store/services';

export type ChooseContainerProps = {
    prodpart: LaneProdPartState | ProdPartGeneric;
    ptablesProp?: LanePtableState[];
    callback: () => void;
};

type SChooseContainerProps = {
    ptablesData?: LanePtableState[];
    hotUpdate: boolean;
};

type DChooseContainerProps = {
    changeTable: (config: CPA) => any;
};

const initialState = {disabled: false, openModal: false};
type State = Readonly<typeof initialState>;

export class ChooseContainer extends React.Component<ChooseContainerProps & SChooseContainerProps & DChooseContainerProps, State> {
    readonly state = initialState;

    public render() {
        const ptbls = this.props.ptablesProp ? this.props.ptablesProp : this.props.ptablesData;
        return (
            <>
                <CustomButtonProdPartsInfo action={this.toggleModal} disabled={!this.props.ptablesData || this.props.ptablesData.length < 1} />
                <ChooseTable
                    toggle={this.toggleModal}
                    open={this.state.openModal}
                    pts={ptbls!}
                    pp={this.transformProdPart()}
                    handleAdd={this.handleAdd}
                    handleReserve={this.handleReserve}
                    handleTransfer={this.handleTransfer}
                />
            </>
        );
    }

    private transformProdPart = (): LaneProdPartState => {
        const {prodpart} = this.props;
        if (typeof prodpart.variety === 'string') return prodpart as LaneProdPartState;
        else {
            const cast = prodpart as ProdPartGeneric;
            return {
                id: cast.id,
                packaging: cast.packaging,
                order_arrival_at: cast.order_arrival_at,
                pallets_count: cast.pallets_count,
                pallets_total: cast.pallets_total,
                boxes_count: cast.boxes_count,
                boxes_total: cast.boxes_total,
                order_id: cast.order_id,
                order_reference: cast.order_reference,
                order_state: cast.order_state,
                variety: cast.merchandise.name,
                category: cast.category,
                ptables: cast.ptables ? cast.ptables.map(pt => pt.id) : [],
                customer: cast.customer,
                erp_prodpart_code: cast.erp_prodpart_code,
                kg_total: cast.kg_total,
                kg_box_prodpart: cast.kg_box_prodpart,
                merchandise: cast.merchandise,
            } as LaneProdPartState;
        }
    };
    private handleAdd = (payload: {prodpart: string; erpCode: string; pt: LanePtableState}) => () => {
        this.props.changeTable({
            ptable: payload.pt,
            newAssign: payload.prodpart,
            newReserve: undefined,
            newTransfer: undefined,
            callback: this.props.callback,
        });
    };

    private handleReserve = (payload: {prodpart: string; erpCode: string; pt: LanePtableState}) => () => {
        this.props.changeTable({
            ptable: payload.pt,
            newAssign: undefined,
            newReserve: payload.prodpart,
            newTransfer: undefined,
            callback: this.props.callback,
        });
    };

    private handleTransfer = (payload: {prodpart: string; erpCode: string; pt: LanePtableState}) => () => {
        this.props.changeTable({
            ptable: payload.pt,
            newAssign: undefined,
            newReserve: undefined,
            newTransfer: payload.prodpart,
            callback: this.props.callback,
        });
    };

    private async importProdpart() {
        // if (!this.props.prodpart.id) {
        console.log('HOT', this.props.hotUpdate);
        if (this.props.hotUpdate) {
            const data = await ProdPartServices.importProdpart(this.props.prodpart.erp_prodpart_code, this.props.prodpart.id);
            if (data && data.data && data.data.prodpartId) {
                this.props.prodpart.id = data.data.prodpartId;
                console.log('data:', this.props.prodpart.id);
            }
        }
    }

    private toggleModal = () => {
        if (!this.state.openModal) {
            this.importProdpart().then(() => {
                this.setState({openModal: !this.state.openModal});
            });
        } else {
            this.setState({openModal: !this.state.openModal});
        }
    };
}

const mapStateToProps = (state: AppStoreState) => ({
    ptablesData: AreaSelectors.getPtables(state),
    hotUpdate: state.parameters.parameters.find(p => p.name === 'SET_HOT_PRODPARTS_UPDATE')?.value === '1',
});
const mapDispatchToProps = (dispatch: any) => ({
    changeTable: (payload: CPA) => dispatch(changePtableAssign(payload)),
});

export const ChooseContainerConnected = connect(mapStateToProps, mapDispatchToProps)(ChooseContainer);
