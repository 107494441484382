import styled from 'styled-components';

export const WrapLoaderDiv = styled.div`
    position: fixed !important;
    background-color: rgba(255, 255, 255, 0.85);
    top: 0;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
`;

export const WrappingDiv = styled.div`
    width: calc(75%);
    margin: auto;
    margin-top: 8px;
    height: calc(100% - 65px);
    background-color: #efefef;
    padding: 5px;
    margin-bottom: 5px;
`;

export const WrapInfo = styled.div`
    padding: 10px;

    ul {
        list-style-type: none;
    }
`;

export const DivElement = styled.div`
    border-bottom: 2px solid gray;
`;

export const WrapLabelInfo = styled.span`
    width: 300px;
    display: inline-block;
`;

export const WrapValueInfo = styled.span`
    margin-left: 5px;
`;

export const WrapLoaderDivModal = styled.div`
    position: fixed !important;
    top: 0;
    height: 100% !important;
    weight: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
`;
