import * as React from 'react';
import {connect} from 'react-redux';

import {getAreaAllEmployees, getAreaAllProdparts, getAreaAllPtables, getCurrentDump} from 'src/app/lanemanager/lane/store/thunks';
import {OCard, WrapCardsToRow, WrapFullContainer} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';

import {DumpInfoConnected} from '../DumpInfo';
import {PalletsInfoConnected} from '../PalletsInfo';
import {ProdPartsInfoConnected} from '../ProdPartsInfo';
import {IconButton} from '@material-ui/core';

type DProps = {
    getCurrentDump(): void;
    getProdparts(): void;
    getEmployees(): void;
    getPtables(): void;
};

type Props = {
    lane: any;
};

type State = {
    showProdparts: boolean;
};

const initialState = {
    showProdparts: true,
};

class Overview extends React.Component<DProps & Props, State> {
    readonly state: State = initialState;

    private timer: any;

    componentDidMount() {
        this.props.getCurrentDump();
        this.props.getProdparts();
        this.props.getEmployees();
        this.props.getPtables();
        this.timer = setInterval(() => {
            this.props.getCurrentDump();
            this.props.getProdparts();
            this.props.getEmployees();
            this.props.getPtables();
        }, 30000);
    }

    componentDidUpdate() {
        this.props.getCurrentDump();
        this.props.getProdparts();
        this.props.getEmployees();
        this.props.getPtables();
        /*this.timer = setInterval(() => {
            this.props.getCurrentDump();
            this.props.getProdparts();
            this.props.getEmployees();
            this.props.getPtables();
        }, 30000);*/
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    private handleShowProdparts = () => {
        this.setState({showProdparts: !this.state.showProdparts});
    };

    public render() {
        const VIEW_ACCESS = IsInRuleset([
            'LANEMANAGER_COMMON_VIEW',
            'LANEMANAGER_COMMON_TOTAL',
            'LANEMANAGER_STATE_TOTAL',
            'LANEMANAGER_STATE_VIEW',
            'LANEMANAGER_PRODPARTS_VIEW',
            'LANEMANAGER_PRODPARTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_VIEW',
            'LANEMANAGER_DOWNTIMES_TOTAL',
            'LANEMANAGER_DOWNTIMES_VIEW',
            'LANEMANAGER_PALLETS_VIEW',
            'LANEMANAGER_DUMPS_VIEW',
            'LANEMANAGER_PERSONNEL_TOTAL',
            'LANEMANAGER_PERSONNEL_VIEW',
            'LANEMANAGER_PERFORMANCE_VIEW',
        ]);
        const TOTAL_ACCESS = IsInRuleset(['LANEMANAGER_STATE_TOTAL', 'LANEMANAGER_COMMON_TOTAL']);
        const {showProdparts} = this.state;

        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <WrapFullContainer>
                    <WrapCardsToRow style={{height: !showProdparts ? '48%' : '100%'}}>
                        <OCard height={'99%'} width={'35%'} contentHeight={'95%'} contentPadding={'8px 8px 0 8px'} overflowX={'auto'}>
                            <DumpInfoConnected />
                        </OCard>
                        <OCard height={'99%'} width={'65%'} contentHeight={'100%'} contentPadding={'0px'} overflowX={'auto'}>
                            <PalletsInfoConnected />
                        </OCard>
                    </WrapCardsToRow>

                    <WrapCardsToRow style={{height: !showProdparts ? '52%' : '0%'}}>
                        <OCard width={'100%'} height={'100%'} contentHeight={'100%'} contentPadding={0}>
                            <ProdPartsInfoConnected />
                        </OCard>
                    </WrapCardsToRow>

                    <div style={{position: 'fixed', bottom: '15px', right: '15px'}}>
                        <IconButton
                            onClick={() => this.handleShowProdparts()}
                            style={{
                                backgroundColor: '#04A64E7F',
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {!showProdparts ? <div style={{fontWeight: 'bold'}}>&dArr;</div> : <div style={{fontWeight: 'bold'}}>&uArr;</div>}
                        </IconButton>
                    </div>
                </WrapFullContainer>
            )
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getCurrentDump: () => dispatch(getCurrentDump()),
    getProdparts: () => dispatch(getAreaAllProdparts()),
    getEmployees: () => dispatch(getAreaAllEmployees()),
    getPtables: () => dispatch(getAreaAllPtables()),
});

export const OverviewConnected = connect(null, mapDispatchToProps)(Overview);
