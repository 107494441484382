import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router';
import styled from 'styled-components';

import {AreasControl} from 'src/app/administration/areasControl/AreasControl';
import {BackupControl} from 'src/app/administration/backupControl/BackupControl';
// import {AreaControl} from 'src/app/administration/areaControl/components/AreaControl';
import {BargunsControl} from 'src/app/administration/bargunsControl/BargunsControl';
import {BarReadersControl} from 'src/app/administration/barReadersControl/BarReadersControl';
import {CardControlConnected} from 'src/app/administration/cardControl/components/CardControl';
import {CausesControl} from 'src/app/administration/causesControl/CausesControl';
import {CentersControl} from 'src/app/administration/centerControl/CentersControl';
import {DevicesControl} from 'src/app/administration/devicesControl/DevicesControl';
import {DowntimeCategoriesControl} from 'src/app/administration/downtimeCategoriesControl/DowntimeCategoriesControl';
import {EmployeesControl} from 'src/app/administration/employeesControl/EmployeesControl';
import {JobtypesControl} from 'src/app/administration/jobtypesControl/JobtypesControl';
import {PtablesControl} from 'src/app/administration/ptablesControl/PtablesControl';
import {TasksControl} from 'src/app/administration/tasksControl/TasksControl';
import {TaskGroupsControl} from 'src/app/administration/taskGroupsControl/TaskGroupsControl';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {Typography} from '@material-ui/core';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FixedScannerIcon from '@material-ui/icons/BlurCircular';
import CategoryIcon from '@material-ui/icons/Category';
import CardIcon from '@material-ui/icons/CreditCard';
import CenterIcon from '@material-ui/icons/Domain';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocationIcon from '@material-ui/icons/LocationOn';
import BargunIcon from '@material-ui/icons/Looks';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/SettingsEthernet';
import Device from '@material-ui/icons/Tablet';
import TableIcon from '@material-ui/icons/TableChart';
import WorkIcon from '@material-ui/icons/Work';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {NonProductiveTimesControl} from './nonProductiveTimesControl/NonProductiveTimesControl';
import {PalletDefinitionCstmFieldsControl} from './palletDefinitionCstmFieldsControl/PalletDefinitionCstmFieldsControl';
import {AreasetsControl} from './areasetControl/AreasetsControl';
import {RatesControl} from './ratesControl/RatesControl';
import {EmployeeCostsControl} from './employeeCostsControl/EmployeeCostsControl';
import {FestivesControl} from './festivesControl/FestivesControl';
import {CalculateCostExecutionsControl} from './calculateCostExecutionsControl/CalculateCostExecutionsControl';
import {InternalParametersControl} from './parametersControl/InternalParametersControlPanel';
import {AppParametersControl} from './parametersControl/AppParametersControlPanel';
import {LiftPalletsControl} from "./palletsControl/LiftPalletsControl";
import { ScalesDevicesControl } from './scalesDevicesControl/ScalesDevicesControl';
//import {ModulesControl} from './modulesControl/ModulesControl';

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
    classes: {
        root: string;
    };
};

type State = {
    routeTitle: string;
    generic: boolean;
};
const initialState: State = {routeTitle: '', generic: true};

const styles = ({palette}: Theme) =>
    createStyles({
        root: {
            backgroundColor: palette.background.paper,
            padding: 0,
            height: 'calc(100% - 48px)',
            width: '100%',
        },
    });

export class AdministrationIndexPageBase extends React.Component<Props & SProps & DProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.findRoute();
    }
    componentWillReceiveProps(nextProps: SProps) {
        this.findRoute(nextProps.pathname);
    }

    render() {
        const {classes} = this.props;
        const {generic} = this.state;
        return (
            <>
                <StatusBar title={this.state.routeTitle} />
                <div className={classes.root}>
                    <TabContainerNoTabs>
                        <Switch>
                            <GuardSingleAccessRoute accessSet={['DEVICES_TOTAL', 'DEVICES_VIEW']} exact={true} path="/administration/devices" component={DevicesControl} />
                            <GuardSingleAccessRoute accessSet={['CARDS_TOTAL']} exact={true} path="/administration/cards" component={CardControlConnected} />
                            <GuardSingleAccessRoute accessSet={['AREAS_TOTAL', 'AREAS_VIEW']} exact={true} path="/administration/areas" component={AreasControl} />
                            <GuardSingleAccessRoute accessSet={['CENTERS_TOTAL', 'CENTERS_VIEW']} exact={true} path="/administration/centers" component={CentersControl} />
                            <GuardSingleAccessRoute accessSet={['AREASETS_TOTAL', 'AREASETS_VIEW']} exact={true} path="/administration/areasets" component={AreasetsControl} />
                            <GuardSingleAccessRoute accessSet={['RATES_TOTAL', 'RATES_VIEW']} exact={true} path="/administration/rates" component={RatesControl} />
                            <GuardSingleAccessRoute accessSet={['FESTIVES_TOTAL', 'FESTIVES_VIEW']} exact={true} path="/administration/festives" component={FestivesControl} />
                            <GuardSingleAccessRoute accessSet={['CALCULATE_COST_EXECUTIONS_TOTAL', 'CALCULATE_COST_EXECUTIONS_VIEW']} exact={true} path="/administration/calculate-cost-executions" component={CalculateCostExecutionsControl} />
                            <GuardSingleAccessRoute
                                accessSet={['EMPLOYEE_COSTS_TOTAL', 'EMPLOYEE_COSTS_VIEW']}
                                exact={true}
                                path="/administration/employee-costs"
                                component={EmployeeCostsControl}
                            />
                            <GuardSingleAccessRoute accessSet={['BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW']} exact={true} path="/administration/barguns" component={BargunsControl} />
                            <GuardSingleAccessRoute
                                accessSet={['BARREADERS_TOTAL', 'BARREADERS_VIEW']}
                                exact={true}
                                path="/administration/bar-readers"
                                component={BarReadersControl}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['SCALESDEVICES_TOTAL', 'SCALESDEVICES_VIEW']}
                                exact={true}
                                path="/administration/scales-devices"
                                component={ScalesDevicesControl}
                            />
                            <GuardSingleAccessRoute accessSet={['PTABLES_TOTAL', 'PTABLES_VIEW']} exact={true} path="/administration/ptables" component={PtablesControl} />
                            <GuardSingleAccessRoute
                                accessSet={['DOWNTIMECAUSES_TOTAL', 'DOWNTIMECAUSES_VIEW']}
                                exact={true}
                                path="/administration/causes"
                                component={CausesControl}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['DOWNTIMECATEGORIES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']}
                                exact={true}
                                path="/administration/categories"
                                component={DowntimeCategoriesControl}
                            />
                            <GuardSingleAccessRoute accessSet={['TASKGROUPS_TOTAL', 'TASKGROUPS_VIEW']} exact={true} path="/administration/groups" component={TaskGroupsControl} />
                            <GuardSingleAccessRoute accessSet={['JOBTYPES_TOTAL', 'JOBTYPES_VIEW']} exact={true} path="/administration/jobtypes" component={JobtypesControl} />
                            <GuardSingleAccessRoute accessSet={['NON_PRODUCTIVE_TIMES_TOTAL', 'NON_PRODUCTIVE_TIMES_VIEW']} exact={true} path="/administration/non-productive-times" component={NonProductiveTimesControl} />
                            <GuardSingleAccessRoute
                                // accessSet={['ADMIN, SYSTEM']}
                                exact={true}
                                path="/administration/parameters"
                                component={AppParametersControl}
                            />
                            <GuardSingleAccessRoute
                                // accessSet={['ADMIN, SYSTEM']}
                                exact={true}
                                path="/administration/pallet-definition-cstm-fields"
                                component={PalletDefinitionCstmFieldsControl}
                            />
                            <GuardSingleAccessRoute accessSet={['SYSTEM']} exact={true} path="/administration/parameters/internal" component={InternalParametersControl} />

                            <GuardSingleAccessRoute accessSet={['TASKS_TOTAL', 'TASKS_VIEW']} exact={true} path="/administration/tasks" component={TasksControl} />
                            <GuardSingleAccessRoute accessSet={['BACKUP']} exact={true} path="/administration/backups" component={BackupControl} />
                            <GuardSingleAccessRoute
                                accessSet={['EMPLOYEES_TOTAL', 'EMPLOYEES_VIEW', 'EMPLOYEES_VIEW_EXCEL']}
                                exact={true}
                                path="/administration/employees"
                                component={EmployeesControl}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['ADMIN','LIFT_PALLETS']}
                                exact={true}
                                path="/administration/lift-pallets"
                                component={LiftPalletsControl}
                            />
                        </Switch>
                        {generic && (
                            <div style={{display: 'flex'}}>
                                <WrapLeftList>
                                    <Typography style={{textAlign: 'center'}} variant="h5">
                                        Sección de presencia
                                    </Typography>
                                    <List component="nav">
                                        {IsInRuleset(['EMPLOYEES_TOTAL', 'EMPLOYEES_VIEW', 'EMPLOYEES_VIEW_EXCEL']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/employees');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/employees"
                                            >
                                                <ListItemIcon>
                                                    <PeopleIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de empleados" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset('CARDS_TOTAL') && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/cards');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/cards"
                                            >
                                                <ListItemIcon>
                                                    <CardIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de tarjetas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['TASKS_TOTAL', 'TASKS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/tasks');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/tasks"
                                            >
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de tareas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/areas');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/areas"
                                            >
                                                <ListItemIcon>
                                                    <LocationIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de areas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['CENTERS_TOTAL', 'CENTERS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/centers');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/centers"
                                            >
                                                <ListItemIcon>
                                                    <CenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de centros" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['AREASETS_TOTAL', 'AREASETS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/areasets');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/areasets"
                                            >
                                                <ListItemIcon>
                                                    <LocationIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de grupos de areas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['RATES_TOTAL', 'RATES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/rates');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/rates"
                                            >
                                                <ListItemIcon>
                                                    <LocationIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Tarifas por empleado y jornada" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['FESTIVES_TOTAL', 'FESTIVES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/festives');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/festives"
                                            >
                                                <ListItemIcon>
                                                    <LocationIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de festivos" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['EMPLOYEE_COSTS_TOTAL', 'EMPLOYEE_COSTS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/employee-costs');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/employee-costs"
                                            >
                                                <ListItemIcon>
                                                    <LocationIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de costes de empleados" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['DOWNTIMECAUSES_TOTAL', 'DOWNTIMECAUSES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/causes');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/causes"
                                            >
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de causas de las paradas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['DOWNTIMECATEGORIES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/categories');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/categories"
                                            >
                                                <ListItemIcon>
                                                    <CategoryIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de categorías de las paradas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['TASKGROUPS_TOTAL', 'TASKGROUPS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/groups');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/groups"
                                            >
                                                <ListItemIcon>
                                                    <GroupWorkIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de los grupos de tareas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['JOBTYPES_TOTAL', 'JOBTYPES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/jobtypes');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/jobtypes"
                                            >
                                                <ListItemIcon>
                                                    <WorkIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de los roles de trabajadores" />
                                            </ListItem>
                                        )}
                                    </List>
                                </WrapLeftList>
                                <WrapRightList>
                                    <Typography style={{textAlign: 'center'}} variant="h5">
                                        Ajustes de dispositivos
                                    </Typography>
                                    <List component="nav">
                                        {IsInRuleset(['DEVICES_TOTAL', 'DEVICES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/devices');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/devices"
                                            >
                                                <ListItemIcon>
                                                    <Device />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de dispositivos" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/barguns');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/barguns"
                                            >
                                                <ListItemIcon>
                                                    <BargunIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de bases de pistolas" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['BARREADERS_TOTAL', 'BARREADERS_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/bar-readers');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/bar-readers"
                                            >
                                                <ListItemIcon>
                                                    <FixedScannerIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de lectores fijos" />
                                            </ListItem>
                                        )}
                                        {IsInRuleset(['PTABLES_TOTAL', 'PTABLES_VIEW']) && (
                                            <ListItem
                                                button={true}
                                                component={'a'}
                                                onClick={e => {
                                                    this.props.push('/administration/ptables');
                                                    e.preventDefault();
                                                }}
                                                href="/administration/ptables"
                                            >
                                                <ListItemIcon>
                                                    <TableIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Gestión de mesas de paletizado" />
                                            </ListItem>
                                        )}
                                    </List>
                                </WrapRightList>
                            </div>
                        )}
                    </TabContainerNoTabs>
                </div>
            </>
        );
    }
    private findRoute = (pathname: string = this.props.pathname) => {
        const path = pathname.split('/')[2];
        const routeTitle = this.getTitle(path);
        let generic = false;
        if (routeTitle === 'Administración') generic = true;
        this.setState({routeTitle, generic});
    };

    private getTitle = (path: string) => {
        switch (path) {
            case 'employees':
                return 'Gestión de empleados';
            case 'cards':
                return 'Gestión de tarjetas';
            case 'rates':
                return 'Tarifas por empleado y jornada';
            case 'festives':
                return 'Gestión de festivos';
            case 'employee-costs':
                return 'Gestión de costes de empleados';
            case 'calculate-cost-executions':
                return 'Recálculo de costes bajo demanda';
            case 'tasks':
                return 'Gestión de tareas';
            case 'areas':
                return 'Gestión de áreas';
            case 'areasets':
                return 'Gestión de grupos de áreas';
            case 'centers':
                return 'Gestión de centros';
            case 'devices':
                return 'Gestión de dispositivos';
            case 'parameters':
                return 'Gestión de parámetros del sistema';
            case 'barguns':
                return 'Gestión de bases de pistolas';
            case 'bar-readers':
                return 'Gestión de lectores fijos';
            case 'scales-devices':
                return 'Gestión de básculas';
            case 'ptables':
                return 'Gestión de mesas de paletizado';
            case 'causes':
                return 'Gestión de causas de las paradas';
            case 'categories':
                return 'Gestión de categorías de las paradas';
            case 'non-productive-times':
                return ' Gestión de tiempos no productivos';
            case 'groups':
                return 'Gestión de los grupos de tareas';
            case 'jobtypes':
                return 'Gestión de los roles de trabajadores';
            case 'backups':
                return 'Gestión de las copias de seguridad';
            case 'lift-pallets':
                return 'Remonte de palés';
            case 'pallet-definition-cstm-fields':
                return 'Gestión de campos palés personalizados';
            default:
                return 'Administración';
        }
    };
}

export const AdministrationIndexPage = connect<SProps, DProps, Props, AppStoreState>(
    state => ({
        pathname: RouteHelpers.getLocation(state),
    }),
    {push},
)(withStyles(styles)(AdministrationIndexPageBase));

const WrapLeftList = styled.div`
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    a {
        display: flex;
        text-decoration: inherit;
        color: inherit;
    }
`;

const WrapRightList = styled.div`
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    a {
        display: flex;
        text-decoration: inherit;
        color: inherit;
    }
`;
