import * as React from 'react';
import {connect} from 'react-redux';

import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField, CustomDropdown} from 'src/shared/style';
import {CircularProgress, Typography} from '@material-ui/core';
import {AreaControlServices} from '../administration/areaControl/store/services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {AreaShiftServices} from '../lanemanager/areashift/store/areashift.services';
import AddIcon from '@material-ui/icons/Add';
import {WrapLoaderDivModal} from '../lanemanager/palletInformation/Wrap';
import {Tooltip} from '@material-ui/core';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface Props {
    employeeTaskId: string;
    taskId: string;
    areaId: string;
    centerId: string;
    areaShiftId: string;
    createdAt: string;
    finishedAt: string;
    createdAtDay: string;
    finishedAtDay: string;
    open: boolean;
    centersList: Array<{id: string; name: string}>;
    tasksList: Array<{id: string; name: string}>;
    hidePresenceManagementModal: () => void;
    createActivity: (areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string, dayCreatedAt: string, dayFinishedAt: string, areaShiftId: string) => void;
    editActivity: (employeeTaskId: string, areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string, areaShiftId: string) => void;
}

type State = {
    openAlert: boolean;
    employeeTaskId: string;
    taskId: string;
    areaId: string;
    centerId: string;
    areaShiftId: string;
    createdAt: string;
    finishedAt: string;
    createdAtDay: string;
    finishedAtDay: string;
    areasList: Array<{id: string; name: string}>;
    areaShiftsList: Array<{id: string; name: string}>;
    isLoading: boolean;
    showModalCreateNewAreashift: boolean;
};

const initialState: State = {
    openAlert: false,
    employeeTaskId: '',
    taskId: '',
    areaId: '',
    centerId: '',
    areaShiftId: '',
    createdAt: '',
    finishedAt: '',
    createdAtDay: '',
    finishedAtDay: '',
    areasList: [],
    areaShiftsList: [],
    isLoading: false,
    showModalCreateNewAreashift: false,
};

export class ActivityModal extends React.Component<Props, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({employeeTaskId: nextProps.employeeTaskId});
        this.setState({taskId: nextProps.taskId});
        this.setState({areaId: nextProps.areaId});
        this.setState({areaShiftId: nextProps.areaShiftId});
        this.setState({centerId: nextProps.centerId});
        this.setState({createdAt: nextProps.createdAt});
        this.setState({finishedAt: nextProps.finishedAt});
        this.setState({createdAtDay: nextProps.createdAtDay});
        this.setState({finishedAtDay: nextProps.finishedAtDay});
        if (nextProps.centerId) {
            this.fetchAreas(nextProps.centerId);
        }
        if (nextProps.areaId) {
            this.fetchAreaShifts(nextProps.areaId, nextProps.createdAtDay, nextProps.createdAt);
        }
    }

    private hideModalTable = () => {
        this.props.hidePresenceManagementModal();
    };

    private fetchAreas = async (centerId: string) => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreasByCenter(centerId), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private fetchAreaShifts = async (areaId: string, createdAtDay: any, createdAt: any) => {
        // Comprobación porque si no consulta aunque no se hayan puesto los segundos
        const fullDate = createdAtDay + ' ' + createdAt;
        if (fullDate.length === 19) {
            const areaShiftsList = await fetchWithFeedback(AreaShiftServices.getByAreaAndDate(areaId, createdAtDay + ' ' + createdAt), {
                accessor: 'areaShifts',
            });
            this.setState({areaShiftsList});
        }
    };

    private handleChangeCenter: HandleChange = e => {
        this.setState({centerId: e.target.value});
        this.fetchAreas(e.target.value);
    };

    private handleChangeArea: HandleChange = e => {
        this.setState({areaId: e.target.value});
        if (e.target.value && this.state.createdAtDay && this.state.createdAt) {
            this.fetchAreaShifts(e.target.value, this.state.createdAtDay, this.state.createdAt);
        }
    };

    private handleChangeCreatedAt: HandleChange = e => {
        this.setState({createdAt: e.target.value});
        if (this.state.areaId && this.state.createdAtDay && e.target.value) {
            this.fetchAreaShifts(this.state.areaId, this.state.createdAtDay, e.target.value);
        }
    };

    private handleChangeCreatedAtDay: HandleChange = e => {
        this.setState({createdAtDay: e.target.value});
        if (this.state.areaId && e.target.value && this.state.createdAt) {
            this.fetchAreaShifts(this.state.areaId, e.target.value, this.state.createdAt);
        }
    };

    private createAreashiftAutomatic = async () => {
        const data = {
            area_id: this.state.areaId,
            created_at_employee_task: this.state.createdAtDay + ' ' + this.state.createdAt,
        };
        this.setState({isLoading: true});
        this.forceUpdate();
        const areashift = await fetchWithFeedback(AreaShiftServices.createAutomatic(data), {
            successMessage: 'Turno creado',
            errorMessage: 'No se ha podido crear el turno. Revise que no exista ya',
        });

        if (areashift) {
            this.fetchAreaShifts(this.state.areaId, this.state.createdAtDay, this.state.createdAt);
        }
        this.setState({isLoading: false});
        this.forceUpdate();
    };

    render() {
        const {centersList, tasksList} = this.props;
        const {areasList, areaShiftsList} = this.state;
        return (
            <>
                <TableModal
                    title={this.state.employeeTaskId !== '' ? 'Editar' : 'Crear'}
                    open={this.props.open}
                    handleClose={this.hideModalTable}
                    draggable={true}
                    maxWidth={false}
                >
                    <CustomDialog
                        title="Confirmación"
                        contentText="¿Está seguro que quiere crear un turno automático?"
                        handleClose={() => this.setState({showModalCreateNewAreashift: false})}
                        open={this.state.showModalCreateNewAreashift}
                        handle={() => {
                            this.createAreashiftAutomatic();
                            this.setState({showModalCreateNewAreashift: false});
                        }}
                    />
                    <Typography
                        component="div"
                        style={{
                            width: 700,
                        }}
                    >
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 5}}>
                                <Typography
                                    component="div"
                                    style={{
                                        width: '90%',
                                    }}
                                >
                                    <CustomDropdown
                                        title={'Centro'}
                                        dropdownName={'centers'}
                                        data={centersList || []}
                                        value={this.state.centerId}
                                        margin={'0'}
                                        handleChoose={this.handleChangeCenter}
                                    />
                                </Typography>
                            </div>
                            <div style={{flexGrow: 7, marginRight: '0px'}}>
                                <Typography
                                    component="div"
                                    style={{
                                        width: '90%',
                                    }}
                                >
                                    <CustomDropdown
                                        title={'Área'}
                                        dropdownName={'areas'}
                                        data={areasList || []}
                                        value={this.state.areaId}
                                        margin={'0'}
                                        handleChoose={this.handleChangeArea}
                                    />
                                </Typography>
                            </div>
                        </Typography>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography
                                component="div"
                                style={{
                                    width: '95%',
                                    marginBottom: 5,
                                }}
                            >
                                <CustomDropdown
                                    title={'Tarea'}
                                    dropdownName={'tasks'}
                                    data={tasksList || []}
                                    value={this.state.taskId}
                                    margin={'0'}
                                    handleChoose={e => this.setState({taskId: e.target.value})}
                                />
                            </Typography>
                        </Typography>

                        {this.state.employeeTaskId === '' && (
                            <>
                                <Typography
                                    component="div"
                                    style={{
                                        marginBottom: 10,
                                        marginTop: 5,
                                        width: 500,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <div style={{width: '45%'}}>
                                        <CustomTextField
                                            margin={'0 auto 10px auto'}
                                            width={'100%'}
                                            label="Fecha inicio"
                                            error={false}
                                            value={this.state.createdAtDay}
                                            name="created_at_day"
                                            variant={'outlined'}
                                            type="date"
                                            shrink={true}
                                            onChange={this.handleChangeCreatedAtDay}
                                        />
                                    </div>
                                    <div style={{width: '45%'}}>
                                        <CustomTextField
                                            margin={'0 30px 10px 30px'}
                                            width={'100%'}
                                            label="Fecha Fin"
                                            error={false}
                                            value={this.state.finishedAtDay}
                                            name="finished_at_day"
                                            variant={'outlined'}
                                            type="date"
                                            shrink={true}
                                            onChange={e => this.setState({finishedAtDay: e.target.value})}
                                        />
                                    </div>
                                </Typography>
                            </>
                        )}
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                width: 500,
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <div style={{width: '45%'}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'100%'}
                                    label="Hora inicio"
                                    error={false}
                                    value={this.state.createdAt}
                                    name="created_at"
                                    variant={'outlined'}
                                    type="time"
                                    shrink={true}
                                    onChange={this.handleChangeCreatedAt}
                                />
                            </div>
                            <div style={{width: '45%'}}>
                                <CustomTextField
                                    margin={'0 30px 10px 30px'}
                                    width={'100%'}
                                    label="Hora Fin"
                                    error={false}
                                    value={this.state.finishedAt}
                                    name="finished_at"
                                    variant={'outlined'}
                                    type="time"
                                    shrink={true}
                                    onChange={e => this.setState({finishedAt: e.target.value})}
                                />
                            </div>
                        </Typography>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography
                                component="div"
                                style={{
                                    width: '95%',
                                    marginBottom: 5,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            >
                                {this.state.isLoading && (
                                    <WrapLoaderDivModal>
                                        <CircularProgress style={{color: 'blue', margin: '16px'}} />
                                        <Typography
                                            style={{
                                                padding: '50px',
                                                opacity: '1',
                                            }}
                                        ></Typography>
                                    </WrapLoaderDivModal>
                                )}
                                <div style={{width: '85%'}}>
                                    <CustomDropdown
                                        title={'Turno'}
                                        dropdownName={'areaShifts'}
                                        data={areaShiftsList || []}
                                        value={this.state.areaShiftId}
                                        margin={'0'}
                                        handleChoose={e => this.setState({areaShiftId: e.target.value})}
                                    />
                                </div>
                                <div style={{width: '15%', marginLeft: '10px', marginTop: '20px'}}>
                                    <CustomButton
                                        width={'25%'}
                                        margin={'0px 0px 0px auto'}
                                        onClick={() => {
                                            this.setState({showModalCreateNewAreashift: true});
                                            //this.createAreashiftAutomatic();
                                        }}
                                        padding={'3px'}
                                    >
                                        <Tooltip title={'Crear turno según fecha de inicio del fichaje'} placement="top">
                                            <span>
                                                {'Turno '}
                                                <AddIcon />
                                            </span>
                                        </Tooltip>
                                    </CustomButton>
                                </div>
                            </Typography>
                        </Typography>
                        <div style={{display: 'flex'}}>
                            <CustomButton
                                width={'25%'}
                                margin={'0px 0px 0px auto'}
                                onClick={() => {
                                    if (this.state.employeeTaskId !== '') {
                                        this.props.editActivity(
                                            this.state.employeeTaskId,
                                            this.state.areaId,
                                            this.state.taskId,
                                            this.state.createdAt,
                                            this.state.finishedAt,
                                            this.state.areaShiftId,
                                        );
                                    } else {
                                        this.props.createActivity(
                                            this.state.areaId,
                                            this.state.taskId,
                                            this.state.createdAt,
                                            this.state.finishedAt,
                                            this.state.createdAtDay,
                                            this.state.finishedAtDay,
                                            this.state.areaShiftId,
                                        );
                                    }
                                }}
                                padding={'3px'}
                            >
                                {'Guardar'}
                            </CustomButton>
                        </div>
                    </Typography>
                </TableModal>
            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: !!state.laneSelect.AutoPalletise,
});

export const ActivityModalConnected = connect(mapStateToProps, null)(ActivityModal);
