import * as React from 'react';

import {LaneDowntimeCategory, LaneDowntimeCause} from 'src/app/lanemanager/downtime/store/types';
// ! FIXME: unify all styled components!
import {CustomTextField} from 'src/app/lanemanager/export/components/styled';
import {CustomButton, CustomDropdown} from 'src/shared/style';
import {WrapCreateButton} from 'src/shared/style/Wrap';
import {notify} from 'src/utils/Notification';
import throttle from 'lodash/throttle';

export type DowtimeCreateSProps = {
    categories: LaneDowntimeCategory[];
    causes: LaneDowntimeCause[];
};

type DowntimeCreateProps = {
    handleCreate: (causeId: string, description: string) => void;
};

const initialState = {
    cause: undefined,
    category: undefined,
    description: undefined,
    filteredCauses: {},
};

type State = Readonly<{
    cause?: string;
    category?: string;
    description?: string;
    filteredCauses: FilteredCauses;
}>;

export class DowntimeCreate extends React.Component<DowntimeCreateProps & DowtimeCreateSProps, State> {
    private throttled = throttle((fn: () => any) => fn(), 5000);
    readonly state = initialState;

    componentDidMount() {
        this.setState({filteredCauses: this.filterByCategory()});
    }

    shouldComponentUpdate() {
        return true;
    }

    public render() {
        const {categories} = this.props;
        const {filteredCauses} = this.state;
        return (
            <>
                <CustomDropdown nullEntry={true} data={categories} handleChoose={this.handleChoose} value={this.state.category} dropdownName="category" title="Categoria" />
                <CustomDropdown
                    nullEntry={true}
                    data={this.state.category ? filteredCauses[this.state.category!] : []}
                    handleChoose={this.handleChoose}
                    value={this.state.cause}
                    dropdownName="cause"
                    title="Causa"
                />
                <CustomTextField label={'Descripción'} handleText={this.handleChoose} value={this.state.description} />
                <WrapCreateButton>
                    <CustomButton bgcolor={'#00D4AE'} margin={'auto'} onClick={() => this.throttled(() => this.handleCreateDowntime())}>
                        Crear la parada
                    </CustomButton>
                </WrapCreateButton>
            </>
        );
    }

    private handleCreateDowntime = () => {
        const {cause, description} = this.state;
        if (cause) {
            // @ts-ignore
            this.props.handleCreate(cause, description || '');
            this.setState({description: ''});
            this.forceUpdate();
        } else
            notify({
                message: 'No se puede crear la parada con los campos en blanco',
                status: 'warning',
            });
    };

    private handleChoose = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.name === 'category' || e.target.name === 'cause' || e.target.name === 'description') {
            e.persist();
            if (e.target.name === 'category') {
                if (e.target.value !== this.state.category) {
                    this.setState({cause: undefined});
                }
            }
            this.setState(() => ({[e.target.name]: e.target.value} as any));
        }
    };

    private filterByCategory = () => {
        const {categories, causes} = this.props;
        const obj: FilteredCauses = {};
        if (categories)
            categories.forEach(c => {
                const filteredCauses = causes.filter(cau => cau.category_id === c.id);
                Object.defineProperty(obj, c.id, {
                    value: filteredCauses,
                    enumerable: true,
                });
            });

        return obj;
    };
}

type FilteredCauses = {
    [key: string]: LaneDowntimeCause[];
};
