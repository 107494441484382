// import {GrantRanks} from 'src/global/authorization/normalizeGrants';

export enum GrantGroupsRanks {
    ADMIN = 'ADMIN',
    BACKUP = 'BACKUP',
    EMPLOYEES_TOTAL = 'EMPLOYEES_TOTAL',
    RATES_TOTAL = 'RATES_TOTAL',
    EMPLOYEE_COSTS_TOTAL = 'EMPLOYEE_COSTS_TOTAL',
    FESTIVES_TOTAL = 'FESTIVES_TOTAL',
    CALCULATE_COST_EXECUTIONS_TOTAL = 'CALCULATE_COST_EXECUTIONS_TOTAL',
    DEVICES_TOTAL = 'DEVICES_TOTAL',
    CARDS_TOTAL = 'CARDS_TOTAL',
    CENTERS_TOTAL = 'CENTERS_TOTAL',
    AREASETS_TOTAL = 'AREASETS_TOTAL',
    TASKS_TOTAL = 'TASKS_TOTAL',
    AREAS_TOTAL = 'AREAS_TOTAL',
    DOWNTIMECAUSES_TOTAL = 'DOWNTIMECAUSES_TOTAL',
    DOWNTIMECATEGORIES_TOTAL = 'DOWNTIMECATEGORIES_TOTAL',
    TASKGROUPS_TOTAL = 'TASKGROUPS_TOTAL',
    PTABLES_TOTAL = 'PTABLES_TOTAL',
    JOBTYPES_TOTAL = 'JOBTYPES_TOTAL',
    NON_PRODUCTIVE_TIMES_TOTAL = 'NON_PRODUCTIVE_TIMES_TOTAL',
    BARGUNBASES_TOTAL = 'BARGUNBASES_TOTAL',
    BARREADERS_TOTAL = 'BARREADERS_TOTAL',
    SCALESDEVICES_TOTAL = 'SCALESDEVICES_TOTAL',
    AREASHIFTS_TOTAL = 'AREASHIFTS_TOTAL',
    LANEMANAGER_STATE_TOTAL = 'LANEMANAGER_STATE_TOTAL',
    LANEMANAGER_DOWNTIMES_TOTAL = 'LANEMANAGER_DOWNTIMES_TOTAL',
    LANEMANAGER_PERSONNEL_TOTAL = 'LANEMANAGER_PERSONNEL_TOTAL',
    LANEMANAGER_PRODPARTS_TOTAL = 'LANEMANAGER_PRODPARTS_TOTAL',
    MESSAGES_CREATE = 'MESSAGES_CREATE',
    MESSAGES_VIEW_READ = 'MESSAGES_VIEW_READ',
    LANEMANAGER_COMMON_TOTAL = 'LANEMANAGER_COMMON_TOTAL',
    LANEMANAGER_COMMON_VIEW = 'LANEMANAGER_COMMON_VIEW',
    SYSTEM = 'SYSTEM',
    EMPLOYEES_VIEW = 'EMPLOYEES_VIEW',
    RATES_VIEW = 'RATES_VIEW',
    EMPLOYEE_COSTS_VIEW = 'EMPLOYEE_COSTS_VIEW',
    FESTIVES_VIEW = 'FESTIVES_VIEW',
    CALCULATE_COST_EXECUTIONS_VIEW = 'CALCULATE_COST_EXECUTIONS_VIEW',
    DEVICES_VIEW = 'DEVICES_VIEW',
    CENTERS_VIEW = 'CENTERS_VIEW',
    AREASETS_VIEW = 'AREASETS_VIEW',
    TASKS_VIEW = 'TASKS_VIEW',
    AREAS_VIEW = 'AREAS_VIEW',
    DOWNTIMECAUSES_VIEW = 'DOWNTIMECAUSES_VIEW',
    DOWNTIMECATEGORIES_VIEW = 'DOWNTIMECATEGORIES_VIEW',
    TASKGROUPS_VIEW = 'TASKGROUPS_VIEW',
    PTABLES_VIEW = 'PTABLES_VIEW',
    JOBTYPES_VIEW = 'JOBTYPES_VIEW',
    NON_PRODUCTIVE_TIMES_VIEW = 'NON_PRODUCTIVE_TIMES_VIEW',
    BARGUNBASES_VIEW = 'BARGUNBASES_VIEW',
    BARREADERS_VIEW = 'BARREADERS_VIEW',
    SCALESDEVICES_VIEW = 'SCALESDEVICES_VIEW',
    AREASHIFTS_VIEW = 'AREASHIFTS_VIEW',
    REPORTS_BOXES_VIEW = 'REPORTS_BOXES_VIEW',
    EMPLOYEES_VIEW_EXCEL = 'EMPLOYEES_VIEW_EXCEL',
    AREASHIFTS_VIEW_EXCEL = 'AREASHIFTS_VIEW_EXCEL',
    LIFT_PALLETS = 'LIFT_PALLETS',
    REPORTS_EMPLOYEES_WORKING_VIEW = 'REPORTS_EMPLOYEES_WORKING_VIEW',
    LANEMANAGER_STATE_VIEW = 'LANEMANAGER_STATE_VIEW',
    ERROR_LOG_VIEW = 'ERROR_LOG_VIEW',
    INCIDENCE_LOG_VIEW = 'INCIDENCE_LOG_VIEW',
    LANEMANAGER_PALLETS_VIEW = 'LANEMANAGER_PALLETS_VIEW',
    LANEMANAGER_DOWNTIMES_VIEW = 'LANEMANAGER_DOWNTIMES_VIEW',
    LANEMANAGER_PRODPARTS_VIEW = 'LANEMANAGER_PRODPARTS_VIEW',
    LANEMANAGER_PERSONNEL_VIEW = 'LANEMANAGER_PERSONNEL_VIEW',
    LANEMANAGER_PERFORMANCE_VIEW = 'LANEMANAGER_PERFORMANCE_VIEW',
    REPORTS_TASKS_BY_EMPLOYEE_VIEW = 'REPORTS_TASKS_BY_EMPLOYEE_VIEW',
    REPORTS_TASKS_BY_EMPLOYEE_EXCEL = 'REPORTS_TASKS_BY_EMPLOYEE_EXCEL',
    REPORTS_REGISTERED_TASKS_VIEW = 'REPORTS_REGISTERED_TASKS_VIEW',
    REPORTS_REGISTERED_TASKS_EXCEL = 'REPORTS_REGISTERED_TASKS_EXCEL',
    REPORTS_TASKS_TIME_PER_AREA_VIEW = 'REPORTS_TASKS_TIME_PER_AREA_VIEW',
    REPORTS_TASKS_TIME_PER_AREA_EXCEL = 'REPORTS_TASKS_TIME_PER_AREA_EXCEL',
    REPORTS_WORKSHIFT_TIMES_VIEW = 'REPORTS_WORKSHIFT_TIMES_VIEW',
    REPORTS_WORKSHIFT_TIMES_EXCEL = 'REPORTS_WORKSHIFT_TIMES_EXCEL',
    MESSAGES_VIEW = 'MESSAGES_VIEW',
    REPORTS_AREASHIFTS_VIEW = 'REPORTS_AREASHIFTS_VIEW',
    REPORTS_AREASHIFTS_EXCEL = 'REPORTS_AREASHIFTS_EXCEL',
    LANEMANAGER_DUMPS_VIEW = 'LANEMANAGER_DUMPS_VIEW',
    MESSAGES_PERSONAL = 'MESSAGES_PERSONAL',
    LANEMANAGER_AREASHIFTS_TOTAL = 'LANEMANAGER_AREASHIFTS_TOTAL',
    LANEMANAGER_AREASHIFTS_VIEW = 'LANEMANAGER_AREASHIFTS_VIEW',
    REPORTS_PRODPARTS_VIEW = 'REPORTS_PRODPARTS_VIEW',
    PALLETS_INFORMATION_AND_REGISTRY = 'PALLETS_INFORMATION_AND_REGISTRY',
    PALLETS_INFORMATION_AND_REGISTRY_ERP = 'PALLETS_INFORMATION_AND_REGISTRY_ERP',
    PALLETS_REGISTRY_ERP = 'PALLETS_REGISTRY_ERP',
    PALLET_DEFINITION_CSTM_FIELDS = 'PALLET_DEFINITION_CSTM_FIELDS',
    REPORTS_PALLETS_VIEW = 'REPORTS_PALLETS_VIEW',
    REPORTS_PALLETS_VIEW_EXCEL = 'REPORTS_PALLETS_VIEW_EXCEL',
    REPORTS_PALLETS_INFO_VIEW = 'REPORTS_PALLETS_INFO_VIEW',
    REPORTS_PALLETS_INFO_EXCEL = 'REPORTS_PALLETS_INFO_EXCEL',
    REPORTS_BATCHES_INFO_VIEW = 'REPORTS_BATCHES_INFO_VIEW',
    REPORTS_BATCHES_INFO_EXCEL = 'REPORTS_BATCHES_INFO_EXCEL',
    PRESENCEMANAGEMENT_TOTAL = 'PRESENCEMANAGEMENT_TOTAL',
    PRESENCEMANAGEMENT_VIEW = 'PRESENCEMANAGEMENT_VIEW',
    ALERTS_PERSONAL_ADMIN = 'ALERTS_PERSONAL_ADMIN',
    ALERTS_PERSONAL = 'ALERTS_PERSONAL',
    ALERTS_EXCEEDED_KG_BATCHES = 'ALERTS_EXCEEDED_KG_BATCHES',
    ALERTS_TASK_REGISTERED_BETWEEN_NOT_USUAL = 'ALERTS_TASK_REGISTERED_BETWEEN_NOT_USUAL',
    HIDE_MENU = 'HIDE_MENU',
    SHOW_MENU = 'SHOW_MENU',
    BLUEPRINT_VIEW = 'BLUEPRINT_VIEW',
    BLUEPRINT_KPIS = 'BLUEPRINT_KPIS',
    KPIS_VIEW = 'KPIS_VIEW',
    KPIS_CONTROL_TOTAL = 'KPIS_CONTROL_TOTAL',
    REPORTS_TASKS_TIME_VIEW = 'REPORTS_TASKS_TIME_VIEW',
    SET_INACTIVITY_ALERT_USERS = 'SET_INACTIVITY_ALERT_USERS',
    VIEW_INACTIVITY_ALERT_USERS = 'VIEW_INACTIVITY_ALERT_USERS',
    CREATE_DUMPS_LATER = 'CREATE_DUMPS_LATER',
    GET_ORIGIN_INFO = 'GET_ORIGIN_INFO',
    UPDATE_DUMPS_BOXES = 'CREATE_DUMPS_LATER',
    VIEW_COMPLETED_PALLET_INFO_WINDOW = 'VIEW_COMPLETED_PALLET_INFO_WINDOW',
    UPDATE_PALLET_PRODPART = 'UPDATE_PALLET_PRODPART',
    UPDATE_PALLET_MERCHANDISE = 'UPDATE_PALLET_MERCHANDISE',
    UPDATE_PALLET_TYPE = 'UPDATE_PALLET_TYPE',
    UPDATE_PALLET_PACKAGING = 'UPDATE_PALLET_PACKAGING',
    PALLET_SUBPALLET_TOTAL = 'PALLET_SUBPALLET_TOTAL',
    UPDATE_SUBPALLET_MERCHANDISE = 'UPDATE_SUBPALLET_MERCHANDISE',
    UPDATE_SUBPALLET_TYPE = 'UPDATE_SUBPALLET_TYPE',
    UPDATE_SUBPALLET_PACKAGING = 'UPDATE_SUBPALLET_PACKAGING',
    UPDATE_SUBPALLET_KG_BOX_EDIT = 'UPDATE_SUBPALLET_KG_BOX_EDIT',
}

export enum GrantSameNames {
    ADMIN = 'ADMIN',
    SYSTEM = 'SYSTEM',
    IDENTIFY = 'IDENTIFY',
    REGISTER_TASK = 'REGISTER_TASK',
    FINALIZE_ACTIVE_TASK_FROM_TERMINAL = 'FINALIZE_ACTIVE_TASK_FROM_TERMINAL',
    VIEW_REGISTERED_TASKS = 'VIEW_REGISTERED_TASKS',
    FINISH_TASK_FROM_WEB_INTERFACE = 'FINISH_TASK_FROM_WEB_INTERFACE',
    CHANGE_TO_TASK = 'CHANGE_TO_TASK',
    VIEW_REGISTERED_TASKS_PER_AREA_TODAY = 'VIEW_REGISTERED_TASKS_PER_AREA_TODAY',
    VIEW_DOWNTIMES = 'VIEW_DOWNTIMES',
    CREATE_AREA_DOWNTIME_PREFLIGHT = 'CREATE_AREA_DOWNTIME_PREFLIGHT',
    VIEW_AREA_DOWNTIMES = 'VIEW_AREA_DOWNTIMES',
    CREATE_AREA_DOWNTIME = 'CREATE_AREA_DOWNTIME',
    CONTINUE_AREA_DOWNTIME = 'CONTINUE_AREA_DOWNTIME',
    ASSIGN_CARDS_PREFLIGHT = 'ASSIGN_CARDS_PREFLIGHT',
    VIEW_ASSIGNED_CARDS = 'VIEW_ASSIGNED_CARDS',
    ASSIGN_CARDS_VIEW_REGISTERED_CARDS = 'ASSIGN_CARDS_VIEW_REGISTERED_CARDS',
    ASSIGN_CARDS_DEVICE_ENABLE = 'ASSIGN_CARDS_DEVICE_ENABLE',
    ASSIGN_CARDS_DEVICE_DISABLE = 'ASSIGN_CARDS_DEVICE_DISABLE',
    ASSIGN_CARDS_DEVICE_STATE_BY_ID = 'ASSIGN_CARDS_DEVICE_STATE_BY_ID',
    ASSIGN_CARDS_REGISTER = 'ASSIGN_CARDS_REGISTER',
    ASSIGN_CARDS_ASSIGN_TO_EMPLOYEE = 'ASSIGN_CARDS_ASSIGN_TO_EMPLOYEE',
    VIEW_BATCHES = 'VIEW_BATCHES',
    VIEW_DUMP_BY_ID = 'VIEW_DUMP_BY_ID',
    VIEW_PRODPARTS = 'VIEW_PRODPARTS',
    VIEW_PALLETS = 'VIEW_PALLETS',
    VIEW_BOXES = 'VIEW_BOXES',
    VIEW_BOXES_BARGUN = 'VIEW_BOXES_BARGUN',
    VIEW_PRODPART_BY_ID = 'VIEW_PRODPART_BY_ID',
    VIEW_PRODPART_PTABLES = 'VIEW_PRODPART_PTABLES',
    VIEW_PRODPARTS_BY_AREA = 'VIEW_PRODPARTS_BY_AREA',
    VIEW_PTABLES_BY_AREA = 'VIEW_PTABLES_BY_AREA',
    VIEW_EMPLOYEES_WORKING = 'VIEW_EMPLOYEES_WORKING',
    VIEW_EMPLOYEES_WORKING_AREA = 'VIEW_EMPLOYEES_WORKING_AREA',
    VIEW_EMPLOYEES_WORKING_OLD = 'VIEW_EMPLOYEES_WORKING_OLD',
    VIEW_REMAINING_BOXES = 'VIEW_REMAINING_BOXES',
    VIEW_DUMPS = 'VIEW_DUMPS',
    VIEW_DUMPS_BY_AREA = 'VIEW_DUMPS_BY_AREA',
    VIEW_DUMP_BY_AREA_CURRENT = 'VIEW_DUMP_BY_AREA_CURRENT',
    VIEW_DUMP_TOTAL_BOXES = 'VIEW_DUMP_TOTAL_BOXES',
    PTABLE_ASSIGN = 'PTABLE_ASSIGN',
    PTABLE_UNASSIGN = 'PTABLE_UNASSIGN',
    PTABLE_RESERVE = 'PTABLE_RESERVE',
    PTABLE_UNRESERVE = 'PTABLE_UNRESERVE',
    PALLET_START = 'PALLET_START',
    PALLET_FINISH = 'PALLET_FINISH',
    BOX_REGISTER = 'BOX_REGISTER',
    DUMP_NEW = 'DUMP_NEW',
    DUMP_MODIFY = 'DUMP_MODIFY',
    REGISTERED_TASKS = 'REGISTERED_TASKS',
    REGISTERED_TASKS_EXCEL = 'REGISTERED_TASKS_EXCEL',
    TASKS_BY_EMPLOYEE = 'TASKS_BY_EMPLOYEE',
    TASKS_BY_EMPLOYEE_EXCEL = 'TASKS_BY_EMPLOYEE_EXCEL',
    WORKSHIFT_TIMES = 'WORKSHIFT_TIMES',
    WORKSHIFT_TIMES_EXCEL = 'WORKSHIFT_TIMES_EXCEL',
    TASKS_TIME_PER_AREA = 'TASKS_TIME_PER_AREA',
    TASKS_TIME_PER_AREA_EXCEL = 'TASKS_TIME_PER_AREA_EXCEL',
    CREATE_EMPLOYEES = 'CREATE_EMPLOYEES',
    VIEW_EMPLOYEES = 'VIEW_EMPLOYEES',
    EDIT_EMPLOYEES = 'EDIT_EMPLOYEES',
    DELETE_EMPLOYEES = 'DELETE_EMPLOYEES',
    VIEW_FOTOS = 'VIEW_FOTOS',
    UPLOAD_EXCEL = 'UPLOAD_EXCEL',
    VIEW_EMPLOYEES_EXCEL = 'VIEW_EMPLOYEES_EXCEL',
    EMPLOYEES_TOGGLE_TO_JOBTYPE = 'EMPLOYEES_TOGGLE_TO_JOBTYPE',
    DETTACH_MASS_FROM_JOBTYPE = 'DETTACH_MASS_FROM_JOBTYPE',
    ATTACH_MASS_TO_JOBTYPE = 'ATTACH_MASS_TO_JOBTYPE',
    CREATE_JOBTYPES = 'CREATE_JOBTYPES',
    VIEW_JOBTYPES = 'VIEW_JOBTYPES',
    EDIT_JOBTYPES = 'EDIT_JOBTYPES',
    DELETE_JOBTYPES = 'DELETE_JOBTYPES',
    VIEW_JOBTYPES_BY_EMPLOYEE = 'VIEW_JOBTYPES_BY_EMPLOYEE',
    JOBTYPE_TOGGLE_TO_TASK = 'JOBTYPE_TOGGLE_TO_TASK',
    CREATE_TASKS = 'CREATE_TASKS',
    VIEW_TASKS = 'VIEW_TASKS',
    EDIT_TASKS = 'EDIT_TASKS',
    DELETE_TASKS = 'DELETE_TASKS',
    VIEW_JOBTYPE_TASKS = 'VIEW_JOBTYPE_TASKS',
    VIEW_TASKGROUP_TASKS = 'VIEW_TASKGROUP_TASKS',
    VIEW_TASKGROUPS_BY_AREA = 'VIEW_TASKGROUPS_BY_AREA',
    TASKGROUP_TOGGLE_TO_TASK = 'TASKGROUP_TOGGLE_TO_TASK',
    CREATE_TASKGROUPS = 'CREATE_TASKGROUPS',
    VIEW_TASKGROUPS = 'VIEW_TASKGROUPS',
    EDIT_TASKGROUPS = 'EDIT_TASKGROUPS',
    DELETE_TASKGROUPS = 'DELETE_TASKGROUPS',
    CREATE_AREAS = 'CREATE_AREAS',
    VIEW_AREAS = 'VIEW_AREAS',
    EDIT_AREAS = 'EDIT_AREAS',
    DELETE_AREAS = 'DELETE_AREAS',
    AREA_TOGGLE_TO_TASKGROUP = 'AREA_TOGGLE_TO_TASKGROUP',
    CREATE_PTABLES = 'CREATE_PTABLES',
    VIEW_PTABLES = 'VIEW_PTABLES',
    EDIT_PTABLES = 'EDIT_PTABLES',
    DELETE_PTABLES = 'DELETE_PTABLES',
    CREATE_DOWNTIMECAUSES = 'CREATE_DOWNTIMECAUSES',
    VIEW_DOWNTIMECAUSES = 'VIEW_DOWNTIMECAUSES',
    EDIT_DOWNTIMECAUSES = 'EDIT_DOWNTIMECAUSES',
    DELETE_DOWNTIMECAUSES = 'DELETE_DOWNTIMECAUSES',
    CREATE_DOWNTIMECAUSES_PREFLIGHT = 'CREATE_DOWNTIMECAUSES_PREFLIGHT',
    DOWNTIMECAUSE_TOGGLE_TO_AREA = 'DOWNTIMECAUSE_TOGGLE_TO_AREA',
    DOWNTIMECAUSE_TOGGLE_TO_TASK = 'DOWNTIMECAUSE_TOGGLE_TO_TASK',
    CREATE_DOWNTIMECATEGORIES = 'CREATE_DOWNTIMECATEGORIES',
    VIEW_DOWNTIMECATEGORIES = 'VIEW_DOWNTIMECATEGORIES',
    EDIT_DOWNTIMECATEGORIES = 'EDIT_DOWNTIMECATEGORIES',
    DELETE_DOWNTIMECATEGORIES = 'DELETE_DOWNTIMECATEGORIES',
    CREATE_CENTERS = 'CREATE_CENTERS',
    VIEW_CENTERS = 'VIEW_CENTERS',
    EDIT_CENTERS = 'EDIT_CENTERS',
    DELETE_CENTERS = 'DELETE_CENTERS',
    CREATE_AREASETS = 'CREATE_AREASETS',
    VIEW_AREASETS = 'VIEW_AREASETS',
    EDIT_AREASETS = 'EDIT_AREASETS',
    CREATE_DEVICES = 'CREATE_DEVICES',
    VIEW_DEVICES = 'VIEW_DEVICES',
    EDIT_DEVICES = 'EDIT_DEVICES',
    DELETE_DEVICES = 'DELETE_DEVICES',
    VIEW_SINGLE_DEVICE = 'VIEW_SINGLE_DEVICE',
    VIEW_DEVICES_PREFIXES = 'VIEW_DEVICES_PREFIXES',
    DEVICES_REGISTER = 'DEVICES_REGISTER',
    VIEW_AREASHIFTS = 'VIEW_AREASHIFTS',
    CREATE_AREASHIFTS = 'CREATE_AREASHIFTS',
    VIEW_SINGLE_AREASHIFT = 'VIEW_SINGLE_AREASHIFT',
    VIEW_AREASHIFTS_BY_AREA = 'VIEW_AREASHIFTS_BY_AREA',
    VIEW_AREASHIFT_BY_AREA_CURRENT = 'VIEW_AREASHIFT_BY_AREA_CURRENT',
    AREASHIFT_FINISH = 'AREASHIFT_FINISH',
    VIEW_AREASHIFTS_EXCEL = 'VIEW_AREASHIFTS_EXCEL',
    CREATE_BARGUNBASES = 'CREATE_BARGUNBASES',
    VIEW_BARGUNBASES = 'VIEW_BARGUNBASES',
    EDIT_BARGUNBASES = 'EDIT_BARGUNBASES',
    DELETE_BARGUNBASES = 'DELETE_BARGUNBASES',
    BARGUNBASES_TYPES = 'BARGUNBASES_TYPES',
    CREATE_BARREADERS = 'CREATE_BARREADERS',
    VIEW_BARREADERS = 'VIEW_BARREADERS',
    EDIT_BARREADERS = 'EDIT_BARREADERS',
    DELETE_BARREADERS = 'DELETE_BARREADERS',
    BARREADERS_TYPES = 'BARREADERS_TYPES',
    VIEW_MESSAGES = 'VIEW_MESSAGES',
    CREATE_MESSAGES = 'CREATE_MESSAGES',
    VIEW_MESSAGES_OF_EMPLOYEE = 'VIEW_MESSAGES_OF_EMPLOYEE',
    READ_MESSAGES = 'READ_MESSAGES',
    VIEW_ERRORS = 'VIEW_ERRORS',
    CREATE_ERRORS = 'CREATE_ERRORS',
    VIEW_INCIDENCES = 'VIEW_INCIDENCES',
    VIEW_TABLES = 'VIEW_TABLES',
    DOWNLOAD_TABLES = 'DOWNLOAD_TABLES',
    VIEW_PALLET_DETAILED_INFO = 'VIEW_PALLET_DETAILED_INFO',
    CHANGE_PALLET_LOGISTICUNIT = 'CHANGE_PALLET_LOGISTICUNIT',
    VIEW_PALLET_SIMPLE_INFO = 'VIEW_PALLET_SIMPLE_INFO',
    ENQUEUE_PALLET = 'ENQUEUE_PALLET',
    SET_USERS_RECEIVE_INACTIVITY_ALERTS = 'SET_USERS_RECEIVE_INACTIVITY_ALERTS',
    VIEW_USERS_RECEIVE_INACTIVITY_ALERTS = 'VIEW_USERS_RECEIVE_INACTIVITY_ALERTS',
    CREATE_DUMPS_LATER = 'CREATE_DUMPS_LATER',
    GET_ORIGIN_INFO = 'GET_ORIGIN_INFO',
    UPDATE_DUMPS_BOXES = 'CREATE_DUMPS_LATER',
    GET_PALLET_COMPLETED_INFO = 'GET_PALLET_COMPLETED_INFO',
    GET_PALLET_TYPE_LIST_FROM_ERP = 'GET_PALLET_TYPE_LIST_FROM_ERP',
    GET_MERCHANDISE_LIST_FROM_ERP = 'GET_MERCHANDISE_LIST_FROM_ERP',
    GET_PACKAGING_LIST_FROM_ERP = 'GET_PACKAGING_LIST_FROM_ERP',
    UPDATE_PALLET_PRODPART = 'UPDATE_PALLET_PRODPART',
    UPDATE_PALLET_MERCHANDISE = 'UPDATE_PALLET_MERCHANDISE',
    UPDATE_PALLET_PACKAGING = 'UPDATE_PALLET_PACKAGING',
    UPDATE_PALLET_TYPE = 'UPDATE_PALLET_TYPE',
    UPDATE_PALLET_CURRENT_SUBPALLET = 'UPDATE_PALLET_CURRENT_SUBPALLET',
    ADD_PALLET_SUBPALLET = 'ADD_PALLET_SUBPALLET',
    DELETE_PALLET_SUBPALLET = 'DELETE_PALLET_SUBPALLET',
    UPDATE_SUBPALLET_MERCHANDISE = 'UPDATE_PALLET_MERCHANDISE',
    UPDATE_SUBPALLET_PACKAGING = 'UPDATE_PALLET_PACKAGING',
    UPDATE_SUBPALLET_TYPE = 'UPDATE_PALLET_TYPE',
    UPDATE_SUBPALLET_KG_BOX_EDIT = 'UPDATE_SUBPALLET_KG_BOX_EDIT',
}

// export const RANKED: Array<{
//   [k: string]: GrantSameNames[] | string;
//   category: string;
//   name: string;
// }> = [
//   {[GrantGroupsRanks.ADMIN]: [GrantSameNames.ADMIN], category: 'Admin', name: 'Admin'},
//   {[GrantGroupsRanks.SEYTE]: [GrantSameNames.SEYTE], category: 'Seyte', name: 'Seyte'},
//   {
//     [GrantGroupsRanks.EMPLOYEES_TOTAL]: [
//       GrantSameNames.CREATE_EMPLOYEES,
//       GrantSameNames.VIEW_EMPLOYEES,
//       GrantSameNames.EDIT_EMPLOYEES,
//       GrantSameNames.DELETE_EMPLOYEES,
//       GrantSameNames.VIEW_FOTOS,
//       GrantSameNames.UPLOAD_EXCEL,
//       GrantSameNames.VIEW_EMPLOYEES_EXCEL,
//       GrantSameNames.EMPLOYEES_TOGGLE_TO_JOBTYPE,
//       GrantSameNames.DETTACH_MASS_FROM_JOBTYPE,
//       GrantSameNames.ATTACH_MASS_TO_JOBTYPE,
//     ],
//     category: 'Empleados',
//     name: 'Control Total',
//   },
//   {
//     [GrantGroupsRanks.EMPLOYEES_VIEW_EXCEL]: [
//       GrantSameNames.VIEW_EMPLOYEES,
//       GrantSameNames.VIEW_FOTOS,
//       GrantSameNames.VIEW_EMPLOYEES_EXCEL,
//     ],
//     category: 'Empleados',
//     name: 'Ver con Excel',
//   },
//   {
//     [GrantGroupsRanks.EMPLOYEES_VIEW]: [
//       GrantSameNames.VIEW_EMPLOYEES,
//       GrantSameNames.VIEW_FOTOS,
//     ],
//     category: 'Empleados',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.AREASHIFTS_TOTAL]: [
//       GrantSameNames.VIEW_AREASHIFTS,
//       GrantSameNames.CREATE_AREASHIFTS,
//       GrantSameNames.VIEW_SINGLE_AREASHIFT,
//       GrantSameNames.AREASHIFT_FINISH,
//       GrantSameNames.VIEW_AREASHIFTS_EXCEL,
//     ],
//     category: 'Turnos',
//     name: 'Control total',
//   },

//   {
//     [GrantGroupsRanks.AREASHIFTS_VIEW_EXCEL]: [
//       GrantSameNames.VIEW_AREASHIFTS,
//       GrantSameNames.VIEW_AREASHIFTS_EXCEL,
//     ],
//     category: 'Turnos',
//     name: 'Ver con excel',
//   },
//   {
//     [GrantGroupsRanks.AREASHIFTS_VIEW]: [GrantSameNames.VIEW_AREASHIFTS],
//     category: 'Turnos',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.AREAS_TOTAL]: [
//       GrantSameNames.VIEW_AREAS,
//       GrantSameNames.CREATE_AREAS,
//       GrantSameNames.DELETE_AREAS,
//       GrantSameNames.EDIT_AREAS,
//       GrantSameNames.AREA_TOGGLE_TO_TASKGROUP,
//     ],
//     category: 'Áreas',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.AREAS_VIEW]: [GrantSameNames.VIEW_AREAS],
//     category: 'Áreas',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.DEVICES_TOTAL]: [
//       GrantSameNames.VIEW_DEVICES,
//       GrantSameNames.DELETE_DEVICES,
//       GrantSameNames.CREATE_DEVICES,
//       GrantSameNames.PATCH_DEVICES,
//       GrantSameNames.VIEW_SINGLE_DEVICE,
//       GrantSameNames.PREFIXES,
//       GrantSameNames.REGISTER,
//     ],
//     category: 'Dispositivos',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.DEVICES_VIEW]: [
//       GrantSameNames.VIEW_DEVICES,
//       GrantSameNames.VIEW_SINGLE_DEVICE,
//     ],
//     category: 'Dispositivos',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.TASKGROUPS_TOTAL]: [
//       GrantSameNames.CREATE_TASKGROUPS,
//       GrantSameNames.DELETE_TASKGROUPS,
//       GrantSameNames.EDIT_TASKGROUPS,
//       GrantSameNames.VIEW_TASKGROUPS,
//       GrantSameNames.VIEW_TASKGROUP_TASKS,
//     ],
//     category: 'Grupos de tareas',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.TASKGROUPS_VIEW]: [
//       GrantSameNames.VIEW_TASKGROUPS,
//       GrantSameNames.VIEW_TASKGROUP_TASKS,
//     ],
//     category: 'Grupos de tareas',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.JOBTYPES_TOTAL]: [
//       GrantSameNames.CREATE_JOBTYPES,
//       GrantSameNames.EDIT_JOBTYPES,
//       GrantSameNames.VIEW_JOBTYPES,
//       GrantSameNames.VIEW_JOBTYPE_TASKS,
//       GrantSameNames.VIEW_JOBTYPES_BY_EMPLOYEE,
//       GrantSameNames.JOBTYPE_TOGGLE_TO_TASK,
//     ],
//     category: 'Roles de trabajadores',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.JOBTYPES_VIEW]: [
//       GrantSameNames.VIEW_JOBTYPES,
//       GrantSameNames.VIEW_JOBTYPE_TASKS,
//     ],
//     category: 'Roles de trabajadores',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.CENTERS_TOTAL]: [
//       GrantSameNames.EDIT_CENTERS,
//       GrantSameNames.DELETE_CENTERS,
//       GrantSameNames.EDIT_CENTERS,
//       GrantSameNames.VIEW_CENTERS,
//     ],
//     category: 'Centros',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.CENTERS_VIEW]: [GrantSameNames.VIEW_CENTERS],
//     category: 'Centros',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.CARDS_TOTAL]: [
//       GrantSameNames.ASSIGN_CARDS,
//       GrantSameNames.VIEW_EMPLOYEES,
//     ],
//     category: 'Tarjetas',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.DOWNTIMECAUSE_TOTAL]: [
//       GrantSameNames.DELETE_DOWNTIME_CAUSES,
//       GrantSameNames.CREATE_DOWNTIME_CAUSES,
//       GrantSameNames.VIEW_DOWNTIME_CAUSES,
//       GrantSameNames.EDIT_DOWNTIME_CAUSES,
//       GrantSameNames.VIEW_AREAS,
//       GrantSameNames.VIEW_TASKS,
//     ],
//     category: 'Causas de parada',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.DOWNTIMECAUSE_VIEW]: [
//       GrantSameNames.VIEW_DOWNTIME_CAUSES,
//       GrantSameNames.VIEW_AREAS,
//       GrantSameNames.VIEW_TASKS,
//     ],
//     category: 'Causas de parada',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.DOWNTIMECATEGORIES_TOTAL]: [
//       GrantSameNames.VIEW_DOWNTIME_CATEGORIES,
//       GrantSameNames.CREATE_DOWNTIME_CATEGORIES,
//       GrantSameNames.PATCH_DOWNTIME_CATEGORIES,
//       GrantSameNames.DELETE_DOWNTIME_CATEGORIES,
//     ],
//     category: 'Categorías de parada',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.DOWNTIMECATEGORIES_VIEW]: [GrantSameNames.VIEW_DOWNTIME_CATEGORIES],
//     category: 'Categorías de parada',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.BARGUNBASES_TOTAL]: [
//       GrantSameNames.BARGUNBASES_TYPES,
//       GrantSameNames.EDIT_BARGUNBASES,
//       GrantSameNames.CREATE_BARGUNBASES,
//       GrantSameNames.VIEW_BARGUNBASES,
//       GrantSameNames.DELETE_BARGUNBASES,
//     ],
//     category: 'Bases de pistolas',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.BARGUNBASES_VIEW]: [GrantSameNames.VIEW_BARGUNBASES],
//     category: 'Bases de pistolas',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.BARREADERS_TOTAL]: [
//       GrantSameNames.BARREADERS_TYPES,
//       GrantSameNames.CREATE_BARREADERS,
//       GrantSameNames.DELETE_BARREADERS,
//       GrantSameNames.EDIT_BARREADERS,
//       GrantSameNames.VIEW_BARREADERS,
//     ],
//     category: 'Lectores fijos',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.BARREADERS_VIEW]: [GrantSameNames.VIEW_BARREADERS],
//     category: 'Lectores fijos',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.PTABLES_TOTAL]: [
//       GrantSameNames.CREATE_PTABLES,
//       GrantSameNames.DELETE_PTABLES,
//       GrantSameNames.EDIT_PTABLES,
//       GrantSameNames.VIEW_PTABLES,
//       GrantSameNames.VIEW_AREAS,
//     ],
//     category: 'Mesas de paletizado',
//     name: 'Control total',
//   },
//   {
//     [GrantGroupsRanks.PTABLES_VIEW]: [GrantSameNames.VIEW_PTABLES],
//     category: 'Mesas de paletizado',
//     name: 'Ver',
//   },
//   {
//     [GrantGroupsRanks.MESSAGES_CREATE]: [
//       GrantSameNames.CREATE_MESSAGES,
//       GrantSameNames.VIEW_EMPLOYEES,
//     ],
//     category: 'Mensajes',
//     name: 'Crear mensajes',
//   },
//   {
//     [GrantGroupsRanks.MESSAGES_PERSONAL]: [
//       GrantSameNames.VIEW_MESSAGES_OF_EMPLOYEE,
//       GrantSameNames.READ_MESSAGES,
//     ],
//     category: 'Mensajes',
//     name: 'Leer y ver propios',
//   },
//   {
//     [GrantGroupsRanks.MESSAGES_VIEW]: [GrantSameNames.VIEW_MESSAGES],
//     category: 'Mensajes',
//     name: 'Ver todos',
//   },
//   {
//     [GrantGroupsRanks.MESSAGES_VIEW_READ]: [
//       GrantSameNames.VIEW_MESSAGES,
//       GrantSameNames.READ_MESSAGES,
//     ],
//     category: 'Mensajes',
//     name: 'Leer y ver todos',
//   },
//   {
//     [GrantGroupsRanks.ERROR_LOG_VIEW]: [GrantSameNames.VIEW_ERRORS],
//     category: 'Errores e Incidencias',
//     name: 'Ver errores',
//   },
//   {
//     [GrantGroupsRanks.INCIDENCE_LOG_VIEW]: [GrantSameNames.VIEW_INCIDENCES],
//     category: 'Errores e Incidencias',
//     name: 'Ver incidencias',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_EMPLOYEES_WORKING_VIEW]: [
//       GrantSameNames.VIEW_EMPLOYEES_WORKING,
//     ],
//     category: 'Reportes',
//     name: 'Ver empleados trabajando actualmente',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_REGISTERED_TASKS_VIEW]: [GrantSameNames.REGISTERED_TASKS],
//     category: 'Reportes',
//     name: 'Ver tareas registradas',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_REGISTERED_TASKS_EXCEL]: [
//       GrantSameNames.REGISTERED_TASKS_EXCEL,
//       GrantSameNames.REGISTERED_TASKS,
//     ],
//     category: 'Reportes',
//     name: 'Ver tareas registradas con excel',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_TASKS_BY_EMPLOYEE_VIEW]: [GrantSameNames.TASKS_BY_EMPLOYEE],

//     category: 'Reportes',
//     name: 'Ver tareas por empleado',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_TASKS_BY_EMPLOYEE_EXCEL]: [
//       GrantSameNames.TASKS_BY_EMPLOYEE,
//       GrantSameNames.TASKS_BY_EMPLOYEE_EXCEL,
//     ],
//     category: 'Reportes',
//     name: 'Ver tareas por empleado con excel',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_TASKS_TIME_PER_AREA_VIEW]: [
//       GrantSameNames.TASKS_TIME_PER_AREA,
//     ],
//     category: 'Reportes',
//     name: 'Ver tareas por área',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_TASKS_TIME_PER_AREA_EXCEL]: [
//       GrantSameNames.TASKS_TIME_PER_AREA,
//       GrantSameNames.TASKS_TIME_PER_AREA_EXCEL,
//     ],
//     category: 'Reportes',
//     name: 'Ver tareas por área con excel',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_WORKSHIFT_TIMES_VIEW]: [GrantSameNames.WORKSHIFT_TIMES],
//     category: 'Reportes',
//     name: 'Ver tiempos por turno',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_WORKSHIFT_TIMES_EXCEL]: [
//       GrantSameNames.WORKSHIFT_TIMES,
//       GrantSameNames.WORKSHIFT_TIMES_EXCEL,
//     ],
//     category: 'Reportes',
//     name: 'Ver tiempos por turno con excel',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_AREASHIFTS_VIEW]: [GrantSameNames.VIEW_AREASHIFTS],
//     category: 'Reportes',
//     name: 'Ver turnos',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_AREASHIFTS_EXCEL]: [
//       GrantSameNames.VIEW_AREASHIFTS,
//       GrantSameNames.VIEW_AREASHIFTS_EXCEL,
//     ],
//     category: 'Reportes',
//     name: 'Ver turnos con excel',
//   },
//   {
//     [GrantGroupsRanks.REPORTS_BOXES_VIEW]: [GrantSameNames.VIEW_BOXES],
//     category: 'Reportes',
//     name: 'Ver cajas',
//   },
//   {
//     [GrantGroupsRanks.LANEMANAGER_COMMON_TOTAL]: [
//       GrantSameNames.VIEW_AREAS,
//       GrantSameNames.VIEW_BOXES,
//       GrantSameNames.AREASHIFT_FINISH,
//       GrantSameNames.CREATE_AREASHIFTS,
//       GrantSameNames.VIEW_AREASHIFTS_BY_AREA,
//       GrantSameNames.VIEW_SINGLE_AREASHIFT,
//       GrantSameNames.VIEW_CURRENT_AREASHIFT,
//       GrantSameNames.VIEW_PALLETS,
//       GrantSameNames.VIEW_PRODPARTS,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//       GrantSameNames.CREATE_AREA_DOWNTIME,
//       GrantSameNames.CONTINUE_AREA_DOWNTIME,
//       GrantSameNames.VIEW_AREA_DOWNTIMES,
//       GrantSameNames.VIEW_DUMPS,
//       GrantSameNames.CONTROL_PTABLE,
//       GrantSameNames.VIEW_EMPLOYEES_WORKING,
//       GrantSameNames.FINISH_TASK_FROM_WEB_INTERFACE,
//       GrantSameNames.CHANGE_TO_TASK,
//       GrantSameNames.VIEW_TASKS,
//     ],
//     category: 'Jefe de línea',
//     name: 'Opciones comunes con control total',
//   },
//   {
//     [GrantGroupsRanks.LANEMANAGER_COMMON_VIEW]: [
//       GrantSameNames.VIEW_AREAS,
//       GrantSameNames.VIEW_BOXES,
//       GrantSameNames.VIEW_AREASHIFTS_BY_AREA,
//       GrantSameNames.VIEW_SINGLE_AREASHIFT,
//       GrantSameNames.VIEW_CURRENT_AREASHIFT,
//       GrantSameNames.VIEW_PALLETS,
//       GrantSameNames.VIEW_PRODPARTS,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//       GrantSameNames.VIEW_AREA_DOWNTIMES,
//       GrantSameNames.VIEW_DUMPS,
//       GrantSameNames.VIEW_EMPLOYEES_WORKING,
//       GrantSameNames.VIEW_EMPLOYEES_WORKING_AREA,
//     ],
//     category: 'Jefe de línea',
//     name: 'Opciones comunes sólo para ver',
//   },
//   {
//     [GrantGroupsRanks.LANE_DUMPS_VIEW]: [GrantSameNames.VIEW_DUMPS],
//     category: 'Jefe de línea',
//     name: 'Ver volcados',
//   },
//   {
//     [GrantGroupsRanks.LANE_PRODPARTS_VIEW]: [
//       GrantSameNames.VIEW_PRODPARTS,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//     ],
//     category: 'Jefe de línea',
//     name: 'Ver partes de producción',
//   },
//   {
//     [GrantGroupsRanks.LANE_PRODPARTS_TOTAL]: [
//       GrantSameNames.VIEW_PRODPARTS,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//       GrantSameNames.CONTROL_PTABLE,
//       GrantSameNames.VIEW_PTABLES,
//     ],
//     category: 'Jefe de línea',
//     name: 'Control total partes de producción',
//   },
//   {
//     [GrantGroupsRanks.LANE_STATE_VIEW]: [
//       GrantSameNames.VIEW_DUMPS,
//       GrantSameNames.VIEW_PRODPARTS,
//       GrantSameNames.VIEW_PTABLES,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//     ],
//     category: 'Jefe de línea',
//     name: 'Ver estado de la línea',
//   },
//   {
//     [GrantGroupsRanks.LANE_STATE_TOTAL]: [
//       GrantSameNames.VIEW_DUMPS,
//       GrantSameNames.VIEW_PRODPARTS,
//       GrantSameNames.VIEW_PTABLES,
//       // GrantSameNames.VIEW_PRODPART_PTABLES,
//       GrantSameNames.CONTROL_PTABLE,
//     ],
//     category: 'Jefe de línea',
//     name: 'Control total de estado de la línea',
//   },
//   {
//     [GrantGroupsRanks.LANE_PERSONNEL_VIEW]: [GrantSameNames.VIEW_EMPLOYEES_WORKING_AREA],
//     category: 'Jefe de línea',
//     name: 'Ver personal',
//   },
//   {
//     [GrantGroupsRanks.LANE_PALLETS_VIEW]: [GrantSameNames.VIEW_PALLETS],
//     category: 'Jefe de línea',
//     name: 'Ver palés',
//   },
//   {
//     [GrantGroupsRanks.LANE_PERSONNEL_TOTAL]: [
//       GrantSameNames.VIEW_EMPLOYEES_WORKING_AREA,
//       GrantSameNames.FINISH_TASK_FROM_WEB_INTERFACE,
//       GrantSameNames.CHANGE_TO_TASK,
//     ],
//     category: 'Jefe de línea',
//     name: 'Control total de personal',
//   },
//   {
//     [GrantGroupsRanks.LANE_PERFORMANCE_VIEW]: [],
//     category: 'Jefe de línea',
//     name: 'Ver rendimiento',
//   },
//   {
//     [GrantGroupsRanks.LANE_DOWNTIMES_VIEW]: [GrantSameNames.VIEW_AREA_DOWNTIMES],
//     category: 'Jefe de línea',
//     name: 'Ver paradas',
//   },
//   {
//     [GrantGroupsRanks.LANE_DOWNTIMES_TOTAL]: [
//       GrantSameNames.VIEW_AREA_DOWNTIMES,
//       GrantSameNames.CREATE_AREA_DOWNTIME,
//       GrantSameNames.CONTINUE_AREA_DOWNTIME,
//     ],
//     category: 'Jefe de línea',
//     name: 'Control total paradas',
//   },
// ];
