import * as React from 'react';
import {useState} from 'react';
import {CustomButton, CustomTextField} from '../../../../../shared/style';
import {TableModal} from '../../../../../shared/components/Modal';
import {LanePtableState} from '../../store/types';
import styled from 'styled-components';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

type Props = {
    ptable: LanePtableState;
    onClose: (isUpdated: boolean) => void;
    open: boolean;
};

export const PalletChangeKgBoxModal: React.FunctionComponent<Props> = props => {
    const {ptable, onClose, open} = props;

    const pallet = ptable.pallet;

    let kgBoxEditNumber = '0';
    if (pallet && pallet.current_subpallet_kg_box_edit !== undefined) {
        kgBoxEditNumber = pallet.current_subpallet_kg_box_edit;
    }

    const [kgBox, setKgBox] = useState<string>(kgBoxEditNumber);
    const [disabledButton, setdisabledButton] = useState<boolean>(false);

    React.useEffect(() => {
        if (pallet) {
            setKgBox(kgBoxEditNumber);
        }
    }, [pallet, kgBoxEditNumber]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'kg_box') {
            if (ptable && ptable.pallet) {
                console.log('e.target.value', e.target.value);
                setKgBox(e.target.value);
            }
        }
    };

    const updatePallet = async (close = true) => {
        const kgBoxNumber = Number(kgBox);
        if (kgBoxNumber >= 0) {
            setdisabledButton(true);
            if (pallet?.current_subpallet_id !== undefined) {
                await fetchWithFeedback(PalletsServices.updateSubpalletKgBoxEdit(pallet?.current_subpallet_id, kgBoxNumber), {
                    successMessage: 'Se ha actualizado el peso por envase correctamente: ' + kgBoxNumber,
                    errorMessage: 'No se ha podido actualizar el peso por envase',
                });
            }

            setdisabledButton(false);
            //Cerrar ventana
            if (close) {
                onClose(true);
            }
        } else {
            notify({message: 'El número no es correcto', status: 'warning'});
        }
    };

    return (
        <>
            {/* FORM CHANGE KG BOX MODAL */}

            <TableModal open={open} title={ptable.name} handleClose={() => onClose(false)}>
                <BoxText>
                    <CustomTextField
                        margin={'0 auto'}
                        width={'100%'}
                        label="Kg por envase"
                        error={false}
                        value={kgBox}
                        name="kg_box"
                        variant={'outlined'}
                        type="string"
                        onChange={handleChange}
                    />
                </BoxText>
                <CustomButton onClick={() => updatePallet(true)} width={'100%'} bgcolor="grey" disabled={disabledButton}>
                    Actualizar
                </CustomButton>
            </TableModal>
        </>
    );
};

const BoxText = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 30px;
    }
    height: 30px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
    padding: 10px 0;
`;
