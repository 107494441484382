import {findSystemParameter} from '../../global/systemParameters/systemParameters';
import {ParameterCPN} from '../../types/app_types';

export const validateBarcode = (barcode: string) => {
    return isValidPrefix(barcode) && isValidLenght(barcode) && isValidBarcode(barcode);
};

const isValidLenght = (barcode: string) => {
    return barcode.length === 13;
};

const isValidPrefix = (barcode: string) => {
    const prefixList = findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value.split(';');

    if (prefixList) {
        let startWithPrefix = false;
        for (let prefix of prefixList) {
            if (barcode.startsWith(prefix)) {
                startWithPrefix = true;
            }
        }
        if (!startWithPrefix) {
            return false;
        }
    }
    return true;
};

const isValidBarcode = (barcode: string) => {
    let validBarcode = barcode.substring(0, 12);

    let weightFlag = true;
    let sum = 0;

    for (let i = validBarcode.length - 1; i >= 0; i--) {
        sum += Number(validBarcode[i]) * (weightFlag ? 3 : 1);
        weightFlag = !weightFlag;
    }

    validBarcode += (10 - (sum % 10)) % 10;
    return validBarcode === barcode;
};
