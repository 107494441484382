import * as React from 'react';
import {ColumnType} from 'src/types';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {CustomButton, OCard} from '../../../shared/style';
import {Dialog, DialogTitle, FormControlLabel, FormGroup, Switch, Tooltip} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import {PalletsServices} from '../../lanemanager/pallets/store/services';
import {TableModal} from '../../../shared/components/Modal';
import {PalletInfoErpMerchandise, PalletInfoErpMerchandiseFamily, PalletInfoSubpallet} from './types';
import {findInitialParameter} from '../../../global/initalParameters/initialParameters';
import {TableComponent} from '../../../shared/components/TableComponent';

export interface Props {
    open: boolean;
    handleClose: () => void;
    handleSelect: (selected: any) => void;
    subpallet: PalletInfoSubpallet;
    erpCenterId: string;
    erpFamilyId: string;
}

type State = {
    openFiltersDialog: boolean,
    filterableFamilies: (PalletInfoErpMerchandiseFamily & {
        selected: boolean
    })[],
    selectedFamilyIds: string;
};

export const initialState: State = {
    openFiltersDialog: false,
    filterableFamilies: [],
    selectedFamilyIds: '',
};

export class SelectErpMerchandiseModal extends React.Component<Props, State> {
    readonly state = initialState;

    columns: ColumnType<any> = [];

    componentDidMount() {
        this.setState({...this.state, selectedFamilyIds: '' + this.props.erpFamilyId});
        this.columns = this.getColumnFormat();
    }

    private alreadySubpalletMerchandiseExist(erpPallet: PalletInfoErpMerchandise) {
        return this.props.subpallet.merchandise_name === erpPallet.erpMerchandiseName;
    }

    private getColumnFormat = (): ColumnType<ProdPartGeneric> => {
        return [
            {
                Header: '',
                sortable: false,
                style: {textAlign: 'center'},
                filterable: false,
                Cell: item => (
                    <>
                        <CustomButton
                            disabled={this.alreadySubpalletMerchandiseExist(item.original)}
                            width={'24px'}
                            minWidth={'24px'}
                            height={'24px'}
                            margin={'2px 2px 2px 2px'}
                            bgcolor={'#c44'}
                            onClick={() => {
                                this.props.handleSelect(item.original);
                            }}
                        >
                            <Tooltip title={'Seleccionar'} placement='top'>
                                <CheckCircleOutline />
                            </Tooltip>
                        </CustomButton>
                    </>
                ),
            },
            {
                Header: 'Nombre artículo',
                headerClassName: 'header_cell_string',
                accessor: 'erpMerchandiseName',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 200,
            },
            {
                Header: 'Código artículo',
                headerClassName: 'header_cell_string',
                accessor: 'erpMerchandiseCode',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Nombre familia',
                headerClassName: 'header_cell_string',
                accessor: 'erpFamilyName',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 200,
            },
            {
                Header: 'Código familia',
                headerClassName: 'header_cell_string',
                accessor: 'erpFamilyCode',
                className: 'cell_string',
                filterable: true,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Id familia',
                headerClassName: 'header_cell_string',
                accessor: 'erpFamilyId',
                className: 'cell_string',
                filterable: true,
                sortable: false,
                show: false,
            },
        ];
    };

    private handleOpenFilterDialog = () => {
        this.setState({
            ...this.state,
            openFiltersDialog: true,
        });
    };

    private handleCloseFilterDialog = () => {
        this.setState({
            ...this.state,
            openFiltersDialog: false,
        });
    };

    private handleFiltersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedErpFamilyId = event.target.value;
        const selected = event.target.checked;
        const erpMerchandiseFamily = this.state.filterableFamilies
            .find((erpMerchandiseFamily: PalletInfoErpMerchandiseFamily) => erpMerchandiseFamily.erpFamilyId === selectedErpFamilyId);
        if (erpMerchandiseFamily) {
            erpMerchandiseFamily.selected = selected;
        }
        const selectedFamilyIds: string[] = [];

        this.state.filterableFamilies.forEach((erpMerchandiseFamily: (PalletInfoErpMerchandiseFamily & {selected: boolean})) => {
            const alreadyExist = selectedFamilyIds.find((erpMerchandiseFamilyId: string) => erpMerchandiseFamilyId === erpMerchandiseFamily.erpFamilyId);
            if (erpMerchandiseFamily.selected && !alreadyExist) {
                selectedFamilyIds.push(erpMerchandiseFamily.erpFamilyId);
            }
        });
        this.setState({
            ...this.state,
            selectedFamilyIds: selectedFamilyIds.join(';'),
        });
    };

    private handleGetEntries = (erpMerchandises: PalletInfoErpMerchandise[]) => {
        const filterableFamilies: (PalletInfoErpMerchandiseFamily & {selected: boolean})[] = [];
        const erpMerchandise = erpMerchandises.find((erpMerchandise: PalletInfoErpMerchandise) => erpMerchandise.erpFamilyId === this.props.erpFamilyId);
        if (erpMerchandise) {
            filterableFamilies.push({
                selected: true,
                erpFamilyId: erpMerchandise.erpFamilyId,
                erpCenterId: erpMerchandise.erpCenterId,
                erpFamilyCode: erpMerchandise.erpFamilyCode,
                erpFamilyName: erpMerchandise.erpFamilyName,
            });
        }
        this.setState({
            ...this.state,
            filterableFamilies,
        });
    };

    render() {

        const {filterableFamilies, selectedFamilyIds} = this.state;

        return (
            <TableModal maxWidth={'100%'} title={'Seleccione un artículo'} handleClose={this.props.handleClose}
                        open={this.props.open}>
                <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'}
                       marginBottom={'1em'}>

                    <Dialog onClose={this.handleCloseFilterDialog}
                            onEnter={this.handleOpenFilterDialog}
                            aria-labelledby='simple-dialog-title'
                            open={this.state.openFiltersDialog}
                    >
                        <DialogTitle id='simple-dialog-title'
                                     style={{backgroundColor: `#${findInitialParameter('FRONT_APP_COLOR')?.value || ''}`}}
                        >
                            <span style={{color: 'white'}}>Filtro familia</span>
                        </DialogTitle>
                        <FormGroup
                            style={{padding: '16px'}}>
                            {filterableFamilies && filterableFamilies.length && (
                                filterableFamilies.map(erpMerchandise => (
                                    <FormControlLabel
                                        control={<Switch
                                            name={erpMerchandise.erpFamilyId}
                                            checked={erpMerchandise.selected}
                                            onChange={this.handleFiltersChange}
                                            value={erpMerchandise.erpFamilyId}
                                        />}
                                        label={`${erpMerchandise.erpFamilyName} [${erpMerchandise.erpFamilyCode}]`}
                                    />
                                ))
                            )}

                        </FormGroup>
                    </Dialog>

                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={PalletsServices.getErpMerchandiseList}
                        serviceArgs={[this.props.erpCenterId]}
                        extraFilter={true}
                        handleGetEntries={this.handleGetEntries}
                        handleExtraFilter={this.handleOpenFilterDialog}
                        filtered={[
                            {id: 'erpFamilyId', value: selectedFamilyIds},
                        ]}
                    />

                </OCard>
            </TableModal>
        );
    }
}
