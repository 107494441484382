import * as React from 'react';
import {useEffect, useState} from 'react';
import {PalletInfoTransferBoxesModal} from './PalletInfoTransferBoxesModal';
import {LanePtableState, LanePtableStatePalletSubpallet, LanePtableStatePalletSubpalletBatch} from '../../store/types';
import {TableModal} from '../../../../../shared/components/Modal';
import {CustomButton, CustomTextField} from '../../../../../shared/style';
import {Typography} from '@material-ui/core';
import {parseInt} from 'lodash';

type Props = {
    ptables: LanePtableState[];
    onClose: (isTransfered: boolean) => void;
    selectedBatch: LanePtableStatePalletSubpalletBatch;
    selectedPtablePrevious: LanePtableState;
    open: boolean;
};

type State = {
    selectedPtableTarget: LanePtableState | null;
    openTransferBoxesModal: boolean;
};

export const PalletInfoSelectTargetPtableModal: React.FunctionComponent<Props> = props => {
    const {ptables, selectedPtablePrevious, selectedBatch, onClose, open} = props;

    const [state, setState] = useState<State>({
        selectedPtableTarget: null,
        openTransferBoxesModal: false,
    });

    const [boxesToTransfer, setBoxesToTransfer] = useState<number>(selectedBatch.boxes);

    useEffect(() => {
        setBoxesToTransfer(selectedBatch.boxes);
    }, [selectedBatch, selectedBatch.boxes]);

    const handleChangeBoxesToTransfer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'boxes_count') {
            if (e.target.value === '') {
                setBoxesToTransfer(0);
            } else {
                let newIntValue = parseInt(e.target.value);
                if (isNaN(newIntValue)) return;

                if (newIntValue <= props.selectedBatch.boxes && newIntValue >= 0) {
                    setBoxesToTransfer(newIntValue);
                }
            }
        }
    };

    const handleCloseTransferBoxesModal = (isTransfer: boolean) => {
        if (isTransfer) {
            onClose(isTransfer);
        }
        setState({
            ...state,
            openTransferBoxesModal: false,
            selectedPtableTarget: null,
        });
        setBoxesToTransfer(0);
    };

    const close = () => {
        setState({
            ...state,
            openTransferBoxesModal: false,
            selectedPtableTarget: null,
        });
        setBoxesToTransfer(0);
        onClose(false);
    };

    const openTransferBoxesModal = (ptable: LanePtableState) => {
        if (ptable.pallet) {
            ptable.pallet.current_subpallet = ptable.pallet.subpallets.find((subpallet: LanePtableStatePalletSubpallet) => subpallet.id === ptable.pallet?.current_subpallet_id)!;
        }
        setState({
            ...state,
            openTransferBoxesModal: true,
            selectedPtableTarget: ptable,
        });
    };

    return (
        <>
            {state.selectedPtableTarget && (
                <PalletInfoTransferBoxesModal
                    selectedPtablePrevious={selectedPtablePrevious}
                    selectedPtableTarget={state.selectedPtableTarget}
                    selectedBatch={selectedBatch}
                    boxesToTransfer={boxesToTransfer}
                    onClose={isTransfer => handleCloseTransferBoxesModal(isTransfer)}
                    open={state.openTransferBoxesModal}
                />
            )}

            {ptables.length === 0 && (
                <>
                    <div style={{padding: 10, paddingLeft: 20, fontSize: 14}}>No hay datos sobre las mesas...</div>
                    <CustomButton onClick={() => onClose(false)}>Cerrar</CustomButton>
                </>
            )}

            {ptables.length > 0 && (
                <TableModal title={(selectedPtablePrevious && selectedPtablePrevious.name) || 'Cerrando'} open={open} handleClose={close}>
                    <br />

                    <CustomTextField
                        margin={'0 auto'}
                        width={'100%'}
                        label="Número de cajas a transferir"
                        error={false}
                        value={boxesToTransfer}
                        name="boxes_count"
                        variant={'outlined'}
                        type="number"
                        onChange={handleChangeBoxesToTransfer}
                    />

                    {(ptables.map(ptable => {
                        if (selectedPtablePrevious.pallet && ptable.ptable_id !== selectedPtablePrevious.ptable_id) {
                            return (
                                <Typography
                                    component="div"
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <>
                                        <CustomButton disabled={boxesToTransfer <= 0} onClick={() => openTransferBoxesModal(ptable)} padding={'3px'}>
                                            {ptable.name}
                                        </CustomButton>
                                        <br />
                                    </>
                                </Typography>
                            );
                        } else return undefined;
                    }) as any) || 'No tiene cajas de orígenes'}
                </TableModal>
            )}
        </>
    );
};
