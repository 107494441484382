import React from 'react';
import {
    TransferBoxesType,
    TransferOriginPallet,
    TransferOriginPalletSubpallet,
    TransferOriginPalletSubpalletBatch,
    TransferTargetPallet,
    TransferTargetPalletSubpallet,
    TransferTargetPalletSubpalletBatch,
} from './store/liftPalletsControl.types';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    createStyles,
    Grid,
    List,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import AddButtom from '@material-ui/icons/AddCircle';
import ClearButton from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';
import {GetPalletModal} from '../../lanemanager/lane/components/PalletsInfo/GetPalletModal';
import {CreatePalletModal} from './CreatePalletModal';
import {NotifyActionType} from '../../../utils/Notification/action-types';
import {Advertisement} from '../../../shared/components/Advertisement';
import {PalletInformationServices} from '../../lanemanager/palletInformation/store/palletInformation.services';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {
    PalletInfoUnit,
    PalletInfoUnitSubpallet,
    PalletInfoUnitSubpalletBatch,
} from '../../lanemanager/palletInformation/store/palletInformation.types';
import {TransferBoxesDialog} from './TransferBoxesDialog';
import {LiftPalletsControlService} from './store/liftPalletsControl.service';
import {notify} from '../../../utils/Notification';
import {LiftPalletsOriginPalletListItem} from './LiftPalletsOriginPalletListItem';
import {LiftPalletsTargetPalletListItem} from './LiftPalletsTargetPalletListItem';
import {WrapLoaderDiv} from '../../lanemanager/palletInformation/Wrap';


type Props = {
    classes: any
};

type State = {
    requestPalletId: 'origin' | 'dest' | '';
    originPalletList: Map<string, TransferOriginPallet>;
    showAd: boolean;
    targetPallet: TransferTargetPallet | null;
    isLoading: boolean;
    isLoadingMsg: string;
    tempOriginPallet: TransferOriginPallet | null;
    maxBoxToTransfer: number;
    showCreatePallet: boolean;
};

const initialState: State = {
    requestPalletId: '',
    originPalletList: new Map<string, TransferOriginPallet>(),
    showAd: false,
    targetPallet: null,
    isLoading: false,
    isLoadingMsg: '',
    tempOriginPallet: null,
    maxBoxToTransfer: 0,
    showCreatePallet: false,
};

export class LiftPalletsControlBase extends React.Component<Props, State> {
    readonly GET_PALLET_MSG = 'Obteniendo información del palé...';
    readonly UPDATE_PALLET_MSG = 'Actualizando información del palé...';
    readonly CREATE_PALLET_MSG = 'Creando palé...';
    readonly TRANSFER_BOXES_MSG = 'Transfiriendo cajas...';

    readonly state = initialState;
    private adMessage = '';

    componentWillUnmount() {
        this.state.originPalletList.clear();
        this.setState(initialState);
    }

    private setLoading = (msg: string) => {
        this.setState({
            ...this.state,
            isLoading: true,
            isLoadingMsg: msg,
        });
        this.forceUpdate();
    };

    private unsetLoading = () => {
        this.setState({
            ...this.state,
            isLoading: false,
            isLoadingMsg: '',
        });
        this.forceUpdate();
    };

    private handleSelectDestPallet = () => {
        this.setState({
            ...this.state,
            requestPalletId: 'dest',
        });
    };

    private handleCreatePallet = () => {
        this.setState({
            ...this.state,
            showCreatePallet: true,
        });
    };

    private handleAddOriginPallet = () => {
        this.setState({
            ...this.state,
            requestPalletId: 'origin',
        });
    };

    private handleClearOriginPallets = () => {
        this.state.originPalletList.clear();
        this.forceUpdate();
    };

    private updateTransferBoxes = () => {
        const transferedBoxes = this.totalBoxesToTransfer();
        const destPallet = this.state.targetPallet;
        if (destPallet) {
            const destPalletBoxes = (destPallet.current_subpallet.boxes_count ?? 0) + transferedBoxes;
            if (this.state.targetPallet) destPallet.current_subpallet.boxes_count = destPalletBoxes;
        }

        const maxBoxToTransfer = this.state.maxBoxToTransfer - transferedBoxes;

        this.setState({...this.state, maxBoxToTransfer});
        this.state.originPalletList.forEach((originPallet: TransferOriginPallet) => {
            originPallet.current_subpallet.boxes_count -= originPallet.current_subpallet.transfered_boxes;
            originPallet.current_subpallet.transfered_boxes = 0;

            originPallet.current_subpallet.batches.forEach(b => {
                b.boxes -= b.boxes_count;
                b.boxes_count = 0;
            });
        });
    };

    private resetTransferBoxes = () => {
        this.state.originPalletList.forEach((originPallet: TransferOriginPallet) => {
            let boxCount = 0;

            originPallet.current_subpallet.batches.forEach(b => {
                boxCount += b.boxes;
                b.boxes_count = 0;
            });
            originPallet.current_subpallet.transfered_boxes = 0;
            originPallet.current_subpallet.boxes_count = boxCount;
        });
    };

    private handleTransferBoxes = () => {
        this.setLoading(this.TRANSFER_BOXES_MSG);

        let transfer: TransferBoxesType = {
            origin_pallet: [],
            target_id: this.state.targetPallet?.id ?? '',
        };

        this.state.originPalletList.forEach((originPallet: TransferOriginPallet) => {
            const batches: TransferOriginPalletSubpalletBatch[] = [];
            if (originPallet.current_subpallet.transfered_boxes > 0) {
                originPallet.current_subpallet.batches.forEach((batch: TransferOriginPalletSubpalletBatch) => {

                    if (batch.boxes_count > 0) {
                        batches.push({
                            ...batch,
                            boxes_to_transfer: batch.boxes_count,
                        });
                    }
                });

                const pallet: TransferOriginPallet = {
                    ...originPallet,
                    id: originPallet.id,
                    current_subpallet: {
                        ...originPallet.current_subpallet,
                        batches,
                    },
                };

                transfer.origin_pallet.push(pallet);
            }
        });

        LiftPalletsControlService.transferBoxes(transfer).then((r) => {
            if (r.status === 200) {
                notify({
                    status: 'success',
                    message: 'La operación se ha realizado con éxito',
                });
                this.updateTransferBoxes();
            } else {
                notify({
                    status: 'error',
                    message: 'No se pudieron transferir las cajas',
                });
            }
            this.unsetLoading();
        }).catch((e) => {
            console.log(e);
            this.unsetLoading();
            notify({
                status: 'error',
                message: 'No se pudieron transferir las cajas',
            });
        });
    };

    private hideVinculatePallet = () => {
        this.setState({...this.state, requestPalletId: ''});
    };

    private hideCreatePallet = () => {
        this.setState({...this.state, showCreatePallet: false});
    };

    private handleSelectOriginPallet = (barcode: string) => {
        this.setState({
            ...this.state,
            tempOriginPallet: (this.state.originPalletList.get(barcode) ?? null),
        });
    };

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            ...this.state,
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            ...this.state,
            showAd: false,
        });
    };

    private handlePalletBarcodeReader = (barcode: string, requestId: string) => {

        if (requestId === 'origin') {
            this.addOriginPallet(barcode);
        } else if (requestId === 'dest') {
            this.setDestPallet(barcode);
        }

        this.setState({
            ...this.state,
            requestPalletId: '',
        });
    };

    private handleCreatePalletBarcodeReader = (barcode: string, prodpart: string, areaId: string) => {

        this.setLoading(this.CREATE_PALLET_MSG);

        PalletsServices.vinculatePalletFromProdpart(barcode, prodpart, areaId)
            .then(data => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const maxBoxes = palletInfo.boxes_total - palletInfo.boxes_count;
                    this.setState({...this.state, targetPallet: palletInfo, maxBoxToTransfer: maxBoxes});
                    this.resetTransferBoxes();

                    if (maxBoxes === 0) this.showAd('Al palé seleccionado no se le pueden añadir más cajas');
                } else {
                    this.showAd('No se pudo crear el palé pallet');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.showAd('No se pudo crear el palé pallet');
                this.unsetLoading();
            });

        this.setState({
            ...this.state,
            showCreatePallet: false,
        });
    };


    private addOriginPallet = (barcode: string) => {
        if (barcode === this.state.targetPallet?.palletBarcode) {
            this.showAd('El palé de origen no puede ser igual al de destino');
            return;
        }

        if (this.state.originPalletList.has(barcode)) {
            this.showAd('El palé seleccionado ya existe en el listado');
            return;
        }

        this.setLoading(this.GET_PALLET_MSG);

        PalletInformationServices.getInfo(barcode)
            .then((data) => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const transferPallet: TransferOriginPallet = this.fetchPalletInfoToTransferOriginPallet(palletInfo);
                    this.state.originPalletList.set(barcode, transferPallet);

                } else {
                    this.showAd('No existe ningún palé con el código de barras introducido');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.unsetLoading();
                this.showAd('No se pudo obtener información del pallet');
            });
    };

    private updateOriginPallet = (barcode: string) => {

        if (!this.state.originPalletList.has(barcode)) {
            this.showAd('El palé a actualizar no existe en el listado');
            return;
        }

        this.setLoading(this.UPDATE_PALLET_MSG);

        PalletInformationServices.getInfo(barcode)
            .then(data => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const transferPallet: TransferOriginPallet = this.fetchPalletInfoToTransferOriginPallet(palletInfo);
                    this.state.originPalletList.set(barcode, transferPallet);

                } else {
                    this.showAd('No existe ningún palé con el código de barras introducido');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.unsetLoading();
                this.showAd('No se pudo obtener información del palé');
            });
    };

    private fetchPalletInfoToTransferOriginPallet(palletInfo: PalletInfoUnit): TransferOriginPallet {
        const subpallets: TransferOriginPalletSubpallet[] = palletInfo.subpallets.map((subpallet: PalletInfoUnitSubpallet) => {
            return {
                batches: subpallet.batches.map((batch: PalletInfoUnitSubpalletBatch) => {
                    return {
                        boxes: Number(batch.boxes),
                        boxes_count: 0,
                        id: batch.id,
                        erp_origin_code: batch.erp_origin_code,
                    };
                }) as TransferOriginPalletSubpalletBatch[],
                boxes_count: Number(subpallet.boxes_count),
                boxes_total: Number(subpallet.boxes_total),
                id: subpallet.id,
                merchandise_name: subpallet.merchandise_name,
                transfered_boxes: 0,
                prodpart_code: subpallet.prodpart_code,
            };
        });

        const currentSubpallet: TransferOriginPalletSubpallet = subpallets.find((subpallet: TransferOriginPalletSubpallet) => {
            return subpallet.id === palletInfo.current_subpallet_id;
        })!;

        return {
            id: palletInfo.id,
            palletBarcode: palletInfo.palletBarcode,
            current_subpallet_id: palletInfo.current_subpallet_id,
            subpallets,
            current_subpallet: currentSubpallet,
        };
    }

    private fetchPalletInfoToTransferTargetPallet(palletInfo: PalletInfoUnit): TransferTargetPallet {
        const subpallets: TransferTargetPalletSubpallet[] = palletInfo.subpallets.map((subpallet: PalletInfoUnitSubpallet) => {
            return {
                batches: subpallet.batches.map((batch: PalletInfoUnitSubpalletBatch) => {
                    return {
                        boxes: Number(batch.boxes),
                        boxes_count: 0,
                        id: batch.id,
                        erp_origin_code: batch.erp_origin_code,
                    };
                }) as TransferTargetPalletSubpalletBatch[],
                boxes_count: Number(subpallet.boxes_count),
                boxes_total: Number(subpallet.boxes_total),
                id: subpallet.id,
                merchandise_name: subpallet.merchandise_name,
                prodpart_code: subpallet.prodpart_code,
            };
        });

        const currentSubpallet: TransferTargetPalletSubpallet = subpallets.find((subpallet: TransferTargetPalletSubpallet) => {
            return subpallet.id === palletInfo.current_subpallet_id;
        })!;

        return {
            id: palletInfo.id,
            palletBarcode: palletInfo.palletBarcode,
            current_subpallet_id: palletInfo.current_subpallet_id,
            subpallets,
            current_subpallet: currentSubpallet,
        };
    }

    private handleRemoveOriginPallet = (barcode: string) => {
        this.state.originPalletList.delete(barcode);
        this.forceUpdate();
    };

    private handleRemoveTargetPallet = () => {
        this.setState({...this.state, targetPallet: null});
        this.forceUpdate();
    };

    private totalBoxesToTransfer = (): number => {
        const values = this.state.originPalletList.values();
        let counter = 0;
        let res = values.next();
        while (!res.done) {
            counter += res.value.current_subpallet.transfered_boxes;
            res = values.next();
        }
        return counter;
    };

    private handleOriginTransferBoxes = (transferedBoxes: number, palletBarcode: string | undefined, transfer: Map<string, TransferOriginPalletSubpalletBatch>) => {

        this.setState({...this.state, tempOriginPallet: null});

        if (palletBarcode) {
            const originPallet = this.state.originPalletList.get(palletBarcode);

            if (originPallet) {
                originPallet.current_subpallet.transfered_boxes = transferedBoxes;
                const batches: TransferOriginPalletSubpalletBatch[] = [];
                transfer.forEach((batch: TransferOriginPalletSubpalletBatch) => {
                    const _batch = Object.assign<any, TransferOriginPalletSubpalletBatch>({}, batch);
                    batches.push(_batch);
                });
                originPallet.current_subpallet.batches = batches;
            }
        }
    };

    private setDestPallet = (barcode: string) => {

        if (this.state.targetPallet?.palletBarcode === barcode) {
            this.showAd('Ha seleccionado el palé actual');
            return;
        }
        if (this.state.originPalletList.has(barcode)) {
            this.showAd('El palé seleccionado ya existe en la lista de origen');
            return;
        }

        this.setLoading(this.GET_PALLET_MSG);

        PalletInformationServices.getInfo(barcode)
            .then(data => {
                const palletInfo = data.data.pallet;
                const targetPallet = this.fetchPalletInfoToTransferTargetPallet(palletInfo);
                if (palletInfo) {
                    const maxBoxes = palletInfo.boxes_total - palletInfo.boxes_count;
                    this.setState({...this.state, targetPallet, maxBoxToTransfer: maxBoxes});
                    this.resetTransferBoxes();

                    if (maxBoxes === 0) this.showAd('Al palé seleccionado no se le pueden añadir más cajas');
                } else {
                    this.showAd('No existe ningún palé con el código de barras  introducido');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.showAd('No se pudo obtener información del pallet');
                this.unsetLoading();
            });
    };


    render() {
        const {classes} = this.props;
        const originPalletList = Array.from(this.state.originPalletList);
        const totalBoxesToTransfer = this.totalBoxesToTransfer();

        return (
            <>

                {
                    !this.state.maxBoxToTransfer && (
                        <div style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '30px',
                            padding: '10px',
                            backgroundColor: 'yellow',
                        }}>
                            No hay cajas para transferir. Recuerda que debe haber disponibilidad en origen y hueco en destino.
                        </div>
                    )
                }

                <Grid container className={classes.fitH} alignItems='center'>
                    <Grid item xs={2} />

                    <Grid item xs={3} className={classes.gridContainer}>
                        <Typography color='inherit' variant='h6'>
                            Origen
                        </Typography>
                        <Card className={classes.cardContainer}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant='body1' className={classes.cardHeader}>
                                    Total de cajas a
                                    transferir: <b>{totalBoxesToTransfer}</b>/{this.state.maxBoxToTransfer}
                                </Typography>
                                <Card className={classes.palletListContainer}>
                                    <List className={classes.palletList}>
                                        <>
                                            {
                                                originPalletList.map(
                                                    (value) => {
                                                        return (
                                                            <LiftPalletsOriginPalletListItem
                                                                pallet={value[1]}
                                                                handleClick={this.handleSelectOriginPallet}
                                                                handleRemove={this.handleRemoveOriginPallet}
                                                                handlePalletUpdated={this.updateOriginPallet}
                                                            />
                                                        );
                                                    },
                                                )
                                            }
                                        </>
                                    </List>
                                </Card>

                                <Grid container className={classes.cardButtons} justify='flex-end'>
                                    <Grid item>
                                        <Tooltip title='Limpiar palés de origen'>
                                            <IconButton onClick={this.handleClearOriginPallets}>
                                                <ClearButton color='secondary' fontSize='large' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='Añadir palé de origen'>
                                            <IconButton onClick={this.handleAddOriginPallet}>
                                                <AddButtom color='primary' fontSize='large' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align='center'>
                            <ArrowIcon className={classes.arrow} />
                        </Typography>

                    </Grid>
                    <Grid item xs={3} className={classes.gridContainer}>
                        <Typography color='inherit' variant='h6'>
                            Destino
                        </Typography>
                        <Card className={classes.cardContainer}>
                            <CardContent className={classes.cardContent}>
                                {this.state.targetPallet && (
                                    <LiftPalletsTargetPalletListItem
                                        pallet={this.state.targetPallet}
                                        handleClick={() => {
                                        }}
                                        handleRemove={this.handleRemoveTargetPallet}
                                    />
                                )}
                                {/*<Card className={classes.palletContainer}>*/}
                                {/*    {this.state.targetPallet ?*/}
                                {/*        (*/}
                                {/*            <Typography variant='subtitle1' align='center'>*/}
                                {/*                <b>{this.state.targetPallet.palletBarcode}</b>&nbsp;*/}
                                {/*                ({this.state.targetPallet.current_subpallet.boxes_count}/{this.state.targetPallet.current_subpallet.boxes_total})*/}
                                {/*            </Typography>*/}
                                {/*        )*/}
                                {/*        :*/}
                                {/*        (*/}
                                {/*            <Typography variant='h5' align='center'>*/}
                                {/*                - No hay selección -*/}
                                {/*            </Typography>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Card>*/}
                                <Button className={classes.selectButton} variant='contained' color='primary'
                                        onClick={this.handleSelectDestPallet}>
                                    Seleccionar palé
                                </Button>
                                <Button className={classes.selectButton} variant='contained' color='primary'
                                        onClick={this.handleCreatePallet}>
                                    Crear nuevo palé
                                </Button>
                                <div className={classes.gap} />
                                <Grid container className={classes.cardButtons} justify='flex-end'>
                                    <Grid item>
                                        <Button
                                            className={classes.transButton}
                                            variant='contained'
                                            color='primary'

                                            onClick={this.handleTransferBoxes}
                                            disabled={this.state.targetPallet === null || totalBoxesToTransfer === 0}
                                        >
                                            Transferir
                                        </Button>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <GetPalletModal open={this.state.requestPalletId !== ''}
                                hide={this.hideVinculatePallet}
                                requestId={this.state.requestPalletId}
                                handleBarcodeReaded={this.handlePalletBarcodeReader} />
                <CreatePalletModal open={this.state.showCreatePallet}
                                   hide={this.hideCreatePallet}
                                   requestId={this.state.requestPalletId}
                                   handleBarcodeReaded={this.handleCreatePalletBarcodeReader} />
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage}
                               type={NotifyActionType.error} />
                <TransferBoxesDialog
                    open={this.state.tempOriginPallet !== null}
                    pallet={this.state.tempOriginPallet}
                    transferedBoxes={totalBoxesToTransfer}
                    maxBoxes={this.state.maxBoxToTransfer}
                    handleClose={() => this.setState({...this.state, tempOriginPallet: null})}
                    handleTransfer={this.handleOriginTransferBoxes}
                />
                {this.state.isLoading && (
                    <WrapLoaderDiv>
                        <CircularProgress style={{color: 'blue', margin: '16px'}} />
                        <Typography style={{
                            padding: '10px',
                            backgroundColor: '#f2f2f2',
                            opacity: '1',
                        }}>{this.state.isLoadingMsg}</Typography>
                    </WrapLoaderDiv>
                )}
            </>
        );
    }
}

const styles = createStyles({
    fitH: {
        height: '100%',
    },
    gridContainer: {
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContainer: {
        height: '100%!important',
    },
    cardContent: {
        height: '100%!important',
        display: 'flex',
        flex: 'max-content',
        flexDirection: 'column',
        alignContent: 'flex-end',
        padding: 0,
    },
    cardHeader: {
        margin: '8px',
    },
    palletListContainer: {
        height: '100%!important',
        margin: '8px',
        backgroundColor: '#eeeeee',
        border: 'gray',
        flex: 'max-content',
        overflow: 'auto',
    },
    palletListElement: {
        padding: '8px',
        cursor: 'pointer',
    },
    palletContainer: {
        margin: '8px',
        backgroundColor: '#eeeeee',
        border: 'gray',
    },
    transferBoxesCard: {
        padding: '8px',
    },
    cardButtons: {},
    fitH75: {
        height: '75%',
    },
    no_margin: {
        height: '200px',
        margin: 0,
        backgroundColor: 'blue',
    },
    gap: {
        flexGrow: 10,
        flex: 'max-content',
    },
    badge: {
        top: 14,
        padding: 3,
        right: -15,
        width: 20,
        height: 20,
    },
    arrow: {
        color: 'gray',
        height: '50%',
        width: '50%',
    },
    selectButton: {
        margin: '8px',
    },
    transButton: {
        margin: '12px',
        color: 'white',
    },
    loaderContainer: {
        position: 'absolute',
        backgroundColor: 'rgba(255,255,255,0.85)',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    loader: {
        color: 'blue',
        margin: '16px',
    },
});

export const LiftPalletsControl = withStyles(styles)(LiftPalletsControlBase);


