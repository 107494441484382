import {Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, List, Typography} from '@material-ui/core';
import {ListItem} from '@material-ui/core/es';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import {LaneBatchesWithBoxesState} from '../../lanemanager/lane/store/types';
import {UpdateOriginsControlService} from '../../lanemanager/lane/components/ModifyDumps/store/modifyDumpsControl.service';
import {UpdateOriginBoxesType, UpdateOriginType} from '../../lanemanager/lane/components/ModifyDumps/store/modifyDumpsControl.types';
import {notify} from '../../../utils/Notification';
import {CustomButton, CustomTextField} from '../../../shared/style';
import {PalletInfoSubpallet} from './types';

type State = {
    transfers: Map<string, LaneBatchesWithBoxesState>;
    originalBoxesCount: Map<string, number>;
    addedBoxes: number;
    removedBoxes: number;
    showConfirmDialog: boolean;
    showSpinner: boolean;
    newOriginCode: string;
};

const initialState: State = {
    transfers: new Map<string, LaneBatchesWithBoxesState>(),
    originalBoxesCount: new Map<string, number>(),
    addedBoxes: 0,
    removedBoxes: 0,
    showConfirmDialog: false,
    showSpinner: false,
    newOriginCode: '',
};

type Props = {
    open: boolean;
    subpallet: PalletInfoSubpallet;
    handleClose: () => void;
    handleSave: () => void;
};

export class ModifySubpalletComposition extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.fillTransfer();
        }
    }

    private fillTransfer = () => {
        this.state.transfers.clear();
        if (this.props.subpallet) {
            this.props.subpallet &&
                this.props.subpallet.origins.forEach(b => {
                    if (b.erp_batch_code && b.max_boxes > 0) {
                        const _batch = Object.assign({}, b);
                        this.state.transfers.set(b.erp_batch_code, _batch);
                        this.state.originalBoxesCount.set(b.erp_batch_code, b.number_boxes);
                        this.setState({...this.state, addedBoxes: 0, removedBoxes: 0});
                    }
                });
            this.setState({...this.state, addedBoxes: 0, removedBoxes: 0});
        }
    };

    private handleTransferBoxes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const batchCode = event.target.id;
        let newValue = parseInt(event.target.value);

        const batch = this.state.transfers.get(batchCode);
        if (batch) {
            if (newValue < 0 || isNaN(newValue)) newValue = 0;
            batch.number_boxes = newValue;
            this.updateInfo();
        }
    };

    private getPalletCurrentBoxesCount = (): number => {
        let total: number = 0;
        this.state.transfers.forEach(v => {
            let n = +v.number_boxes;
            total = total + n;
        });

        return total;
    };

    private updateInfo = () => {
        let addedBoxes = 0;
        let removedBoxes = 0;

        this.state.transfers.forEach(v => {
            const current = v.number_boxes;
            const original = this.state.originalBoxesCount.get(v.erp_batch_code) ?? 0;
            const delta = current - original;
            if (delta > 0) addedBoxes += delta;
            else if (delta < 0) removedBoxes -= delta;
        });

        this.setState({...this.state, addedBoxes, removedBoxes});
    };

    private updateOrigins = () => {
        if (this.state.addedBoxes === 0 && this.state.removedBoxes === 0) return;

        this.setState({...this.state, showConfirmDialog: false, showSpinner: true});

        const origins: UpdateOriginBoxesType[] = [];

        this.state.transfers.forEach(v => {
            const batch: UpdateOriginBoxesType = {
                originId: v.batch_id,
                originalBoxes: this.state.originalBoxesCount.get(v.erp_batch_code) ?? 0,
                palletBoxes: v.number_boxes,
            };
            origins.push(batch);
        });

        const transfer: UpdateOriginType = {
            origins,
            palletId: '--',
        };

        transfer.palletId = this.props.subpallet.pallet_id ?? '--';
        transfer.subpalletId = this.props.subpallet.id ?? '--';

        UpdateOriginsControlService.updateBoxes(transfer)
            .then(r => {
                if (r.status === 200) {
                    if (r.data.update && r.data.update.length) {
                        if (this.props.subpallet) {
                            let palletBoxesCount = 0;
                            this.props.subpallet.origins = [];
                            r.data.update.forEach(u => {
                                this.props.subpallet?.origins.push(u);
                                palletBoxesCount += parseInt(u.number_boxes + '', 10);
                            });
                            this.props.subpallet.boxes_count = palletBoxesCount;
                        }
                        this.fillTransfer();
                    }

                    this.setState({...this.state, newOriginCode: ''});
                    this.setState({...this.state, showSpinner: false});

                    if (r.data.errors && r.data.errors.length) {
                        notify({
                            status: 'notify',
                            message: 'Origen actualizado con errores. Revisar log.',
                        });
                    } else {
                        notify({
                            status: 'success',
                            message: 'Origen actualizado',
                        });
                    }
                    this.props.handleSave();
                } else {
                    this.setState({...this.state, showSpinner: false});
                    notify({
                        status: 'error',
                        message: 'No se ha podido acceder al servicio. Vuelva a intentarlo.',
                    });
                }
            })
            .catch(e => {
                this.setState({...this.state, showSpinner: false});
                console.log(e);
                notify({
                    status: 'error',
                    message: 'No se ha podido acceder al servicio. Vuelva a intentarlo.',
                });
            });
    };

    private addOrigin = () => {
        if (!this.state.newOriginCode) return;

        this.setState({...this.state, showSpinner: true});
        UpdateOriginsControlService.getOrigin(this.state.newOriginCode)
            .then(r => {
                this.setState({...this.state, showSpinner: false});
                if (r.status === 200) {
                    this.state.transfers.set(this.state.newOriginCode, r.data);
                    this.state.originalBoxesCount.set(this.state.newOriginCode, r.data.number_boxes);
                    this.setState({...this.state, newOriginCode: ''});
                    notify({
                        status: 'success',
                        message: 'Añadido origen.',
                    });
                } else if (r.status === 204) {
                    notify({
                        status: 'error',
                        message: 'No existe el origen buscado.',
                    });
                } else {
                    notify({
                        status: 'error',
                        message: 'No se ha podido acceder al servicio. Vuelva a intentarlo.',
                    });
                }
            })
            .catch(e => {
                this.setState({...this.state, showSpinner: false});
                console.log(e);
                notify({
                    status: 'error',
                    message: 'No se ha podido acceder al servicio. Vuelva a intentarlo.',
                });
            });
    };

    private handleChangeOriginCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = (e.target.value as unknown) as string;
        this.setState({...this.state, newOriginCode: newValue});
    };

    render() {
        const batchesList = Array.from(this.state.transfers);

        return (
            this.props.open && (
                <>
                    <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{'Modificar composición'}</DialogTitle>
                        <DialogContent>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <b>{this.props.subpallet.pallet_code}</b> ({this.getPalletCurrentBoxesCount()}/{this.props.subpallet.boxes_total})
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.fillTransfer} color="secondary">
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>

                            <Typography variant="caption">Total de cajas añadidas: {<b>{this.state.addedBoxes}</b>}</Typography>
                            <Typography variant="caption">Total de cajas removidas: {<b>{this.state.removedBoxes}</b>}</Typography>

                            <Paper
                                style={{
                                    width: '100%',
                                    maxHeight: '200px',
                                    overflowY: 'scroll',
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                <List>
                                    {batchesList.map(v => (
                                        <ListItem key={v[0]}>
                                            <Card style={{padding: '8px', width: '100%'}}>
                                                <Typography variant="caption">
                                                    Origen: <b>{v[0]}</b>
                                                </Typography>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Typography inline={true} variant="subtitle1">
                                                            Cajas actuales <b>{this.state.originalBoxesCount.get(v[0])}</b>&nbsp;
                                                        </Typography>
                                                        <Typography inline={true} variant="subtitle1">
                                                            :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Input type="number" style={{textAlign: 'right'}} value={v[1].number_boxes} id={v[0]} onChange={this.handleTransferBoxes} />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>

                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{marginBottom: 4}}>
                                        <b>Añadir origen</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <CustomTextField
                                        margin={'0 auto'}
                                        width={'100%'}
                                        label="Código del origen"
                                        value={this.state.newOriginCode}
                                        error={false}
                                        name="barcode_pallet"
                                        variant={'outlined'}
                                        type="string"
                                        onChange={this.handleChangeOriginCode}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <CustomButton width={'100%'} bgcolor="blue" onClick={this.addOrigin}>
                                        {'Buscar'}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.props.handleClose} color="secondary">
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (this.state.addedBoxes === 0 && this.state.removedBoxes === 0) return;
                                    this.setState({...this.state, showConfirmDialog: true});
                                }}
                            >
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.showConfirmDialog}>
                        <DialogTitle>Editar composición</DialogTitle>
                        <DialogContent>
                            <List>
                                {batchesList.map(v => (
                                    <ListItem key={v[0]}>
                                        <Typography variant="caption">
                                            Origen: <b>{v[0]}</b> ({this.state.originalBoxesCount.get(v[1].erp_batch_code)} &#8594;
                                            <b>{v[1].number_boxes}</b>)
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Grid container alignItems="center" spacing={8} style={{margin: 8}}>
                                <Grid item xs>
                                    <CustomButton
                                        onClick={() => {
                                            this.setState({...this.state, showConfirmDialog: false});
                                        }}
                                        width={'100%'}
                                        bgcolor="red"
                                    >
                                        Cancelar
                                    </CustomButton>
                                </Grid>
                                <Grid item xs>
                                    <CustomButton onClick={this.updateOrigins} width={'100%'} bgcolor="green">
                                        Aceptar
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.showSpinner}
                        PaperProps={{
                            style: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <DialogContent>
                            <CircularProgress
                                style={{
                                    color: 'blue',
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                </>
            )
        );
    }
}
