import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {FlexRow, FlexRowItem} from './Wrap';
import {CustomButton, CustomTextField} from '../../../../../shared/style';
import {TableModal} from '../../../../../shared/components/Modal';
import {LanePtableState, LanePtableStatePalletSubpallet, LanePtableStatePalletSubpalletBatch} from '../../store/types';
import {PalletsServices} from '../../../pallets/store/services';
import {notify} from '../../../../../utils/Notification';
import {PalletInfoSelectTargetPtableModal} from './PalletInfoSelectTargetPtableModal';
import {IsInRuleset} from '../../../../../global/authorization/grantSet';
import {PalletCompleteInfoModal} from '../../../../global/palletInfo/PalletInfoModal';
import {CustomDialog} from '../../../../../shared/components/CustomDialog';
import {EnqueuePalletsControl} from './EnqueuePalletsControl';
import {fetchWithFeedback} from '../../../../../utils/fetcherValidate';
import {PalletInfoPallet} from '../../../../global/palletInfo/types';

type Props = {
    ptable: LanePtableState;
    auto_palletise: boolean;
    ptables: LanePtableState[];
    onClose: (isUpdated: boolean) => void;
    open: boolean;
};

type State = {
    selectedBatch: LanePtableStatePalletSubpalletBatch | null;
    openPalletCompleteInfoModal: boolean;
    openPalletsQueueModal: boolean;
    openConfirmationFinishPalletDialog: boolean;
    openConfirmationRemoveBoxes: boolean;
    openPalletsSelectTargetPalletModal: boolean;
};

export const PalletInfoModal: React.FunctionComponent<Props> = props => {
    const {ptable, auto_palletise, ptables, onClose, open} = props;

    const pallet = ptable.pallet;

    const [state, setState] = useState<State>({
        selectedBatch: null,
        openPalletCompleteInfoModal: false,
        openPalletsQueueModal: false,
        openConfirmationFinishPalletDialog: false,
        openConfirmationRemoveBoxes: false,
        openPalletsSelectTargetPalletModal: false,
    });

    if (pallet) {
        pallet.current_subpallet = pallet.subpallets.find((subpallet: LanePtableStatePalletSubpallet) => subpallet.id === ptable.pallet?.current_subpallet_id)!;
    }

    const currentSubpallet = pallet?.current_subpallet;

    const [boxesToTransfer, setBoxesToTransfer] = useState<number>(0);

    useEffect(() => {
        if (pallet && currentSubpallet) {
            setBoxesToTransfer(currentSubpallet.boxes_count);
        }
    }, [pallet, currentSubpallet]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'boxes_count') {
            if (ptable && ptable.pallet) {
                const newValue = Number(e.target.value);
                if (newValue >= 0) {
                    setBoxesToTransfer(newValue);
                }
            }
        }
    };

    const handleCloseSelectTargetPtableModal = (isTransfered: boolean = false) => {
        setState({...state, openPalletsSelectTargetPalletModal: false});
        if (isTransfered) {
            onClose(true);
        }
    };

    const handleClosePalletCompleteInfoModal = (pallet?: PalletInfoPallet) => {
        setState({...state, openPalletCompleteInfoModal: false});
        if (pallet) {
            onClose(true);
        }
    };

    const transferBoxes = (batch: LanePtableStatePalletSubpalletBatch) => {
        setState({
            ...state,
            openPalletsSelectTargetPalletModal: true,
            selectedBatch: batch,
        });
    };

    const removeBoxesToPallet = async () => {
        let res;
        if (ptable.pallet && ptable.pallet.current_subpallet) {
            const pallet = ptable.pallet;
            const currentSubpallet = pallet.current_subpallet;

            const numberBoxesToRemove = currentSubpallet.boxes_count - boxesToTransfer;
            const palletId = pallet.id;
            const ptableId = ptable.ptable_id;
            const currentdate = new Date();
            const datetime =
                currentdate.getFullYear() +
                '-' +
                (currentdate.getMonth() + 1) +
                '-' +
                currentdate.getDate() +
                '_' +
                currentdate.getHours() +
                ':' +
                currentdate.getMinutes() +
                ':' +
                currentdate.getSeconds();

            res = await fetchWithFeedback(PalletsServices.remove(ptableId, palletId, numberBoxesToRemove, datetime), {
                returnConfirmation: true,
                errorMessage: 'No se ha podido actualizar el valor',
                successMessage: 'Valor actualizado correctamente',
            });

            if (res) {
                onClose(true);
            }
        }
    };

    const updatePallet = async (close = true) => {
        if (boxesToTransfer >= 0 && ptable && ptable.pallet) {
            const newValue = boxesToTransfer;
            const palletId = ptable.pallet.id;
            const ptableId = ptable.ptable_id;

            //Comprobar si el valor está entre 0 y el valor actual
            if (checkBoxesAmountToRemove()) {
                setState({...state, openConfirmationRemoveBoxes: true});
            } else if (checkBoxesAmount()) {
                const currentdate = new Date();
                const datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    ':' +
                    currentdate.getMinutes() +
                    ':' +
                    currentdate.getSeconds();

                const res = await fetchWithFeedback(PalletsServices.update(ptableId, palletId, newValue, datetime), {
                    returnConfirmation: true,
                    errorMessage: 'No se ha podido actualizar el valor',
                    successMessage: 'Valor actualizado correctamente',
                });

                if (close) {
                    onClose(true);
                }

                return res;
            }
        }
    };

    const getTextConfirmationRemoveBoxes = () => {
        let textConfirmationRemoveBoxes = '';

        if (ptable.pallet && boxesToTransfer >= 0 && ptable.pallet && ptable.pallet.current_subpallet) {
            const pallet = ptable.pallet;
            const currentSubpallet = pallet.current_subpallet;
            textConfirmationRemoveBoxes = '¿Está seguro que quiere desvincular ' + (currentSubpallet.boxes_count - boxesToTransfer) + ' cajas del palé?';
        }
        return textConfirmationRemoveBoxes;
    };

    const finishPallet = async () => {
        if (ptable.pallet) {
            const pallet = ptable.pallet;
            const res = await updatePallet(false);
            if (res) {
                await fetchWithFeedback(PalletsServices.finish(pallet.id), {
                    errorMessage: 'Error al finalizar pale',
                    successMessage: 'Pale finalizado con exito',
                });
            } else {
                notify({
                    message: 'No se puede finalizar el pale',
                    status: 'error',
                });
            }

            setState({...state, openConfirmationFinishPalletDialog: false});
            onClose(true);
        }
    };

    const checkBoxesAmount = (): boolean => {
        let isValid = false;

        if (!isNaN(boxesToTransfer) && ptable.pallet) {
            const pallet = ptable.pallet;
            const currentSubpallet = pallet.current_subpallet;

            const newValue = boxesToTransfer;
            const maxValue = currentSubpallet.boxes_total;
            const minValue = currentSubpallet.boxes_count;

            if (newValue <= maxValue && newValue >= minValue) {
                isValid = true;
            } else {
                notify({
                    message: `El valor ha de estar comprendido entre ${minValue} y ${maxValue}`,
                    status: 'error',
                });
            }
        }

        return isValid;
    };

    const checkBoxesAmountToRemove = (): boolean => {
        let isRemove = false;
        if (boxesToTransfer >= 0 && ptable.pallet && ptable.pallet.current_subpallet) {
            const pallet = ptable.pallet;
            const currentSubpallet = pallet.current_subpallet;

            const newValue = boxesToTransfer;
            const maxValue = currentSubpallet.boxes_count;
            const minValue = 0;

            if (newValue < maxValue && newValue >= minValue) {
                isRemove = true;
            }
        }
        return isRemove;
    };

    return (
        <>
            {/* REMOVE PALLET BOXES CONFIRMATION DIALOG */}

            <CustomDialog
                title="Confirmación"
                contentText={getTextConfirmationRemoveBoxes()}
                handleClose={() => setState({...state, openConfirmationRemoveBoxes: false})}
                open={state.openConfirmationRemoveBoxes}
                handle={() => {
                    removeBoxesToPallet();
                    setState({...state, openConfirmationRemoveBoxes: false});
                }}
            />

            {/* CONFIRMATION FINISH PALLET DIALOG */}

            <CustomDialog
                open={state.openConfirmationFinishPalletDialog}
                title="Alerta"
                contentText={`¿Desea finalizar el palé con  ${boxesToTransfer} cajas?`}
                handle={() => finishPallet()}
                handleClose={() => setState({...state, openConfirmationFinishPalletDialog: false})}
            />

            {IsInRuleset(['LANEMANAGER_COMMON_TOTAL']) && (
                <EnqueuePalletsControl
                    palletsQueue={ptable.pallets_queue}
                    currentPalletBarcode={ptable.pallet?.barcode ?? ''}
                    ptableId={ptable.ptable_id}
                    prodpartId={ptable.assigned_to}
                    open={state.openPalletsQueueModal}
                    handleClose={() => setState({...state, openPalletsQueueModal: false})}
                />
            )}

            {/* PALLET INFO SELECT TARGET PTABLE MODAL */}

            {state.selectedBatch && (
                <PalletInfoSelectTargetPtableModal
                    ptables={ptables}
                    onClose={(isTransfered: boolean) => handleCloseSelectTargetPtableModal(isTransfered)}
                    open={state.openPalletsSelectTargetPalletModal}
                    selectedPtablePrevious={ptable}
                    selectedBatch={state.selectedBatch}
                />
            )}

            {/* PALLET COMPLETE INFO MODAL */}

            {ptable.pallet && (
                <PalletCompleteInfoModal
                    open={state.openPalletCompleteInfoModal}
                    onClose={(pallet?: PalletInfoPallet) => handleClosePalletCompleteInfoModal(pallet)}
                    palletBarcode={ptable.pallet.barcode}
                />
            )}

            {/* PALLET INFO MODAL */}

            <TableModal open={open} title={ptable.name} handleClose={() => onClose(false)}>
                <Typography
                    component="div"
                    style={{
                        marginBottom: 10,
                        marginTop: 5,
                        minHeight: 200,
                        width: 500,
                    }}
                >
                    {ptable.pallet && ptable.pallet.subpallets.length > 1 && ptable.pallet.current_subpallet && ptable.pallet.current_subpallet.merchandise && (
                        <>
                            <Typography
                                style={{
                                    width: '97%',
                                    textAlign: 'center',
                                    padding: '5px',
                                    backgroundColor: 'rgba(228,255,210,0.87)',
                                    marginBottom: '10px',
                                    border: '1px solid #90C26FDD',
                                    borderRadius: '5px',
                                }}
                            >
                                Subpalé actual: <b>{ptable.pallet?.current_subpallet.merchandise.name ?? ''}</b>
                            </Typography>
                        </>
                    )}

                    <FlexRow>
                        {auto_palletise && (
                            <FlexRowItem>
                                <CustomTextField
                                    margin={'0 auto'}
                                    width={'100%'}
                                    label="Cajas actuales"
                                    error={false}
                                    value={boxesToTransfer}
                                    name="boxes_count"
                                    variant={'outlined'}
                                    type="number"
                                    onChange={handleChange}
                                />
                            </FlexRowItem>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                flexGrow: 1,
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                fontSize: '1.2rem',
                                backgroundColor: 'rgba(228,255,210,0.87)',
                                padding: '12px',
                                border: '1px solid #90C26FDD',
                                borderRadius: '5px',
                            }}
                        >
                            {ptable.pallet && ptable.pallet.current_subpallet && (
                                <div style={{flexGrow: 1, textAlign: 'center'}}>
                                    {(ptable.pallet && ptable.pallet.current_subpallet && ptable.pallet.current_subpallet.boxes_count) || '-'}/
                                    {(ptable.pallet && ptable.pallet.current_subpallet && ptable.pallet.current_subpallet.boxes_total) || '-'}
                                </div>
                            )}
                        </div>
                    </FlexRow>

                    {auto_palletise ? (
                        <>
                            <Grid container alignItems="center" spacing={8}>
                                <Grid item xs>
                                    <CustomButton onClick={() => updatePallet(true)} width={'100%'} bgcolor="grey">
                                        Actualizar
                                    </CustomButton>
                                </Grid>
                                <Grid item xs>
                                    <CustomButton onClick={() => setState({...state, openConfirmationFinishPalletDialog: true})} width={'100%'}>
                                        Finalizar
                                    </CustomButton>
                                </Grid>
                            </Grid>

                            {(ptable.pallet &&
                                ptable.pallet.current_subpallet &&
                                ptable.pallet.current_subpallet.batches &&
                                ptable.pallet.current_subpallet.batches.map((batch: LanePtableStatePalletSubpalletBatch) => {
                                    if (ptable.pallet) {
                                        return (
                                            <Typography
                                                component="div"
                                                style={{
                                                    marginTop: 10,
                                                    display: 'flex',
                                                    flexFlow: 'row wrap',
                                                    justifyContent: 'space-between',
                                                    padding: '0px 6px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span>
                                                    {} Origen {batch.erp_origin_code}
                                                </span>
                                                <span
                                                    style={{
                                                        flexGrow: 1,
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        paddingRight: '20px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '35px',
                                                        }}
                                                    >
                                                        <span>&rarr;</span>
                                                        <span style={{textAlign: 'right'}}>{batch.boxes}</span>
                                                    </div>
                                                </span>
                                                <CustomButton onClick={() => transferBoxes(batch)} width={'110px'} height={'20px'} padding={'0px'} margin={'3px'}>
                                                    Transferir
                                                </CustomButton>
                                            </Typography>
                                        );
                                    } else return undefined;
                                })) ||
                                'No tiene cajas de orígenes'}

                            <div style={{height: '16px'}} />
                            {IsInRuleset(['LANEMANAGER_COMMON_TOTAL']) && ptable && (
                                <Grid container alignItems="center" spacing={8}>
                                    <Grid item xs>
                                        <CustomButton onClick={() => setState({...state, openPalletsQueueModal: true})} width={'100%'} bgcolor="green">
                                            Palés en cola ({ptable.pallets_queue.length})
                                        </CustomButton>
                                    </Grid>
                                    <Grid item xs>
                                        <CustomButton onClick={() => setState({...state, openPalletCompleteInfoModal: true})} width={'100%'} bgcolor="blue">
                                            Modificar palé
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            <CustomButton onClick={() => setState({...state, openConfirmationFinishPalletDialog: true})} width={'100%'}>
                                Finalizar
                            </CustomButton>
                        </>
                    )}
                </Typography>
            </TableModal>
        </>
    );
};
