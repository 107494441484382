import * as React from 'react';
import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomDropdown, CustomTextField, WrapCreateButton} from 'src/shared/style';
import styled from 'styled-components';
import {notify} from 'src/utils/Notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchWithFeedback} from '../../../utils/fetcherValidate';
import {ShiftsMapService} from '../../../shared/components/services';

type CreateProps = {
    open: boolean;
    totalNumBoxes: number;
    dumpedNumBoxes: number;
    availableBoxes: number;
    barcode: string;
    username: string;
    handleClosePostDump: () => void;
    handleCreatePostDump: (originCode : string,
                        boxCount : number,
                        workshiftDate : string,
                        workshiftType : string) => void;
};

type State = Readonly<{
    numBoxes: number;
    enablePostButton: boolean;
    shift_name: string;
    shift_id: string;
    shift_date: string;
    shiftsList: Array<{id: string; name: string}>;
}>;

const initialState = {
    numBoxes: 100,
    enablePostButton: true,
    shift_name: '',
    shift_id: '',
    shift_date: '',
    shiftsList: [],
};

export class CreatePostDumpWithInfoModal extends React.Component<CreateProps, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({numBoxes: nextProps.availableBoxes});
        this.setState({enablePostButton: true});
    }

    componentDidMount() {
        this.fetchShiftsMap();
        let numBoxes = this.props.totalNumBoxes - this.props.dumpedNumBoxes;
        this.setState({...this.state, numBoxes})
    }

    private fetchShiftsMap = async () => {
        const shiftsList : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shiftsList});
    }

    private createPostDump = async () => {
        if (this.state.numBoxes > this.props.availableBoxes || this.state.numBoxes <= 0) {
            notify({
                message: 'Número no válido de cajas. Debe ser un número entre 1 y ' + this.props.availableBoxes,
                status: 'warning',
            });
            return;
        }

        if (!this.props.barcode || this.state.numBoxes < 1 || !this.state.shift_date || !this.state.shift_id) {
            notify({message: 'Hay que rellenar todos los campos. Por favor, revise el formulario.', status: 'error'});
            return;
        }

        this.setState({enablePostButton: false});
        await this.props.handleCreatePostDump(this.props.barcode, this.state.numBoxes, this.state.shift_date, this.state.shift_id);
        this.setState({enablePostButton: true});
    };

    private handleChangeBoxes = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        e.persist();
        this.setState(() => ({numBoxes: e.target.value} as any));
    };

    public render() {

        const {shift_id, shift_date, shiftsList} = this.state;

        return (
            <>
                <TableModal title={'Crear volcado a posteriori'} open={this.props.open} handleClose={() => this.props.handleClosePostDump()}>
                    <BoxText>
                        {'Código introducido: '} {this.props.barcode}
                    </BoxText>
                    <BoxText>
                        {'Código Origen: '} {this.props.barcode}
                    </BoxText>
                    <BoxText>
                        {'Cajas totales: '} {this.props.totalNumBoxes}
                    </BoxText>
                    <BoxText>
                        {'Cajas ya volcadas: '} {this.props.dumpedNumBoxes}
                    </BoxText>
                    <BoxField style={{marginTop: '10px'}}>
                        <CustomTextField
                            margin={'0 auto'}
                            width={'100%'}
                            label='Número cajas a volcar'
                            error={false}
                            value={this.state.numBoxes}
                            name='boxes_count'
                            variant={'outlined'}
                            type='number'
                            onChange={this.handleChangeBoxes}
                        />
                    </BoxField>
                    <BoxField>
                        <CustomTextField
                            margin={'15px 5px 10px 0'}
                            width={'100%'}
                            label="Fecha"
                            error={false}
                            value={shift_date}
                            name="workShift"
                            variant={'outlined'}
                            type="date"
                            shrink={true}
                            onChange={this.handleInputs('shift_date')}
                        />
                    </BoxField>
                    <BoxField>
                        <CustomDropdown
                            title={'Turno'}
                            margin={'10px 5px 10px 0'}
                            data={shiftsList}
                            dropdownName={'center'}
                            value={shift_id}
                            handleChoose={e => this.setState({shift_id: e.target.value})}
                        />
                    </BoxField>
                    <WrapCreateButton>
                        <CustomButton
                            bgcolor={'#00D4AE'}
                            margin={'auto'}
                            disabled={!this.state.enablePostButton}
                            onClick={() => {
                                this.createPostDump();
                            }}
                        >
                            {!this.state.enablePostButton ? <CircularProgress color='secondary' style={{
                                height: 24,
                                width: 24,
                            }} /> : 'Crear volcado a posteriori'}
                        </CustomButton>
                    </WrapCreateButton>
                </TableModal>
            </>
        );

    }

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<State, keyof State>);
    };
}

const BoxField = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 39px;
    }
    height: 59px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
`;

const BoxText = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 30px;
    }
    height: 30px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
`;
