import axios from 'axios';

import {API_URL} from 'src/global/api';

const apiUrl = API_URL();
// console.log('URL>>', apiUrl);
export const fetcher = axios.create({
    baseURL: apiUrl,
    //timeout: 14000,
    timeout: 200000,
    withCredentials: true,
    validateStatus: status => {
        return status >= 200; // default
    },
});
