import axios, {AxiosPromise} from 'axios';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import subWeeks from 'date-fns/sub_weeks';
import debounce from 'lodash/debounce';
import invoke from 'lodash/invoke';
import React, {Component} from 'react';
import DatePicker, {DatePickerProps} from 'react-date-picker';
import isEqual from 'react-fast-compare';
import {connect} from 'react-redux';
import ReactTable, {Filter, FilterRender, SortingRule, TableProps} from 'react-table';
import {Dispatch} from 'redux';
import styled from 'styled-components';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {Button, FilledInput, InputLabel, MenuItem, Select, Typography, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import ClearAll from '@material-ui/icons/ClearAll';
import CloudUpload from '@material-ui/icons/CloudUpload';
import FilterIcon from '@material-ui/icons/FilterList';
import FilterList from '@material-ui/icons/FilterList';
import GetApp from '@material-ui/icons/GetApp';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import Refresh from '@material-ui/icons/Refresh';
import {PaginatedTableActions} from 'src/shared/components/store/paginatedTable.actions';
import {OCard, StyledDatePicker, WrapFullContainerNowrap} from 'src/shared/style';
import {ColumnType, PaginatedServiceArgs} from 'src/types';
import {fetchPaginateHelper} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {PaginatedTableSelectors} from 'src/shared/components/store/paginatedTable.selectors';
import {AppStoreState} from 'src/store';
import {buildURL} from 'src/utils/buildURL';
import {Spinner} from 'src/app/global/spinner';
import {Advertisement} from './Advertisement';
import {NotifyActionType} from 'src/utils/Notification/action-types';

type Props<T = any> = {
    columnFormat: ColumnType;
    service: ({params, args}: PaginatedServiceArgs) => AxiosPromise<PaginateResponse<T>>;
    defaultPageSize: number;
    noPaginationComponent?: boolean;
    showSizeOptions?: boolean;
    loading?: boolean;
    propsToOwnTable?: {[k: string]: any};
    autorefresh?: boolean;
    autorefreshDelay?: number;
    defaultSortedProp?: SortingRule[];
    filtered?: Filter[];
    options?: boolean;
    excel?: boolean;
    pdf?: boolean;
    toStore?: string;
    saveLastFilterToStore?: boolean;
    upload?: boolean;
    handleGetEntries?: (entries: any[]) => void;
    handleExtraFilter?: () => void;
    extraFilter?: boolean;
    getTableFetchHook?(fn: (...args: any[]) => void): void;
    createHook?(): void;
    customFilterFunction?(data: T): T;
    serviceArgs?: string[] | string;
    additionalButtons?: Array<{
        icon: JSX.Element;
        tooltipText: string;
        onClick(): void;
    }>;
    twoWeeksFilter?: boolean;
};
type DProps = {
    dispatch: Dispatch;
};
type SProps = {
    selfFilters: (id: string) => ReturnType<typeof PaginatedTableSelectors.getFilters>;
};

type State = {
    entries: any[];
    pages: number;
    filtered: Array<{id: string; value: string | boolean}>;
    date: {[k: string]: Date | Date[] | undefined};
    dropdownFilter: {[k: string]: any};
    uploading: boolean;
    uploadProgress: number;
    expanded: {[k: string]: any};
    loading: boolean;
    pending: boolean;
    showAd: boolean;
    messageAd: string;
    typeAd: NotifyActionType;
    pageSize: number;
};

type ComponentProps<T> = Props<T> & DProps & SProps;

export class PaginatedTable<T = any> extends Component<ComponentProps<T>, State> {
    static defaultProps = {options: true};
    readonly state: State = {
        entries: [],
        pages: 0,
        filtered: [],
        dropdownFilter: {},
        date: {},
        uploading: false,
        uploadProgress: 0,
        expanded: {},
        loading: false,
        pending: false,
        showAd: false,
        messageAd: '',
        typeAd: NotifyActionType.success,
        pageSize: 0,
    };
    private injectedColumns: ColumnType<T> = [];
    private paginateTemp: any;
    private lastPaginateParams: any;
    private debounced = debounce((fn: () => any) => fn(), 400);
    private timer: any;
    private serviceUrl: string | undefined = '';
    private inputRef = React.createRef<HTMLInputElement>();
    private isLoading = false;

    componentDidMount() {
        if (!this.props.saveLastFilterToStore && this.props.toStore) this.props.dispatch(PaginatedTableActions.clear({id: this.props.toStore}));
        this.paginateTemp = undefined;
        this.lastPaginateParams = undefined;

        this.injectDatePickers();
        this.injectDateFormats();
        this.injectDropdownFilters();
        this.injectCenterFormats();
        if (typeof this.props.getTableFetchHook === 'function') this.props.getTableFetchHook(this.reloadData);
        if (this.props.autorefresh) {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.reloadData();
            }, this.props.autorefreshDelay || 5000);
        }
    }

    private hideAd = () => {
        this.setState({
            ...this.state,
            showAd: false,
            messageAd: '',
            typeAd: NotifyActionType.success,
        });
    };

    private getFeedbackImportFile(res: any) {
        if (res.data && res.data.message) {
            let typeAd = NotifyActionType.success;
            if (res.status !== 200) {
                typeAd = NotifyActionType.error;
            }
            const messageAd = res.data.message + this.getConflictsToAd(res.data);
            this.setState({...this.state, messageAd: messageAd});
            this.setState({...this.state, typeAd: typeAd});
            this.setState({...this.state, showAd: true});
        }
    }

    private getConflictsToAd(data: any) {
        let messageConflicts = '<ul>';
        if (data.conflicts && data.conflicts.length !== 0) {
            data.conflicts.forEach((conflictObject: any) => {
                if (conflictObject.errors) {
                    conflictObject.errors.forEach((errorText: any) => {
                        messageConflicts += '<li>[Registro ' + conflictObject.row + ']: ' + errorText + '</li>';
                    });
                }
            });
        }
        messageConflicts += '</ul>';
        return messageConflicts;
    }

    componentWillUnmount() {
        this.setState({
            ...this.state,
            entries: [],
            pages: 0,
            filtered: [],
            dropdownFilter: {},
            date: {},
            uploading: false,
            uploadProgress: 0,
            expanded: {},
        });
        if (this.props.autorefresh) clearInterval(this.timer);

        this.injectedColumns = [];
        this.paginateTemp = undefined;
        this.lastPaginateParams = undefined;
        if (!this.props.saveLastFilterToStore && this.props.toStore) this.props.dispatch(PaginatedTableActions.clear({id: this.props.toStore}));
    }

    render() {
        const {
            defaultPageSize,
            showSizeOptions,
            //loading,
            excel,
            pdf,
            propsToOwnTable,
            createHook,
            defaultSortedProp,
            options,
            upload,
            twoWeeksFilter,
            autorefresh,
            additionalButtons,
            noPaginationComponent,
            extraFilter,
        } = this.props;
        const {filtered, entries, pages, uploading, expanded, pageSize} = this.state;
        return (
            <WrapFullContainerNowrap>
                <Spinner isLoading={this.state.loading} />
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.state.messageAd} type={this.state.typeAd} autoHideDuration={300000} />
                <OCard width={'100%'} height={'100%'} contentHeight={'100%'} contentPadding={0}>
                    <Typography component="div" style={{height: '100%', margin: 'auto'}}>
                        {options && (
                            <OptionsDiv>
                                {!autorefresh && (
                                    <Tooltip title="Recargar" color="secondary" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => {
                                                notify({
                                                    message: 'Se están actualizado los datos',
                                                    status: 'notify',
                                                    timeout: 2000,
                                                });
                                                this.reloadData();
                                            }}
                                        >
                                            <Refresh color="secondary" />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip title="Limpiar filtros" placement="bottom">
                                    <Button
                                        color="secondary"
                                        style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                        onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                filtered: [],
                                                date: {},
                                                dropdownFilter: {},
                                            });
                                            if (this.paginateTemp) this.paginateTemp.filtered = [];
                                            this.fetchData(this.paginateTemp);
                                        }}
                                    >
                                        <ClearAll />
                                    </Button>
                                </Tooltip>
                                {extraFilter && (
                                    <Tooltip title="Filtros adicionales" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => {
                                                if (this.props.handleExtraFilter) {
                                                    this.props.handleExtraFilter();
                                                }
                                            }}
                                        >
                                            <FilterList />
                                        </Button>
                                    </Tooltip>
                                )}
                                {pdf && (
                                    <Tooltip title="Descargar PDF" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => {
                                                if (this.serviceUrl) window.open(buildURL(this.serviceUrl + '/pdf?', this.transformToPaginatingParams(this.lastPaginateParams)));
                                            }}
                                        >
                                            <PictureAsPdfIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                                {excel && (
                                    <Tooltip title="Descargar Excel" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => {
                                                if (this.serviceUrl) window.open(buildURL(this.serviceUrl + '/excel?', this.transformToPaginatingParams(this.lastPaginateParams)));
                                            }}
                                        >
                                            <GetApp />
                                        </Button>
                                    </Tooltip>
                                )}
                                {upload && (
                                    <>
                                        <Tooltip title="Subir Excel con datos" placement="bottom">
                                            <Button color="secondary" style={{padding: 0}}>
                                                {!uploading ? (
                                                    <label
                                                        htmlFor="excelInput"
                                                        style={{
                                                            display: 'flex',
                                                            zIndex: 20,
                                                            cursor: 'pointer',
                                                            padding: 0,
                                                            margin: 0,
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <CloudUpload style={{margin: 'auto'}} />
                                                    </label>
                                                ) : (
                                                    <div style={{display: 'flex'}}>
                                                        <CircularProgress
                                                            style={{height: 24, width: 24, margin: 'auto'}}
                                                            variant="static"
                                                            color="secondary"
                                                            value={this.state.uploadProgress}
                                                        />
                                                    </div>
                                                )}
                                            </Button>
                                        </Tooltip>
                                        <input
                                            style={{visibility: 'hidden', width: 0}}
                                            ref={this.inputRef}
                                            name="excel"
                                            id="excelInput"
                                            type="file"
                                            value=""
                                            onChange={event => {
                                                this.uploadFile(event);
                                                event.target.value = '';
                                            }}
                                        />
                                    </>
                                )}
                                {typeof createHook === 'function' && (
                                    <Tooltip title="Crear nuevo" placement="bottom">
                                        <Button color="secondary" style={{zIndex: 20, cursor: 'pointer', padding: 0}} onClick={createHook}>
                                            <PlaylistAdd />
                                        </Button>
                                    </Tooltip>
                                )}
                                <div style={{flexGrow: 1}} />
                                {twoWeeksFilter && (
                                    <Tooltip title="Filtrar últimas dos semanas" placement="bottom">
                                        <Button color="secondary" style={{zIndex: 20, cursor: 'pointer', padding: 0}} onClick={this.twoWeeksFilter}>
                                            <FilterIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                                {invoke(additionalButtons, 'map', (data: {icon: JSX.Element; tooltipText: string; onClick(): void}, index: number) => (
                                    <Tooltip title={data.tooltipText} key={index} placement="bottom">
                                        <Button color="secondary" style={{zIndex: 20, cursor: 'pointer', padding: 0}} onClick={data.onClick}>
                                            {data.icon}
                                        </Button>
                                    </Tooltip>
                                ))}
                            </OptionsDiv>
                        )}
                        <ReactTable
                            filtered={[...filtered, ...(this.props.filtered ?? [])]}
                            style={{
                                height: `calc(100% - ${options ? '36px' : '0px'})`,
                                width: '100%',
                            }}
                            manual={true}
                            defaultSorted={defaultSortedProp ?? []}
                            data={[...entries] || []}
                            onFetchData={this.controlForPaginate}
                            resizable={false}
                            sortable={true}
                            className="-striped -highlight"
                            columns={this.injectedColumns.length ? [...this.injectedColumns] : []}
                            previousText={'Anterior'}
                            nextText={'Siguiente'}
                            loadingText={'Obteniendo datos...'}
                            noDataText={'No hay entradas'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'líneas'}
                            showPageSizeOptions={!!showSizeOptions}
                            defaultPageSize={defaultPageSize || 20}
                            pages={pages}
                            pageSize={pageSize}
                            pageSizeOptions={this.addDefaultPagesize()}
                            loading={(this.state.loading || this.isLoading) && entries.length < 1}
                            PaginationComponent={noPaginationComponent ? () => null : undefined}
                            getTheadFilterThProps={() => ({
                                style: {overflow: 'inherit'},
                            })}
                            expanded={expanded}
                            getTrProps={(state: any, rowInfo: any) => {
                                if (rowInfo && rowInfo.row) {
                                    let isExpanded = false;
                                    if (state.expanded[rowInfo.index] !== undefined && state.expanded[rowInfo.index] !== false) {
                                        isExpanded = true;
                                    }
                                    return {
                                        style: {
                                            borderTop: isExpanded ? 'solid 2px #df3448c2' : '',
                                        },
                                    };
                                } else {
                                    return {
                                        style: {
                                            borderTop: '',
                                        },
                                    };
                                }
                            }}
                            onExpandedChange={(newExpanded, index) => {
                                if (newExpanded[index[0]] === false) {
                                    newExpanded = {};
                                } else {
                                    Object.keys(newExpanded).forEach(k => {
                                        newExpanded[k] = +k === index[0] ? {} : false;
                                    });
                                }
                                this.setState({...this.state, expanded: newExpanded});
                            }}
                            {...propsToOwnTable}
                        />
                    </Typography>
                </OCard>
            </WrapFullContainerNowrap>
        );
    }

    private twoWeeksFilter = () => {
        const findDateRangePickerOption = this.injectedColumns.find(ic => typeof ic.daterange !== undefined);
        if (findDateRangePickerOption) {
            const now = new Date();
            const twoWeeksAgo = subWeeks(now, 2);
            // date[findDateRangePickerOption.accessor as string] = [twoWeeksAgo, now];
            this.datePickerDataParser([twoWeeksAgo, now], findDateRangePickerOption.accessor as string);
        }
    };

    private uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const etf = e.target.files;
        if (etf !== null && etf.length) {
            this.setState({...this.state, uploading: true});
            const data = new FormData();
            data.append('file', etf[0], etf[0].name);
            try {
                const res = await axios.post(this.serviceUrl + '/upload-excel', data, {
                    withCredentials: true,
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            ...this.state,
                            uploadProgress: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                        if (ProgressEvent.loaded === ProgressEvent.total) {
                            notify({
                                message: 'Se ha subido su archivo',
                                status: 'success',
                            });
                            this.setState({...this.state, uploading: false});
                        }
                    },
                });
                if (res) {
                    this.getFeedbackImportFile(res);
                    this.reloadData();
                }
            } catch (error) {
                console.error('ERROR', error.response);
                this.setState({...this.state, uploading: false});
                this.getFeedbackImportFile(error.response);
                notify({
                    message: 'Ha surgido un error con la subida de su archivo',
                    status: 'error',
                });
            }
        }
    };

    private addDefaultPagesize = () => {
        const arr = [5, 10, 20, 25, 50, 100];
        if (this.props.defaultPageSize) {
            const exist = arr.find(it => it === this.props.defaultPageSize);
            if (exist) return arr;
            const index = arr.findIndex(it => it > this.props.defaultPageSize);
            if (index > -1) arr.splice(index, 0, this.props.defaultPageSize);
        }
        return arr;
    };

    /**
     * This function is responsible for the sorting,
     * filtering and the manual pagiantion, triggers
     * the debounce or not under neccesary conditions,
     * needs refactor probably
     *
     * @private
     * @memberof PaginatedTable
     */
    private controlForPaginate = (state?: any, instance?: any) => {
        const writeCache = () => {
            this.paginateTemp = state;
            this.paginateTemp.filtered = instance.state.filtered;
            stateTemp = state;
            stateTemp.filtered = instance.state.filtered;
        };
        let stateTemp: any;
        let fetched = false;
        if (state && instance && !isEqual(this.state.filtered, instance.state.filtered)) {
            if (state) {
                writeCache();
            } else stateTemp = this.paginateTemp;
            // console.log('hago debounce');
            this.debounced(() => this.fetchData(stateTemp));
            this.setState({...this.state, filtered: instance.state.filtered});
            fetched = true;
        } else if (!this.paginateTemp && !fetched) {
            writeCache();
            // console.log('hago normal');
            this.fetchData(stateTemp);
        } else if (!isEqual(state.sorted, this.paginateTemp.sorted)) {
            // console.log('hago sort', state.sorted, this.paginateTemp.sorted);
            writeCache();
            this.fetchData(stateTemp);
        } else if (state && this.paginateTemp.page !== state.page) {
            // console.log('hago pagination manual', this.paginateTemp.page, state.page);
            writeCache();
            this.fetchData(stateTemp);
        } else if (state && this.paginateTemp.pageSize !== state.pageSize) {
            // console.log('hago pagination manual', this.paginateTemp.page, state.page);
            writeCache();
            this.fetchData(stateTemp);
        }
    };

    private transformToPaginatingParams = ({pageSize, page, sorted, filtered}: TableProps, sum?: boolean) => {
        return {
            'page-size': pageSize || 20,
            page: page ? (sum ? page + 1 : page) : 1,
            sorted,
            filtered:
                filtered && filtered.length
                    ? filtered.reduce(
                          (acc: any, now: any) => ({
                              ...acc,
                              [now.id]: now.value,
                          }),
                          {},
                      )
                    : undefined,
        };
    };

    private fetchData = async (state?: any) => {
        if (this.state.loading) {
            this.setState({...this.state, pending: true});
            return;
        }

        if (this.props.loading) {
            this.setState({...this.state, loading: true});
        }

        if (typeof this.props.service === 'function') {
            const transformed = this.transformToPaginatingParams(state, true);
            this.props.toStore &&
                state &&
                this.props.dispatch(
                    PaginatedTableActions.add({
                        id: this.props.toStore,
                        payload: {
                            page: state.page,
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: state.filtered,
                        },
                    }),
                );
            this.isLoading = true;

            const res = await fetchPaginateHelper(
                this.props.service({
                    params: transformed,
                    args: this.props.serviceArgs,
                }),
                {returnUrl: this.props.excel || this.props.pdf},
            );

            this.isLoading = false;

            if (!this.state.pending) {
                if (res && res.data && Array.isArray(res.data.data)) {
                    const entries = [...res.data.data];
                    const pageSize = this.props.defaultPageSize || 20;
                    this.setState({
                        ...this.state,
                        pages: Math.ceil(res.data.total / res.data.per_page),
                        entries,
                        pageSize,
                        filtered: state.filtered,
                    });
                    this.serviceUrl = res.url;
                    if (this.props.handleGetEntries) {
                        this.props.handleGetEntries([...res.data.data]);
                    }
                }
                this.setState({...this.state, loading: false});
                this.lastPaginateParams = {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                };
                this.paginateTemp = state;
            } else {
                this.setState({...this.state, pending: false, loading: false}, () => this.fetchData(this.paginateTemp));
            }
        }
    };

    private reloadData = () => {
        if (this.lastPaginateParams) this.lastPaginateParams.filtered = this.state.filtered;
        this.fetchData(this.lastPaginateParams ? this.lastPaginateParams : {filtered: this.state.filtered});
    };

    private injectDatePickers() {
        if (!this.props.columnFormat) return;
        this.injectedColumns = this.props.columnFormat.map(cf => {
            if (cf.date) {
                cf.filterable = true;
                cf.Filter = this.datePickerFilter(cf.accessor as string, DatePicker);
            }
            if (cf.daterange) {
                cf.filterable = true;
                cf.Filter = this.datePickerFilter(cf.accessor as string, DateRangePicker);
            }
            return cf;
        });
    }

    private injectDropdownFilters() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.dropdownFilter) {
                ic.filterable = true;
                ic.Filter = this.dropdownFilter(
                    typeof ic.accessor === 'string' ? ic.accessor : ic.id || 'NO_DROPDOWN_ACCESSOR_OR_ID',
                    ic.dropdownFilterData || [{id: '1', name: 'SE_NECESITAN_DATOS_PARA_EL_COMPONENTE'}],
                );
                this.setState({
                    ...this.state,
                    dropdownFilter: {
                        ...this.state.dropdownFilter,
                        [typeof ic.accessor === 'string' ? ic.accessor : ic.id || 'NO_DROPDOWN_ACCESSOR_OR_ID']: ic.dropdownFilterData ? '' : '1',
                    },
                });
            }
            return ic;
        });
    }

    private injectDateFormats() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.dateFormat)
                ic.Cell = item => (
                    <>
                        {item.original &&
                            item.original[ic.accessor as string] &&
                            format(item.original[ic.accessor as string], 'DD/MM/YYYY', {
                                locale: es,
                            })}
                    </>
                );
            if (ic.dateTimeFormat)
                ic.Cell = item => (
                    <>
                        {item.original &&
                            item.original[ic.accessor as string] &&
                            format(new Date(item.original[ic.accessor as string]), 'DD/MM/YYYY HH:mm:ss', {
                                locale: es,
                            })}
                    </>
                );
            return ic;
        });
    }

    private injectCenterFormats() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.centerCell) ic.style = {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'};
            ic.headerStyle = {textTransform: 'uppercase'};
            return ic;
        });
    }

    private datePickerDataParser = (incoming: Date | Date[], name: string) => {
        let transformed = '';
        const data = incoming !== null ? incoming : undefined;
        if (data && !Array.isArray(data)) transformed = format(data, 'DD/MM/YYYY', {locale: es});
        if (data && Array.isArray(data))
            // we create the date range `${date}-${date}`
            transformed = data.reduce((acc, curr) => (acc ? acc + '-' + format(curr, 'DD/MM/YYYY', {locale: es}) : format(curr, 'DD/MM/YYYY', {locale: es})), '');
        const find = this.paginateTemp.filtered.find((item: any) => item.id === name);
        let addedOrMutated: State['filtered'] = [];
        if (data && find) {
            addedOrMutated = this.paginateTemp.filtered.map((f: any) => {
                if (f.id === name) f.value = transformed;
                return f;
            });
        } else if (data && !find) {
            addedOrMutated = this.paginateTemp.filtered.concat({
                id: name,
                value: transformed,
            });
        } else {
            const index = this.paginateTemp.filtered.findIndex((item: any) => item.id === name);
            if (index > -1) {
                addedOrMutated = this.paginateTemp.filtered;
                addedOrMutated.splice(index, 1);
            }
        }
        this.setState({...this.state, filtered: addedOrMutated, date: {...this.state.date, [name]: data}});
        this.fetchData({...this.paginateTemp, filtered: addedOrMutated});
        this.setState({
            date: {...this.state.date, [name]: data},
        });
    };

    private datePickerFilter = (name: string, Picker: (props: DatePickerProps) => JSX.Element): FilterRender => () => (
        <StyledDatePicker>
            <Picker calendarIcon={null} value={this.state.date[name]} onChange={d => this.datePickerDataParser(d, name)} />
        </StyledDatePicker>
    );

    private dropdownFilter = (name: string, values: Array<{id: string | boolean; name: string; [k: string]: any}>): FilterRender => () => {
        return (
            <DropdownForTable
                data={values}
                nullEntry={true}
                value={this.state.dropdownFilter[name] || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const {value} = e.target;
                    const indexOf = this.paginateTemp.filtered.findIndex((f: any) => f.id === name);
                    let modified = this.paginateTemp.filtered;
                    const extractFromValues = values.find(it => it.id === value);
                    if (indexOf > -1)
                        modified.splice(indexOf, 1, {
                            id: name,
                            value,
                        });
                    else if (indexOf < 0 && extractFromValues)
                        modified.push({
                            id: name,
                            value: extractFromValues.id,
                        });
                    if (!value) modified = modified.filter((it: any) => it.id !== name);
                    this.setState({
                        ...this.state,
                        dropdownFilter: {...this.state.dropdownFilter, [name]: value},
                        filtered: modified,
                    });
                    this.fetchData({...this.paginateTemp, filtered: modified});
                    this.setState({
                        dropdownFilter: {...this.state.dropdownFilter, [name]: value},
                    });
                }}
            />
        );
    };
}

export const PaginatedTableComponent = connect<SProps, DProps, Props, AppStoreState>(
    state => ({selfFilters: (id: string) => PaginatedTableSelectors.getFilters(id, state)}),
    dispatch => ({
        dispatch,
    }),
)(PaginatedTable);

const OptionsDiv = styled.div`
    height: 36px;
    flex-direction: row-reverse;
    display: flex;
`;

const DropdownForTable = withStyles({
    select: {
        padding: '2px 32px 1px 6px',
    },
    root: {
        height: 28,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
})((props: {data: any[]} & {[k: string]: any}) => {
    const unique = `dropdown-${!!props.title ? props.title.toLowerCase() : Math.ceil(Math.random() * 10000)}`;
    return (
        // <WrapCustomDropdown width={props.containerWidth} margin={props.margin}>
        <>
            <InputLabel htmlFor={unique}>{props.title}</InputLabel>
            <Select
                className={props.classes.root}
                classes={{select: props.classes.select}}
                value={props.value}
                onChange={props.onChange}
                error={!!props.error}
                style={{height: props.height}}
                input={
                    <FilledInput
                        // style={{padding: '20px 32px 12px 12px'}}
                        name={props.dropdownName}
                        value={props.value}
                        id={unique}
                    />
                }
            >
                {props.nullEntry && (
                    <MenuItem value={undefined}>
                        <em />
                    </MenuItem>
                )}
                {props.data &&
                    props.data.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </>
    );
});
