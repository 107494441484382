import * as React from 'react';
//import {CustomDialog} from 'src/shared/components/CustomDialog';
import {TableModal} from 'src/shared/components/Modal';
import {WrapCreateButton, CustomButton, CustomTextField} from 'src/shared/style';
import styled from 'styled-components';
//import {CustomTextField} from 'src/app/lanemanager/export/components/styled';
// import {DumpsServices} from './store/services';
import {notify} from 'src/utils/Notification';
import CircularProgress from '@material-ui/core/CircularProgress';

type CreateProps = {
    open: boolean;
    totalNumBoxes: number;
    dumpedNumBoxes: number;
    availableBoxes: number;
    barcode: string;
    username: string;
    handleClose: () => void;
    handleCreate: (barcodeLogisticunit: string, numberBoxes: number) => void;
};

const initialState = {
    numBoxes: 100,
    enableButton: true
};

type State = Readonly<{
    numBoxes: number;
    enableButton: boolean;
}>;

export class CreateDumpWithInfoModal extends React.Component<CreateProps, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({numBoxes: nextProps.availableBoxes});
        this.setState({enableButton: true});
    }

    private createDump = async () => {
        if(this.state.numBoxes > this.props.availableBoxes || this.state.numBoxes <= 0){
            notify({
                message: 'Número no válido de cajas. Debe ser un número entre 1 y '+this.props.availableBoxes,
                status: 'warning',
            });
        }else{
            this.setState({enableButton: false});
            await this.props.handleCreate(this.props.barcode, this.state.numBoxes);
            this.setState({enableButton: true});
        }        
    };

    private handleChangeBoxes = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        e.persist();
        this.setState(() => ({numBoxes: e.target.value} as any));
    };

    public render() {
        return (
            <>
                <TableModal title={'Crear volcado'} open={this.props.open} handleClose={() => this.props.handleClose()}>
                    <BoxText>
                        {'Código introducido: '} {this.props.barcode}
                    </BoxText>
                    <BoxText>
                        {'Código Origen: '} {this.props.barcode}
                    </BoxText>
                    <BoxText>
                        {'Cajas totales: '} {this.props.totalNumBoxes}
                    </BoxText>
                    <BoxText>
                        {'Cajas ya volcadas: '} {this.props.dumpedNumBoxes}
                    </BoxText>
                    <BoxField>
                        <CustomTextField
                            margin={'0 auto'}
                            width={'100%'}
                            label="Número cajas a volcar"
                            error={false}
                            value={this.state.numBoxes}
                            name="boxes_count"
                            variant={'outlined'}
                            type="number"
                            onChange={this.handleChangeBoxes}
                        />
                    </BoxField>
                    <WrapCreateButton>
                        <CustomButton
                            bgcolor={'#00D4AE'}
                            margin={'auto'}
                            disabled={!this.state.enableButton}
                            onClick={() => {
                                this.createDump();                                
                            }}
                        >
                            {!this.state.enableButton ? <CircularProgress color="secondary" style={{height: 24, width: 24}} /> : 'Crear volcado'}
                        </CustomButton>
                    </WrapCreateButton>
                </TableModal>
            </>
        );
    }
}

const BoxField = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 39px;
    }
    height: 59px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
`;

const BoxText = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 30px;
    }
    height: 30px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
`;
