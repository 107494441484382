import * as React from 'react';
import {connect} from 'react-redux';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {AreaControlServices} from 'src/app/administration/areaControl/store/services';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import { ScalesDeviceUnit } from './store/scalesDevicesControl.types';
import { ScalesDevicesControlServices } from './store/scalesDevicesControl.services';
import { ScalesDeviceForm } from './ScalesDeviceForm';
import { PtablesControlServices } from '../ptablesControl/store/ptablesControl.services';
import { Checkbox } from '@material-ui/core';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type State = Readonly<{
    open: boolean;
    areasList: Array<{id: string; name: string}>;
    ptablesList: Array<{id: string; name: string}>;
    selectedScalesDevice?: ScalesDeviceUnit;
}>;

const initialState: State = {
    open: false,
    selectedScalesDevice: undefined,
    areasList: [],
    ptablesList: []
};


export class ScalesDevicesControlBase extends React.Component<SProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchAreas();
        this.fetchPtables();
    }

    // private slectedEmployees: Array<{employeeId: string; rules: string[]}> = [];
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClose = () => this.setState({open: false, selectedScalesDevice: undefined});

    private handleEmptyOpen = () => this.setState({open: true});
    private handleOpen = (selectedScalesDevice?: ScalesDeviceUnit) => this.setState({open: true, selectedScalesDevice});

    private handleUpdateScalesDevice= () => this.useTableHook();

    private fetchAreas = async () => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreas(), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private fetchPtables = async () => {
        const ptablesList = await fetchWithFeedback(PtablesControlServices.getAll(), {
            accessor: 'ptables',
        });
        this.setState({ptablesList});
    };

    private columns: ColumnType<ScalesDeviceUnit> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Dirección IP',
            headerClassName: 'header_cell_string',
            accessor: 'ip',
            className: 'cell_string',
        },
        {
            Header: 'Puerto',
            headerClassName: 'header_cell_string',
            accessor: 'port',
            width: 70,
            className: 'cell_string',
        },
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            accessor: 'area_name',
            className: 'cell_string',
        },
        {
            Header: 'Mesa paletizado',
            headerClassName: 'header_cell_string',
            accessor: 'ptable_name',
            className: 'cell_string',
        },
        {
            Header: 'Solo lectura',
            accessor: 'only_reading',
            width: 150,
            Cell: item => <Checkbox checked={!!+item.original.only_reading} />,
            centerCell: true,
        },
        {
            Header: 'Activo',
            accessor: 'active',
            width: 150,
            Cell: item => <Checkbox checked={!!+item.original.active} />,
            centerCell: true,
        },
    ];

    render() {
        const {open, selectedScalesDevice, areasList, ptablesList} = this.state;
        return (
            IsInRuleset(['SCALESDEVICES_TOTAL', 'SCALESDEVICES_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Gestión de básculas'} open={open} handleClose={this.handleClose}>
                        <ScalesDeviceForm
                            areasList={areasList}
                            ptablesList={ptablesList}
                            scalesDevice={selectedScalesDevice}
                            handleClose={this.handleClose}
                            updateScalesDevice={this.handleUpdateScalesDevice}
                        />
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={ScalesDevicesControlServices.getAll}
                        getTableFetchHook={this.getTableHook}
                        accessor={'scalesDevices'}
                        createHook={IsInRuleset('SCALESDEVICES_TOTAL') ? this.handleEmptyOpen : undefined}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: () => IsInRuleset('SCALESDEVICES_TOTAL') && this.handleOpen(rowInfo.original),
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

export const ScalesDevicesControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
}))(ScalesDevicesControlBase);

const TableWrap = styled.div`
    width: 67%;
    height: 100%;
    margin: auto;
`;
