import * as React from 'react';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {PalletInfoSubpallet} from './types';
import styles from './ChangeProdpart.module.css';
import {TableModal} from 'src/shared/components/Modal';
import {FlexRow, FlexRowItem} from 'src/app/lanemanager/lane/components/PalletsInfo/Wrap';
import {CustomButton, CustomTextField} from 'src/shared/style';

export interface Props {
    open: boolean;
    handleClose: () => void;
    handleSelect: (numberBoxes: number) => void;
    title: string;
    prodpartOrigin: any | undefined;
    prodpart: ProdPartGeneric;
    subpallet?: PalletInfoSubpallet;
}

type State = {
    prevBoxKg: number;
    nextBoxesAfter: number;
    nextBoxKg: number;
    boxesToTransfer: number;
    nextBoxesToTransfer: number;
    nextBoxes: number;
    sending: boolean;
};

const initialState: State = {
    prevBoxKg: 0,
    nextBoxesAfter: 0,
    nextBoxKg: 0,
    boxesToTransfer: 0,
    nextBoxesToTransfer: 0,
    nextBoxes: 0,
    sending: false,
};

export class SelectProdpartDialogConfirmation extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        if (this.props.subpallet) {
            this.setState({...this.state, nextBoxesToTransfer: this.props.subpallet?.boxes_count});
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props) || JSON.stringify(prevState) !== JSON.stringify(this.state)) {
            if (prevProps.prodpartOrigin && prevProps.subpallet) {
                const prevBoxKg = parseFloat((prevProps.prodpartOrigin.kg_total / prevProps.prodpartOrigin.boxes_total).toPrecision(2));
                const nextBoxKg = parseFloat((prevProps.prodpart.kg_total / prevProps.prodpart.boxes_total).toPrecision(2));
                const nextBoxes = Math.round((Number(prevBoxKg) * prevProps.subpallet?.boxes_count) / nextBoxKg);

                this.setState({
                    ...this.state,
                    prevBoxKg: prevBoxKg,
                    nextBoxKg: nextBoxKg,
                    nextBoxes,
                });
            }
        }
    }

    private handleChangeNextBoxCount = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'next_boxes_count') {
            if (e.target.value === '') {
                this.setState({...this.state, nextBoxesToTransfer: 0});
            } else {
                let nextBoxesToTrans = parseInt(e.target.value);
                if (isNaN(nextBoxesToTrans)) return;

                if (nextBoxesToTrans < 0) {
                    nextBoxesToTrans = 0;
                }

                this.setState({
                    ...this.state,
                    nextBoxesToTransfer: nextBoxesToTrans,
                });
            }
        }
    };

    sendTransferBoxes() {
        console.log('sendTransferBoxes');
    }

    private handleSend = async () => {
        this.props.handleSelect(this.state.nextBoxesToTransfer);
    };

    render() {
        const {sending} = this.state;

        return (
            <TableModal open={this.props.open} title={this.props.title} handleClose={this.props.handleClose}>
                {((this.props.subpallet &&
                    (this.props.subpallet.is_manually_pallet || this.props.subpallet.is_manually_packaging || this.props.subpallet.is_manually_merchandise)) ||
                    (this.props.subpallet && this.props.subpallet.merchandise_id !== this.props.prodpart.merchandise.id) ||
                    (this.props.subpallet && this.props.subpallet.merchandise_family_id !== this.props.prodpart.merchandise.family_id)) && (
                    <div style={{backgroundColor: '#f8cfcf', borderRadius: '5px', padding: '7px'}}>
                        {this.props.subpallet &&
                            (this.props.subpallet.is_manually_pallet || this.props.subpallet.is_manually_packaging || this.props.subpallet.is_manually_merchandise) && (
                                <p style={{color: 'darkred'}}>Este subpalé ya ha sido modificado por el usuario y los datos en rojo no se pueden alterar</p>
                            )}
                        {this.props.subpallet && this.props.subpallet.merchandise_id !== this.props.prodpart.merchandise.id && (
                            <p style={{color: 'darkred'}}>El producto del subpalé actual no coincide con el producto del parte</p>
                        )}
                        {this.props.subpallet && this.props.subpallet.merchandise_family_id !== this.props.prodpart.merchandise.family_id && (
                            <p style={{color: 'darkred'}}>La familia del subpalé actual no coincide con la familia del parte</p>
                        )}
                    </div>
                )}

                <p>
                    Artículo:
                    {this.props.subpallet && this.props.subpallet.is_manually_merchandise && <span style={{color: 'darkred'}}>&nbsp;{this.props.subpallet.merchandise_name}</span>}
                    {(!this.props.subpallet || (this.props.subpallet && !this.props.subpallet.is_manually_merchandise)) && (
                        <span style={{color: 'darkgreen'}}>&nbsp;{this.props.prodpart.merchandise.name}</span>
                    )}
                </p>
                <p>
                    Nombre palé:
                    {this.props.subpallet && this.props.subpallet.is_manually_pallet && <span style={{color: 'darkred'}}>&nbsp;{this.props.subpallet.pallet_name}</span>}
                    {(!this.props.subpallet || (this.props.subpallet && !this.props.subpallet.is_manually_pallet)) && (
                        <span style={{color: 'darkgreen'}}>&nbsp;{this.props.prodpart.pallet_name}</span>
                    )}
                </p>
                <p>
                    Código palé:
                    {this.props.subpallet && this.props.subpallet.is_manually_pallet && <span style={{color: 'darkred'}}>&nbsp;{this.props.subpallet.pallet_code}</span>}
                    {(!this.props.subpallet || (this.props.subpallet && !this.props.subpallet.is_manually_pallet)) && (
                        <span style={{color: 'darkgreen'}}>&nbsp;{this.props.prodpart.erp_pal_codigo}</span>
                    )}
                </p>
                <p>
                    Envase:
                    {this.props.subpallet && this.props.subpallet.is_manually_packaging && <span style={{color: 'darkred'}}>&nbsp;{this.props.subpallet.packaging_name}</span>}
                    {(!this.props.subpallet || (this.props.subpallet && !this.props.subpallet.is_manually_packaging)) && (
                        <span style={{color: 'darkgreen'}}>&nbsp;{this.props.prodpart.packaging}</span>
                    )}
                </p>
                <p>
                    Código envase:
                    {this.props.subpallet && this.props.subpallet.is_manually_packaging && <span style={{color: 'darkred'}}>&nbsp;{this.props.subpallet.packaging_code}</span>}
                    {(!this.props.subpallet || (this.props.subpallet && !this.props.subpallet.is_manually_packaging)) && (
                        <span style={{color: 'darkgreen'}}>&nbsp;{this.props.prodpart.erp_env_codigo}</span>
                    )}
                </p>
                <p>
                    Cajas:
                    <span style={{color: 'darkgreen'}}>
                        &nbsp;{this.props.prodpart.boxes_count}/{this.props.prodpart.boxes_per_pallet}
                    </span>
                </p>
                {this.props.prodpartOrigin && (
                    <div style={styles}>
                        <table style={{width: '100%'}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className={styles.tdRight}>Origen</th>
                                    <th className={styles.tdRight}>Destino</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Parte</b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>{this.props.prodpartOrigin?.erp_prodpart_code}</b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>{this.props.prodpart?.erp_prodpart_code}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Cajas</b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>
                                            {this.props.subpallet?.boxes_count}/{this.props.prodpartOrigin?.boxesPerPallet}
                                        </b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>
                                            {this.props.subpallet?.boxes_count}/{this.props.prodpart?.boxes_per_pallet}
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Peso caja</b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>{this.state.prevBoxKg}</b>
                                    </td>
                                    <td className={styles.tdRight}>
                                        <b>{this.state.nextBoxKg}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                <FlexRow>
                    {this.props.prodpartOrigin && (
                        <FlexRowItem>
                            <CustomTextField
                                label="Número de cajas en palé tras cambio de parte"
                                error={false}
                                value={this.state.nextBoxesToTransfer}
                                name="next_boxes_count"
                                variant={'outlined'}
                                type="number"
                                onChange={this.handleChangeNextBoxCount}
                            />
                        </FlexRowItem>
                    )}

                    <FlexRowItem>
                        <CustomButton
                            disabled={
                                (this.props.prodpartOrigin && (this.state.nextBoxesToTransfer === 0 || this.state.nextBoxesToTransfer > this.props.prodpart?.boxes_per_pallet)) ||
                                sending
                            }
                            onClick={this.handleSend}
                            padding={'3px'}
                        >
                            Continuar
                        </CustomButton>
                    </FlexRowItem>
                </FlexRow>
            </TableModal>
        );
    }
}
