import styled from 'styled-components';

export const WrapTables = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
`;

export const Content = styled.div`
    width: 100%;
    height: 82%;
    color: 'black';
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
        background-color: #f2f2f2;
    }
`;

export const TextProduct = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 0.8rem;
`;

export const Text = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 0.7rem;
`;

export const TextBold = styled.div`
    text-align: center;
    font-size: 0.8rem;
    color: darkblue;
`;

export const FlexRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 8px;
    gap: 10px;
`;

export const FlexRowItem = styled.div`
    flex-grow: 1;
    max-width: 50%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
`;

export const TitleRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 4px;
    height: 16%;
`;

export const PalletCell = styled.div`
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    font-size: 1rem;
`;

export const CustomTable = styled('div')<{color?: string}>`
    margin: 8px;
    padding: 2px;
    text-align: center;
    height: 200px;
    min-width: 200px;
    max-width: 200px;
    flex: 1 0 200px;
    border: 5px solid;
    border-color: ${({color}) => color};
    border-radius: 5px;
    box-shadow: 0px 0px 1px 1px #666;
`;
