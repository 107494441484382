import {EmployeeTaskResponse} from 'src/app/presencemanagement/store';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'presence-management';
// employee/{employeeId}/workshift/{workshift}/tasks
export const EmployeeTaskServices = {
    getByWorkshiftAndEmployee: (workshift: string, employeeId: string) =>
        guardedFetcher.get<EmployeeTaskResponse>(`${prefix}/employee/${employeeId}/workshift/${workshift}/activities`),

    getTasksAllowedByEmployee: (employeeId: string) => guardedFetcher.get(`${prefix}/employeeId/${employeeId}/allowed-tasks-by-employee`),

    registerActivity: (employeeId: string, areaId: string, taskId: string, createdAt: string, finishedAt: string, areaShiftId: string) =>
        guardedFetcher.post(`${prefix}/register-activity`, {
            employee_id: employeeId,
            task_id: taskId,
            area_id: areaId,
            created_at: createdAt,
            finished_at: finishedAt,
            area_shift_id: areaShiftId,
        }),

    editActivity: (id: string, employeeId: string, areaId: string, taskId: string, createdAt: string, finishedAt: string, areaShiftId: string) =>
        guardedFetcher.patch(`${prefix}/${id}/update-activity`, {
            employee_id: employeeId,
            task_id: taskId,
            area_id: areaId,
            created_at: createdAt,
            finished_at: finishedAt,
            area_shift_id: areaShiftId,
        }),

    deleteActivity: (id: string) => guardedFetcher.delete(`${prefix}/${id}/delete-activity`),
};
