import * as React from 'react';
import {Button, IconButton, Typography} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {invoke} from 'lodash';
import {TabContainer} from '../../lanemanager/Wrap';
import {fetchWithFeedback} from '../../../utils/fetcherValidate';
import {PalletsServices} from '../../lanemanager/pallets/store/services';
import {SelectErpPalletModal} from './SelectErpPalletModal';
import {SelectErpMerchandiseModal} from './SelectErpMerchandiseModal';
import {SelectErpPackagingModal} from './SelectErpPackagingModal';
import styled from 'styled-components';
import {ModifySubpalletComposition} from './ModifySubpalletComposition';
import {CustomDialog} from '../../../shared/components/CustomDialog';
import {PalletInfoErpMerchandise, PalletInfoErpPackaging, PalletInfoErpPallet, PalletInfoPallet, PalletInfoSubpallet} from './types';
import {IsInRuleset} from '../../../global/authorization/grantSet';
import {GrantGroupsRanks} from '../../../global/authorization/ranking';
import {SelectProdpartModal} from './SelectProdpartModal';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {SelectProdpartDialogConfirmation} from './SelectedProdpartDialogConfirmation';

export interface Props {
    isLoading: boolean;
    subpallet: PalletInfoSubpallet;
    pallet: PalletInfoPallet;
    handleLoading: (loading: boolean) => void;
    updatePalletState: (palletInfo: any) => void;
    refreshData: () => void;
    selecCurrentSubpallet: (subpallet: PalletInfoSubpallet) => void;
    selectedSubpalletId: string;
    handleRemoveSubpallet: (subpallet: PalletInfoSubpallet) => void;
}

type State = {
    openSelectPaleModal: boolean;
    openSelectPackagingModal: boolean;
    openSelectMerchandiseModal: boolean;
    openEditComposition: boolean;
    openDeleteSubpalletDialog: boolean;
    openSelectProdpartModal: boolean;
    openChangeProdpartConfirmationDialog: boolean;
    selectedProdpartToChangeSubpallet?: ProdPartGeneric;
};

const initialState: State = {
    openSelectPaleModal: false,
    openSelectPackagingModal: false,
    openSelectMerchandiseModal: false,
    openEditComposition: false,
    openDeleteSubpalletDialog: false,
    openSelectProdpartModal: false,
    openChangeProdpartConfirmationDialog: false,
};

export class SubpalletInfo extends React.Component<Props, State> {
    readonly state = initialState;

    private async handleSelectProdpart(numberBoxes: number) {
        if (this.state.selectedProdpartToChangeSubpallet) {
            this.props.handleLoading(true);
            const palletInfo = await fetchWithFeedback(
                PalletsServices.updateSubpalletProdpart(this.props.subpallet.id, this.state.selectedProdpartToChangeSubpallet.id, numberBoxes),
                {
                    successMessage: 'Se ha actualizado el parte correctamente: ' + this.state.selectedProdpartToChangeSubpallet.erp_prodpart_code,
                    errorMessage: 'No se ha podido actualizar el parte',
                },
            );
            this.props.handleLoading(false);
            this.props.updatePalletState(palletInfo);
        }
    }

    private async handleSelectPalletType(palletType: PalletInfoErpPallet) {
        this.props.handleLoading(true);
        const palletInfo = await fetchWithFeedback(
            PalletsServices.updateSubpalletType(this.props.subpallet.id, palletType.erpPalletName, palletType.erpPalletCode, palletType.erpTara),
            {
                successMessage: 'Se ha actualizado el pale correctamente: ' + palletType.erpPalletName,
                errorMessage: 'No se ha podido actualizar el pale',
            },
        );
        this.props.handleLoading(false);
        this.props.updatePalletState(palletInfo);
    }

    private async handleSelectPackaging(packaging: PalletInfoErpPackaging) {
        this.props.handleLoading(true);
        const palletInfo = await fetchWithFeedback(
            PalletsServices.updateSubpalletPackaguing(this.props.subpallet.id, packaging.erpPackagingName, packaging.erpPackagingCode, packaging.erpTara),
            {
                successMessage: 'Se ha actualizado el envase correctamente: ' + packaging.erpPackagingName,
                errorMessage: 'No se ha podido actualizar el envase',
            },
        );
        this.props.handleLoading(false);
        this.props.updatePalletState(palletInfo);
    }

    private async handleSelectMerchandise(erpMerchandise: PalletInfoErpMerchandise) {
        this.props.handleLoading(true);
        const palletInfo = await fetchWithFeedback(PalletsServices.updateSubpalletMerchandise(this.props.subpallet.id, erpMerchandise.erpMerchandiseCode), {
            successMessage: 'Se ha actualizado el artículo correctamente: ' + erpMerchandise.erpMerchandiseName,
            errorMessage: 'No se ha podido actualizar el artículo',
        });
        this.props.handleLoading(false);
        this.props.updatePalletState(palletInfo);
    }

    private async handleUpdateComposition() {
        const palletInfo = await this.props.refreshData();
        this.props.updatePalletState(palletInfo);
    }

    render() {
        const {subpallet, selectedSubpalletId, pallet, isLoading} = this.props;
        const {
            openSelectMerchandiseModal,
            openSelectPackagingModal,
            openSelectPaleModal,
            openEditComposition,
            openDeleteSubpalletDialog,
            openSelectProdpartModal,
            selectedProdpartToChangeSubpallet,
            openChangeProdpartConfirmationDialog,
        } = this.state;

        return (
            <>
                {selectedProdpartToChangeSubpallet && (
                    <SelectProdpartDialogConfirmation
                        open={openChangeProdpartConfirmationDialog}
                        title={'Los datos del subpalé serán los siguientes, ¿Desea continuar?'}
                        prodpart={selectedProdpartToChangeSubpallet}
                        prodpartOrigin={subpallet.prodpart}
                        subpallet={subpallet}
                        handleSelect={numberBoxes => {
                            const {selectedProdpartToChangeSubpallet, ...state} = this.state;
                            this.setState(
                                {
                                    ...state,
                                    openChangeProdpartConfirmationDialog: false,
                                },
                                () => this.handleSelectProdpart(numberBoxes),
                            );
                        }}
                        handleClose={() => this.setState({...this.state, openChangeProdpartConfirmationDialog: false})}
                    />
                )}

                <CustomDialog
                    open={openDeleteSubpalletDialog}
                    title={'Confirme la operación'}
                    contentText={'¿Está seguro que desea eliminar el subpalé?'}
                    handle={() => {
                        this.setState({...this.state, openDeleteSubpalletDialog: false}, () => {
                            this.props.handleRemoveSubpallet(subpallet as PalletInfoSubpallet);
                        });
                    }}
                    handleClose={() => this.setState({...this.state, openDeleteSubpalletDialog: false})}
                />

                <SelectProdpartModal
                    open={openSelectProdpartModal}
                    title={'Seleccione un parte'}
                    newSubpallet={false}
                    subpallets={pallet.subpallets}
                    erpOrderCode={pallet.erp_order_code}
                    areaId={pallet.area_id}
                    handleClose={() =>
                        this.setState({
                            ...this.state,
                            openSelectProdpartModal: false,
                        })
                    }
                    handleSelect={item => {
                        this.setState({
                            ...this.state,
                            selectedProdpartToChangeSubpallet: item,
                            openSelectProdpartModal: false,
                            openChangeProdpartConfirmationDialog: true,
                        });
                    }}
                />

                <SelectErpPalletModal
                    open={openSelectPaleModal}
                    handleClose={() =>
                        this.setState({
                            ...this.state,
                            openSelectPaleModal: false,
                        })
                    }
                    handleSelect={item =>
                        this.setState(
                            {
                                ...this.state,
                                openSelectPaleModal: false,
                            },
                            () => this.handleSelectPalletType(item),
                        )
                    }
                    subpallet={subpallet}
                />

                <SelectErpMerchandiseModal
                    open={openSelectMerchandiseModal}
                    subpallet={subpallet}
                    erpCenterId={pallet.erp_center_id}
                    erpFamilyId={pallet.erp_family_id}
                    handleClose={() =>
                        this.setState({
                            ...this.state,
                            openSelectMerchandiseModal: false,
                        })
                    }
                    handleSelect={item =>
                        this.setState(
                            {
                                ...this.state,
                                openSelectMerchandiseModal: false,
                            },
                            () => this.handleSelectMerchandise(item),
                        )
                    }
                />

                <SelectErpPackagingModal
                    open={openSelectPackagingModal}
                    handleClose={() =>
                        this.setState({
                            ...this.state,
                            openSelectPackagingModal: false,
                        })
                    }
                    handleSelect={item =>
                        this.setState(
                            {
                                ...this.state,
                                openSelectPackagingModal: false,
                            },
                            () => this.handleSelectPackaging(item),
                        )
                    }
                    subpallet={subpallet}
                />

                <ModifySubpalletComposition
                    open={openEditComposition}
                    subpallet={subpallet}
                    handleSave={() => {
                        this.setState(
                            {
                                ...this.state,
                                openEditComposition: false,
                            },
                            () => {
                                this.handleUpdateComposition();
                            },
                        );
                    }}
                    handleClose={() =>
                        this.setState({
                            ...this.state,
                            openEditComposition: false,
                        })
                    }
                />

                <TabContainer>
                    <Typography
                        component="div"
                        style={{
                            marginBottom: 10,
                            marginTop: 5,
                            display: 'flex',
                            flexFlow: 'row wrap',
                            backgroundColor: 'white',
                        }}
                    >
                        <div style={{flexGrow: 4, padding: 10}}>
                            <WrappingP>
                                <b>COMPOSICION</b>

                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => {
                                        this.setState({...this.state, openEditComposition: true});
                                    }}
                                    color="secondary"
                                >
                                    <EditIcon />
                                </IconButton>
                            </WrappingP>
                            <hr />
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell align="center">Origen</TableCell>
                                        <TableCell align="center">Cajas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subpallet &&
                                        invoke(subpallet.origins, 'map', (o: {batch_id: number; date_batch: string; erp_batch_code: string; number_boxes: number}) => (
                                            <TableRow key={o.batch_id}>
                                                <TableCell>{o.date_batch}</TableCell>
                                                <TableCell align="center">{o.erp_batch_code}</TableCell>
                                                <TableCell align="center">{o.number_boxes}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div style={{flexGrow: 4, padding: 10}}>
                            <WrappingRowDivSpaceBetween>
                                <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                                    <div>Cajas subpalé</div>
                                    <div>
                                        <span style={{color: 'darkgreen'}}>{subpallet.boxes_count}</span>
                                        &nbsp;/&nbsp;
                                        <span style={{color: 'darkgreen'}}>{subpallet.boxes_total}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                                    <div>Cajas parte</div>
                                    <div>
                                        <span style={{color: 'darkgreen'}}>{subpallet.prodpart_boxes_made}</span>
                                        &nbsp;/&nbsp;
                                        <span style={{color: 'darkgreen'}}>{subpallet.prodpart_boxes_total}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                                    <div>Peso caja</div>
                                    <div>
                                        <span style={{color: 'darkgreen'}}>{Number(subpallet.box_weight).toPrecision(2)}&nbsp;kg</span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    {IsInRuleset(GrantGroupsRanks.PALLET_SUBPALLET_TOTAL) && (
                                        <>
                                            <Button
                                                style={{width: '100%'}}
                                                disabled={isLoading || !!(selectedSubpalletId && subpallet.id === selectedSubpalletId)}
                                                variant="outlined"
                                                color={selectedSubpalletId && subpallet.id === selectedSubpalletId ? 'primary' : 'secondary'}
                                                onClick={() => this.props.selecCurrentSubpallet(subpallet as PalletInfoSubpallet)}
                                            >
                                                {selectedSubpalletId && subpallet.id === selectedSubpalletId ? 'Seleccionado como principal' : 'Seleccionar como principal'}
                                            </Button>
                                            <Button
                                                style={{width: '100%'}}
                                                disabled={isLoading || !!(selectedSubpalletId && subpallet.id === selectedSubpalletId)}
                                                variant="outlined"
                                                color={selectedSubpalletId && subpallet.id === selectedSubpalletId ? 'primary' : 'secondary'}
                                                onClick={() =>
                                                    this.setState({
                                                        ...this.state,
                                                        openDeleteSubpalletDialog: true,
                                                    })
                                                }
                                            >
                                                Eliminar
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </WrappingRowDivSpaceBetween>

                            <WrappingRowDiv>
                                Parte:&nbsp;
                                <span style={{color: 'darkgreen'}}>{subpallet?.erp_prodpart_code}</span>
                                {IsInRuleset(GrantGroupsRanks.UPDATE_PALLET_PRODPART) && (
                                    <IconButton
                                        disabled={isLoading}
                                        onClick={() => {
                                            this.setState({...this.state, openSelectProdpartModal: true});
                                        }}
                                        color="secondary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </WrappingRowDiv>

                            <WrappingRowDiv>
                                Artículo:&nbsp;
                                <span style={{color: subpallet?.is_manually_merchandise ? 'darkred' : 'darkgreen'}}>
                                    {subpallet?.merchandise_name + ' ( ' + subpallet?.merchandise_code + ' ) ' ?? '-'}
                                </span>
                                {IsInRuleset(GrantGroupsRanks.UPDATE_SUBPALLET_MERCHANDISE) && (
                                    <IconButton
                                        disabled={isLoading}
                                        onClick={() => {
                                            this.setState({...this.state, openSelectMerchandiseModal: true});
                                        }}
                                        color="secondary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </WrappingRowDiv>

                            <WrappingRowDiv>
                                Envase:&nbsp;
                                <span style={{color: subpallet?.is_manually_packaging ? 'darkred' : 'darkgreen'}}>
                                    {subpallet?.packaging_name + ' ( ' + subpallet?.packaging_code + ' ) ' ?? '-'}
                                </span>
                                {IsInRuleset(GrantGroupsRanks.UPDATE_SUBPALLET_PACKAGING) && (
                                    <IconButton
                                        disabled={isLoading}
                                        onClick={() => {
                                            this.setState({...this.state, openSelectPackagingModal: true});
                                        }}
                                        color="secondary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </WrappingRowDiv>

                            <WrappingRowDiv>
                                Palé:&nbsp;
                                <span style={{color: subpallet?.is_manually_pallet ? 'darkred' : 'darkgreen'}}>
                                    {subpallet?.pallet_name + ' ( ' + subpallet?.pallet_code + ' ) ' ?? '-'}
                                </span>
                                {IsInRuleset(GrantGroupsRanks.UPDATE_SUBPALLET_TYPE) && (
                                    <IconButton
                                        disabled={isLoading}
                                        onClick={() => {
                                            this.setState({...this.state, openSelectPaleModal: true});
                                        }}
                                        color="secondary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </WrappingRowDiv>
                        </div>
                    </Typography>
                </TabContainer>
            </>
        );
    }
}

const WrappingRowDiv = styled.div`
    margin-top: 8px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
`;

const WrappingRowDivSpaceBetween = styled.div`
    margin-top: 8px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
`;

const WrappingP = styled.p`
    text-align: center;
`;
