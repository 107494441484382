import {AxiosRequestConfig} from 'axios';

import {LoginActions} from 'src/app/login/store/login.actions';
import {GetGrantSet, RULESET} from 'src/global/authorization/grantSet';
import {fetcher} from 'src/global/fetcher';
import {dispatcher} from 'src/store/store';
import {notify} from 'src/utils/Notification';

export let guardedFetcher: AxiosGuarded;

export const initGuardedFetcher = () => {
    guardedFetcher = new AxiosGuarded();
    return guardedFetcher;
};

class AxiosGuarded {
    async get<T = any>(url: string, config?: AxiosRequestConfig) {
        // console.log('hago GET', url, config);
        if (await this.checkAccess(url, 'GET')) {
            const response = await fetcher.get<T>(url, config);
            if (response.status === 401) dispatcher(LoginActions.logout());
            return response;
        } else return await Promise.reject('NO_ACCESS');
        // fetcher.get<T>('');
    }
    async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
        // console.log('hago POST', url, data, config);
        if (await this.checkAccess(url, 'POST')) {
            const response = await fetcher.post<T>(url, data, config);
            if (response.status === 401) dispatcher(LoginActions.logout());
            return response;
        } else return await Promise.reject('NO_ACCESS');
        // fetcher.post<T>('');
    }
    async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
        // console.log('hago PUT', url, data, config);
        if (await this.checkAccess(url, 'PUT')) {
            const response = await fetcher.put<T>(url, data, config);
            if (response.status === 401) dispatcher(LoginActions.logout());
            return response;
        } else return await Promise.reject('NO_ACCESS');
        // fetcher.put<T>('');
    }
    async patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
        // console.log('hago PATCH', url, data, config);
        if (await this.checkAccess(url, 'PATCH')) {
            const response = await fetcher.patch<T>(url, data, config);
            if (response.status === 401) dispatcher(LoginActions.logout());
            return response;
        } else return await Promise.reject('NO_ACCESS');
        // fetcher.patch<T>('');
    }
    async delete(url: string, config?: AxiosRequestConfig) {
        // console.log('hago DELETE', url, config);
        if (await this.checkAccess(url, 'DELETE')) {
            return await fetcher.delete(url, config);
        } else return await Promise.reject('NO_ACCESS');
        // fetcher.delete('');
    }

    private checkAccess = (
        url: string,
        method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    ) =>
        new Promise(res => {
            const fUrl = url.split('?')[0];
            const urled = 'api/' + fUrl;
            GetGrantSet().then(resolution => {
                if (resolution) {
                    if (RULESET && RULESET.grants.find(g => g.url === '*' && g.method === '*')) {
                        // console.log('HE ENCONTRADO EL WIDLCARD');
                        return res(true);
                    }
                    let found = false;
                    const splittedPath = urled.split('/');
                    for (const grant of RULESET.grants) {
                        if (found) break;
                        const splittedGrant = grant.url.split('/');
                        // console.log(splittedGrant, splittedPath);
                        if (splittedGrant.length !== splittedPath.length) continue;
                        let coince = true;
                        // tslint:disable-next-line
                        for (let i = 0; i < splittedGrant.length; i++) {
                            if (splittedGrant[i].indexOf('{') !== -1) continue;
                            if (!(splittedGrant[i] === splittedPath[i] && coince)) {
                                coince = false;
                                break;
                            }
                        }
                        found = coince;
                    }
                    if (found) {
                        return res(true);
                    } else {
                        console.error(
                            '!!!!!!!!!!!!!!!NO HE ENCONTRADO GRANT',
                            RULESET,
                            urled,
                            method,
                            splittedPath,
                        );
                        notify({message: 'No tiene acceso a esta operación.', status: 'warning'});
                        res(false);
                    }
                }
            });
        });
}
