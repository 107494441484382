import React from 'react';
import styled from 'styled-components';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {CustomButton} from 'src/shared/style/CustomButton';
import {notifyValidator} from 'src/utils/notifyValidator';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

import {PalletDefinitionCstmField} from './store/palletDefinitionCstmFieldsControl.types';
import {Checkbox, FormControlLabel, IconButton, List, ListItem, TextField} from '@material-ui/core';
import {PalletDefinitionCstmFieldsControlServices} from './store/palletDefinitionCstmFieldsControl.services';
import RemoveButton from '@material-ui/icons/Clear';
import AddButtom from '@material-ui/icons/AddCircle';

type State = Readonly<{
    id: string;
    name: string;
    description: string;
    code_erp: string;
    id_erp: number;
    active: boolean;
    order_item: number;
    withSelector: boolean;
    selectOptions: string[];
}>;

const initialState: Readonly<State> = {
    id: '',
    name: '',
    description: '',
    code_erp: '',
    id_erp: 0,
    active: false,
    order_item: 0,
    withSelector: false,
    selectOptions: [],
};

type Props = {
    palletDefinitionCstmField?: PalletDefinitionCstmField;
    handleClose(): void;
    updatePalletDefinitionCstmField(): void;
};

export class PalletDefinitionCstmFieldForm extends React.Component<Props, State> {
    readonly state = initialState;
    private selectOptionValue: string = '';

    componentDidMount() {
        if (this.props.palletDefinitionCstmField) {
            const {
                id,
                name,
                description,
                code_erp,
                id_erp,
                active,
                order_item,
                select_options,
            } = this.props.palletDefinitionCstmField;

            let withSelector = false;
            let selectOptions: string[] = [];

            if (select_options && select_options.length > 0) {
                withSelector = true;
                selectOptions = select_options.split('|');
            }

            this.setState({
                id,
                name,
                description,
                code_erp,
                id_erp,
                active,
                order_item,
                withSelector,
                selectOptions,
            });
        } else this.setState(initialState);
    }

    private handleActiveCheckbox = () => {
        this.setState({active: !this.state.active});
    };

    private handleWithSelectorCheckbox = () => {
        this.setState({withSelector: !this.state.withSelector});
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    };

    private handleAddOption = (e: any) => {
        e.preventDefault();
        if (e.key === 'Enter' && !this.state.selectOptions.includes(e.target.value) && e.target.value !== '') {
            this.setState({
                ...this.state,
                selectOptions: [...this.state.selectOptions, e.target.value],
            });
            e.target.value = '';
        }
    };

    private handleAddOptionButton = (value: string) => {
        if (!this.state.selectOptions.includes(value) && value !== '') {
            this.setState({
                ...this.state,
                selectOptions: [...this.state.selectOptions, value],
            });
            this.selectOptionValue = '';
        }
    };

    private handleRemoveOption = (index: number) => () => {
        this.setState({
            ...this.state,
            selectOptions: this.state.selectOptions.filter((_, i) => i !== index),
        });
    };


    render() {
        const {id, name, description, code_erp, id_erp, active, order_item} = this.state;
        return (
            <WrapForm>
                <br />
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <FormControlLabel style={{width: 290, marginTop: '0px'}}
                                          control={<Checkbox checked={active} onChange={this.handleActiveCheckbox}
                                                             value='1' />} label='Activo' />
                    </div>
                    <div>
                        <CustomTextField
                            margin={'0 0 12px 0'}
                            label={'Descripción'}
                            variant='outlined'
                            width={'100%'}
                            onChange={this.handleInputs('description')}
                            value={description}
                        />
                    </div>
                    <div style={{display: 'flex', flexFlow: 'row', gap: '12px'}}>
                        <CustomTextField margin={'0 0 12px 0'} label={'Nombre del campo'} variant='outlined'
                                         onChange={this.handleInputs('name')} value={name} />
                        <CustomTextField label={'Código en ERP'} variant='outlined'
                                         onChange={this.handleInputs('code_erp')} value={code_erp} />
                    </div>
                    <div style={{display: 'flex', flexFlow: 'row', gap: '12px', marginBottom: '12px'}}>
                        <CustomTextField label={'ID en ERP'} variant='outlined'
                                         onChange={this.handleInputs('id_erp')} value={id_erp} />
                        <CustomTextField label={'Orden'} variant='outlined'
                                         onChange={this.handleInputs('order_item')} value={order_item} />
                    </div>
                    <div style={{display: 'flex', flexFlow: 'row', gap: '12px', marginBottom: '12px'}}>
                        <FormControlLabel style={{flexGrow: 1}}
                                          control={<Checkbox checked={this.state.withSelector}
                                                             onChange={this.handleWithSelectorCheckbox}
                                                             value='1' />} label='Selector' />
                        {this.state.withSelector && (
                            <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', gap: '12px'}}>
                                <TextField fullWidth={true} label={'Agregar opción'} variant='outlined'
                                           onKeyUp={this.handleAddOption} onChange={(e) => this.selectOptionValue = e.target.value }/>
                                <IconButton style={{padding: '0px', margin: '0px'}}
                                            onClick={() => this.handleAddOptionButton(this.selectOptionValue)}>
                                    <AddButtom style={{padding: '0px', margin: '0px'}} color='primary' />
                                </IconButton>
                            </div>
                        )}

                    </div>
                    {this.state.withSelector && this.state.selectOptions.length > 0 && (
                        <List>
                            {this.state.selectOptions.map((option, index) => (
                                <ListItem key={index} style={{
                                    backgroundColor: index % 2 === 0 ? '' : '#f2f2f2',
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                >
                                    <span>{option}</span>
                                    <IconButton style={{padding: '0px', margin: '0px'}}
                                                onClick={this.handleRemoveOption(index)}>
                                        <RemoveButton style={{padding: '0px', margin: '0px'}} color='secondary' />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </div>

                <CustomButton onClick={this.handleSend} width={'100%'} margin='unset' padding={'5px 0'}>
                    {id ? 'Guardar' : 'Crear'} campo
                </CustomButton>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {id, name, description, code_erp, id_erp, active, order_item, selectOptions} = this.state;
        const validator = notifyValidator(this);
        const select_options = this.state.withSelector ? selectOptions.join('|') : '';

        if (id) {
            const res = await fetchWithFeedback(
                PalletDefinitionCstmFieldsControlServices.update(id, {
                    name,
                    description,
                    code_erp,
                    id_erp,
                    active,
                    order_item,
                    select_options
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.props.updatePalletDefinitionCstmField();
                this.props.handleClose();
            }
        } else {

            const res = await fetchWithFeedback(
                PalletDefinitionCstmFieldsControlServices.create({
                    name,
                    description,
                    code_erp,
                    id_erp,
                    active,
                    order_item,
                    select_options,
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.setState(initialState);
                this.props.updatePalletDefinitionCstmField();
                this.props.handleClose();
            }
        }
    };


}

export const LeftPart = styled.div`
    width: 55%;
`;
export const RightPart = styled.div`
    width: 47%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
export const PhotoDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
