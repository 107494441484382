import * as React from 'react';

import {LanePtableState} from 'src/app/lanemanager/lane/store/types';

import {TableModal} from 'src/shared/components/Modal';
import {Typography} from '@material-ui/core';
import {CustomButton} from 'src/shared/style';
import {VinculatePalletModalConnected} from './VinculatePalletModal';

export interface Props {
    open: boolean;
    hide: () => void;
    ptableSelected: LanePtableState | null;
}

type State = {
    openVinculatePallet: boolean;
    ptableSelected: LanePtableState | null;
};

const initialState: State = {
    openVinculatePallet: false,
    ptableSelected: null,
};

export class PtableWithoutPalletModal extends React.Component<Props, State> {
    readonly state = initialState;

    private hideModalTable = () => {
        this.props.hide();
    };

    private hideVinculatePallet = () => {
        this.setState({openVinculatePallet: false});
        this.hideModalTable();
    };
    /*
    private toggleModalTable = () => {
        this.setState({open: !this.state.open});
    };
*/
    render() {
        return (
            <>
                <TableModal title={'Mesa ' + this.props.ptableSelected?.name} open={this.props.open} handleClose={this.hideModalTable}>
                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomButton
                                onClick={() => {
                                    this.setState({openVinculatePallet: true});
                                    //this.hideModalTable();
                                }}
                                padding={'3px'}
                            >
                                {'Vincular palé'}
                            </CustomButton>
                            <br />
                        </>
                    </Typography>
                </TableModal>
                <VinculatePalletModalConnected open={this.state.openVinculatePallet} hide={this.hideVinculatePallet} ptableSelected={this.props.ptableSelected} />
            </>
        );
    }
}
