import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    List,
    Typography,
} from '@material-ui/core';
import {ListItem} from '@material-ui/core/es';
import React from 'react';
import {TransferOriginPallet, TransferOriginPalletSubpalletBatch} from './store/liftPalletsControl.types';


type State = {
    transfers: Map<string, TransferOriginPalletSubpalletBatch>,
    limitBoxesToTransfer: number,
    initialBoxesToTransfer: number,
}

const initialState: State = {
    transfers: new Map<string, TransferOriginPalletSubpalletBatch>(),
    limitBoxesToTransfer: 0,
    initialBoxesToTransfer: 0,
};

type Props = {
    open: boolean,
    pallet: TransferOriginPallet | null,
    maxBoxes: number,
    transferedBoxes: number,
    handleClose: () => void,
    handleTransfer: (totalBoxesCount: number, palletBarcode: string | undefined, transfer: Map<string, TransferOriginPalletSubpalletBatch>) => void,
}

export class TransferBoxesDialog extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.pallet?.id !== this.props.pallet?.id) this.fillTransfer();
    }

    private fillTransfer = () => {
        if (this.props.pallet !== null) {
            this.props.pallet.current_subpallet.batches.forEach(b => {
                if (b.erp_origin_code && b.boxes > 0) {
                    const _batch = Object.assign({}, b);
                    this.state.transfers.set(b.erp_origin_code, _batch);
                }
            });

            const initialBoxesToTransfer = this.getTotalTransferedBoxesCount();
            const limitBoxesToTransfer = this.props.maxBoxes - this.props.transferedBoxes + initialBoxesToTransfer;

            this.setState({...this.state, limitBoxesToTransfer, initialBoxesToTransfer});
        } else {
            this.state.transfers.clear();
        }
    };

    private handleTransferBoxes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const batchCode = event.target.id;
        let newValue = parseInt(event.target.value);
        const batch = this.state.transfers.get(batchCode);

        if (batch) {
            const oldValue = batch.boxes_count;
            if (newValue < 0) {
                newValue = 0;
            }
            if (newValue > batch.boxes) {
                newValue = batch.boxes;
            }

            batch.boxes_count = newValue;
            const totalBoxes = this.getTotalTransferedBoxesCount();

            if (totalBoxes > this.state.limitBoxesToTransfer) {
                batch.boxes_count = oldValue;
            }

        }
        this.forceUpdate();
    };

    private addAllBoxes = (): void => {
        this.state.transfers.forEach((batch: TransferOriginPalletSubpalletBatch) => {
            if (batch.erp_origin_code && batch.boxes > 0) {
                const _batch = Object.assign<any, TransferOriginPalletSubpalletBatch>({}, {
                    id: batch.id,
                    boxes_count: batch.boxes,
                    erp_origin_code: batch.erp_origin_code,
                    boxes: batch.boxes,
                    boxes_to_transfer: batch.boxes_to_transfer,
                });

                this.state.transfers.set(batch.erp_origin_code, _batch);
            }
        });
        this.forceUpdate();
    };

    private getTotalTransferedBoxesCount = (): number => {
        let total = 0;
        this.state.transfers.forEach((batch: TransferOriginPalletSubpalletBatch) => {
            total += batch.boxes_count;
        });

        return total;
    };


    render() {
        const batchesList = Array.from(this.state.transfers);
        let transBoxesCount = this.getTotalTransferedBoxesCount();
        if (isNaN(transBoxesCount)) transBoxesCount = 0;
        const boxesTotal = this.props.pallet?.current_subpallet.boxes_total ?? 0;
        const boxesCount = (this.props.pallet?.current_subpallet.boxes_count ?? 0) + transBoxesCount;
        const currentBoxesCount = this.props.maxBoxes - this.state.limitBoxesToTransfer + transBoxesCount;

        return (
            this.props.open &&
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Transferir cajas'}</DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1'>

                        <Grid container alignItems='center'>
                            <Grid item xs={8}>
                                <b>{this.props.pallet?.palletBarcode}</b> ({boxesCount}/{boxesTotal})
                            </Grid>
                            <Grid item xs={4}>
                                {((this.state.limitBoxesToTransfer >= boxesTotal) &&
                                    <Typography component='span'
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'blue',
                                                }}>
                                                    <span
                                                        onClick={this.addAllBoxes}>
                                                        Transferir todo
                                                    </span>
                                    </Typography>

                                )}
                            </Grid>
                        </Grid>

                    </Typography>
                    <Typography variant='caption'>
                        Total de cajas marcadas para transferir: <b>{currentBoxesCount}</b>/{this.props.maxBoxes}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Cajas a transferir del palé actual: <b>{transBoxesCount}</b>/{this.state.limitBoxesToTransfer}
                    </Typography>
                    <List>
                        {(
                            batchesList.map((batch: [string, TransferOriginPalletSubpalletBatch]) => (
                                <ListItem key={batch[0]}>
                                    <Card style={{padding: '8px', width: '100%'}}>
                                        <Typography variant='caption'>
                                            Origen: <b>{batch[0]}</b>
                                        </Typography>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={10}>
                                                <Typography variant='subtitle1'>
                                                    Cajas a transferir (<Typography inline={true}
                                                                                    variant='caption'>de <b>{batch[1].boxes}</b></Typography>):
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    type='number'
                                                    style={{textAlign: 'right'}}
                                                    value={batch[1].boxes_count}
                                                    id={batch[0]}
                                                    onChange={this.handleTransferBoxes}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ListItem>
                            ))
                        )}
                    </List>


                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color='secondary'>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => this.props.handleTransfer(transBoxesCount, this.props.pallet?.palletBarcode, this.state.transfers)}
                        color='primary'>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
