import * as React from 'react';
import {TableModal} from 'src/shared/components/Modal';
import {Typography} from '@material-ui/core';
import {CustomButton, CustomDropdown, CustomTextField} from 'src/shared/style';
import {BarcodeScanner} from '../../../shared/components/BarcodeScanner';
import {Advertisement} from '../../../shared/components/Advertisement';
import {validateBarcode} from '../../../shared/functions/BarcodeValidator';
import {NotifyActionType} from '../../../utils/Notification/action-types';
import {findSystemParameter} from 'src/global/systemParameters/systemParameters';
import {ParameterCPN} from 'src/types/app_types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import { AreaControlServices } from '../areaControl/store/services';

export interface Props {
    open: boolean;
    hide: () => void;
    handleBarcodeReaded: (barcode : string, requestId : string, areaId : string) => void;
    requestId: string;
}

type State = {
    open: boolean;
    barcodePallet: string;
    prodpartCode: string;
    areaId: string;
    openBarcodeScanner: boolean;
    showAd: boolean;
    areasList: Array<{id: string; name: string}>;
};

const initialState: State = {
    open: false,
    barcodePallet: '',
    prodpartCode: '',
    areaId: '',
    openBarcodeScanner: false,
    showAd: false,
    areasList: [],
};

export class CreatePalletModal extends React.Component<Props, State> {
    readonly state = initialState;
    private barcodeScanner = React.createRef<BarcodeScanner>();
    private adMessage = '';


    componentDidMount() {
        this.fetchAreas();
        this.setState(initialState);
    }

    private fetchAreas = async () => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreasWithPtable(), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private hideModalTable = () => {
        this.props.hide();
        this.setState({
            barcodePallet: '',
            prodpartCode: '',
        });
    };

    private hideBarcodeScanner = () => {
        this.setState({
            openBarcodeScanner: false,
        });
    };

    private handleChangeProdpartCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'prodpart_code') {
            const newValue = (e.target.value as unknown) as string;

            this.setState({
                prodpartCode: newValue,
            });
        }
    };

    private handleChangeBarcodePallet = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'barcode_pallet') {
            const newValue = (e.target.value as unknown) as string;

            if (newValue.length > 13) return;

            this.setState({
                ...this.state,
                barcodePallet: newValue,
            });

            if (newValue.length === 13) {
                const isValid = validateBarcode(newValue);

                if (isValid) {
                    this.props.handleBarcodeReaded(newValue, this.state.prodpartCode, this.state.areaId);
                } else {
                    this.showAd('El código de barras introducido no tiene el formato correcto');
                }
            }
        }
    };

    handleCodeReaded = (code: string) => {
        this.setState({
            barcodePallet: code,
        });

        const isValid = validateBarcode(code);

        if (isValid) {
            this.props.handleBarcodeReaded(code, this.props.requestId,this.state.areaId);
            this.setState({
                barcodePallet: '',
            });
        } else {
            this.showAd('El código de barras escaneado no tiene el formato correcto');
        }
    };

    scanBarcode = () => {
        if (navigator.mediaDevices) {
            if (this.barcodeScanner.current) {
                this.barcodeScanner.current.init();
                this.setState({openBarcodeScanner: true});
            }
        } else {
            this.showAd('No se puede acceder a la cámara. Se necesita entrar por https y permitir el acceso a la cámara. Gracias.');
        }
    };

    render() {
        return (
            <>
                <TableModal title={'Crear palé'} open={this.props.open} handleClose={this.hideModalTable}>
                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomTextField
                                margin={'0 auto'}
                                width={'100%'}
                                label="Código del parte"
                                error={false}
                                value={this.state.prodpartCode}
                                name="prodpart_code"
                                variant={'outlined'}
                                type="string"
                                onChange={this.handleChangeProdpartCode}
                            />
                            <br></br>
                            <CustomDropdown
                                title={'Área'}
                                dropdownName={'areas'}
                                data={this.state.areasList || []}
                                value={this.state.areaId}
                                margin={'5px 0'}
                                handleChoose={e => this.setState({areaId: e.target.value})}
                            />
                            <br></br>
                            <CustomTextField
                                margin={'0 auto'}
                                width={'100%'}
                                label="Código de barras palé"
                                placeholder={`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}...`}
                                error={false}
                                value={this.state.barcodePallet}
                                name="barcode_pallet"
                                variant={'outlined'}
                                type="string"
                                onChange={this.handleChangeBarcodePallet}
                            />
                        </>
                    </Typography>

                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomButton onClick={this.scanBarcode} padding={'3px'}>
                                {'Escanear código'}
                            </CustomButton>
                            <br />
                        </>
                    </Typography>
                </TableModal>

                <BarcodeScanner open={this.state.openBarcodeScanner} hide={this.hideBarcodeScanner} onReadedCode={this.handleCodeReaded} ref={this.barcodeScanner} />

                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />
            </>
        );
    }
}
