import invoke from 'lodash/invoke';
import * as React from 'react';
import {format} from 'date-fns';
import es from 'date-fns/locale/es';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {PalletInformationServices} from 'src/app/lanemanager/palletInformation/store/palletInformation.services';
import {Button, Card, CardContent, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from '@material-ui/core';
import {CustomButton, CustomTextField, OCard} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {TableModal} from 'src/shared/components/Modal';
import {notify} from '../../../utils/Notification';
import {SelectPalletModal} from './SelectPalletModal';
import localforage from 'localforage';
import {fillStorage, getObjectFromStorage} from 'src/utils/localforage';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {AppStoreState} from 'src/store';
import {connect} from 'react-redux';
import {CustomDialog} from '../../../shared/components/CustomDialog';
import {
    PalletDefinitionCstmField,
    PalletToErpDetail,
    PalletToErpPosterPrinterType,
    PalletToErpPosterPrintType,
    PalletToErpPrinter,
    PalletToErpSubpallet,
    PalletToErpSubpalletBatch,
    PalletToErpSubpalletNewBatch,
} from './types';
import {DivElement, WrapInfo, WrapLabelInfo, WrapLoaderDiv, WrappingDiv, WrapValueInfo} from './Wrap';
import RemoveButton from '@material-ui/icons/Clear';
import {floor} from 'lodash';
import {PalletInfoPallet} from '../../global/palletInfo/types';
import {PalletCompleteInfoModal} from '../../global/palletInfo/PalletInfoModal';
import {IsInRuleset} from 'src/global/authorization/grantSet';

export type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};

type State = {
    isLoading: boolean;
    isLoadingMsg: string;
    palletDetail?: PalletToErpDetail;
    palletBarcodeInput: string;
    numberCopies: number | undefined;
    cstmFields: Map<string, string>;
    loaderMessage: string;
    openModalPrints: boolean;
    openModalPrinters: boolean;
    posterNumberCopies: number | undefined;
    openModalPosterPrints: boolean;
    openModalPosterPrinters: boolean;
    printTypeSelected: PalletToErpPrinter;
    printerTypeSelected: PalletToErpPrinter;
    posterPrintTypeSelected: PalletToErpPrinter;
    posterPrinterTypeSelected: PalletToErpPrinter;
    showSelectPallet: boolean;
    openDialogConfirmInterbloqueo: boolean;
    openDialogConfirnPalletReservedExceded: boolean;
    [key: string]: any;
    openPalletCompleteInfoModal: boolean;
};

const initialState: State = {
    isLoading: false,
    isLoadingMsg: '',
    palletBarcodeInput: '',
    numberCopies: 1,
    cstmFields: new Map(),
    loaderMessage: '',
    openModalPrints: false,
    openModalPrinters: false,
    posterNumberCopies: 0,
    openModalPosterPrints: false,
    openModalPosterPrinters: false,
    printTypeSelected: {name: '', code: ''},
    printerTypeSelected: {name: '', code: ''},
    posterPrintTypeSelected: {name: '', code: ''},
    posterPrinterTypeSelected: {name: '', code: ''},
    showSelectPallet: false,
    openDialogConfirmInterbloqueo: false,
    openDialogConfirnPalletReservedExceded: false,
    openPalletCompleteInfoModal: false,
};

/**
 * 1360200007781
 */
export class PalletInformationErpBase extends React.Component<SProps, State> {
    readonly state = initialState;

    private inputPalletBCRef = React.createRef<HTMLInputElement>();

    private fetchInformation = async (barcode: string = '') => {
        //Por si no recalcula que no deje crear palé
        if (this.state.palletDetail?.pallet) {
            this.state.palletDetail.pallet.kg_to_fill = 1;
        }

        let {palletBarcodeInput} = this.state;

        if (barcode !== '') {
            palletBarcodeInput = barcode;
        }

        this.setState({...initialState});

        if (palletBarcodeInput) {
            this.setLoading('Obteniendo información del palé...');
            const info: PalletToErpDetail = await fetchWithFeedback(PalletInformationServices.getErpInfo(palletBarcodeInput), {
                errorMessage: 'No se han podido retirar los datos del palé indicado',
            });
            this.unsetLoading();

            if (info) {
                let printTypeSelected = {name: '', code: ''};
                let printerTypeSelected = {name: '', code: ''};
                let number_copies = 1;
                let posterPrintTypeSelected = {name: '', code: ''};
                let posterPrinterTypeSelected = {name: '', code: ''};
                let poster_number_copies = 0;
                this.state.cstmFields.clear();
                info.palletDefinitionCstmFields.forEach((v: any) => {
                    const ctmValues = info.palletCstmFields.find((e: any) => e.name === v.name);
                    if (ctmValues) {
                        this.state.cstmFields.set(v.id, ctmValues.value);
                    } else {
                        this.state.cstmFields.set(v.id, '');
                    }
                });

                info.printTypes.forEach((pType: any) => {
                    if (pType.defaultValue === '1') {
                        printTypeSelected = {name: pType.name, code: pType.code};
                    }
                });

                info.posterPrintTypes.forEach((pType: any) => {
                    if (pType.defaultValue === '1') {
                        posterPrintTypeSelected = {name: pType.name, code: pType.code};
                    }
                });

                const length = await printerSelectedInstance.length();
                if (length !== 0) {
                    printerTypeSelected = await getObjectFromStorage(printerSelectedInstance);
                }

                const lengthCopies = await numberCopiesInstance.length();

                if (lengthCopies !== 0) {
                    const CopiesStruct = await getObjectFromStorage(numberCopiesInstance);
                    number_copies = CopiesStruct.numberCopies;
                }

                const posterLength = await posterPrinterSelectedInstance.length();
                if (posterLength !== 0) {
                    posterPrinterTypeSelected = await getObjectFromStorage(posterPrinterSelectedInstance);
                }

                let errors = '';

                if (info.pallet.subpallets.find((subpallet: PalletToErpSubpallet) => !subpallet.prodpart)) {
                    errors += 'No se ha encontrado información del parte de producción.';
                }

                if (info.pallet.subpallets.find((subpallet: PalletToErpSubpallet) => !subpallet.batches.length)) {
                    if (errors.length) errors += '\n';
                    errors += 'No se ha encontrado información de las partidas.';
                }

                if (errors.length) {
                    notify({message: errors, status: 'error'});
                }

                const palletRealWeight = parseFloat(
                    info.pallet.subpallets
                        .map((subpallet: PalletToErpSubpallet) => Number(subpallet.real_weight) ?? 0)
                        .reduce((a, b) => a + b)
                        .toFixed(2),
                );
                const palletEstimatedWeight = parseFloat(
                    info.pallet.subpallets
                        .map((subpallet: PalletToErpSubpallet) => Number(subpallet.estimated_weight))
                        .reduce((a, b) => a + b)
                        .toFixed(2),
                );

                const palletDetail = {
                    ...info,
                    pallet: {
                        ...info.pallet,
                        real_weight: palletRealWeight ?? 0,
                        real_weight_input: palletRealWeight ?? 0,
                        net_weight: 0,
                        net_weight_input: 0,
                        estimated_weight: palletEstimatedWeight ?? 0,
                        kg_to_fill: 0,
                        excess_kg: 0,
                        boxes_count: Number(info.pallet.boxes_count),
                        boxes_total: Number(info.pallet.boxes_total),
                        subpallets: info.pallet.subpallets.map((subpallet: PalletToErpSubpallet) => {
                            return {
                                ...subpallet,
                                prodpart: {
                                    ...subpallet.prodpart,
                                    TaraEnvase: Number(subpallet.prodpart.TaraEnvase),
                                    TaraPale: Number(subpallet.prodpart.TaraPale),
                                    PalesReservados: Number(subpallet.prodpart.PalesReservados),
                                    PalCantidad: Number(subpallet.prodpart.PalCantidad),
                                },
                                batches: subpallet.batches.map((batch: PalletToErpSubpalletBatch) => ({
                                    ...batch,
                                    boxes_count: Number(batch.boxes_count),
                                    available_kg: Number(batch.available_kg ?? 0),
                                    net_weight: Number(batch.net_weight ?? 0),
                                    box_weight: Number(batch.box_weight ?? 0),
                                })),
                                boxes_count: Number(subpallet.boxes_count),
                                boxes_total: Number(subpallet.boxes_total),
                                estimated_weight: Number(subpallet.estimated_weight ?? 0),
                                box_weight: Number(subpallet.box_weight),
                                real_weight: parseFloat((subpallet.real_weight ?? 0).toFixed(2)),
                                real_weight_input: parseFloat((subpallet.real_weight ?? 0).toFixed(2)),
                                net_weight: 0,
                                net_weight_input: 0,
                                new_batches: [],
                                excess_kg: 0,
                                available_kg: 0,
                                kg_to_fill: 0,
                            };
                        }),
                    },
                };

                console.log('palletDetail', palletDetail);

                this.setState(
                    {
                        ...this.state,
                        palletDetail,
                        printTypeSelected,
                        printerTypeSelected,
                        posterPrintTypeSelected,
                        posterPrinterTypeSelected,
                        numberCopies: number_copies,
                        posterNumberCopies: poster_number_copies,
                    },
                    () => {
                        const someSubpalletsProdpartNotExist = info.pallet.subpallets.find((subpallet: PalletToErpSubpallet) => {
                            return !subpallet.prodpart;
                        });
                        if (palletRealWeight && !someSubpalletsProdpartNotExist) {
                            this.calculateWeightsBySubpallets();
                        }
                    },
                );
            } else {
                notify({message: 'No existe el palé', status: 'warning'});
            }
        }
    };

    private fetchOrigin = async (subpallet: PalletToErpSubpallet, erpOriginCode: string) => {
        this.setLoading('Obteniendo origen...');
        const originData = await fetchWithFeedback(PalletInformationServices.getErpOriginInfo(erpOriginCode), {
            errorMessage: 'Error al obtener el origen solicitado',
        });
        this.unsetLoading();

        if (originData) {
            if (!subpallet) {
                notify({message: 'No existe el subpalé en el listado', status: 'warning'});
            } else {
                let prevOrigin = subpallet.batches.find((batch: PalletToErpSubpalletBatch) => batch.erp_origin_code === erpOriginCode);

                if (prevOrigin) {
                    originData.availableKg = originData.availableKg - prevOrigin.net_weight;
                }
                subpallet.new_batches.push({
                    available_kg: originData.availableKg,
                    erp_origin_id: originData.IdOrigen,
                    origin_type: originData.type,
                    erp_origin_code: erpOriginCode,
                    kg: 0,
                });
                this.forceUpdate();
            }
        } else {
            notify({message: 'No existe el origen buscado', status: 'warning'});
        }
    };

    private changePalletNetWeightField = (value: string) => {
        console.log('F1: Se ha cambiado Peso Neto a nivel de palé');
        if (this.state.palletDetail && this.state.palletDetail.pallet) {
            const pallet = this.state.palletDetail.pallet;
            const numberValue = Number(value ?? 0);
            let palletRealWeight = 0;
            const palletBoxesCount = this.calculatePalletBoxesCount(pallet);
            pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
                palletRealWeight += this.calculateRealWeightSubpallet(numberValue, palletBoxesCount, subpallet);
            });
            pallet.real_weight_input = palletRealWeight;
            this.forceUpdate();
        }
    };

    private changePalletRealWeightField = (value: string) => {
        console.log('F2: Se ha cambiado Peso Bruto a nivel de palé');
        if (this.state.palletDetail && this.state.palletDetail.pallet) {
            const pallet = this.state.palletDetail.pallet;
            const numberValue = Number(value ?? 0);
            let palletNetWeight = 0;
            const palletBoxesCount = this.calculatePalletBoxesCount(pallet);
            pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
                palletNetWeight += this.calculateNetWeightSubpallet(numberValue, palletBoxesCount, subpallet);
                console.log('palletNetWeight', palletNetWeight);
            });
            pallet.net_weight_input = palletNetWeight;
            this.forceUpdate();
        }
    };

    private calculateNetWeightSubpallet(palletRealWeight: number, palletBoxesCount: number, subpallet: any) {
        const percentageBoxes = subpallet.boxes_count / palletBoxesCount;
        console.log('calculateNetWeightSubpallet|percentageBoxes', percentageBoxes);
        let realWeight = palletRealWeight <= 0 ? 0 : Math.round(palletRealWeight * percentageBoxes);
        console.log('calculateNetWeightSubpallet|realWeight', realWeight);
        const erpTaraEnvase = subpallet.tara_envase !== null ? subpallet.tara_envase : subpallet.prodpart.TaraEnvase;
        const erpTaraPale = subpallet.tara_pale !== null ? subpallet.tara_pale : subpallet.prodpart.TaraPale;
        const totalPackagingTare = erpTaraEnvase * subpallet.boxes_count;
        const totalPaleTare = erpTaraPale;
        console.log('calculateNetWeightSubpallet|totalPackagingTare', totalPackagingTare);
        console.log('calculateNetWeightSubpallet|totalPaleTare', totalPaleTare);
        const netWeight = realWeight <= 0 ? 0 : Math.round(realWeight - totalPackagingTare - totalPaleTare);
        console.log('calculateNetWeightSubpallet|netWeight', netWeight);
        return netWeight;
    }

    private calculateRealWeightSubpallet(palletNetWeight: number, palletBoxesCount: number, subpallet: any) {
        const percentageBoxes = subpallet.boxes_count / palletBoxesCount;
        const netWeight = palletNetWeight <= 0 ? 0 : Math.round(palletNetWeight * percentageBoxes);
        const erpTaraEnvase = subpallet.tara_envase !== null ? subpallet.tara_envase : subpallet.prodpart.TaraEnvase;
        const erpTaraPale = subpallet.tara_pale !== null ? subpallet.tara_pale : subpallet.prodpart.TaraPale;
        const totalPackagingTare = erpTaraEnvase * subpallet.boxes_count;
        const totalPaleTare = erpTaraPale;
        const realWeight = netWeight <= 0 ? 0 : Math.round(netWeight + totalPackagingTare + totalPaleTare);
        return realWeight;
    }

    private calculatePalletBoxesCount(pallet: any) {
        let palletBoxesCount = 0;

        pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
            palletBoxesCount += subpallet.boxes_count;
        });
        return palletBoxesCount;
    }

    private changeNetWeightField = (subpallet: PalletToErpSubpallet, value: string) => {
        console.log('F3: se ha cambiado Peso Neto a nivel de subpalé');
        const numberValue = Number(value ?? 0);
        const erpTaraEnvase = subpallet.tara_envase !== null ? subpallet.tara_envase : subpallet.prodpart.TaraEnvase;
        const erpTaraPale = subpallet.tara_pale !== null ? subpallet.tara_pale : subpallet.prodpart.TaraPale;
        const totalEnvWeight = (erpTaraEnvase ?? 0) * subpallet.boxes_count;
        const taraPale = erpTaraPale ?? 0;
        if (this.hasSubpalletBatchesBoxes(subpallet)) {
            subpallet.real_weight_input = Math.round(numberValue + taraPale + totalEnvWeight);
        } else {
            subpallet.net_weight_input = 0;
            subpallet.real_weight_input = 0;
        }
        this.forceUpdate();
    };

    private changeRealWeightField = (subpallet: PalletToErpSubpallet, value: string) => {
        console.log('F4: se ha cambiado Peso Bruto a nivel de subpalé');
        const numberValue = Number(value ?? 0);
        const erpTaraEnvase = subpallet.tara_envase !== null ? subpallet.tara_envase : subpallet.prodpart.TaraEnvase;
        const erpTaraPale = subpallet.tara_pale !== null ? subpallet.tara_pale : subpallet.prodpart.TaraPale;
        const totalEnvWeight = (erpTaraEnvase ?? 0) * subpallet.boxes_count;
        const taraPale = erpTaraPale ?? 0;
        if (this.hasSubpalletBatchesBoxes(subpallet)) {
            subpallet.net_weight_input = Math.round(numberValue - taraPale - totalEnvWeight);
        } else {
            subpallet.net_weight_input = 0;
            subpallet.real_weight_input = 0;
        }
        this.forceUpdate();
    };

    private calculateWeightsBySubpallets = () => {
        console.log('F5: se ha aplicado a nivel de subpalé');
        if (this.state.palletDetail && this.state.palletDetail.pallet) {
            let palletRealWeight = 0;
            let palletNetWeight = 0;
            let palletExcessKg = 0;
            let palletKgToFill = 0;

            const pallet = this.state.palletDetail.pallet;

            pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
                if (this.hasSubpalletBatchesBoxes(subpallet)) {
                    const erpTaraEnvase = subpallet.tara_envase !== null ? subpallet.tara_envase : subpallet.prodpart.TaraEnvase;
                    const erpTaraPale = subpallet.tara_pale !== null ? subpallet.tara_pale : subpallet.prodpart.TaraPale;
                    const extraWeight = erpTaraPale + erpTaraEnvase * subpallet.boxes_count;

                    let netWeight = Math.round((Number(subpallet.real_weight_input) ?? 0) - extraWeight);
                    netWeight = netWeight <= 0 ? 0 : netWeight;
                    const boxWeight = parseFloat((netWeight / subpallet.boxes_count).toFixed(2));

                    let totalAvailableWeight = 0;
                    let excessKg = 0;

                    let totalBatchesNetWeight = 0;

                    subpallet.batches.forEach((batch: PalletToErpSubpalletBatch, idx, array) => {
                        //console.log(idx, array);
                        batch.net_weight = Math.round(batch.boxes_count * boxWeight);
                        if (idx === array.length - 1 && netWeight !== totalBatchesNetWeight + batch.net_weight) {
                            const diff = netWeight - (totalBatchesNetWeight + batch.net_weight);
                            batch.net_weight = floor(batch.net_weight) + diff;
                            //batch.net_weight = Math.round(batch.net_weight) + diff;
                        }

                        totalBatchesNetWeight += batch.net_weight;
                        totalAvailableWeight += batch.available_kg;

                        if (batch.net_weight > batch.available_kg) {
                            excessKg += batch.net_weight - batch.available_kg;
                        }
                    });

                    const allKg = subpallet.new_batches.map(b => b.kg).reduce((t, k) => t * 1 + k * 1, 0);

                    netWeight = Math.round(netWeight);
                    subpallet.excess_kg = parseFloat(excessKg.toFixed(2));
                    subpallet.kg_to_fill = parseFloat((excessKg - allKg).toFixed(2));
                    subpallet.total_available_kg = parseFloat(totalAvailableWeight.toFixed(2));
                    subpallet.net_weight = netWeight;
                    subpallet.net_weight_input = netWeight;
                    subpallet.box_weight = parseFloat(boxWeight.toFixed(2));
                    subpallet.real_weight = Number(subpallet.real_weight_input);
                    palletExcessKg += excessKg;
                    palletKgToFill += excessKg - allKg;
                    palletRealWeight += subpallet.real_weight ?? 0;
                    palletNetWeight += netWeight;
                }
            });

            pallet.excess_kg = parseFloat(palletExcessKg.toFixed(2));
            pallet.kg_to_fill = parseFloat(palletKgToFill.toFixed(2));
            pallet.real_weight = parseFloat(palletRealWeight.toFixed(2));
            pallet.real_weight_input = parseFloat(palletRealWeight.toFixed(2));
            pallet.net_weight = parseFloat(palletNetWeight.toFixed(2));
            pallet.net_weight_input = parseFloat(palletNetWeight.toFixed(2));

            this.forceUpdate();
        }
    };

    private calculateWeightsByPallet = () => {
        console.log('F6: se ha aplicado a nivel de palé');
        if (this.state.palletDetail && this.state.palletDetail.pallet) {
            const pallet = this.state.palletDetail.pallet;
            let palletRealWeight = Number(pallet.real_weight_input) ?? 0;
            palletRealWeight = palletRealWeight <= 0 ? 0 : palletRealWeight;
            const palletBoxesCount = this.calculatePalletBoxesCount(pallet);

            let palletNetWeight = 0;
            pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
                if (this.hasSubpalletBatchesBoxes(subpallet)) {
                    const percentageBoxes = subpallet.boxes_count / palletBoxesCount;
                    let realWeight = palletRealWeight <= 0 ? 0 : Math.round(palletRealWeight * percentageBoxes);
                    const netWeight = this.calculateNetWeightSubpallet(palletRealWeight, palletBoxesCount, subpallet);
                    palletNetWeight += netWeight;
                    subpallet.net_weight_input = netWeight;
                    subpallet.real_weight_input = realWeight;
                }
            });

            pallet.real_weight = palletRealWeight;
            pallet.net_weight = Number(palletNetWeight);
            this.calculateWeightsBySubpallets();
        }
    };

    private hasSubpalletBatchesBoxes(subpallet: PalletToErpSubpallet) {
        const batchesBoxesCount = subpallet.batches.map((batch: PalletToErpSubpalletBatch) => batch.boxes_count).reduce((a, b) => a + b, 0);
        return batchesBoxesCount > 0;
    }

    private getFechaFabricacion(finishedAt: string) {
        return finishedAt !== ''
            ? finishedAt
                  .split(' ')[0]
                  .split('-')
                  .reverse()
                  .join('-')
            : '';
    }

    private getFormattedPalletInfo = () => {
        if (!(this.state.palletDetail && this.state.palletDetail.pallet)) return;

        const pallet = this.state.palletDetail.pallet;

        const subpallets: any[] = [];

        pallet.subpallets.forEach((subpallet: PalletToErpSubpallet) => {
            let DatosOrigen: any[] = [];
            subpallet.batches.forEach((batch: PalletToErpSubpalletBatch) => {
                const peso = Math.min(batch.net_weight, batch.available_kg);
                if (peso > 0) {
                    DatosOrigen.push({
                        tipoOrigen: batch.origin_type,
                        codigoOrigen: batch.erp_origin_code,
                        idOrigen: batch.erp_origin_id,
                        peso,
                    });
                }
            });
            subpallet.new_batches.forEach((batch: PalletToErpSubpalletNewBatch) => {
                if (batch.kg > 0) {
                    //Primero tiene que comprobar que no sea un origen que estaba de antes

                    let newOrigin = true;
                    const newDatosOrigen: any[] = [];

                    DatosOrigen.forEach(originInArray => {
                        if (originInArray.idOrigen === batch.erp_origin_id) {
                            originInArray.peso = originInArray.peso + batch.kg;
                            newOrigin = false;
                        }
                        newDatosOrigen.push(originInArray);
                    });

                    if (newOrigin) {
                        DatosOrigen.push({
                            tipoOrigen: batch.origin_type,
                            codigoOrigen: batch.erp_origin_code,
                            idOrigen: batch.erp_origin_id,
                            peso: batch.kg,
                        });
                    } else {
                        DatosOrigen = newDatosOrigen;
                    }
                }
            });
            subpallets.push({
                cantidadPale: '1',
                fechaFabricacion: this.getFechaFabricacion(pallet.finished_at ?? ''),
                pesoBruto: subpallet.real_weight,
                pesoNeto: subpallet.net_weight,
                idLineaPedido: subpallet.prodpart.IdLineaPedido,
                codigoArticulo: subpallet.merchandise_erp_code ?? subpallet.prodpart.CodigoArticulo,
                codigoEnvase: subpallet.erp_env_codigo ?? subpallet.prodpart.CodigoEnvase,
                codigoPale: subpallet.erp_pal_codigo ?? subpallet.prodpart.CodigoPallet,
                codigoMarca: subpallet.prodpart.CodigoMarca,
                cantidadEnvase: subpallet.boxes_count,
                pesoPorCaja: subpallet.box_weight,
                id: subpallet.id,
                DatosOrigen,
            });
        });

        return {
            lineaProduccion: pallet.erp_area_code,
            detalles: subpallets,
        };
    };

    private sendInformationToErp = async (onlySave: boolean) => {
        const {palletDetail, printTypeSelected, printerTypeSelected, numberCopies, posterPrintTypeSelected, posterPrinterTypeSelected, posterNumberCopies} = this.state;

        //Si está cargando no debe poder enviar
        if (this.state.isLoading || !palletDetail || !palletDetail.pallet) {
            return;
        }
        const {pallet} = palletDetail;

        const subpallets = this.state.palletDetail?.pallet?.subpallets;

        const kgToFill = pallet.kg_to_fill ?? 0;
        const netWeight = subpallets
            ? subpallets.reduce((a: number, b: PalletToErpSubpallet) => {
                  return a + b.net_weight;
              }, 0)
            : 0;

        if (pallet.erp_logisticunit_code) {
            notify({message: 'Palé ya registrado en ERP. No se puede volver a registrar', status: 'warning'});
        } else if (!(this.state.palletDetail && this.state.palletDetail.pallet && this.state.palletDetail.pallet.real_weight)) {
            notify({message: 'No se ha establecido el peso bruto del palé', status: 'warning'});
        } else if (Math.floor(kgToFill) > 0) {
            notify({message: 'Hay que rellenar el palé antes de registrarlo', status: 'warning'});
        } else if (Math.floor(netWeight) <= 0) {
            notify({message: 'El peso neto debe ser mayor de cero', status: 'warning'});
        } else if (printerTypeSelected.code === '' && !onlySave) {
            notify({message: 'Debe seleccionar una impresora', status: 'warning'});
        } else {
            this.setLoading('Enviando información del palé...');
            await fillStorage(printerSelectedInstance, printerTypeSelected); // Guardar en caché la impresora seleccionada
            await fillStorage(numberCopiesInstance, {numberCopies: numberCopies}); // Guardar en caché el número de copias
            await fillStorage(posterPrinterSelectedInstance, posterPrinterTypeSelected); // Guardar en caché la impresora seleccionada

            await fetchWithFeedback(
                PalletInformationServices.sendInfoPallet(
                    pallet.id,
                    this.state.cstmFields,
                    this.getFormattedPalletInfo(),
                    printTypeSelected.code,
                    printerTypeSelected.code,
                    numberCopies,
                    this.props.userData.username,
                    posterPrintTypeSelected.code,
                    posterPrinterTypeSelected.code,
                    posterNumberCopies,
                    onlySave,
                ),
                {showMessage: false},
            )
                .then(res => {
                    const logisticunit_barcode = res && res.data && res.data.erp_barcode;

                    if (logisticunit_barcode) {
                        pallet.erp_logisticunit_code = logisticunit_barcode;
                        palletDetail.palletCstmFields = [];
                        notify({
                            message: 'La operación se ha realizado con éxito',
                            status: 'success',
                        });
                    } else {
                        if (res && res.data && res.data.error) {
                            if (res.data.error === 'interbloqueo') {
                                this.setState({...this.state, openDialogConfirmInterbloqueo: true});
                            } else {
                                notify({message: 'Error al enviar la información del palé al ERP', status: 'error'});
                            }
                        } else {
                            notify({
                                message: 'Error no controlado. Intente de nuevo',
                                status: 'error',
                            });
                        }

                        this.unsetLoading();
                        return;
                    }

                    palletDetail.palletCstmFields = [];

                    this.state.cstmFields.forEach((value: string, key: string) => {
                        const cstmDef = this.state.palletDetail?.palletDefinitionCstmFields.find((c: PalletDefinitionCstmField) => c.id === key);
                        if (cstmDef) this.state.palletDetail?.palletCstmFields.push({name: cstmDef.name, value: value});
                    });
                })
                .catch(() => {
                    notify({message: 'Error al enviar la información del palé al ERP', status: 'error'});
                });

            //Ha fallado el envío. Entonces no recargamos los datos
            if (!this.state.isLoading) {
                return;
            }

            this.unsetLoading();

            await this.fetchInformation(pallet.palletBarcode);
        }
    };

    private setLoading = (msg: string) => {
        this.setState({
            ...this.state,
            isLoading: true,
            isLoadingMsg: msg,
        });
    };

    private unsetLoading = () => {
        this.setState({
            ...this.state,
            isLoading: false,
            isLoadingMsg: '',
        });
    };

    private hideSelectPalletModal = () => {
        this.setState({
            ...this.state,
            showSelectPallet: false,
            isLoading: true,
            isLoadingMsg: 'Obteniendo información del palé...',
        });
    };

    private handleBarcodeSelectedInList = (barcodeSelected: string) => {
        this.setState({
            ...this.state,
            palletBarcodeInput: barcodeSelected,
        });
        this.fetchInformation(barcodeSelected);
        this.hideSelectPalletModal();
    };

    private handleTrySendPalletInfoToErp = () => {
        this.setState({...this.state, openDialogConfirmInterbloqueo: false});
        this.sendInformationToErp(false);
    };

    private handleTrySendPalletInfoToErpOnlySave = () => {
        this.setState({...this.state, openDialogConfirmInterbloqueo: false});
        this.sendInformationToErp(true);
    };

    private handleTrySendPalletInfoToErpWitchCheckPallets = (onlySave: boolean = false) => {
        const pallet = this.state.palletDetail?.pallet;

        const reservedPallets = pallet ? pallet.subpallets.reduce((a: number, subpallet: PalletToErpSubpallet) => a + subpallet.prodpart?.PalesReservados ?? 0, 0) : 0;
        const palletCount = pallet ? pallet.subpallets.reduce((a: number, subpallet: PalletToErpSubpallet) => a + subpallet.prodpart?.PalCantidad ?? 0, 0) : 0;

        if (reservedPallets === palletCount) {
            this.setState({...this.state, openDialogConfirnPalletReservedExceded: true});
            return;
        }
        this.sendInformationToErp(onlySave);
    };

    private getProdpartPalletReserved() {
        const subpallets = this.state.palletDetail?.pallet?.subpallets;
        if (subpallets) {
            return subpallets.reduce((accumulated: number, subpallet: PalletToErpSubpallet) => accumulated + subpallet.prodpart?.PalesReservados ?? 0, 0);
        }
        return 0;
    }

    private getProdpartPalletCount() {
        const subpallets = this.state.palletDetail?.pallet?.subpallets;
        if (subpallets) {
            return subpallets.reduce((accumulated: number, subpallet: PalletToErpSubpallet) => accumulated + subpallet.prodpart.PalCantidad, 0);
        }
        return 0;
    }

    private handleClosePalletCompleteInfoModal = (pallet?: PalletInfoPallet) => {
        this.setState({...this.state, openPalletCompleteInfoModal: false}, () => {
            if (pallet) {
                this.fetchInformation(pallet.barcode);
            }
        });
    };

    render() {
        const {
            palletDetail,
            openModalPrinters,
            numberCopies,
            posterNumberCopies,
            cstmFields,
            openDialogConfirmInterbloqueo,
            openDialogConfirnPalletReservedExceded,
            posterPrinterTypeSelected,
            posterPrintTypeSelected,
            openModalPosterPrinters,
            printerTypeSelected,
            printTypeSelected,
            showSelectPallet,
            openModalPrints,
            openModalPosterPrints,
        } = this.state;

        const onlySavePallet = !IsInRuleset(['PALLETS_REGISTRY_ERP']);

        return (
            <>
                <StatusBar />
                <SelectPalletModal open={showSelectPallet} hideSelectPalletModal={this.hideSelectPalletModal} handleSelectPallet={this.handleBarcodeSelectedInList} />

                {!onlySavePallet && (
                    <CustomDialog
                        open={openDialogConfirmInterbloqueo}
                        title={'Error de interbloqueo'}
                        contentText={'¿Desea reintenar el envío de la información del palé al ERP?'}
                        handle={this.handleTrySendPalletInfoToErp}
                        handleClose={() => this.setState({...this.state, openDialogConfirmInterbloqueo: false})}
                    />
                )}

                {onlySavePallet && (
                    <CustomDialog
                        open={openDialogConfirmInterbloqueo}
                        title={'Error de interbloqueo'}
                        contentText={'¿Desea reintenar el envío de la información del palé al ERP?'}
                        handle={this.handleTrySendPalletInfoToErpOnlySave}
                        handleClose={() => this.setState({...this.state, openDialogConfirmInterbloqueo: false})}
                    />
                )}

                <CustomDialog
                    open={openDialogConfirnPalletReservedExceded}
                    title={'Los palés reservados exceden del pedido'}
                    contentText={`Palés reservados ${this.getProdpartPalletReserved()}/${this.getProdpartPalletCount()} Palés pedido. ¿Desea enviar esta información al ERP?`}
                    handle={() => {
                        this.sendInformationToErp(onlySavePallet);
                        this.setState({...this.state, openDialogConfirnPalletReservedExceded: false});
                    }}
                    handleClose={() => this.setState({...this.state, openDialogConfirnPalletReservedExceded: false})}
                />

                <WrappingDiv>
                    <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'}>
                        <Typography component="div">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.fetchInformation();
                                }}
                                style={{
                                    margin: '0px',
                                    display: 'flex',
                                    height: 35,
                                    marginBottom: 10,
                                    marginTop: 5,
                                }}
                                noValidate={true}
                                autoComplete="off"
                            >
                                <TextField
                                    placeholder="Código de barras del palé"
                                    inputRef={this.inputPalletBCRef}
                                    autoFocus={true}
                                    style={{marginRight: 5, width: '100%'}}
                                    InputProps={{style: {height: 35}}}
                                    variant="outlined"
                                    value={this.state.palletBarcodeInput}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') {
                                            this.setState({...this.state, palletBarcodeInput: value});
                                        }
                                    }}
                                />
                                <Button
                                    color="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.fetchInformation();
                                    }}
                                    style={{width: 235}}
                                >
                                    Info
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({
                                            ...this.state,
                                            showSelectPallet: true,
                                        });
                                    }}
                                    style={{width: 235}}
                                >
                                    Seleccionar palé
                                </Button>
                            </form>
                            {palletDetail && palletDetail.pallet && (
                                <>
                                    <WrapInfo>
                                        <h2>Información de palé</h2>
                                        <hr></hr>
                                        <DivElement>
                                            <WrapLabelInfo>Código de barras del palé:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.palletBarcode}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Código de barras del palé en ERP:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.erp_logisticunit_code}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Código Pedido:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.erp_order_code}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Pales reservados pedido:</WrapLabelInfo>
                                            <WrapValueInfo>
                                                <span>
                                                    {this.getProdpartPalletReserved()}/{this.getProdpartPalletCount()}
                                                </span>
                                            </WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Número de cajas totales:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.boxes_total}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Empezado:</WrapLabelInfo>
                                            <WrapValueInfo>
                                                {format(new Date(palletDetail.pallet.started_at), 'DD/MM/YYYY HH:mm:ss', {
                                                    locale: es,
                                                })}
                                            </WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Finalizado:</WrapLabelInfo>
                                            <WrapValueInfo>
                                                {palletDetail.pallet.finished_at &&
                                                    format(new Date(palletDetail.pallet.finished_at), 'DD/MM/YYYY HH:mm:ss', {
                                                        locale: es,
                                                    })}
                                                {!palletDetail.pallet.finished_at && 'No está finalizado'}
                                            </WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Peso bruto:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.real_weight} kg</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Peso neto:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.net_weight} kg</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                            <WrapLabelInfo>Peso estimado:</WrapLabelInfo>
                                            <WrapValueInfo>{palletDetail.pallet.estimated_weight.toFixed(0)} kg</WrapValueInfo>
                                        </DivElement>

                                        {palletDetail &&
                                            invoke(palletDetail.palletCstmFields, 'map', (pcf: {name: string; value: string}) => (
                                                <DivElement key={pcf.name}>
                                                    <WrapLabelInfo>{pcf.name}:</WrapLabelInfo>
                                                    <WrapValueInfo>{pcf.value}</WrapValueInfo>
                                                </DivElement>
                                            ))}
                                    </WrapInfo>

                                    <PalletCompleteInfoModal
                                        open={this.state.openPalletCompleteInfoModal}
                                        onClose={(pallet?: PalletInfoPallet) => this.handleClosePalletCompleteInfoModal(pallet)}
                                        palletBarcode={palletDetail.pallet.palletBarcode}
                                    />

                                    <CustomButton
                                        margin={'5px'}
                                        bgcolor={'green'}
                                        width={'15%'}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({...this.state, openPalletCompleteInfoModal: true});
                                        }}
                                    >
                                        Modificar Palé
                                    </CustomButton>

                                    <WrapInfo>
                                        <h2>Desglose de cajas</h2>
                                        <hr></hr>
                                    </WrapInfo>

                                    {palletDetail &&
                                        palletDetail.pallet &&
                                        palletDetail.pallet.subpallets.map((subpallet: PalletToErpSubpallet, index: number) => (
                                            <WrapInfo style={{marginTop: index === 0 ? '0px' : '10px'}}>
                                                <Card style={{backgroundColor: 'rgba(228,255,210,0.87)'}}>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexFlow: 'row wrap',
                                                            justifyContent: 'space-evenly',
                                                            padding: '5px',
                                                            backgroundColor: '#C5F8A3DD',
                                                        }}
                                                    >
                                                        <span>{subpallet.prodpart_code}</span>
                                                        <span>{subpallet.merchandise_name}</span>
                                                        <span>{subpallet.packaging_name}</span>
                                                        <span>{subpallet.pallet_name}</span>
                                                        <span>
                                                            {subpallet.boxes_count}/{subpallet.boxes_total}
                                                        </span>
                                                    </div>
                                                    <CardContent>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell style={{width: '40%'}}>Código ERP del origen</TableCell>
                                                                    <TableCell align="right" style={{width: '14%'}}>
                                                                        Número de cajas
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '10%'}}>
                                                                        Kg x caja
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '14%'}}>
                                                                        Kilos netos
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '14%'}}>
                                                                        Kilos disponibles
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        style={{
                                                                            width: '14%',
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        Exceso
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {invoke(subpallet.batches, 'map', (batch: PalletToErpSubpalletBatch, id: number) => (
                                                                    <TableRow key={id}>
                                                                        <TableCell style={{width: '40%'}}>
                                                                            {batch.erp_origin_code} ({batch.merchandise_name})
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '14%'}}>
                                                                            {batch.boxes_count}
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '10%'}}>
                                                                            {batch.net_weight && subpallet.box_weight.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '14%'}}>
                                                                            {batch.net_weight && batch.net_weight.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '14%'}}>
                                                                            {batch.available_kg && batch.available_kg.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '14%'}}>
                                                                            {batch.net_weight &&
                                                                                batch.net_weight > batch.available_kg &&
                                                                                (batch.net_weight - batch.available_kg).toFixed(2)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                <TableRow key={'boxes_count'}>
                                                                    <TableCell style={{width: '40%'}}>{'Totales'}</TableCell>
                                                                    <TableCell align="right" style={{width: '15%'}}>
                                                                        <span style={{fontWeight: 'bold'}}>{subpallet.boxes_count}</span>
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '10%'}}>
                                                                        <span style={{fontWeight: 'bold'}}></span>
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '14%'}}>
                                                                        <span style={{fontWeight: 'bold'}}>{subpallet.net_weight && subpallet.net_weight.toFixed(2)}</span>
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: '14%'}}>
                                                                        <span style={{fontWeight: 'bold'}}>
                                                                            {subpallet.total_available_kg ? subpallet.total_available_kg.toFixed(2) : 0}
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{width: 'g14%'}}>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                color: 'red',
                                                                            }}
                                                                        >
                                                                            {subpallet.excess_kg ? subpallet.excess_kg.toFixed(2) : 0}
                                                                        </span>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>

                                                        {subpallet.excess_kg > 0 && (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    padding: '5px',
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'center',
                                                                    marginTop: '8px',
                                                                }}
                                                            >
                                                                <CustomTextField
                                                                    width={'25%'}
                                                                    label={'Código del origen'}
                                                                    value={this.state[subpallet.id] ?? ''}
                                                                    name={'Código'}
                                                                    variant={'outlined'}
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            [subpallet.id]: e.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                                <CustomButton
                                                                    margin={'0'}
                                                                    bgcolor={'green'}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.fetchOrigin(subpallet, this.state[subpallet.id]);
                                                                        this.setState({
                                                                            ...this.state,
                                                                            [subpallet.id]: '',
                                                                        });
                                                                    }}
                                                                >
                                                                    Añadir origen
                                                                </CustomButton>
                                                            </div>
                                                        )}

                                                        <Table>
                                                            <TableBody>
                                                                {invoke(subpallet.new_batches, 'map', (batch: PalletToErpSubpalletNewBatch, id: number) => (
                                                                    <TableRow key={id} style={{background: 'rgb(186, 255, 187)'}}>
                                                                        <TableCell style={{width: '53%'}}>{batch.erp_origin_code}</TableCell>
                                                                        <TableCell align="right" style={{width: '20%'}}>
                                                                            <CustomTextField
                                                                                type={'number'}
                                                                                value={batch.kg}
                                                                                variant={'outlined'}
                                                                                onChange={e => {
                                                                                    batch.kg = e.target.value;
                                                                                    const allKg = subpallet.new_batches
                                                                                        .map(b => b.kg)
                                                                                        .reduce((t: number, k: number) => t * 1 + k * 1, 0);
                                                                                    const kgToFill = subpallet.excess_kg - (allKg - batch.kg);
                                                                                    const maxKg = Math.min(batch.available_kg, kgToFill);
                                                                                    if (batch.kg < 0) batch.kg = 0;
                                                                                    if (batch.kg > maxKg) batch.kg = maxKg;
                                                                                    this.calculateWeightsBySubpallets();
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                width: '12%',
                                                                            }}
                                                                        >
                                                                            {batch.available_kg.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right" style={{width: '15%'}}>
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    const index = subpallet.new_batches.indexOf(batch);
                                                                                    if (index > -1) {
                                                                                        subpallet.new_batches.splice(index, 1);
                                                                                    }
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        [subpallet.id]: '',
                                                                                    });
                                                                                    this.calculateWeightsBySubpallets();
                                                                                }}
                                                                            >
                                                                                <RemoveButton color="secondary" />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>

                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                margin: '10px 10px 10px 0',
                                                            }}
                                                        >
                                                            <CustomTextField
                                                                margin={'5px'}
                                                                type={'number'}
                                                                width={'10%'}
                                                                label={'Peso est.'}
                                                                value={subpallet.estimated_weight}
                                                                name={'Peso estimado'}
                                                                variant={'outlined'}
                                                                disabled={true}
                                                                onChange={() => {}}
                                                            />
                                                            <CustomTextField
                                                                type={'number'}
                                                                width={'10%'}
                                                                label={'Peso bruto'}
                                                                value={subpallet.real_weight_input}
                                                                name={'Peso bruto'}
                                                                variant={'outlined'}
                                                                onChange={e => {
                                                                    subpallet.real_weight_input = e.target.value;
                                                                    this.changeRealWeightField(subpallet, e.target.value);
                                                                }}
                                                            />
                                                            <CustomTextField
                                                                margin={'5px'}
                                                                type={'number'}
                                                                width={'10%'}
                                                                label={'Peso neto'}
                                                                value={subpallet.net_weight_input}
                                                                name={'Peso neto'}
                                                                variant={'outlined'}
                                                                onChange={e => {
                                                                    subpallet.net_weight_input = e.target.value;
                                                                    this.changeNetWeightField(subpallet, e.target.value);
                                                                }}
                                                            />
                                                            <CustomButton
                                                                margin={'5px'}
                                                                bgcolor={'green'}
                                                                width={'15%'}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.calculateWeightsBySubpallets();
                                                                }}
                                                            >
                                                                Aplicar
                                                            </CustomButton>

                                                            {subpallet.excess_kg > 0 && (
                                                                <div style={{marginLeft: 'auto'}}>
                                                                    <span>Kg. a rellenar:&nbsp;</span>
                                                                    <span style={{fontWeight: 'bold'}}>{subpallet.kg_to_fill.toFixed(2)}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </WrapInfo>
                                        ))}

                                    {palletDetail && palletDetail.pallet && palletDetail.pallet.subpallets.length > 1 && (
                                        <WrapInfo>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    margin: '10px 10px 10px 0',
                                                }}
                                            >
                                                <CustomTextField
                                                    margin={'5px'}
                                                    type={'number'}
                                                    width={'10%'}
                                                    label={'Peso est.'}
                                                    value={palletDetail.pallet.estimated_weight}
                                                    name={'Peso estimado'}
                                                    variant={'outlined'}
                                                    disabled={true}
                                                    onChange={() => {}}
                                                />
                                                <CustomTextField
                                                    type={'number'}
                                                    width={'10%'}
                                                    label={'Peso bruto'}
                                                    value={palletDetail.pallet.real_weight_input}
                                                    name={'Peso bruto'}
                                                    variant={'outlined'}
                                                    onChange={e => {
                                                        if (palletDetail && palletDetail.pallet) {
                                                            palletDetail.pallet.real_weight_input = Number(e.target.value);
                                                        }
                                                        this.changePalletRealWeightField(e.target.value);
                                                    }}
                                                />
                                                <CustomTextField
                                                    margin={'5px'}
                                                    type={'number'}
                                                    width={'10%'}
                                                    label={'Peso neto'}
                                                    value={palletDetail.pallet.net_weight_input}
                                                    name={'Peso neto'}
                                                    variant={'outlined'}
                                                    onChange={e => {
                                                        if (palletDetail && palletDetail.pallet) {
                                                            palletDetail.pallet.net_weight_input = Number(e.target.value);
                                                        }
                                                        this.changePalletNetWeightField(e.target.value);
                                                    }}
                                                />
                                                <CustomButton
                                                    margin={'5px'}
                                                    bgcolor={'green'}
                                                    width={'15%'}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.calculateWeightsByPallet();
                                                    }}
                                                >
                                                    Aplicar
                                                </CustomButton>

                                                {palletDetail.pallet.excess_kg > 0 && (
                                                    <div style={{marginLeft: 'auto'}}>
                                                        <span>Kg. a rellenar:&nbsp;</span>
                                                        <span style={{fontWeight: 'bold'}}>{palletDetail.pallet.kg_to_fill.toFixed(2)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </WrapInfo>
                                    )}

                                    <WrapInfo style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        {!onlySavePallet && (
                                            <CustomButton
                                                margin={'5px'}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.handleTrySendPalletInfoToErpWitchCheckPallets();
                                                }}
                                                width={'20%'}
                                            >
                                                Enviar a ERP
                                            </CustomButton>
                                        )}
                                        {onlySavePallet && (
                                            <CustomButton
                                                margin={'5px'}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.handleTrySendPalletInfoToErpWitchCheckPallets(true);
                                                }}
                                                width={'20%'}
                                            >
                                                Modificar sin enviar a ERP
                                            </CustomButton>
                                        )}
                                    </WrapInfo>
                                    {!onlySavePallet && (
                                        <WrapInfo>
                                            <h2>Impresión del ticket</h2>
                                            <hr></hr>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    margin: '10px 10px 10px 0',
                                                }}
                                            >
                                                <CustomButton //margin={'0px 0px 0px auto'}
                                                    margin={'5px'}
                                                    bgcolor={'#64b5f6'}
                                                    width={'25%'}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            ...this.state,
                                                            openModalPrints: true,
                                                        });
                                                    }}
                                                >
                                                    Impreso: {printTypeSelected.name}
                                                </CustomButton>
                                                <CustomButton
                                                    margin={'5px'}
                                                    bgcolor={'#64b5f6'}
                                                    width={'25%'}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            ...this.state,
                                                            openModalPrinters: true,
                                                        });
                                                    }}
                                                >
                                                    Impresora: {printerTypeSelected.name}
                                                </CustomButton>
                                                <CustomTextField
                                                    type={'number'}
                                                    width={'10%'}
                                                    label={'N. Copias'}
                                                    value={numberCopies}
                                                    name={'n_copias'}
                                                    variant={'outlined'}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            numberCopies: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </WrapInfo>
                                    )}
                                    <WrapInfo>
                                        <h2>Información adicional</h2>
                                        <hr></hr>
                                        {!onlySavePallet && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    margin: '10px 10px 10px 0',
                                                }}
                                            >
                                                <CustomButton
                                                    margin={'5px'}
                                                    bgcolor={'#64b5f6'}
                                                    width={'25%'}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            ...this.state,
                                                            openModalPosterPrints: true,
                                                        });
                                                    }}
                                                >
                                                    Impreso Cartel: {posterPrintTypeSelected.name}
                                                </CustomButton>
                                                <CustomButton
                                                    margin={'5px'}
                                                    bgcolor={'#64b5f6'}
                                                    width={'25%'}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            ...this.state,
                                                            openModalPosterPrinters: true,
                                                        });
                                                    }}
                                                >
                                                    Impresora: {posterPrinterTypeSelected.name}
                                                </CustomButton>
                                                <CustomTextField
                                                    type={'number'}
                                                    width={'10%'}
                                                    label={'N. Copias'}
                                                    value={posterNumberCopies}
                                                    name={'poster_n_copias'}
                                                    variant={'outlined'}
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            posterNumberCopies: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {invoke(palletDetail.palletDefinitionCstmFields, 'map', (pdcf: {id: string; name: string; description: string; select_options: string}) => {
                                            const options = pdcf.select_options && pdcf.select_options !== '' ? pdcf.select_options.split('|') : [];
                                            const selectOptionsFormated: {value: string; label: string}[] = [];
                                            options.forEach((o: string) => {
                                                selectOptionsFormated.push({value: o, label: o});
                                            });

                                            if (selectOptionsFormated.length > 0) {
                                                return (
                                                    <fieldset
                                                        key={pdcf.id}
                                                        style={{
                                                            width: '60%',
                                                            margin: '0 auto 10px 0',
                                                            padding: '0px',
                                                            border: '1px solid #ced4da',
                                                            backgroundColor: 'transparent',
                                                            borderRadius: '3px',
                                                        }}
                                                    >
                                                        <legend>{pdcf.name}</legend>
                                                        <select
                                                            style={{
                                                                width: '100%',
                                                                margin: '0px',
                                                                padding: '15px 10px',
                                                                border: 'none',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            placeholder={pdcf.name}
                                                            value={cstmFields.get(pdcf.id)}
                                                            onChange={(e: any) => {
                                                                cstmFields.set(pdcf.id, e.target.value);
                                                                this.forceUpdate();
                                                            }}
                                                        >
                                                            <option></option>
                                                            {selectOptionsFormated.map((s: {value: string; label: string}) => {
                                                                return (
                                                                    <option style={{padding: '5px', margin: '5px'}} key={s.value} value={s.value}>
                                                                        {s.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </fieldset>
                                                );
                                            }

                                            return (
                                                <div key={pdcf.id}>
                                                    <CustomTextField
                                                        margin={'0 auto 10px auto'}
                                                        width={'60%'}
                                                        label={pdcf.name}
                                                        value={cstmFields.get(pdcf.id)}
                                                        name={pdcf.id}
                                                        variant={'outlined'}
                                                        onChange={e => {
                                                            const containQuotes = e.target.value.includes("'"); //Para evitar caracter especial
                                                            const containDoubleQuotes = e.target.value.includes('"'); //Para evitar caracter especial
                                                            if (!containQuotes && !containDoubleQuotes) {
                                                                const {name, value} = e.target;
                                                                cstmFields.set(name, value);
                                                                this.forceUpdate();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </WrapInfo>
                                </>
                            )}
                        </Typography>
                    </OCard>
                </WrappingDiv>

                <TableModal title={'Seleccione un tipo de impreso'} open={openModalPrints} handleClose={() => this.setState({...this.state, openModalPrints: false})}>
                    <br />
                    {((palletDetail &&
                        palletDetail.printTypes &&
                        palletDetail.printTypes.map(printType => {
                            if (printType) {
                                return (
                                    <Typography
                                        key={printType.name}
                                        component="div"
                                        style={{
                                            marginTop: 10,
                                        }}
                                    >
                                        <>
                                            <CustomButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        ...this.state,
                                                        printTypeSelected: {
                                                            name: printType.name,
                                                            code: printType.code,
                                                        },
                                                        openModalPrints: false,
                                                    });
                                                }}
                                                padding={'3px'}
                                            >
                                                {printType.name}
                                            </CustomButton>
                                            <br />
                                        </>
                                    </Typography>
                                );
                            } else return undefined;
                        })) as any) || 'No tiene impresos seleccionables'}
                </TableModal>

                <TableModal title={'Seleccione impresora'} open={openModalPrinters} handleClose={() => this.setState({...this.state, openModalPrinters: false})}>
                    <br />
                    {((palletDetail &&
                        palletDetail.printerTypes &&
                        palletDetail.printerTypes.map(printerType => {
                            if (printerType) {
                                return (
                                    <Typography
                                        key={printerType.name}
                                        component="div"
                                        style={{
                                            marginTop: 10,
                                        }}
                                    >
                                        <>
                                            <CustomButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        ...this.state,
                                                        printerTypeSelected: {
                                                            name: printerType.name,
                                                            code: printerType.code,
                                                        },
                                                        openModalPrinters: false,
                                                    });
                                                }}
                                                padding={'3px'}
                                            >
                                                {printerType.name}
                                            </CustomButton>
                                            <br />
                                        </>
                                    </Typography>
                                );
                            } else return undefined;
                        })) as any) || 'No tiene impresoras seleccionables'}
                </TableModal>

                <TableModal
                    title={'Seleccione un tipo de impreso cartel'}
                    open={openModalPosterPrints}
                    handleClose={() => this.setState({...this.state, openModalPosterPrints: false})}
                >
                    <br />
                    {((palletDetail &&
                        palletDetail.posterPrintTypes &&
                        palletDetail.posterPrintTypes.map((posterPrintType: PalletToErpPosterPrintType) => {
                            if (posterPrintType) {
                                return (
                                    <Typography
                                        key={posterPrintType.name}
                                        component="div"
                                        style={{
                                            marginTop: 10,
                                        }}
                                    >
                                        <>
                                            <CustomButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        ...this.state,
                                                        posterPrintTypeSelected: {
                                                            name: posterPrintType.name,
                                                            code: posterPrintType.code,
                                                        },
                                                        openModalPosterPrints: false,
                                                    });
                                                }}
                                                padding={'3px'}
                                            >
                                                {posterPrintType.name}
                                            </CustomButton>
                                            <br />
                                        </>
                                    </Typography>
                                );
                            } else return undefined;
                        })) as any) || 'No tiene impresos de carteles seleccionables'}
                </TableModal>

                <TableModal title={'Seleccione impresora cartel'} open={openModalPosterPrinters} handleClose={() => this.setState({...this.state, openModalPosterPrinters: false})}>
                    <br />
                    {((palletDetail &&
                        palletDetail.posterPrinterTypes &&
                        palletDetail.posterPrinterTypes.map((posterPrinterType: PalletToErpPosterPrinterType) => {
                            if (posterPrinterType) {
                                return (
                                    <Typography
                                        key={posterPrinterType.name}
                                        component="div"
                                        style={{
                                            marginTop: 10,
                                        }}
                                    >
                                        <>
                                            <CustomButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        ...this.state,
                                                        posterPrinterTypeSelected: {
                                                            name: posterPrinterType.name,
                                                            code: posterPrinterType.code,
                                                        },
                                                        openModalPosterPrinters: false,
                                                    });
                                                }}
                                                padding={'3px'}
                                            >
                                                {posterPrinterType.name}
                                            </CustomButton>
                                            <br />
                                        </>
                                    </Typography>
                                );
                            } else return undefined;
                        })) as any) || 'No tiene impresoras de carteles seleccionables'}
                </TableModal>

                {this.state.isLoading && (
                    <WrapLoaderDiv>
                        <CircularProgress style={{color: 'blue', margin: '16px'}} />
                        <Typography
                            style={{
                                padding: '10px',
                                backgroundColor: '#f2f2f2',
                                opacity: '1',
                            }}
                        >
                            {this.state.isLoadingMsg}
                        </Typography>
                    </WrapLoaderDiv>
                )}
            </>
        );
    }
}

export const PalletInformationErp = connect<SProps, {}, {}, AppStoreState>(
    state => ({
        userData: LoginSelectors.getUserData(state),
    }),
    {},
)(PalletInformationErpBase);

export const printerSelectedInstance = localforage.createInstance({
    name: 'print-selected-store',
    version: 1.0,
    storeName: 'print-selected-store',
});

export const numberCopiesInstance = localforage.createInstance({
    name: 'number-copies-store',
    version: 1.0,
    storeName: 'number-copies-store',
});

export const posterPrinterSelectedInstance = localforage.createInstance({
    name: 'poster-print-selected-store',
    version: 1.0,
    storeName: 'poster-print-selected-store',
});
