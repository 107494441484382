import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {notifyConnect, notifyServerError} from 'src/utils/Notification';

import {LaneProdPartActions} from '../actions';
import {AreaServices} from '../services';
import {LaneActionInterface} from '../types';
import {areaProdPartsResSchemeValidate} from '../validators/AreaProdPartsRes';

export const getAreaAllProdparts = () => async (dispatch: (action: any) => LaneActionInterface, getState: () => AppStoreState) => {
    try {
        const lane = LaneSelectSelectors.getLane(getState());
        const req = await AreaServices.getAllProdparts(lane ? lane.laneId : 'NO_EXISTE_LINEA');
        const {data} = req;
        if (req.status < 400) {
            const validate = await areaProdPartsResSchemeValidate;
            const valid = await validate(data);

            dispatch(LaneProdPartActions.set(data));

            if (!valid) {
                console.error('NO HE VALIDADO> ', validate.errors); // ! POR CONSOLA???
            }
        } else dispatch(notifyServerError());
    } catch (error) {
        dispatch(notifyConnect());
        console.error(error);
        throw new Error(error.message);
    }
};
