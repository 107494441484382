import {guardedFetcher} from 'src/utils/guardedFetcher';
import {UpdateOriginResponse, UpdateOriginType} from "./modifyDumpsControl.types";
import {LaneBatchesWithBoxesState} from "../../../store/types";

const prefix = 'production/control';

export const UpdateOriginsControlService = {

    updateBoxes: (transfer : UpdateOriginType) => {
        return guardedFetcher.post<UpdateOriginResponse>(`${prefix}/origin/update-boxes`, transfer);
    },

    getOrigin: (erpOriginCode : string) => {
        return guardedFetcher.get<LaneBatchesWithBoxesState>(`production/info/origin?code=${erpOriginCode}`);
    }
};
