import {PalletCompleteInfoUnit, PalletInfoUnit, PalletList, PalletSimpleInfoUnit} from './palletInformation.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';
import {PaginatedServiceArgs} from 'src/types';
import {PalletToErpDetail} from '../types';

const prefix = 'production/info';
export const PalletInformationServices = {
    getInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletInfoUnit}>(`${prefix}/detailed-pallet/${palletBarcode}`),
    getErpInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletToErpDetail}>(`${prefix}/detailed-erp-pallet/${palletBarcode}`, {timeout: 400000}),
    getErpOriginInfo: (erpOriginCode: string) => guardedFetcher.get(`${prefix}/erpOrigin?code=${erpOriginCode}`),
    getOrCreateOrigin: (erpOriginCode: string) => guardedFetcher.get(`${prefix}/getOrCreateOrigin?code=${erpOriginCode}`),
    getSimpleInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletSimpleInfoUnit}>(`${prefix}/simple-pallet/${palletBarcode}`),
    getCompleteInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletCompleteInfoUnit}>(`${prefix}/complete-pallet-info/${palletBarcode}`),
    sendInfoPallet: (
        palletId: string,
        cstmFields: any,
        palletData: any,
        printTypeSelected: string,
        printerTypeSelected: string,
        number_copies: number | undefined,
        username: string,
        posterPrintTypeSelected: string,
        posterPrinterTypeSelected: string,
        poster_number_copies: number | undefined,
        onlySave: boolean,
    ) =>
        guardedFetcher.patch(
            `production/control/send-info-pallet/${palletId}`,
            {
                cstmFields,
                palletData,
                printTypeSelected,
                printerTypeSelected,
                number_copies,
                username,
                posterPrintTypeSelected,
                posterPrinterTypeSelected,
                poster_number_copies,
                onlySave,
            },
            {timeout: 200000},
        ),
    addLogisticUnitToPallet: ({logisticunit_barcode, palletId}: {logisticunit_barcode: string; palletId: string}) =>
        guardedFetcher.patch(`production/control/detailed-pallet/${palletId}`, {
            logisticunit_barcode,
        }),
    createPallet: ({logisticunit_barcode, palletId}: {logisticunit_barcode: string; palletId: string}) =>
        guardedFetcher.patch(`production/control/detailed-pallet/${palletId}`, {
            logisticunit_barcode,
        }),
    getAutomaticPalletsNotVinculated: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<PalletList>>(`${prefix}/automatic-pallets-not-vinculated`, {params}),
};
