import {ProdPartsServices} from 'src/app/lanemanager/prodparts/store/prodParts.services';
import {guardedFetcher} from 'src/utils/guardedFetcher';

// import {PaginateParams} from 'src/types';

const prefix = 'crud/ptables';

type Options = {id?: string; name?: string; areaId?: string; barcode?: number | null, display_only?: boolean};
export const PtablesControlServices = {
  // FIXME: change to the propietary service
  get: () => ProdPartsServices.getPtables(),
  getAll: () => guardedFetcher.get<any>(`${prefix}/areaId/all-ptables`),
  getByAreaId: (areaId: string) => guardedFetcher.get<any>(`${prefix}/areaId/${areaId}`),
  // (params?: PaginateParams) => guardedFetcher.get(`${prefix}/all`, {params}),
  update: ({id, name, barcode, areaId, display_only}: Options) =>
    guardedFetcher.patch(`${prefix}/${id}`, {name, barcode, areaId, display_only}),
  create: ({name, areaId, barcode, display_only}: Options) =>
    guardedFetcher.post(`${prefix}`, {name, areaId, barcode, display_only}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
};
