import * as React from 'react';

import {LanePtableState, LanePtableStatePalletSubpalletBatch} from 'src/app/lanemanager/lane/store/types';

import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {parseInt} from 'lodash';

import styles from './TransferBoxes.module.css';
import {PalletsServices} from '../../../pallets/store/services';
import {notify} from '../../../../../utils/Notification';
import {FlexRow, FlexRowItem} from './Wrap';

export interface Props {
    selectedPtablePrevious: LanePtableState;
    selectedPtableTarget: LanePtableState;
    selectedBatch: LanePtableStatePalletSubpalletBatch;
    open: boolean;
    boxesToTransfer: number;
    onClose: (isTransfer: boolean) => void;
}

type State = {
    prevPalletBoxesBefore: number;
    prevPalletBoxesAfter: number;
    prevBoxKg: number;
    prevPalletBoxesTotal: number;
    nextPalletBoxesBefore: number;
    nextBoxesAfter: number;
    nextBoxKg: number;
    nextPalletBoxesTotal: number;
    boxesToTransfer: number;
    nextBoxesToTransfer: number;
    sending: boolean;
};

const initialState: State = {
    prevPalletBoxesBefore: 0,
    prevPalletBoxesAfter: 0,
    prevPalletBoxesTotal: 0,
    prevBoxKg: 0,
    nextPalletBoxesBefore: 0,
    nextPalletBoxesTotal: 0,
    nextBoxesAfter: 0,
    nextBoxKg: 0,
    nextBoxesToTransfer: 0,
    boxesToTransfer: 0,
    sending: false,
};

export class PalletInfoTransferBoxesModal extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.setState({...this.state, boxesToTransfer: this.props.boxesToTransfer});
    }

    private close = (isTransfer = false) => {
        this.setState({...this.state, boxesToTransfer: 0, nextBoxesToTransfer: 0});
        this.props.onClose(isTransfer);
    };

    private handleChangeNextBoxCount = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'next_boxes_count') {
            if (e.target.value === '') {
                this.setState({...this.state, nextBoxesToTransfer: 0});
            } else {
                let nextBoxesToTrans = parseInt(e.target.value);
                if (isNaN(nextBoxesToTrans)) return;

                if (nextBoxesToTrans < 0) {
                    nextBoxesToTrans = 0;
                }

                const {nextBoxKg, prevBoxKg} = this.state;
                const boxesToTransfer = Math.round((Number(nextBoxKg) * nextBoxesToTrans) / Number(prevBoxKg));

                const prevPalletBoxesAfter = this.state.prevPalletBoxesBefore - boxesToTransfer;
                const nextBoxesAfter = this.state.nextPalletBoxesBefore + nextBoxesToTrans;

                if (boxesToTransfer <= this.props.boxesToTransfer) {
                    this.setState({
                        ...this.state,
                        nextBoxesToTransfer: nextBoxesToTrans,
                        boxesToTransfer,
                        nextBoxesAfter,
                        prevPalletBoxesAfter,
                    });
                }
            }
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props) || JSON.stringify(prevState) !== JSON.stringify(this.state)) {
            const boxesToTrans = Number(this.state.boxesToTransfer);

            const previousPallet = this.props.selectedPtablePrevious.pallet;
            const targetPallet = this.props.selectedPtableTarget.pallet;

            if (previousPallet && targetPallet) {
                const prevProdpartBoxesTotal = Number(previousPallet.current_subpallet.prodpart.boxes_total);
                const prevProdpartKgTotal = Number(previousPallet.current_subpallet.prodpart.kg_total);
                const prevPalletBoxesBefore = Number(previousPallet.current_subpallet.boxes_count ?? 0);
                const prevPalletBoxesAfter = prevPalletBoxesBefore - boxesToTrans;
                const prevBoxKg = parseFloat((prevProdpartKgTotal / prevProdpartBoxesTotal).toPrecision(2));

                const nextProdpartBoxesTotal = Number(targetPallet.current_subpallet.prodpart.boxes_total);
                const nextProdpartKgTotal = Number(targetPallet.current_subpallet.prodpart.kg_total);
                const nextPalletBoxesBefore = Number(targetPallet.current_subpallet.boxes_count ?? 0);
                const nextBoxKg = parseFloat((nextProdpartKgTotal / nextProdpartBoxesTotal).toPrecision(2));
                const nextBoxesToTransfer = Math.round((Number(prevBoxKg) * boxesToTrans) / nextBoxKg);
                const nextBoxesAfter = nextPalletBoxesBefore + nextBoxesToTransfer;

                this.setState({
                    ...this.state,
                    prevPalletBoxesBefore,
                    prevBoxKg,
                    prevPalletBoxesAfter,
                    prevPalletBoxesTotal: previousPallet.current_subpallet.boxes_total,
                    nextPalletBoxesBefore,
                    nextBoxKg,
                    nextBoxesAfter,
                    nextPalletBoxesTotal: targetPallet.current_subpallet.boxes_total,
                    nextBoxesToTransfer: nextBoxesToTransfer,
                });
            }
        }
    }

    private sendTransferBoxes = async () => {
        const {selectedBatch, selectedPtablePrevious, selectedPtableTarget} = this.props;
        const {boxesToTransfer, nextBoxesToTransfer} = this.state;

        const palletPrevius = selectedPtablePrevious.pallet;
        const palletTarget = selectedPtableTarget.pallet;

        if (palletPrevius && palletTarget) {
            this.setState({sending: true});
            await PalletsServices.transferBoxes(selectedBatch.id, palletPrevius.id, palletTarget.id, boxesToTransfer, nextBoxesToTransfer)
                .then(r => {
                    if (r.status === 200) {
                        notify({
                            status: 'success',
                            message: 'Cajas transferidas',
                        });
                    } else {
                        let message = 'No se pudieron transferir las cajas';
                        if (r.data.message === 'BOXES_EXCESS_LOGISTIC_UNIT_AMOUNT') message = 'Se ha intentado transferir más cajas de las que contiene el palé de origen';
                        else if (r.data.message === 'BOXES_EXCESS_LOGISTIC_UNIT_CAPACITY') message = 'Se ha intentado transferir cajas a un palé sin capacidad disponible';

                        notify({
                            status: 'error',
                            message: message,
                        });
                    }
                })
                .catch(() => {
                    notify({
                        status: 'error',
                        message: 'No se pudieron transferir las cajas',
                    });
                });
            this.close(true);
            this.setState({sending: false});
        }
    };

    render() {
        const {sending} = this.state;
        return (
            <>
                {this.props.selectedPtablePrevious && (
                    <TableModal title="¿Desea transferir las cajas?" open={this.props.open} handleClose={() => this.close(false)}>
                        <div style={styles}>
                            <table style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className={styles.tdRight}>Origen</th>
                                        <th className={styles.tdRight}>Destino</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Mesa</b>
                                        </td>
                                        <td className={styles.tdRight}>
                                            <b>{this.props.selectedPtablePrevious.name}</b>
                                        </td>
                                        <td className={styles.tdRight}>
                                            <b>{this.props.selectedPtableTarget.name}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Artículo</td>
                                        <td className={styles.tdRight}>{this.props.selectedPtablePrevious.pallet?.current_subpallet.merchandise.name ?? ''}</td>
                                        <td className={styles.tdRight}>{this.props.selectedPtableTarget.pallet?.current_subpallet.merchandise.name ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td>Parte</td>
                                        <td className={styles.tdRight}>{this.props.selectedPtablePrevious.pallet?.current_subpallet.prodpart.erp_prodpart_code ?? ''}</td>
                                        <td className={styles.tdRight}>{this.props.selectedPtableTarget.pallet?.current_subpallet.prodpart.erp_prodpart_code ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td>Cajas parte</td>
                                        <td className={styles.tdRight}>
                                            {this.props.selectedPtablePrevious.pallet?.current_subpallet.prodpart.boxes_count ?? '-'}/
                                            {this.props.selectedPtablePrevious.pallet?.current_subpallet.prodpart.boxes_total ?? '-'}
                                        </td>
                                        <td className={styles.tdRight}>
                                            {this.props.selectedPtableTarget.pallet?.current_subpallet.prodpart.boxes_count ?? '-'}/
                                            {this.props.selectedPtableTarget.pallet?.current_subpallet.prodpart.boxes_total ?? '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cajas antes</td>
                                        <td className={styles.tdRight}>
                                            {this.state.prevPalletBoxesBefore}/{this.state.prevPalletBoxesTotal}
                                        </td>
                                        <td className={styles.tdRight}>
                                            {this.state.nextPalletBoxesBefore}/{this.state.nextPalletBoxesTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cajas a transferir</td>
                                        <td className={styles.tdRight}>{this.state.boxesToTransfer}</td>
                                        <td className={styles.tdRight}>{this.state.nextBoxesToTransfer}</td>
                                    </tr>
                                    <tr>
                                        <td>Cajas después</td>
                                        <td className={styles.tdRight}>
                                            {this.state.prevPalletBoxesAfter}/{this.state.prevPalletBoxesTotal}
                                        </td>
                                        <td className={styles.tdRight}>
                                            {this.state.nextBoxesAfter}/{this.state.nextPalletBoxesTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Peso caja</td>
                                        <td className={styles.tdRight}>{this.state.prevBoxKg} Kg</td>
                                        <td className={styles.tdRight}>{this.state.nextBoxKg} Kg</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />

                        <FlexRow>
                            <FlexRowItem>
                                <CustomTextField
                                    label="Número de cajas en pale de destino"
                                    error={false}
                                    value={this.state.nextBoxesToTransfer}
                                    name="next_boxes_count"
                                    variant={'outlined'}
                                    type="number"
                                    onChange={this.handleChangeNextBoxCount}
                                />
                            </FlexRowItem>
                            <FlexRowItem>
                                <CustomButton disabled={this.state.nextBoxesToTransfer === 0 || sending} onClick={this.sendTransferBoxes} padding={'3px'}>
                                    Transferir
                                </CustomButton>
                            </FlexRowItem>
                        </FlexRow>
                    </TableModal>
                )}
            </>
        );
    }
}
