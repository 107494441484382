import * as React from 'react';
import ReactTable, {Column, RowInfo} from 'react-table';

import {Pallet} from 'src/app/lanemanager/lane/store/types';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import styled from 'styled-components';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Subpallet} from '../../lanemanager/pallets/store/types';

type CostsTypes = 'cost_direct' | 'cost_indirect' | 'cost_manufacturing' | 'cost_total' | 'time_direct' | 'time_indirect' | 'time_manufacturing' | 'time_total';

export const PalletsList: React.FunctionComponent = () => {
    const extractCosts = (items: any, type: CostsTypes) => {
        const {costs} = items.original;
        if (costs && costs.length) {
            const cost = costs[0];
            return cost[type] ? +cost[type] : 0;
        }
        return 0;
    };
    const columns: ColumnType<Pallet> = [
        {
            Header: 'Jornada',
            headerClassName: 'header_cell_string',
            accessor: 'workShift',
            dateFormat: true,
            daterange: true,
            width: 260,
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Código de palé',
            headerClassName: 'header_cell_string',
            accessor: 'palletBarcode',
            width: 150,
            filterable: true,
            sortable: false,
            className: 'cell_string',
            Cell: items => (
                <span>
                    {items.original.palletBarcode}
                    {items.original.subpallets.length > 1 ? <b style={{color: 'darkgreen'}}>(*)</b> : ''}
                </span>
            ),
        },
        {
            Header: 'Cajas',
            headerClassName: 'header_cell_number',
            accessor: 'boxes_count',
            width: 75,
            Cell: items => (
                <>
                    {customFormatNumber(items.original.boxes_count)}/{customFormatNumber(items.original.boxes_total)}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'Mesa',
            headerClassName: 'header_cell_string',
            accessor: 'ptable_name',
            width: 75,
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            accessor: 'center_name',
            width: 150,
            filterable: true,
            sortable: true,
            className: 'cell_string',
        },
        {
            Header: 'C.Directo',
            headerClassName: 'header_cell_number',
            Cell: items => (
                <CostsWrapper>
                    <span>{customFormatNumber(Number(extractCosts(items, 'cost_direct').toFixed(2)))} €</span>
                    <span>{customFormatNumber(Number((extractCosts(items, 'time_direct') / 60).toFixed(1)))} m</span>
                </CostsWrapper>
            ),
            className: 'cell_number',
        },
        {
            Header: 'C.Indirecto',
            headerClassName: 'header_cell_number',
            Cell: items => (
                <CostsWrapper>
                    <span>{customFormatNumber(Number(extractCosts(items, 'cost_indirect').toFixed(2)))} €</span>
                    <span>{customFormatNumber(Number((extractCosts(items, 'time_indirect') / 60).toFixed(1)))} m</span>
                </CostsWrapper>
            ),
            className: 'cell_number',
        },
        {
            Header: 'C.Envasado',
            headerClassName: 'header_cell_number',
            Cell: items => (
                <CostsWrapper>
                    <span>{customFormatNumber(Number(extractCosts(items, 'cost_manufacturing').toFixed(2)))} €</span>
                    <span>{customFormatNumber(Number((extractCosts(items, 'time_manufacturing') / 60).toFixed(1)))} m</span>
                </CostsWrapper>
            ),
            className: 'cell_number',
        },
        {
            Header: 'C.TOTAL',
            headerClassName: 'header_cell_number',
            Cell: items => (
                <CostsWrapper>
                    <span>{customFormatNumber(Number(extractCosts(items, 'cost_total').toFixed(2)))} €</span>
                    <span>{customFormatNumber(Number((extractCosts(items, 'time_total') / 60).toFixed(1)))} m</span>
                </CostsWrapper>
            ),
            className: 'cell_number',
        },
        {
            Header: 'H. Inicio',
            headerClassName: 'header_cell_string',
            accessor: 'started_at',
            // width: 140,
            dateTimeFormat: true,
            className: 'cell_string',
        },
        {
            Header: 'H. Fin',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            // width: 140,
            dateTimeFormat: true,
            className: 'cell_string',
        },
        {
            Header: 'UL final',
            headerClassName: 'header_cell_string',
            accessor: 'erp_logisticunit_code',
            // width: 200,
            Cell: props => <>{props.original.erp_logisticunit_code ? props.original.erp_logisticunit_code : 'Sin asignar todavía'}</>,
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Orígenes',
            accessor: 'batches',
            minWidth: 70,
            maxWidth: 70,
            width: 80,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];
    return IsInRuleset(['REPORTS_PALLETS_VIEW', 'REPORTS_PALLETS_VIEW_EXCEL']) ? (
        <PaginatedTableComponent
            service={PalletsServices.getAllPaginated}
            columnFormat={columns}
            defaultPageSize={20}
            loading={true}
            excel={IsInRuleset('REPORTS_PALLETS_VIEW_EXCEL')}
            propsToOwnTable={{
                SubComponent: (info: RowInfo) => {
                    const subpallets = info.original.subpallets;

                    return (
                        <>
                            {subpallets &&
                                subpallets.map((subpallet: Subpallet) => (
                                    <>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                                backgroundColor: '#FDFFBAFF',
                                            }}
                                        >
                                            <span style={{paddingLeft: '5px'}}>{subpallet.merchandise_name}</span>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.prodpart_code}</span>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.packaging_name}</span>
                                            <span style={{paddingLeft: '5px'}}>
                                                {subpallet.boxes_count}/{subpallet.boxes_total}
                                            </span>
                                        </div>
                                        <ReactTable
                                            data={subpallet.batches}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={subpallet.batches.length}
                                            resizable={false}
                                            getNoDataProps={() => {
                                                if (!subpallet.batches.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            sortable={false}
                                            columns={
                                                [
                                                    {
                                                        Header: '',
                                                        headerClassName: 'subheader_cell_string',
                                                        accessor: 'white-space',
                                                        className: 'subcell_string',
                                                        width: 800,
                                                    },
                                                    {
                                                        Header: 'Código ERP del origen',
                                                        headerClassName: 'subheader_cell_string',
                                                        headerStyle: {backgroundColor: '#333333', color: '#cccccc'},
                                                        accessor: 'erp_batch_code',
                                                        className: 'subcell_string',
                                                    },
                                                    {
                                                        Header: 'Cajas del origen',
                                                        headerClassName: 'subheader_cell_number',
                                                        headerStyle: {backgroundColor: '#333333', color: '#cccccc'},
                                                        accessor: 'boxes',
                                                        className: 'subcell_number',
                                                    },
                                                ] as Column<any>[]
                                            }
                                        />
                                    </>
                                ))}
                        </>
                    );
                },
            }}
        />
    ) : null;
};

const CostsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    //span {
    //    text-align: center;
    //}
`;
