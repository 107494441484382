import * as React from 'react';

// ! FIXME: unify all styled components!
import {CustomButton} from 'src/shared/style';
import {WrapCreateButton} from 'src/shared/style/Wrap';
import {notify} from 'src/utils/Notification';
import {BarcodeScanner} from "../../../shared/components/BarcodeScanner";
import {TextField, Typography} from "@material-ui/core";
import {Advertisement} from "../../../shared/components/Advertisement";
import {NotifyActionType} from "../../../utils/Notification/action-types";
import { TableModal } from 'src/shared/components/Modal';
import { IsInRuleset } from 'src/global/authorization/grantSet';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CreateDumpWithInfoModal } from './CreateDumpWithInfoModal';
import {PalletInformationServices} from 'src/app/lanemanager/palletInformation/store/palletInformation.services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {CreatePostDumpWithInfoModal} from './CreatePostDumpWithInfoModal';

type DumpCreateProps = {
    handleCreate: (barcodeLogisticunit: string, numberBoxes: number) => void;
    handleClose: () => void;
    handleClosePostDump: () => void;
    handleCreatePostDump: (
        originCode : string,
        boxCount : number,
        workshiftDate : string,
        workshiftType : string) => void;
};

const initialState = {
    barcodeLogisticunit: '',
    openBarcodeScanner: false,
    showAd: false,
    enableButton: true,
    enablePostButton: true,
    openCreatePostDumpForm: false,
    openCreateForm: false,
    totalBoxes:0,
    dumpedBoxes:0,
    availableBoxes:0
};

type State = Readonly<{
    barcodeLogisticunit: string;
    openBarcodeScanner: boolean;
    showAd: boolean;
    enableButton: boolean;
    enablePostButton: boolean;
    openCreatePostDumpForm: boolean;
    openCreateForm: boolean;
    totalBoxes:number;
    dumpedBoxes:number;
    availableBoxes:number;
}>;

export class DumpCreate extends React.Component<DumpCreateProps, State> {
    readonly state = initialState;

    shouldComponentUpdate() {
        return true;
    }

    //Éscaner de códigos de barras
    private barcodeScanner = React.createRef<BarcodeScanner>();
    private adMessage = '';

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private handleCloseCreateModal = () => {
        this.setState({openCreateForm: false});
        this.props.handleClose();
    };

    private handleCloseCreatePostModal = () => {
        this.setState({openCreatePostDumpForm: false});
        this.props.handleClosePostDump();
    };

    private handleChangeBarcodePallet = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'barcode_ul') {
            const newValue = (e.target.value as unknown) as string;

            this.setState({
                barcodeLogisticunit: newValue,
            });
        }
    };

    handleCodeReaded = (code: string) => {
        this.setState({
            barcodeLogisticunit: code,
        });
    };

    scanBarcode = () => {
        if (navigator.mediaDevices) {
            if (this.barcodeScanner.current) {
                this.barcodeScanner.current.init();
                this.setState({openBarcodeScanner: true});
            }
        } else {
            this.showAd('No se puede acceder a la cámara. Se necesita entrar por https y permitir el acceso a la cámara. Gracias.');
        }
    };

    private hideBarcodeScanner = () => {
        this.setState({
            openBarcodeScanner: false,
        });
    };

    public render() {
        return (
            <>
                <div style={{paddingTop: '10px'}}>
                    <TextField
                        fullWidth={true}
                        label="Código"
                        error={false}
                        value={this.state.barcodeLogisticunit}
                        name="barcode_ul"
                        variant={'outlined'}
                        type="string"
                        onChange={this.handleChangeBarcodePallet}
                        onKeyPress={ev => {
                            if (this.state.enableButton && ev.key === 'Enter') {
                                this.handleCreateDump();
                                ev.preventDefault();
                            }
                        }}
                        autoFocus={true}
                    />
                </div>

                <Typography
                    component="div"
                    style={{
                        marginTop: 10,
                    }}
                >
                    <>
                        <CustomButton onClick={this.scanBarcode} padding={'3px'}>
                            {'Escanear código'}
                        </CustomButton>
                        <br />
                    </>
                </Typography>

                <BarcodeScanner open={this.state.openBarcodeScanner} hide={this.hideBarcodeScanner} onReadedCode={this.handleCodeReaded} ref={this.barcodeScanner} />
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />

                <WrapCreateButton>
                    <CustomButton
                        bgcolor={'#00D4AE'}
                        margin={'auto'}
                        disabled={!this.state.enableButton}
                        onClick={() => this.handleCreateDump()}>
                        {!this.state.enableButton ? <CircularProgress color="secondary" style={{height: 24, width: 24}} /> : 'Crear el volcado'}
                    </CustomButton>
                </WrapCreateButton>


                <CreateDumpWithInfoModal
                    open={this.state.openCreateForm}
                    totalNumBoxes={this.state.totalBoxes}
                    dumpedNumBoxes={this.state.dumpedBoxes}
                    availableBoxes={this.state.availableBoxes}
                    barcode={this.state.barcodeLogisticunit}
                    username={'jmcastillo'}
                    handleClose={this.handleCloseCreateModal}
                    handleCreate={this.props.handleCreate}
                />

                {IsInRuleset(['CREATE_DUMPS_LATER']) && (
                    <>
                        <WrapCreateButton>
                            <CustomButton
                                bgcolor={'#00E848'}
                                margin={'auto'}
                                disabled={!this.state.enablePostButton}
                                onClick={() => this.handleCreatePostDump()}>
                                {!this.state.enablePostButton ? <CircularProgress color="secondary" style={{height: 24, width: 24}} /> : 'Crear volcado a posteriori'}
                            </CustomButton>
                        </WrapCreateButton>

                        <TableModal title={'Crear volcado a posteriori'} open={this.state.openCreatePostDumpForm} handleClose={this.handleCloseCreatePostModal}>
                            <CreatePostDumpWithInfoModal
                                open={this.state.openCreatePostDumpForm}
                                totalNumBoxes={this.state.totalBoxes}
                                dumpedNumBoxes={this.state.dumpedBoxes}
                                availableBoxes={this.state.availableBoxes}
                                barcode={this.state.barcodeLogisticunit}
                                username={'jmcastillo'}
                                handleClosePostDump={this.handleCloseCreatePostModal}
                                handleCreatePostDump={this.props.handleCreatePostDump}
                            />
                        </TableModal>
                    </>
                )}



            </>
        );
    }

    private handleCreateDump = async () => {

        if(this.state.barcodeLogisticunit === ''){
            notify({
                message: 'No se puede crear el volcado con los campos en blanco',
                status: 'warning',
            });
        }else{
            this.setState({enableButton: false});
            try {
                const originData = await fetchWithFeedback(PalletInformationServices.getOrCreateOrigin(this.state.barcodeLogisticunit), {
                    errorMessage: 'Error al obtener el origen solicitado',
                }).finally(() => {
                    console.log('FINALLY');
                });

                console.log('ORIGIN DATA: ',originData);

                this.setState({
                    totalBoxes:originData.max_boxes,
                    dumpedBoxes:originData.dumped_boxes,
                    availableBoxes:originData.max_boxes-originData.dumped_boxes
                });

                this.setState({
                    openCreateForm: true,
                });

            } catch (error) {
                console.error('Error al crear', error);
            }
        }
    };

    private handleCreatePostDump = async () => {
        if(this.state.barcodeLogisticunit === ''){
            notify({
                message: 'Se requiere el código de origen',
                status: 'warning',
            });
        }else{
            this.setState({enablePostButton: false});
            try {
                const originData = await fetchWithFeedback(PalletInformationServices.getOrCreateOrigin(this.state.barcodeLogisticunit), {
                    errorMessage: 'Error al obtener el origen solicitado',
                }).finally(() => {
                    console.log('FINALLY');
                });

                console.log('ORIGIN DATA: ',originData);

                this.setState({
                    totalBoxes:originData.max_boxes,
                    dumpedBoxes:originData.dumped_boxes,
                    availableBoxes:originData.max_boxes-originData.dumped_boxes
                });

                this.setState({
                    openCreatePostDumpForm: true,
                });

            } catch (error) {
                console.error('Error al crear', error);
            }
        }
    };

}
