import React, {FunctionComponent} from 'react';
import ReactTable, {Column, RowInfo} from 'react-table';
import {ReportsServices} from './store/services';
import {Pallet, Subpallet} from 'src/app/lanemanager/pallets/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';

export const PalletsInfo: FunctionComponent = () => {
    const columns: ColumnType<Pallet> = [
        {
            Header: 'CÓDIGO PALÉ',
            headerClassName: 'header_cell_string',
            accessor: 'palletBarcode',
            filterable: true,
            sortable: false,
            minWidth: 130,
            className: 'cell_string',
        },
        {
            Header: 'PEDIDO',
            headerClassName: 'header_cell_string',
            accessor: 'erp_order_code',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'PARTE',
            headerClassName: 'header_cell_string',
            accessor: 'erp_prodpart_code',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            accessor: 'area_name',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            accessor: 'center_name',
            filterable: true,
            sortable: true,
            className: 'cell_string',
            //style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
        },
        {
            Header: 'MESA',
            headerClassName: 'header_cell_string',
            accessor: 'ptable_name',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'CAJAS',
            headerClassName: 'header_cell_number',
            accessor: 'boxes_count',
            Cell: item => (
                <>
                    {customFormatNumber(item.original.boxes_count)}/{customFormatNumber(item.original.boxes_total)}
                </>
            ),
            className: 'cell_number',
        },

        {
            Header: 'H. INICIO',
            headerClassName: 'header_cell_string',
            filterable: true,
            daterange: true,
            accessor: 'started_at',
            dateTimeFormat: true,
            //className: 'cell_string',
            className: 'cell_string',
        },
        {
            Header: 'H. FIN',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            dateTimeFormat: true,
            className: 'cell_string',
        },
        {
            Header: 'UL FINAL',
            headerClassName: 'header_cell_string',
            accessor: 'erp_logisticunit_code',
            Cell: item => <>{item.original.erp_logisticunit_code ? item.original.erp_logisticunit_code : 'Sin asignar todavía'}</>,
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'ORIGENES',
            headerClassName: 'header_cell_string',
            accessor: 'batches',
            minWidth: 70,
            maxWidth: 70,
            width: 70,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    return (
        <PaginatedTableComponent
            service={ReportsServices.getPalletsAllPaginated}
            columnFormat={columns}
            defaultPageSize={20}
            loading={true}
            excel={true}
            propsToOwnTable={{
                SubComponent: (info: RowInfo) => {
                    const subpallets = info.original.subpallets;

                    return (
                        <>
                            {
                                subpallets && subpallets.map((subpallet: Subpallet) => (
                                    <>
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            backgroundColor: '#FDFFBAFF',
                                        }}>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.merchandise_name}</span>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.prodpart_code}</span>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.packaging_name}</span>
                                            <span style={{paddingLeft: '5px'}}>{subpallet.boxes_count}/{subpallet.boxes_total}</span>
                                        </div>
                                        <ReactTable
                                            data={subpallet.batches}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={subpallet.batches.length}
                                            resizable={false}
                                            getNoDataProps={() => {
                                                if (!subpallet.batches.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            sortable={false}
                                            columns={[
                                                {
                                                    Header: '',
                                                    headerClassName: 'subheader_cell_string',
                                                    accessor: 'white-space',
                                                    className: 'subcell_string',
                                                    width: 200,
                                                },
                                                {
                                                    Header: 'Código ERP del origen',
                                                    headerClassName: 'subheader_cell_string',
                                                    accessor: 'erp_batch_code',
                                                    className: 'subcell_string',
                                                },
                                                {
                                                    Header: 'Fecha Origen',
                                                    headerClassName: 'subheader_cell_string',
                                                    accessor: 'arrival_at',
                                                    Cell: item => (
                                                        <>
                                                            {item.original &&
                                                                item.original.arrival_at &&
                                                                format(new Date(item.original.arrival_at), 'DD/MM/YYYY HH:mm:ss', {
                                                                    locale: es,
                                                                })}
                                                        </>
                                                    ),
                                                    className: 'cell_string',
                                                },
                                                {
                                                    Header: 'Proveedor',
                                                    headerClassName: 'subheader_cell_string',
                                                    accessor: 'name',
                                                    className: 'subcell_string',
                                                },
                                                {
                                                    Header: 'Kg del origen',
                                                    headerClassName: 'subheader_cell_number',
                                                    accessor: 'kg',
                                                    Cell: item => <>{customFormatNumber(Math.round(item.original.kg))}</>,
                                                    className: 'subcell_number',
                                                },
                                                {
                                                    Header: 'Cajas del origen',
                                                    headerClassName: 'subheader_cell_number',
                                                    accessor: 'boxes',
                                                    Cell: item => <>{customFormatNumber(item.original.boxes)}</>,
                                                    className: 'subcell_number',
                                                },
                                            ] as Column<any>[]}
                                        />
                                    </>
                                ))
                            }
                        </>
                    );
                },
            }}
        />
    );
};
