import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {PalletsQueue, PalletsResponse} from './types';
import {palletsValidate} from './validators/PalletsResponse';
import {PalletInfoErpMerchandise} from '../../../global/palletInfo/types';

export const PalletsServices = {
    getAll: async () => {
        try {
            const validate = await palletsValidate;
            const {data, status} = await guardedFetcher.get<PalletsResponse>('production/info/pallets');

            if (!validate(data)) {
                console.log('no he validado>> ', validate.errors);
            }

            if (status === 200) return data;
            else {
                console.log('no he validado>> ', validate.errors);
                return undefined;
            }
        } catch (error) {
            return undefined;
        }
    },

    getAllPaginated: ({params, args}: PaginatedServiceArgs) =>
        guardedFetcher.get<PaginateResponse<PalletsResponse>>('production/info/pallets', {
            params: args ? {...params, filtered: {area_id: args, ...params!.filtered}} : {...params},
        }),
    update: (ptableId: string, palletId: string, boxes: number, datetime: string) =>
        guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${palletId}/addboxes`, {boxes, datetime}),
    remove: (ptableId: string, palletId: string, boxes: number, datetime: string) =>
        guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${palletId}/removeboxes`, {boxes, datetime}),
    finish: (palletId: string) => guardedFetcher.post(`production/control/pallet/${palletId}/finish`),
    finishAllByArea: (areaId: string) => guardedFetcher.post(`production/control/pallets/${areaId}/finish`),
    transferBoxes: (originId: string, previusPalletId: string, targetPalletId: string, prevBoxes: number, nextBoxes: number) =>
        guardedFetcher.post(`production/control/pallet-previous/${previusPalletId}/pallet-target/${targetPalletId}/batch/${originId}/transfer-boxes`, {
            prevBoxes,
            nextBoxes,
        }),
    vinculatePallet: (barcodePallet: string, ptableId: string | undefined) => guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${barcodePallet}/start-web`),
    vinculatePalletFromProdpart: (barcodePallet: string, prodpartCode: string | undefined, areaId: string) =>
        guardedFetcher.post(`production/control/pallet/${barcodePallet}/start-pallet`, {prodpartCode, areaId}),

    enqueuePallets: (queue: PalletsQueue) => guardedFetcher.post(`production/control/ptable/enqueuePallets`, {queue}),

    updateSubpalletProdpart: (palletBarcode: string, prodpartId: string, numberBoxes: number) =>
        guardedFetcher.patch(`production/control/subpallet/${palletBarcode}/prodpart`, {
            prodpartId,
            numberBoxes,
        }),
    updateSubpalletMerchandise: (subpalletId: string, erpMerchandiseCode: number) =>
        guardedFetcher.patch(`production/control/subpallet/${subpalletId}/merchandise`, {
            erpMerchandiseCode,
        }),
    updateSubpalletPackaguing: (subpalletId: string, erpPackagingName: string, erpPackagingCode: number, erpTara: number) =>
        guardedFetcher.patch(`production/control/subpallet/${subpalletId}/packaging`, {
            erpPackagingName,
            erpPackagingCode,
            erpTara,
        }),
    updateSubpalletType: (subpalletId: string, erpPalletName: string, erpPalletCode: number, erpTara: number) =>
        guardedFetcher.patch(`production/control/subpallet/${subpalletId}/type`, {
            erpPalletName,
            erpPalletCode,
            erpTara,
        }),
    updateSubpalletKgBoxEdit: (subpalletId: string, kgBoxEdit: number) =>
        guardedFetcher.patch(`production/control/subpallet/${subpalletId}/kg-box-edit`, {
            kgBoxEdit,
        }),
    updateCurrentSubpallet: (palletBarcode: string, subpalletId: string) => guardedFetcher.patch(`production/control/pallet/${palletBarcode}/current-subpallet/${subpalletId}`),
    addSubpalletByProdpart: (palletBarcode: string, prodpartId: string) => guardedFetcher.post(`production/control/pallet/${palletBarcode}/add-subpallet/${prodpartId}`),
    deleteSubpallet: (palletBarcode: string, subpalletId: string) => guardedFetcher.delete(`production/control/pallet/${palletBarcode}/delete-subpallet/${subpalletId}`),
    getErpPalletList: () => guardedFetcher.get<any>(`integration/erp/pallet-list`),
    getErpMerchandiseList: (erpCenterId: string) => guardedFetcher.get<PaginateResponse<PalletInfoErpMerchandise>>(`integration/erp/merchandise-list/${erpCenterId}`),
    getErpPackagingList: () => guardedFetcher.get<any>(`integration/erp/packaging-list`),
};
