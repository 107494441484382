import {
  ScalesDevicesControlCreateParams,
  ScalesDevicesControlResponse,
} from 'src/app/administration/scalesDevicesControl/store/scalesDevicesControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/scales-devices';

export const ScalesDevicesControlServices = {
  getAll: () => guardedFetcher.get<ScalesDevicesControlResponse>(prefix),
  create: (params: ScalesDevicesControlCreateParams) => guardedFetcher.post(prefix, params),
  update: (id: string, params: Partial<ScalesDevicesControlCreateParams>) =>
    guardedFetcher.patch(`${prefix}/${id}`, params),
};
