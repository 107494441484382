import {Reducer} from 'redux';

import {AreaPtablesRes, LaneActionInterface, LaneActionTypes, LanePtableState, Ptable} from '../types';

export const defaultState: LanePtableState[] = [];

export const lanePtablesReducer: Reducer<LanePtableState[], LaneActionInterface> = (state = defaultState, {type, payload}): LanePtableState[] => {
    if (!state) return state;
    switch (type) {
        case LaneActionTypes.updatePtable: {
            const cast = payload as LanePtableState;
            const updated = state.map(pt => {
                if (pt.ptable_id === cast.ptable_id) {
                    return {...pt, ...cast, state: coputeState(cast)};
                } else return pt;
            });
            return updated;
        }
        case LaneActionTypes.setPtables: {
            const {ptables} = payload as AreaPtablesRes;
            return [...transformPtable(ptables)];
        }
        case LaneActionTypes.clearPtables: {
            return [];
        }
        default:
            return state;
    }
};

/* HELPER FUNCTIONS */
export const coputeState = (pt: any): LanePtableState['state'] => {
    if (pt.assigned_to && pt.reserved_to && !pt.pallet) return 'RESERVADA';
    if (pt.assigned_to && !pt.reserved_to && !pt.pallet) return 'ASIGNADA';
    if (pt.assigned_to && pt.pallet) return 'ACTIVA';
    return 'INACTIVA';
};

export const transformPtable = (ptables: Ptable[], areaId?: string) => {
    let array = ptables;
    if (typeof areaId === 'string') array = ptables.filter(p => p.area_id === areaId);
    return array.map<LanePtableState>(pt => {
        return {
            ptable_id: pt.id,
            assigned_to: pt.assigned_to,
            assigned_to_code: pt.assigned_to_code,
            assigned_to_prodpart_code: pt.assigned_to_prodpart_code,
            reserved_to: pt.reserved_to,
            reserved_to_code: pt.reserved_to_code,
            name: pt.name,
            barcode: pt.barcode,
            pallet: pt.pallet
                ? {
                      id: pt.pallet.id,
                      barcode: pt.pallet.barcode,
                      boxes_count: pt.pallet.boxes_count,
                      boxes_total: pt.pallet.boxes_total,
                      batches: pt.pallet.batches,
                      current_subpallet_id: pt.pallet.current_subpallet_id,
                      current_subpallet_kg_box_edit: pt.pallet.current_subpallet_kg_box_edit,
                      subpallets: pt.pallet.subpallets,
                      current_subpallet: pt.pallet.current_subpallet,
                  }
                : undefined,
            pallets_queue: pt.pallets_queue,
            state: coputeState(pt),
            display_only: pt.display_only,
        };
    });
};
