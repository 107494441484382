import * as React from 'react';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {AreaControlServices} from 'src/app/administration/areaControl/store/services';
import {AreasControlResponse} from 'src/app/administration/areaControl/store/types';
import {PtablesControlServices} from 'src/app/administration/ptablesControl/store/ptablesControl.services';
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomDropdown, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {findSystemParameter} from 'src/global/systemParameters/systemParameters';
import {ParameterCPN} from 'src/types/app_types';

type State = {
    open: boolean;
    id: string;
    name: string;
    barcode: string;
    areasList: AreasControlResponse['areas'];
    areaId: string;
    areaIdValid: boolean;
    barcodeValid: boolean;
    nameValid: boolean;
    display_only: boolean;
};
const initialState: State = {
    open: false,
    id: '',
    name: '',
    areaId: '',
    areasList: [],
    barcode: '',
    barcodeValid: true,
    nameValid: true,
    areaIdValid: true,
    display_only: false,
};

export class PtablesControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHooks = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.fetchAreas();
    }

    private fetchAreas = async () => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreas(), {
            accessor: 'areas',
        });
        this.setState({...this.state, areasList});
    };

    private fetchData = async () => {
        const promise = await PtablesControlServices.getAll();
        let ptables;

        if (promise.data && promise.data.ptables) {
            ptables = promise.data.ptables.map((v : any) => {

                /*const find = this.state.areasList.find(a => a.id === v.area_id);
                const areaName = find ? find.name : '';*/

                return {
                    id : v.id,
                    name : v.name,
                    barcode : v.barcode,
                    area_id : v.area_id,
                    area_name : v.area_name,
                    display_only: v.display_only
                };
            });
        }

        console.log('PATALBES', promise.data.ptables);

        return {
            status : promise.status,
            config : promise.config,
            data : {
                ptables : ptables
            }
        };
    }

    private columns: ColumnType<{id: string; name: string; area_id: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Código de barras',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'barcode',
            className: 'cell_string',
        },
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            filterable: true,
            className: 'cell_string',
            accessor: 'area_name'
            /*
            Cell: item => {
                const find = this.state.areasList.find(a => a.id === item.original.area_id);
                return <>{find && find.name}</>;
            },*/
        },
    ];

    private handleChange: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    };

    private handleClose = () => {
        this.setState({
            ...this.state,
            open: false,
            id: '',
            name: '',
            nameValid: true,
            barcodeValid: true,
            areaIdValid: true,
            barcode: '',
            areaId: '',
            display_only: false,
        });
    };

    private handleOpen = () => {
        this.setState({
            ...this.state,
            open: true,
            nameValid: true,
            barcodeValid: true,
            areaIdValid: true,
        });
    };

    private handleSelect = ({id, name, barcode, area_id, display_only}: {id: string; name: string; barcode: string; area_id: string; display_only: boolean}) => {
        this.setState({
            ...this.state,
            open: true,
            barcode,
            areaId: area_id,
            id,
            name,
            display_only,
            nameValid: true,
            barcodeValid: true,
            areaIdValid: true,
        });
    };

    private handleSending = async () => {
        const {name, id, barcode, areaId, display_only} = this.state;
        const validator = notifyValidator(this);
        if (id) {
            const res = await fetchWithFeedback(
                PtablesControlServices.update({
                    id,
                    name,
                    areaId,
                    barcode: barcode ? +barcode : null,
                    display_only,
                }),
                {showMessage: true, notifyValidator: validator, returnConfirmation: true},
            );
            if (res) this.handleClose();
        } else {
            const res = await fetchWithFeedback(PtablesControlServices.create({name, areaId, barcode: barcode ? +barcode : null, display_only}), {
                showMessage: true,
                notifyValidator: validator,
                returnConfirmation: true,
            });
            if (res)
                this.setState({
                    ...this.state,
                    id: '',
                    name: '',
                    barcode: '',
                    areaId: '',
                    areaIdValid: true,
                    nameValid: true,
                    barcodeValid: true,
                    display_only: false,
                });
        }

        this.useTableHook();
    };

    // private handleChecking = () => {
    //   const {name, areaId} = this.state;
    //   this.setState({nameValid: !!name, barcodeValid: !!areaId}, this.handleSending);
    // };

    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('PTABLES_TOTAL') : false;
        const VIEW_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('PTABLES_VIEW') : false;
        const {name, areaId, barcode, open, areasList, nameValid, barcodeValid, areaIdValid, display_only} = this.state;
        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <TableWrap>
                    <TableModal title={'Gestión de la mesa'} open={open} handleClose={this.handleClose}>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                // minHeight: 300,
                                width: 300,
                            }}
                        >
                            <CustomTextField
                                margin={'5px auto 10px auto'}
                                label="Nombre de la mesa"
                                error={!nameValid}
                                value={name}
                                name="name"
                                variant="outlined"
                                onChange={this.handleChange('name')}
                            />
                            <CustomTextField
                                label="Código de barras"
                                error={!barcodeValid}
                                value={barcode}
                                placeholder={`${findSystemParameter(ParameterCPN.PTABLE_BARCODE_PREFIX)?.value || ''}...`}
                                name="barcode"
                                variant="outlined"
                                onChange={e => {
                                    const {value} = e.target;
                                    if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') this.setState({...this.state, barcode: value});
                                }}
                            />
                            <CustomDropdown
                                error={!areaIdValid}
                                dropdownName={'areas'}
                                data={areasList}
                                value={areaId}
                                handleChoose={e => this.setState({...this.state, areaId: e.target.value})}
                            />
                            <FormControlLabel
                                style={{width: '100%', display: 'flex', justifyContent: 'center'}}
                                control={<Checkbox checked={display_only} onChange={(e: any) => this.setState({...this.state, display_only: e.target.checked})} />}
                                label="Solo Visualización"
                            />
                        </Typography>
                        <CustomButton onClick={this.handleSending} width={'100%'}>
                            {this.state.id ? 'Guardar' : 'Crear'} mesa
                        </CustomButton>
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        //service={PtablesControlServices.get}
                        service={this.fetchData}
                        accessor={'ptables'}
                        createHook={TOTAL_ACCESS ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHooks}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: () => TOTAL_ACCESS && this.handleSelect(rowInfo.original),
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

const TableWrap = styled.div`
    width: 35%;
    height: 100%;
    margin: auto;
`;
