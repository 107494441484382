import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import {push, ConnectedRouterProps, RouterAction} from 'connected-react-router';
import {Button, Grid, Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VideocamIcon from '@material-ui/icons/Videocam';

import React from 'react';
import {LaneSelectActions, LaneSelectSelectors} from '../laneSelect/store';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';
import SettingsIcon from '@material-ui/icons/Settings';
import GraphIcon from '@material-ui/icons/Poll';
import WarningIcon from '@material-ui/icons/Warning';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {FullSizeModal} from 'src/shared/components/FullSizeModal';
import {LaneReport} from 'src/app/information/lane/LaneReport';
//import {TabContainer} from '../lanemanager/Wrap';
//import {OverviewConnected} from '../lanemanager/lane/components/Overview';
import {LaneIndexPage} from '../lanemanager/LaneIndexPage';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
//import {AlertExcessiveInactiveTask} from '../lanemanager/alerts/AlertExcessiveInactiveTask';
import {LoginSelectors} from '../login/store/login.selectors';
import {AlertExcessiveInactiveTaskModal} from '../lanemanager/alerts/AlertExcessiveInactiveTaskModal';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {AlertServices} from '../lanemanager/alerts/store/alert.services';

type DProps = {
    push(path: string, state?: any): RouterAction;
    selectLane(lane: string, name: string, dumpBarcode: string, autoPalletise: boolean, isMasivePalletize: boolean): void;
};

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};

type State = {
    tab: number;
    idMoreDetails?: number;
    wheelEnabled: boolean;
    panEnabled: boolean;
    openLanemanager: boolean;
    openChart: boolean;
    titleCurrentArea: string;
    openAlertExcessiveInactiveTask: boolean;
    alertsInactiveTime: any;
    areaSelectedToAlert: string;
};
const initialState: Readonly<State> = {
    tab: 0,
    idMoreDetails: undefined,
    wheelEnabled: true,
    panEnabled: true,
    openLanemanager: false,
    openChart: false,
    titleCurrentArea: '',
    openAlertExcessiveInactiveTask: false,
    alertsInactiveTime: undefined,
    areaSelectedToAlert: '',
};
type ComponentProps = ConnectedRouterProps & SProps & DProps & Props;

const selectLane = (lane: string, name: string, dumpBarcode: string, autoPalletise: boolean, masivePalletize: boolean) =>
    LaneSelectActions.select(lane, name, dumpBarcode, autoPalletise, masivePalletize);

class BlueprintClass extends React.Component<ComponentProps, State> {
    readonly state = initialState;

    image: any;

    componentWillReceiveProps(nextProps: Props) {
        this.setState({alertsInactiveTime: nextProps.alertsInactiveTime});
    }

    getFontSizeByScale(scale: number, specificFontSize: number) {
        const min = 1;
        const max = 3;
        let fontSize = specificFontSize / scale;
        if (fontSize <= min) fontSize = min;
        else if (fontSize >= max) fontSize = max;

        return fontSize;
    }

    //REVIEW: ¿pasar al los ficheros css??
    getTextShadow(size: number, unit: string) {
        return (
            size +
            unit +
            ' ' +
            size +
            unit +
            ' 0 white, ' +
            '-' +
            size +
            unit +
            ' ' +
            size +
            unit +
            ' 0 white, ' +
            '-' +
            size +
            unit +
            ' -' +
            size +
            unit +
            ' 0 white, ' +
            size +
            unit +
            ' -' +
            size +
            unit +
            ' 0 white, ' +
            size +
            unit +
            ' 0 0 white, ' +
            '-' +
            size +
            unit +
            ' 0 0 white, ' +
            '0 ' +
            size +
            unit +
            ' 0 white, ' +
            '0 -' +
            size +
            unit +
            ' 0 white'
        );
    }

    private checkAlertTransitInactiveExcessive = (idToCheck: string) => {
        if (
            this.state.alertsInactiveTime &&
            this.state.alertsInactiveTime[this.state.areaSelectedToAlert] &&
            this.state.alertsInactiveTime[this.state.areaSelectedToAlert].length
        ) {
            fetchWithFeedback(AlertServices.checkedExcessiveInactiveTask(idToCheck, this.props.userData.employeeId));

            const alertsInactiveTime = this.state.alertsInactiveTime;
            const alertsInactiveTimeByArea = this.state.alertsInactiveTime[this.state.areaSelectedToAlert].filter((item: any) => item.id !== idToCheck);
            alertsInactiveTime[this.state.areaSelectedToAlert] = alertsInactiveTimeByArea;
            this.setState({
                alertsInactiveTime: alertsInactiveTime,
            });
        }
    };

    private hideModalLanemanager = () => {
        this.setState({openLanemanager: false});
    };

    private hideModalChart = () => {
        this.setState({openChart: false});
    };

    private haveEditLineGrants = () => {
        return IsInRuleset(['LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_STATE_TOTAL', 'LANEMANAGER_STATE_VIEW']);
    };

    private haveSeeGraphsGrants = () => {
        return IsInRuleset(['LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_STATE_TOTAL', 'LANEMANAGER_STATE_VIEW']);
    };

    render() {
        const {titleLevel} = this.props;
        const pointForModal = this.state.idMoreDetails !== undefined ? this.props.points[this.state.idMoreDetails] : undefined;
        const defaultScale = 0.2;
        const widthImage = this.image && this.image.offsetWidth ? this.image.offsetWidth * defaultScale : 0;
        const widthScreen = window.innerWidth;
        const positionInitialX = widthScreen > widthImage ? widthScreen / 2 - widthImage / 2 : 0;

        return (
            <div style={{height: '100%'}}>
                <FullSizeModal
                    closeText={'VOLVER AL PLANO'}
                    title={'Gestión de la ' + this.state.titleCurrentArea}
                    open={this.state.openLanemanager}
                    handleClose={this.hideModalLanemanager}
                >
                    {/* <TabContainer>
                        <OverviewConnected />
                    </TabContainer> */}
                    <LaneIndexPage classes={{root: 'test'}} isModal={true} />
                </FullSizeModal>

                <FullSizeModal
                    closeText={'VOLVER AL PLANO'}
                    title={'Gráficas y KPIs de ' + this.state.titleCurrentArea}
                    open={this.state.openChart}
                    handleClose={this.hideModalChart}
                >
                    <LaneReport type={'Holas'} />
                </FullSizeModal>

                <AlertExcessiveInactiveTaskModal
                    openAlertExcessiveInactiveTask={this.state.openAlertExcessiveInactiveTask}
                    excesiveTasks={
                        this.state.alertsInactiveTime && this.state.alertsInactiveTime[this.state.areaSelectedToAlert]
                            ? this.state.alertsInactiveTime[this.state.areaSelectedToAlert]
                            : undefined
                    }
                    handleClose={() => this.setState({openAlertExcessiveInactiveTask: false})}
                    checkAlertTransitInactiveExcessive={this.checkAlertTransitInactiveExcessive}
                />
                <div key={this.props.id} className="wrapper_blueprint" style={{height: '100%'}}>
                    <TransformWrapper
                        defaultScale={defaultScale}
                        defaultPositionY={0}
                        //defaultPositionX={widthImage !== 0 ? positionInitialX : undefined}
                        wheel={{step: 50, wheelEnabled: this.state.wheelEnabled}}
                        pan={{disabled: !this.state.panEnabled}}
                        positionX={!this.props.centeredBlueprint && widthImage !== 0 ? positionInitialX : undefined}
                        options={{
                            limitToBounds: false,
                            minScale: this.props.config.minScale,
                            maxScale: this.props.config.maxScale,
                            centerContent: false,
                        }}
                    >
                        {(props: {scale: number; resetTransform: any; setPositionY: any; setPositionX: any}) => {
                            if (!this.props.centeredBlueprint && widthImage !== 0) {
                                this.props.handleChangePosition();
                            }

                            return (
                                <TransformComponent>
                                    <div className="blueprint">
                                        <img
                                            style={{visibility: widthImage === 0 ? 'hidden' : 'unset'}}
                                            className="blueprint_image"
                                            ref={element => (this.image = element)}
                                            src={this.props.blueprintImg}
                                            alt="map"
                                        />
                                        {props.scale >= titleLevel && (
                                            <div className="nave" style={{fontSize: '1rem'}}>
                                                {this.props.points.map((point, index) => {
                                                    const pointIsColumn = +point.width < +point.height;
                                                    return (
                                                        <div
                                                            key={'point_' + index}
                                                            style={{
                                                                top: point.top + 'px',
                                                                left: point.left + 'px',
                                                                width: point.width + 'px',
                                                                height: point.height + 'px',
                                                                position: 'absolute',
                                                                fontSize: this.getFontSizeByScale(props.scale, point.fontSize) + 'rem',
                                                                textShadow: this.getTextShadow(0.08, 'em'),
                                                                overflow: 'hidden',
                                                            }}
                                                            className="point"
                                                        >
                                                            <div
                                                                className="point_background"
                                                                style={{
                                                                    width: 'calc(100% - 2em)',
                                                                    height: 'calc(100% - 2em)',
                                                                    position: 'absolute',
                                                                    top: '1em',
                                                                    right: '1em',
                                                                    backgroundImage: 'url(' + point.background + ')',
                                                                    backgroundPosition: 'center center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundSize: 'contain',
                                                                    opacity: '0.2',
                                                                }}
                                                            />
                                                            {/* <AlertExcessiveInactiveTask employeeId={this.props.userData.employeeId} laneId={point.data.areaId} /> */}
                                                            {props.scale >= titleLevel && point.data && point.data.title && (
                                                                <div
                                                                    className={'wrapper__point_title'}
                                                                    style={{
                                                                        width: pointIsColumn ? '0' : '',
                                                                        margin: '0 auto',
                                                                        left: pointIsColumn ? '50%' : '0',
                                                                        top: '-' + (point.titleSize + 1) + 'em',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={'data_title '}
                                                                        style={{
                                                                            fontSize: point.titleSize + 'em',
                                                                            fontWeight: 'bold',
                                                                            whiteSpace: 'nowrap',
                                                                            position: 'fixed',
                                                                            display: 'flex',
                                                                            marginTop: '0.3em',
                                                                            alignItems: 'center',
                                                                            height: '1em',
                                                                            transform: pointIsColumn ? 'translate(-50%)' : '',
                                                                            cursor: 'pointer',
                                                                            width: 'width: calc(50% - 2em);',
                                                                        }}
                                                                        onClick={() => this.setState({idMoreDetails: index})}
                                                                    >
                                                                        <div className={'status status--' + point.status} />
                                                                        <span>{point.data.title + ' (' + point.data.workShift + ')'}</span>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div style={{position: 'absolute', right: '-32px'}}>
                                                                <div style={{position: 'fixed'}}>
                                                                    <Grid direction={'column'} spacing={40}>
                                                                        {this.haveEditLineGrants() && (
                                                                            <Grid item>
                                                                                <Tooltip title={'Ir a gestión de línea'}>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            this.props.selectLane(
                                                                                                point.data.areaId,
                                                                                                point.data.title,
                                                                                                point.data.areaDumpBarcode,
                                                                                                point.data.areaAutoPalletise,
                                                                                                point.data.areaMasivePalletize,
                                                                                            );
                                                                                            //this.props.push('/lanemanager');
                                                                                            this.setState({titleCurrentArea: point.data.title});
                                                                                            this.setState({openLanemanager: true});
                                                                                        }}
                                                                                        style={{cursor: 'pointer'}}
                                                                                        color="primary"
                                                                                    >
                                                                                        <SettingsIcon style={{fontSize: 60, color: '#CCCCCC'}} />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        )}

                                                                        {this.haveSeeGraphsGrants() && (
                                                                            <Grid item>
                                                                                <Tooltip title={'Ir a gráficas y KPIs'}>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            this.props.selectLane(
                                                                                                point.data.areaId,
                                                                                                point.data.title,
                                                                                                point.data.areaDumpBarcode,
                                                                                                point.data.areaAutoPalletise,
                                                                                                point.data.areaMasivePalletize,
                                                                                            );
                                                                                            this.setState({titleCurrentArea: point.data.title});
                                                                                            this.setState({openChart: true});
                                                                                        }}
                                                                                        style={{cursor: 'pointer'}}
                                                                                        color="primary"
                                                                                    >
                                                                                        <GraphIcon style={{fontSize: 60, color: '#CCCCCC'}} />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        )}

                                                                        <Grid item>
                                                                            <Tooltip title={'Visualizar cámara'}>
                                                                                <span
                                                                                    onClick={() => this.setState({idMoreDetails: index})}
                                                                                    style={{cursor: 'pointer'}}
                                                                                    color="primary"
                                                                                >
                                                                                    <VideocamIcon style={{fontSize: 60, color: '#CCCCCC'}} />
                                                                                </span>
                                                                            </Tooltip>
                                                                        </Grid>

                                                                        {point.data.areaId in this.state.alertsInactiveTime &&
                                                                            this.state.alertsInactiveTime[point.data.areaId].length > 0 && (
                                                                                <Grid item>
                                                                                    <Tooltip title={'Ver alertas'}>
                                                                                        <span
                                                                                            onClick={() => {
                                                                                                this.setState({areaSelectedToAlert: point.data.areaId});
                                                                                                //this.setState({excesiveTasks: this.state.alertsInactiveTime[point.data.areaId]});
                                                                                                this.setState({openAlertExcessiveInactiveTask: true});
                                                                                            }}
                                                                                            style={{cursor: 'pointer'}}
                                                                                            color="primary"
                                                                                        >
                                                                                            <WarningIcon style={{fontSize: 60, color: 'red'}} />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            )}
                                                                    </Grid>
                                                                </div>
                                                            </div>

                                                            <div className={`point_content ${point.status ? 'point_content--' + point.status : ''}`}>
                                                                <div className={'wrapper__info_product'}>
                                                                    {this.props.zoomsOfDetailsLevels[1] <= props.scale && point.data && point.data.infoProduct && (
                                                                        <div className="info info_product">
                                                                            {point.data.infoProduct.map(
                                                                                (info: {prefix: string; value: any; suffix: string; type: string}, indexInfoProduct: number) => (
                                                                                    <span key={'infoProduct_' + indexInfoProduct}>
                                                                                        {info.prefix}
                                                                                        {info.type && info.type === 'number' ? customFormatNumber(info.value) : info.value}
                                                                                        {info.suffix}
                                                                                    </span>
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={`wrapper__info_data ${pointIsColumn ? '' : 'wrapper__info_data--column'}`}
                                                                    style={{
                                                                        flexDirection: pointIsColumn ? 'column' : 'row',
                                                                    }}
                                                                >
                                                                    <div className="info_data__column">
                                                                        {this.props.zoomsOfDetailsLevels[0] <= props.scale &&
                                                                            point.data &&
                                                                            (point.data.infoBatch && point.data.infoBatch.activeBatch ? (
                                                                                <div className="info info_batch">
                                                                                    <Tooltip
                                                                                        title={
                                                                                            point.data.infoBatch.activeBatch +
                                                                                            ' (' +
                                                                                            customFormatNumber(Number(point.data.infoBatch.countBox)) +
                                                                                            ') - ' +
                                                                                            customFormatNumber(point.data.infoBatch.kg.dumped_kg) +
                                                                                            point.data.infoBatch.kg.suffix_dumped_kg +
                                                                                            customFormatNumber(point.data.infoBatch.kg.total_kg) +
                                                                                            point.data.infoBatch.kg.suffix_total_kg
                                                                                        }
                                                                                    >
                                                                                        <span className="highlight">
                                                                                            {point.data.infoBatch.activeBatch} ({point.data.infoBatch.countBox})
                                                                                            {' - ' +
                                                                                                customFormatNumber(point.data.infoBatch.kg.dumped_kg) +
                                                                                                point.data.infoBatch.kg.suffix_dumped_kg +
                                                                                                customFormatNumber(point.data.infoBatch.kg.total_kg) +
                                                                                                point.data.infoBatch.kg.suffix_total_kg}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title={point.data.infoBatch.productName}>
                                                                                        <span style={{fontWeight: 'bold'}}>{point.data.infoBatch.productName}</span>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="info info_batch">
                                                                                    <span className="highlight">No hay origen activo</span>
                                                                                </div>
                                                                            ))}
                                                                        {this.props.zoomsOfDetailsLevels[3] <= props.scale && point.data && point.data.clientsPallets && (
                                                                            <div
                                                                                className="info info_clients_pallets"
                                                                                onMouseEnter={() => this.setState({wheelEnabled: false})}
                                                                                onTouchStart={() => this.setState({panEnabled: false})}
                                                                                onMouseLeave={() => this.setState({wheelEnabled: true})}
                                                                                onTouchEnd={() => this.setState({panEnabled: true})}
                                                                            >
                                                                                {point.data.clientsPallets.map(({name, pallets_count, pallets_total}, clientPalletsIndex) => {
                                                                                    let classValue = '';
                                                                                    if (pallets_count === 0) classValue = 'info_clients_pallets__value--not-started';
                                                                                    else if (pallets_count === pallets_total) classValue = 'info_clients_pallets__value--finished';

                                                                                    return (
                                                                                        <div key={'clientPallets_' + clientPalletsIndex}>
                                                                                            <Tooltip title={name}>
                                                                                                <span className="info_clients_pallets__label">{name} </span>
                                                                                            </Tooltip>
                                                                                            <span className={'info_clients_pallets__value ' + classValue}>
                                                                                                {pallets_count} / {pallets_total}{' '}
                                                                                            </span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {this.props.zoomsOfDetailsLevels[3] <= props.scale && (
                                                                        <div className="info_data__column">
                                                                            {this.props.zoomsOfDetailsLevels[3] <= props.scale && point.data && point.data.lastStop && (
                                                                                <div className="info info_lastStop">
                                                                                    <div>
                                                                                        <Tooltip title={point.data.lastStop.label}>
                                                                                            <span className="info_lastStop__label">{point.data.lastStop.label} </span>
                                                                                        </Tooltip>
                                                                                        <span className="info_lastStop__value highlight">{point.data.lastStop.value} </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {this.props.zoomsOfDetailsLevels[3] <= props.scale && point.data && point.data.directEmployees && (
                                                                                <div
                                                                                    className="info info_direct_employees"
                                                                                    style={{maxHeight: pointIsColumn ? point.height / 4 + 'px' : undefined}}
                                                                                    onMouseEnter={() => this.setState({wheelEnabled: false})}
                                                                                    onTouchStart={() => this.setState({panEnabled: false})}
                                                                                    onMouseLeave={() => this.setState({wheelEnabled: true})}
                                                                                    onTouchEnd={() => this.setState({panEnabled: true})}
                                                                                >
                                                                                    {point.data.directEmployees.map(({name, task}, indexDirectEmployees: number) => (
                                                                                        <div key={'directEmployees_' + indexDirectEmployees}>
                                                                                            <Tooltip title={name}>
                                                                                                <span className="info_direct_employees__label">{name} </span>
                                                                                            </Tooltip>
                                                                                            <span className="info_direct_employees__value">{task} </span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </TransformComponent>
                            );
                        }}
                    </TransformWrapper>
                </div>
                <div className="modals">
                    {pointForModal && (
                        <Dialog
                            fullScreen={window.innerWidth < 768}
                            fullWidth={true}
                            maxWidth={'md'}
                            open={!!pointForModal}
                            onClose={() => this.setState({idMoreDetails: undefined})}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <WrappingDivModal>
                                <DialogTitle id="responsive-dialog-title">{pointForModal && pointForModal.data.title + ' ( ' + pointForModal.data.workShift + ' )'}</DialogTitle>
                                <div style={{overflow: 'auto', height: 'calc(80vh - 120px)'}}>
                                    <div
                                        style={{
                                            height: '100%',
                                            minWidth: '500px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <VideocamOffIcon style={{fontSize: '150px', position: 'absolute'}} />
                                        {pointForModal.cam.thumb && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundImage: 'url(' + pointForModal.cam.thumb + ')',
                                                    backgroundPosition: 'center 0%',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <DialogActions>
                                    <Button onClick={() => this.setState({idMoreDetails: undefined})} color="primary" autoFocus={true}>
                                        Cerrar
                                    </Button>
                                </DialogActions>
                            </WrappingDivModal>
                        </Dialog>
                    )}
                </div>
            </div>
        );
    }
}

type Props = {
    id: string;
    zoomsOfDetailsLevels: number[];
    titleLevel: number;
    points: Array<{
        top: number;
        left: number;
        width: number;
        height: number;
        background: string;
        status: string;
        fontSize: number;
        titleSize: number;
        data: {
            title: string;
            areaId: string;
            areaDumpBarcode: string;
            areaAutoPalletise: boolean;
            areaMasivePalletize: boolean;
            workShift?: number;
            infoProduct?: any[];
            infoBatch?: {activeBatch: string; countBox: string; productName: string; kg: any};
            clientsPallets?: Array<{name: string; pallets_count: number; pallets_total: number}>;
            lastStop?: {label: string; value: string};
            directEmployees?: Array<{name: string; task: string}>;
        };
        cam: {
            url?: string;
            thumb: string;
        };
    }>;
    blueprintImg: string;
    config: {minScale: number; maxScale: number};
    centeredBlueprint: boolean;
    handleChangePosition: any;
    alertsInactiveTime: any;
};

const WrappingDivModal = styled.div`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: rgba(50, 50, 50, 1);
`;

export const Blueprint = connect<SProps, DProps, {}, AppStoreState>(state => ({lane: LaneSelectSelectors.getLane(state), userData: LoginSelectors.getUserData(state)}), {
    push,
    selectLane,
})(BlueprintClass);
