import React from 'react';
import styled from 'styled-components';

import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {CustomDropdown} from 'src/shared/style';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {notify} from 'src/utils/Notification';
import { ScalesDeviceUnit } from './store/scalesDevicesControl.types';
import { ScalesDevicesControlServices } from './store/scalesDevicesControl.services';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { PtablesControlServices } from '../ptablesControl/store/ptablesControl.services';

const initialState: State = {
  ipValid: true,
  ip: '',
  port: '',
  area_id: '',
  area_idValid: true,
  ptable_id: '',
  ptable_idValid: true,
  name: '',
  id: '',
  nameValid: true,
  portValid: true,
  timeout: 0,
  value_to_reset: 0,
  minimun_variation_to_reset: 0,
  maximum_variation: 0,
  only_reading: false,
  active: false,
  ptablesList: []
};

type State = Readonly<{
  ipValid: boolean;
  id: string;
  name: string;
  ip: string;
  port: string;
  area_id: string;
  ptable_id: string;
  portValid: boolean;
  nameValid: boolean;
  area_idValid: boolean;
  ptable_idValid: boolean;
  timeout: number;
  value_to_reset: number;
  minimun_variation_to_reset: number;
  maximum_variation: number;
  only_reading: boolean;
  active: boolean;
  ptablesList: any[];
}>;

type Props = {
  scalesDevice?: ScalesDeviceUnit;
  areasList: Array<{id: string; name: string}>;
  ptablesList: Array<{id: string; name: string}>;
  handleClose(): void;
  updateScalesDevice(): void;
};

export class ScalesDeviceForm extends React.Component<Props, State> {
  readonly state = initialState;

  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const {ptablesList} = this.props;
    if (this.props.scalesDevice) {
      const {id, name, ip, area_id, port, ptable_id, timeout, value_to_reset, minimun_variation_to_reset,maximum_variation, only_reading, active} = this.props.scalesDevice;      
      
      if(area_id !== undefined){
        this.refreshPtableDropdown(area_id);
      }
      

      this.setState({
        id,
        name,
        ip,
        area_id: area_id || '',
        ptable_id: ptable_id || '',
        port: '' + port,
        ipValid: true,
        area_idValid: true,
        ptable_idValid: true,
        nameValid: true,
        portValid: true,
        timeout,
        value_to_reset,
        minimun_variation_to_reset,
        maximum_variation,
        only_reading,
        active,
        ptablesList
      });
    } else
      this.setState({
        ipValid: true,
        ip: '',
        area_id: '',
        area_idValid: true,
        ptable_id: '',
        ptable_idValid: true,
        name: '',
        id: '',
        port: '',
        nameValid: true,
        portValid: true,
        timeout: 0,
        value_to_reset: 0,
        minimun_variation_to_reset: 0,
        maximum_variation: 0,
        only_reading: false,
        active: false,
        ptablesList
      });
  }

  private handleCheckboxActive = () => {
    this.setState({active: !this.state.active});
  };

  private handleCheckboxOnlyReading = () => {
    this.setState({only_reading: !this.state.only_reading});
  };

  private refreshPtableDropdown = async (areaId: string) => {
        const ptablesList = await fetchWithFeedback(PtablesControlServices.getByAreaId(areaId), {
            accessor: 'ptables',
        });
        this.setState({ptablesList});
    };

  render() {
    const {
      area_id,
      area_idValid,
      ptable_id,
      ptable_idValid,
      ipValid,
      ip,
      name,
      id,
      port,
      nameValid,
      portValid,
      timeout,
      value_to_reset,
      minimun_variation_to_reset,
      maximum_variation,
      only_reading,
      active,
      ptablesList
    } = this.state;
    const {areasList} = this.props;
    return (
      <WrapForm margin={'5px 10px'}>
        <div>
          <CustomTextField
                        margin={'11px 0'}
                        label={'Nombre'}
                        variant="outlined"
                        error={!nameValid}
                        width={'100%'}
                        onChange={this.handleInputs('name')}
                        value={name}
                      />
        </div>
        <DataDiv>
          
          <LeftSide>
            <CustomDropdown
              title={'Área'}
              dropdownName={'areas'}
              data={areasList || []}
              error={!area_idValid}
              value={area_id}
              margin={'5px 0'}
              handleChoose={e => {
                this.setState({area_id: e.target.value});
                this.refreshPtableDropdown(e.target.value);
              }}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Dirección IP'}
              variant="outlined"
              error={!ipValid}
              width={'100%'}
              onChange={this.handleInputs('ip')}
              value={ip}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Timeout'}
              variant="outlined"
              width={'100%'}
              onChange={this.handleInputs('timeout')}
              value={timeout}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Variación mínima para resetear'}
              variant="outlined"
              width={'100%'}
              onChange={this.handleInputs('minimun_variation_to_reset')}
              value={minimun_variation_to_reset}
            />
            <FormControlLabel
              style={{width: 200}}
              control={
                <Checkbox
                  checked={only_reading}
                  onChange={this.handleCheckboxOnlyReading}
                  value="only_reading"
                />
              }
              label="Sólo lectura"
            />
          </LeftSide>
          <RightSide>
            <CustomDropdown
              title={'Mesa de paletizado'}
              dropdownName={'ptables'}
              data={ptablesList || []}
              error={!ptable_idValid}
              value={ptable_id}
              margin={'5px 0'}
              handleChoose={e => this.setState({ptable_id: e.target.value})}
            />
            
            <CustomTextField
              margin={'11px 0'}
              label={'Puerto'}
              variant="outlined"
              error={!portValid}
              width={'100%'}
              onChange={this.handleInputs('port')}
              value={port}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Valor para resetear'}
              variant="outlined"
              width={'100%'}
              onChange={this.handleInputs('value_to_reset')}
              value={value_to_reset}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Máxima variación permitida'}
              variant="outlined"
              width={'100%'}
              onChange={this.handleInputs('maximum_variation')}
              value={maximum_variation}
            />
            <FormControlLabel
              style={{width: 200}}
              control={
                <Checkbox
                  checked={active}
                  onChange={this.handleCheckboxActive}
                  value="active"
                />
              }
              label="Activo?"
            />
            
          </RightSide>
        </DataDiv>{' '}
        <WrapSaveButton>
          <CustomButton
            bgcolor="#34baa2"
            onClick={this.handleSend}
            width="100%"
            margin="unset">
            {id ? 'Guardar' : 'Crear'} báscula
          </CustomButton>
        </WrapSaveButton>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    const {
      area_id, 
      ptable_id,
      ip, 
      name, 
      id, 
      port,
      timeout,
      value_to_reset,
      minimun_variation_to_reset,
      maximum_variation,
      only_reading,
      active
    } = this.state;
    const validator = notifyValidator(this);
    if (
      // tslint:disable-next-line
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ip,
      )
    ) {
      this.setState({ipValid: false});
      notify({message: 'La dirección IP no es válida.', status: 'error'});
    } else if (id) {
      const res = await fetchWithFeedback(
        ScalesDevicesControlServices.update(id, {
          area_id,
          ptable_id,
          ip,
          name,
          port: port ? +port : null,
          timeout,
          value_to_reset,
          minimun_variation_to_reset,
          maximum_variation,
          only_reading,
          active
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validator},
      );
      if (res) {
        this.props.updateScalesDevice();
        this.props.handleClose();
      }
    } else {
      const res = await fetchWithFeedback(
        ScalesDevicesControlServices.create({
          area_id,
          ptable_id,
          ip,
          name,
          port: port ? +port : null,
          timeout,
          value_to_reset,
          minimun_variation_to_reset,
          maximum_variation,
          only_reading,
          active
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validator},
      );
      if (res) {
        this.setState({
          ipValid: true,
          ip: '',
          area_id: '',
          area_idValid: true,
          name: '',
          id: '',
          port: '',
          nameValid: true,
          portValid: true,
        });
        this.props.updateScalesDevice();
      }
    }
  };

  private handleInputs: HandleNamedChange<State> = name => e => {
    this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<
      State,
      keyof State
    >);
  };
}
const DataDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
