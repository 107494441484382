import * as React from 'react';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {CustomButton} from 'src/shared/style/CustomButton';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {Tooltip} from '@material-ui/core';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface TableCardcontrolProps {
    createNewAssign(employeeId: string, fn: () => void): void;
    unassignCards(employeeId: string): void;
    employeeCodeModeParam?: String;
}

export class TableCardControl extends React.Component<TableCardcontrolProps> {
    state = {showModal: false, idToDelete: '0'};
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };
    public render() {
        // const {employees, onFetch} = this.props;

        const showErpEmployeeCode = this.props.employeeCodeModeParam === 'ERP_MODE' ? true : false;
        const showInternalEmployeeCode = this.props.employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;

        return (
            <div>
                <CustomDialog
                    title="Confirmación"
                    contentText="¿Está seguro que quiere desvincular todas las tarjetas del empleado?"
                    handleClose={() => this.setState({showModal: false})}
                    open={this.state.showModal}
                    handle={() => {
                        this.props.unassignCards(this.state.idToDelete);
                        this.setState({showModal: false});
                    }}
                />

                <PaginatedTableComponent
                    columnFormat={[
                        {
                            Header: 'Código empleado',
                            headerClassName: 'header_cell_string',
                            accessor: 'code',
                            className: 'cell_string',
                            show: showInternalEmployeeCode,
                            filterable: true,
                        },
                        {
                            Header: 'Código empleado',
                            headerClassName: 'header_cell_string',
                            accessor: 'erp_employee_code',
                            className: 'cell_string',
                            show: showErpEmployeeCode,
                            filterable: true,
                        },
                        {Header: 'Nombre', headerClassName: 'header_cell_string', accessor: 'name', className: 'cell_string', filterable: true},
                        {Header: 'Apellidos', headerClassName: 'header_cell_string', accessor: 'surname', className: 'cell_string', filterable: true},
                        {
                            Header: 'Tarjeta asignada?',
                            headerClassName: 'header_cell_number',
                            accessor: 'cards',
                            // Cell: item => <>{item.original.cards}</>,
                            className: 'cell_number',
                            sortable: false,
                            dropdownFilter: true,
                            dropdownFilterData: [
                                {id: '0', name: '0 tarjetas'},
                                {id: '1', name: '1 tarjeta'},
                                {id: '2', name: 'Más de 2'},
                            ],
                        },
                        {Header: 'Última asignación', headerClassName: 'header_cell_string', accessor: 'last_card_date', className: 'cell_string', filterable: false},
                        {
                            Header: 'Asignación',
                            sortable: false,
                            style: {textAlign: 'center'},
                            Cell: item => (
                                <>
                                    <CustomButton
                                        width={'75px'}
                                        height={'24px'}
                                        padding={'0 5px 9px 5px'}
                                        margin={'2px 2px 2px 2px'}
                                        bgcolor={'#4c4'}
                                        onClick={() => this.props.createNewAssign(item.original.id, this.useTableHook)}
                                    >
                                        Asignar
                                    </CustomButton>
                                    <CustomButton
                                        width={'24px'}
                                        minWidth={'24px'}
                                        height={'24px'}
                                        padding={'0 5px 9px 5px'}
                                        margin={'2px 2px 2px 2px'}
                                        bgcolor={'#c44'}
                                        onClick={
                                            () =>
                                                this.setState({
                                                    showModal: true,
                                                    idToDelete: item.original.id,
                                                })
                                            // this.props.unassignCards(item.original.id, this.useTableHook)
                                        }
                                    >
                                        <Tooltip title={'Desvincular todas las tarjetas del empleado'} placement="top">
                                            <LinkOffIcon />
                                        </Tooltip>
                                    </CustomButton>
                                </>
                            ),
                        },
                    ]}
                    autorefresh={true}
                    // autorefreshDelay={2000}
                    service={EmployeesControlServices.getAllEmployees}
                    showSizeOptions={false}
                    getTableFetchHook={this.getTableHook}
                    defaultPageSize={20}
                />
            </div>
        );
    }
}
