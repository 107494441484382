import * as React from 'react';

import {CustomButton, OCard} from 'src/shared/style';
import styled from 'styled-components';
import {FullSizeModal} from 'src/shared/components/FullSizeModal';
//import { customFormatNumber } from 'src/shared/functions/FormatHelpers';
//import { differenceInMinutes } from 'date-fns';
import {ColumnType} from 'src/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {PalletList} from './store/palletInformation.types';
import {PalletInformationServices} from './store/palletInformation.services';
import {Tooltip} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ReactTable, {Column, RowInfo} from 'react-table';
import {Subpallet} from '../pallets/store/types';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {customFormatNumber} from '../../../shared/functions/FormatHelpers';

export interface Props {
    open: boolean;
    hideSelectPalletModal: () => void;
    handleSelectPallet: (barcode: any) => void;
}

const initialState = {
    palletsAutomatic: [],
};

// const Table = TableComponent<LaneSelectResponse>();
export class SelectPalletModal extends React.Component<Props> {
    state = initialState;

    columns: ColumnType<PalletList> = [];

    componentDidMount() {
        this.columns = this.getColumnFormat();
    }

    private hideModalTable = () => {
        this.props.hideSelectPalletModal();
    };

    private getColumnFormat = (): ColumnType<PalletList> => {
        return [
            {
                Header: '',
                sortable: false,
                style: {textAlign: 'center'},
                width: 50,
                Cell: item => (
                    <>
                        <CustomButton
                            width={'24px'}
                            minWidth={'24px'}
                            height={'24px'}
                            margin={'2px 2px 2px 2px'}
                            bgcolor={'#c44'}
                            onClick={() => {
                                this.props.handleSelectPallet(item.original.palletBarcode);
                            }}
                        >
                            <Tooltip title={'Seleccionar'} placement="top">
                                <CheckCircleOutline />
                            </Tooltip>
                        </CustomButton>
                    </>
                ),
            },
            {
                Header: 'Número de serie',
                headerClassName: 'header_cell_string',
                accessor: 'serial_number',
                className: 'cell_string',
                filterable: true,
                sortable: false,
            },
            {
                Header: 'Parte',
                headerClassName: 'header_cell_string',
                accessor: 'erp_prodpart_code',
                className: 'cell_string',
                filterable: true,
                sortable: false,
            },
            {
                Header: 'Pedido',
                headerClassName: 'header_cell_string',
                accessor: 'erp_order_code',
                className: 'cell_string',
                filterable: true,
                sortable: false,
            },
            {
                Header: 'Artículo',
                headerClassName: 'header_cell_string',
                accessor: 'current_subpallet_merchandise_name',
                className: 'cell_string',
                filterable: true,
                sortable: false,
                Cell: item => <>{`${item.original.current_subpallet_merchandise_name}${item.original.mixed ? '(*)' : ''}`}</>,
            },
            {
                Header: 'Envase',
                headerClassName: 'header_cell_string',
                accessor: 'current_subpallet_packaging_name',
                className: 'cell_string',
                filterable: true,
                sortable: false,
            },
            {
                Header: 'Cantidad Envases',
                headerClassName: 'header_cell_number',
                accessor: 'boxes_count',
                className: 'cell_number',
                sortable: false,
            },
            {
                Header: 'Kg',
                headerClassName: 'header_cell_number',
                accessor: 'real_weight',
                className: 'cell_number',
                sortable: false,
            },
            {
                Header: 'Area',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                className: 'cell_string',
                filterable: true,
                sortable: false,
            },
            {
                Header: 'Número de palé',
                headerClassName: 'header_cell_string',
                accessor: 'palletBarcode',
                className: 'cell_string',
                filterable: true,
                sortable: false,
                Cell: items => (
                    <span>
                        {items.original.palletBarcode}
                        {items.original.subpallets.length > 1 ? <b style={{color: 'darkgreen'}}>(*)</b> : ''}
                    </span>
                ),
            },
            {
                Header: 'Fecha Palé',
                headerClassName: 'header_cell_string',
                accessor: 'finished_at',
                dateTimeFormat: true,
                className: 'cell_string',
                sortable: true,
            },
            {
                Header: '',
                headerClassName: 'header_cell_string',
                className: 'cell_string',
                sortable: false,
                expander: true,
            },
        ];
    };

    render() {
        return (
            <>
                <FullSizeModal title={'Selección de palés no vinculados'} open={this.props.open} handleClose={this.hideModalTable}>
                    <WrappingDiv>
                        <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                            <PaginatedTableComponent
                                columnFormat={this.columns}
                                defaultPageSize={20}
                                service={PalletInformationServices.getAutomaticPalletsNotVinculated}
                                loading={true}
                                propsToOwnTable={{
                                    SubComponent: (info: RowInfo) => {
                                        const subpallets = info.original.subpallets;

                                        return (
                                            <>
                                                {subpallets &&
                                                    subpallets.map((subpallet: Subpallet) => (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                                                    backgroundColor: '#FDFFBAFF',
                                                                }}
                                                            >
                                                                <span style={{paddingLeft: '5px'}}>{subpallet.merchandise_name}</span>
                                                                <span style={{paddingLeft: '5px'}}>{subpallet.prodpart_code}</span>
                                                                <span style={{paddingLeft: '5px'}}>{subpallet.packaging_name}</span>
                                                                <span style={{paddingLeft: '5px'}}>
                                                                    {subpallet.boxes_count}/{subpallet.boxes_total}
                                                                </span>
                                                            </div>
                                                            <ReactTable
                                                                data={subpallet.batches}
                                                                showPagination={false}
                                                                minRows={0}
                                                                pageSize={subpallet.batches.length}
                                                                resizable={false}
                                                                getNoDataProps={() => {
                                                                    if (!subpallet.batches.length) return {style: {display: 'none'}};
                                                                    return {};
                                                                }}
                                                                sortable={false}
                                                                columns={
                                                                    [
                                                                        {
                                                                            Header: '',
                                                                            headerClassName: 'subheader_cell_string',
                                                                            accessor: 'white-space',
                                                                            className: 'subcell_string',
                                                                            width: 200,
                                                                        },
                                                                        {
                                                                            Header: 'Código ERP del origen',
                                                                            headerClassName: 'subheader_cell_string',
                                                                            accessor: 'erp_batch_code',
                                                                            className: 'subcell_string',
                                                                        },
                                                                        {
                                                                            Header: 'Fecha Origen',
                                                                            headerClassName: 'subheader_cell_string',
                                                                            accessor: 'arrival_at',
                                                                            Cell: item => (
                                                                                <>
                                                                                    {item.original &&
                                                                                        item.original.arrival_at &&
                                                                                        format(new Date(item.original.arrival_at), 'DD/MM/YYYY HH:mm:ss', {
                                                                                            locale: es,
                                                                                        })}
                                                                                </>
                                                                            ),
                                                                            className: 'cell_string',
                                                                        },
                                                                        {
                                                                            Header: 'Proveedor',
                                                                            headerClassName: 'subheader_cell_string',
                                                                            accessor: 'name',
                                                                            className: 'subcell_string',
                                                                        },
                                                                        {
                                                                            Header: 'Kg del origen',
                                                                            headerClassName: 'subheader_cell_number',
                                                                            accessor: 'kg',
                                                                            Cell: item => <>{customFormatNumber(Math.round(item.original.kg))}</>,
                                                                            className: 'subcell_number',
                                                                        },
                                                                        {
                                                                            Header: 'Cajas del origen',
                                                                            headerClassName: 'subheader_cell_number',
                                                                            accessor: 'boxes',
                                                                            Cell: item => <>{customFormatNumber(item.original.boxes)}</>,
                                                                            className: 'subcell_number',
                                                                        },
                                                                    ] as Column<any>[]
                                                                }
                                                            />
                                                        </>
                                                    ))}
                                            </>
                                        );
                                    },
                                }}
                            />
                        </OCard>
                    </WrappingDiv>
                </FullSizeModal>
            </>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(99%);
    margin: auto;
    margin-top: 8px;
    height: calc(99%);
    padding: 0px;
    margin-bottom: 5px;
`;
