import * as React from 'react';
import {connect} from 'react-redux';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {DumpsServices} from 'src/app/lanemanager/dumps/store/services';
import {Batch} from 'src/app/lanemanager/lane/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
//import {TableWrap} from 'src/terminal/components';
import {TableModal} from 'src/shared/components/Modal';
import {DumpCreate} from './DumpCreate';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
//import CancelIcon from '@material-ui/icons/Cancel';
//import ReplayIcon from '@material-ui/icons/Replay';
import {Tooltip} from '@material-ui/core';
//import {CustomDialog} from 'src/shared/components/CustomDialog';
//import {CustomButton} from 'src/shared/style';
import {ReactivateBatchDialog} from './ReactivateBatchDialog';
import {CancelDumpDialog} from './CancelDumpDialog';
import {ModifyDumpModal} from './ModifyDumpModal';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import styled from 'styled-components';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {DumpData} from './store/types';
import {DumpTypeEnum} from './models/DumpType.enum';

export type DProps = {
    setActive: () => void;
    setInactive: () => void;
    clearFilters: () => void;
    // createDowntime: (causeId: string, description: string) => void;
    getPreflight: () => void;
    cancelDump(dumpId: string): void;
};

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};

type State = {
    open: boolean;
    showCancelModal: boolean;
    showModifyModal: boolean;
    showReactivateModal: boolean;
    selectedDumpId: string;
    selectedDumpBoxes: number;
    selectedDumpBarcode: string;
};
const initialState = {
    open: false,
    showCancelModal: false,
    showModifyModal: false,
    showReactivateModal: false,
    selectedDumpId: '',
    selectedDumpBoxes: 0,
    selectedDumpBarcode: '',
};

const ERRORS_MAP = new Map<string, string>([
    ['AREA_HAVENT_CURRENT_AREA_SHIFT', 'La línea actual no tiene turno activo.'],
    ['PRODPART_DOESNT_MATCH', 'La línea actual está en pausa.'],
    ['THIS_LOGISTIC_UNIT_IS_FULL_DUMPED', 'El origen ya se ha volcado completamente.'],
    ['EXCEEDED_ORIGIN_AVAILABLE_BOXES', 'Se ha excedido el nº de cajas del origen.'],
    ['NO_GAP_BETWEEN_DUMPS', 'No hay hueco entre volcados.'],
]);


export class DumpsBase extends React.Component<SProps & DProps, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    columns: ColumnType<Batch> = [
        // {
        //     Header: '',
        //     sortable: false,
        //     style: {textAlign: 'center'},
        //     width: 50,
        //     Cell: item => (
        //         <>
        //             {item.original.last_dump === true && (
        //                 <CustomButton
        //                     width={'24px'}
        //                     minWidth={'24px'}
        //                     height={'24px'}
        //                     margin={'2px 2px 2px 2px'}
        //                     bgcolor={'#c44'}
        //                     onClick={() => {
        //                         this.setState({
        //                             showReactivateModal: true,
        //                             selectedDumpId: item.original.id,
        //                         });
        //                     }}
        //                 >
        //                     <Tooltip title={'Reactivar su origen'} placement="top">
        //                         <ReplayIcon />
        //                     </Tooltip>
        //                 </CustomButton>
        //             )}
        //         </>
        //     ),
        // },
        {
            Header: 'Código Origen',
            headerClassName: 'header_cell_string',
            accessor: 'logisticunitBarcode',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Estado',
            headerClassName: 'header_cell_string',
            accessor: 'state',
            Cell: items => (
                <>
                    {items.original.state === 'ongoing'
                        ? 'EN CURSO'
                        : items.original.state === 'finished'
                            ? 'FINALIZADO'
                            : items.original.state === 'canceled'
                                ? 'CANCELADO'
                                : 'VOLCADO'}
                </>
            ),
            className: 'cell_string',
        },
        {
            Header: 'Cajas',
            headerClassName: 'header_cell_number',
            accessor: 'dumped_boxes',
            filterable: false,
            sortable: true,
            width: 80,
            className: 'cell_number',
            Cell: items => (
                <>
                    {items.original.state !== 'canceled' && (
                        <Tooltip title={'Modificar cajas de volcado'} placement='top'>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                }}
                                onClick={() => {
                                    this.setState({
                                        showModifyModal: true,
                                        selectedDumpId: items.original.id,
                                        selectedDumpBoxes: items.original.dumped_boxes,
                                        selectedDumpBarcode: items.original.logisticunitBarcode,
                                    });
                                }}
                            >
                                {customFormatNumber(items.original.dumped_boxes)}
                            </span>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            Header: 'H. Volcado',
            headerClassName: 'header_cell_string',
            accessor: 'dumpDate',
            dateTimeFormat: true,
            daterange: false,
            filterable: false,
            className: 'cell_string',
        },
        {
            Header: 'Proveedor',
            headerClassName: 'header_cell_string',
            accessor: 'providerName',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Producto',
            headerClassName: 'header_cell_string',
            accessor: 'productName',
            className: 'cell_string',
            sortable: false,
            filterable: true,
        },
        {
            Header: 'Protocolos Calidad',
            headerClassName: 'header_cell_string',
            accessor: 'qualityProtocol',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'H. Origen',
            headerClassName: 'header_cell_string',
            accessor: 'arrival_at',
            dateTimeFormat: true,
            className: 'cell_string',
        },
    ];

    private handleClose = () => {
        this.setState({open: false});
    };

    private handleOpen = () => {
        this.setState({open: true});
    };

    private handleSending = async (originCode: string, boxCount: number) => {
        const {lane, userData} = this.props;
        if (lane) {
            try {
                const data: DumpData = {
                    box_count: boxCount,
                    username: userData.username,
                    context: DumpTypeEnum.CONTEXT_APP,
                };
                await fetchWithFeedback(DumpsServices.createOrUpdate(lane.laneDumpBarcode, originCode, data), {
                    successMessage: 'Se ha creado el volcado',
                    errorMessage: 'Error al crear el volcado. Revisar las condiciones.',
                    errorAccessor: 'message',
                    errorMessageMap: ERRORS_MAP,
                });
                this.useTableHook();
                this.setState({open: false});
            } catch (error) {
                console.error('Error al crear', error);
            }
        }
    };

    private handleSendCreateDumpLater = async (
        originCode: string,
        boxCount: number,
        workshiftDate: string,
        workshiftType: string) => {
        const {lane, userData} = this.props;
        if (lane) {
            const data: DumpData = {
                box_count: boxCount,
                username: userData.username,
                context: DumpTypeEnum.CONTEXT_APP_AFTER,
                workshift_date: workshiftDate,
                workshift_type: workshiftType,
            };
            try {
                await fetchWithFeedback(DumpsServices.createOrUpdate(lane.laneDumpBarcode, originCode, data), {
                    successMessage: 'Se ha creado el volcado',
                    errorMessage: 'Error al crear el volcado. Revisar las condiciones.',
                    errorAccessor: 'message',
                    errorMessageMap: ERRORS_MAP,
                });
                this.useTableHook();
                this.setState({open: false});
            } catch (error) {
                console.error('Error al crear', error);
            }
        }
    };

    private handleCloseReactiveModal = () => {
        this.setState({showReactivateModal: false});
    };

    private handleCloseCancelModal = () => {
        this.setState({showCancelModal: false});
    };

    private handleCloseModifyModal = () => {
        this.setState({showModifyModal: false});
    };

    private getTableHook = (fn: () => void) => {
        this.tableHook = fn;
    };

    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    render() {
        const {lane} = this.props;
        return (
            <TableWrap>
                {/*<ExpansionWrap>*/}
                {/*<div>*/}
                <ReactivateBatchDialog
                    open={this.state.showReactivateModal}
                    idDumpToReactivate={this.state.selectedDumpId}
                    handleClose={this.handleCloseReactiveModal}
                    handleRefresh={this.useTableHook}
                />
                <CancelDumpDialog
                    open={this.state.showCancelModal}
                    idDumpToCancel={this.state.selectedDumpId}
                    handleClose={this.handleCloseCancelModal}
                    handleRefresh={this.useTableHook}
                />
                <ModifyDumpModal
                    open={this.state.showModifyModal}
                    idDumpToModify={this.state.selectedDumpId}
                    currentNumBoxes={this.state.selectedDumpBoxes}
                    barcode={this.state.selectedDumpBarcode}
                    username={this.props.userData.username}
                    handleClose={this.handleCloseModifyModal}
                    handleRefresh={this.useTableHook}
                />
                {/* <TableWrap> */}
                <TableModal title={'Gestión de volcados'} open={this.state.open} handleClose={this.handleClose}>
                    <DumpCreate handleCreate={this.handleSending} handleCreatePostDump={this.handleSendCreateDumpLater}
                                handleClose={this.handleClose} handleClosePostDump={this.handleClose} />
                </TableModal>
                <TableComponentWrap>
                    <PaginatedTableComponent
                        service={DumpsServices.getAllPaginated}
                        columnFormat={this.columns}
                        defaultPageSize={20}
                        createHook={this.handleOpen}
                        getTableFetchHook={this.getTableHook}
                        serviceArgs={[lane.laneId || 'NO_EXISTE_LINEA']}
                        loading={true}
                    />
                </TableComponentWrap>
                {/*</ExpansionWrap>*/}
            </TableWrap>
            // </div>
        );
    }
}

export const Dumps = connect<SProps, any, any, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
    userData: LoginSelectors.getUserData(state),
}))(DumpsBase);

const TableWrap = styled.div`
    height: 100%;
    margin-top: 0;
`;

const TableComponentWrap = styled.div`
    height: 100%;
`;
