import * as React from 'react';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const modalStyles = {
    root: {
        backgroundColor: '#EE3333',
        display: 'table',
        width: '100%',
        height: '100%',
    },
    paperWidthSm: {
        maxWidth: '35000',
        width: '35000',
    },
};

const StyledDialogTitle = withStyles({
    root: {
        backgroundColor: '#DEDEDE',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '0 30px',
    },
})(DialogTitle);

// const StyledDialogContent = withStyles({
//     root: {
//         backgroundColor: '#EFEFEF',
//         margin: '0',
//         padding: '0',
//     },
// })(DialogTitle);

type Props = {
    classes: {[k: string]: string};
    open: boolean | string | number;
    title?: string;
    closeText?: string;
    handleClose(...args: any[]): void;
    buttons?: any;
    children?: any;
    fullWidth?: boolean;
    maxWidth?: string;
    withMargin?: boolean;
};

export const FullSizeModal = withStyles(modalStyles)((props: Props) => {
    const {open, title, buttons, children, handleClose, withMargin, closeText} = props;
    let fullScreen = true;
    let styleValues = {};
    let fullwidth = false;

    if (withMargin) {
        fullScreen = false;
        fullwidth = true;
        styleValues = {
            width: '100%',
            maxWidth: false,
        };
    }

    return (
        <Dialog maxWidth={'xl'} fullScreen={fullScreen} fullWidth={fullwidth} style={styleValues} open={getOpen(open)} onClose={handleClose}>
            <StyledDialogTitle>
                <TitleWrapper>
                    <span style={{alignSelf: 'center'}}>{title}</span>
                    <Button color="secondary" onClick={handleClose}>
                        {closeText && closeText !== '' && <span>{closeText}</span>}
                        <CloseIcon />
                    </Button>
                </TitleWrapper>
            </StyledDialogTitle>
            {children}
            {/*<StyledDialogContent></StyledDialogContent>*/}
            {buttons && <DialogActions>{buttons}</DialogActions>}
        </Dialog>
    );
});

const getOpen = (open: boolean | string | number) => {
    if (typeof open === 'string') return !!open;
    if (typeof open === 'number') {
        if (open <= 0) return false;
        else return true;
    }
    if (typeof open === 'boolean') return open;
    return false;
};

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
`;
