import React from 'react';
import ReactTable, {Column, RowInfo} from 'react-table';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {Pallet, Subpallet} from 'src/app/lanemanager/pallets/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {connect} from 'react-redux';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {IsInRuleset} from '../../../global/authorization/grantSet';
import {GrantGroupsRanks} from '../../../global/authorization/ranking';
import {PalletCompleteInfoModal} from '../../global/palletInfo/PalletInfoModal';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};

type State = {
    showPalletInfoModal: boolean;
    palletSelected: string;
};

const initialState: State = {
    showPalletInfoModal: false,
    palletSelected: '',
};

export class PalletsBase extends React.Component<SProps, State> {
    state = initialState;

    render() {
        const {showPalletInfoModal, palletSelected} = this.state;

        const columns: ColumnType<Pallet> = [
            {
                Header: 'CÓDIGO PALÉ',
                headerClassName: 'header_cell_string',
                accessor: 'palletBarcode',
                filterable: true,
                sortable: false,
                minWidth: 130,
                className: 'cell_string',
                Cell: (items: any) => (
                    <span>
                        {items.original.palletBarcode}
                        {items.original.subpallets.length > 1 ? <b style={{color: 'darkgreen'}}>(*)</b> : ''}
                    </span>
                ),
            },
            {
                Header: 'PEDIDO',
                headerClassName: 'header_cell_string',
                accessor: 'erp_order_code',
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'PARTE',
                headerClassName: 'header_cell_string',
                accessor: 'erp_prodpart_code',
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'Área',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                filterable: true,
                sortable: true,
                className: 'cell_string',
                Cell: (item: any) => {
                    return IsInRuleset([GrantGroupsRanks.VIEW_COMPLETED_PALLET_INFO_WINDOW]) ? (
                        <div
                            onClick={() => {
                                this.setState(
                                    {
                                        ...this.state,
                                        palletSelected: item.original.palletBarcode,
                                    },
                                    () => this.setState({...this.state, showPalletInfoModal: true}),
                                );
                            }}
                        >
                            {item.original.center_name}
                        </div>
                    ) : (
                        <div>{item.original.center_name}</div>
                    );
                },
            },
            {
                Header: 'MESA',
                headerClassName: 'header_cell_string',
                accessor: 'ptable_name',
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'CAJAS',
                headerClassName: 'header_cell_number',
                accessor: 'boxes_count',
                Cell: (item: any) => (
                    <>
                        {customFormatNumber(item.original.boxes_count)}/{customFormatNumber(item.original.boxes_total)}
                    </>
                ),
                className: 'cell_number',
            },

            {
                Header: 'H. INICIO',
                headerClassName: 'header_cell_string',
                accessor: 'started_at',
                dateTimeFormat: true,
                className: 'cell_string',
            },
            {
                Header: 'H. FIN',
                headerClassName: 'header_cell_string',
                accessor: 'finished_at',
                dateTimeFormat: true,
                className: 'cell_string',
            },
            {
                Header: 'UL FINAL',
                headerClassName: 'header_cell_string',
                accessor: 'erp_logisticunit_code',
                Cell: (item: any) => <>{item.original.erp_logisticunit_code ? item.original.erp_logisticunit_code : 'Sin asignar todavía'}</>,
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'ORIGENES',
                headerClassName: 'header_cell_string',
                accessor: 'batches',
                minWidth: 70,
                maxWidth: 70,
                width: 70,
                expander: true,
                style: {
                    cursor: 'pointer',
                    fontSize: 25,
                    padding: '0',
                    textAlign: 'center',
                    userSelect: 'none',
                },
            },
        ] as any;

        return (
            <>
                <PalletCompleteInfoModal open={showPalletInfoModal} onClose={this.hidePalletInfotModal} palletBarcode={palletSelected} />
                <PaginatedTableComponent
                    service={PalletsServices.getAllPaginated}
                    serviceArgs={this.props.lane.laneId}
                    columnFormat={columns}
                    defaultPageSize={20}
                    loading={true}
                    propsToOwnTable={{
                        SubComponent: (info: RowInfo) => {
                            const subpallets = info.original.subpallets;

                            return (
                                <>
                                    {subpallets &&
                                        subpallets.map((subpallet: Subpallet) => (
                                            <>
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                                        backgroundColor: '#FDFFBAFF',
                                                    }}
                                                >
                                                    <span style={{paddingLeft: '5px'}}>{subpallet.merchandise_name}</span>
                                                    <span style={{paddingLeft: '5px'}}>{subpallet.prodpart_code}</span>
                                                    <span style={{paddingLeft: '5px'}}>{subpallet.packaging_name}</span>
                                                    <span style={{paddingLeft: '5px'}}>
                                                        {subpallet.boxes_count}/{subpallet.boxes_total}
                                                    </span>
                                                </div>
                                                <ReactTable
                                                    data={subpallet.batches}
                                                    showPagination={false}
                                                    minRows={0}
                                                    pageSize={subpallet.batches.length}
                                                    resizable={false}
                                                    getNoDataProps={() => {
                                                        if (!subpallet.batches.length)
                                                            return {
                                                                style: {
                                                                    display: 'none',
                                                                },
                                                            };
                                                        return {};
                                                    }}
                                                    sortable={false}
                                                    columns={
                                                        [
                                                            {
                                                                Header: '',
                                                                headerClassName: 'subheader_cell_string',
                                                                accessor: 'white-space',
                                                                className: 'subcell_string',
                                                                width: 800,
                                                            },
                                                            {
                                                                Header: 'Código ERP del origen',
                                                                headerClassName: 'subheader_cell_string',
                                                                accessor: 'erp_batch_code',
                                                                className: 'subcell_string',
                                                            },
                                                            {
                                                                Header: 'Cajas del origen',
                                                                headerClassName: 'subheader_cell_number',
                                                                accessor: 'boxes',
                                                                Cell: (item: any) => <>{customFormatNumber(item.original.boxes)}</>,
                                                                className: 'subcell_number',
                                                            },
                                                        ] as Column<any>[]
                                                    }
                                                />
                                            </>
                                        ))}
                                </>
                            );
                        },
                    }}
                />
            </>
        );
    }

    private hidePalletInfotModal = () => {
        this.setState({
            ...this.state,
            showPalletInfoModal: false,
        });
    };
}

export const Pallets = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(PalletsBase);
