import {AppStoreState} from 'src/store';
import {ParameterCPN} from '../../types/app_types';

export const ParametersSelectors = {
    getParameters: (state: AppStoreState) => state.parameters,
    getValuePresenceManagement: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'PRESENCE_MANAGEMENT_ACTIVE') {
                value = element.value;
            }
        });
        return value;
    },
    getNonProductiveTimeManagement: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'NON_PRODUCTIVE_TIMES_MANAGEMENT_ACTIVE') {
                value = element.value;
            }
        });
        return value;
    },
    getIsClassicMenu: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'SET_CLASSIC_MENU') {
                value = element.value;
            }
        });
        return value;
    },
    getLineKPIsDaysBefore: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'LANE_KPIS_DAYS_BEFORE') {
                value = element.value;
            }
        });
        return value;
    },
    getSundaysFestive: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'SUNDAYS_FESTIVE') {
                value = element.value;
            }
        });
        return value;
    },
    getSaturdaysFestive: (state: AppStoreState) => {
        let value = '';
        state.parameters.parameters.forEach(element => {
            if (element.name === 'SATURDAYS_FESTIVE') {
                value = element.value;
            }
        });
        return value;
    },
    getValueOfParameter: (state: AppStoreState, parameterName: string) => {
        const paramFound = state.parameters.parameters.find(param => param.name === parameterName);
        return paramFound ? paramFound.value : null;
    },
    getUnloadBoxesCorrectionFactor: (state: AppStoreState) => {
        let value = "1";
        console.log(state.parameters);
        state.parameters.parameters.forEach(element => {
            if (element.name === ParameterCPN.UNLOAD_BOXES_CORRECTION_FACTOR) {
                value = element.value;
            }
        });
        return parseFloat(value);
    }
};
