import * as React from 'react';
import {useEffect, useState} from 'react';

import {Dump, LaneProdPartState, LanePtableState, LanePtableStatePalletSubpallet} from 'src/app/lanemanager/lane/store/types';

import {Content, CustomTable, FlexRow, PalletCell, Text, TextBold, TextProduct, TitleRow} from './Wrap';
import {ChipWrap} from '../ProdPartsInfo/Wrap';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {GrantGroupsRanks} from 'src/global/authorization/ranking';

export type TableProps = LanePtableState & {
    onHandleClickTable?: (ptableSelected: LanePtableState) => void;
    onHandleChangeKgBoxTable?: (ptableSelected: LanePtableState) => void;
    prodpart?: LaneProdPartState;
    changeTable: (ptableSelected: LanePtableState) => void;
    currentDump: Dump | null;
};

export const Table: React.FunctionComponent<TableProps> = props => {
    const {state, name, pallet, onHandleClickTable, onHandleChangeKgBoxTable, display_only, prodpart, currentDump} = props;

    const [currentSubpallet, setCurrentSubpallet] = useState<LanePtableStatePalletSubpallet | undefined>(undefined);

    useEffect(() => {
        if (pallet && pallet.subpallets) {
            const palletCurrentSubpallet = pallet.subpallets.find((s: LanePtableStatePalletSubpallet) => pallet && s.id === pallet.current_subpallet_id);
            setCurrentSubpallet(palletCurrentSubpallet);
        } else {
            setCurrentSubpallet(undefined);
        }
    }, [pallet]);

    let styleDisplayOnly = {};
    if (display_only) {
        styleDisplayOnly = {
            pointerEvents: 'none',
            opacity: '0.5',
        };
    }

    const {kg_total, boxes_total} = prodpart || {kg_total: 0, boxes_total: 0};
    let kgBox = 0;
    if (kg_total > 0 && boxes_total > 0) {
        if (pallet && pallet?.current_subpallet_kg_box_edit) {
            kgBox = Number(Number.parseFloat(pallet?.current_subpallet_kg_box_edit).toPrecision(3));
        } else {
            kgBox = Number((kg_total / boxes_total).toPrecision(2));
        }
    }

    const boxesCountKg = Number((kgBox * (pallet?.boxes_count ?? 0)).toPrecision(2));

    //console.log('Prodpart', prodpart);
    let kgBoxProdpart;
    if (prodpart && prodpart.kg_box_prodpart) {
        kgBoxProdpart = parseFloat(prodpart.kg_box_prodpart).toFixed(2);
    }

    let color = '#aaaaaa';
    if (state === 'INACTIVA') color = 'lightgray';
    if (state === 'ACTIVA') color = '#04A64E7F';
    if (state === 'ASIGNADA') color = 'yellow';
    if (state === 'RESERVADA') color = '#f16b00';

    if (currentSubpallet && currentSubpallet.merchandise && currentDump && currentDump.merchandise) {
        if (currentSubpallet.merchandise.family_id !== currentDump.merchandise.family_id) {
            color = '#ea4a4a';
        }
    }

    let pointerChangeKgBox = {color: 'red', cursor: 'default'};
    if (IsInRuleset(GrantGroupsRanks.UPDATE_SUBPALLET_KG_BOX_EDIT)) {
        pointerChangeKgBox = {color: 'red', cursor: 'pointer'};
    }

    if (name && state)
        return (
            <CustomTable
                color={color}
                style={{
                    ...styleDisplayOnly,
                    backgroundColor: pallet && pallet.subpallets && pallet.subpallets.length > 1 ? '#fdffba' : '#e2e2e2',
                }}
            >
                <TitleRow>{state !== 'INACTIVA' ? <ChipWrap state={state} name={name} color={color} onDelete={props.changeTable(props)} /> : <TextBold>{name}</TextBold>}</TitleRow>
                <Content
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        if (!onHandleClickTable) return;
                        onHandleClickTable({...props});
                    }}
                >
                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-evenly', height: '100%'}}>
                        <TextProduct>
                            {currentSubpallet?.merchandise.name}
                            {pallet && pallet.subpallets && pallet.subpallets.length > 1 ? <b>(*)</b> : ''}
                        </TextProduct>

                        <FlexRow>
                            <PalletCell>
                                {(prodpart && prodpart.boxes_count) || '-'}/{(prodpart && prodpart.boxes_total) || '-'}
                            </PalletCell>
                        </FlexRow>

                        <FlexRow>
                            <PalletCell>
                                {(currentSubpallet && currentSubpallet.boxes_count) || '-'}/{(currentSubpallet && currentSubpallet.boxes_total) || '-'}
                            </PalletCell>
                            <PalletCell
                                style={pointerChangeKgBox}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (IsInRuleset(GrantGroupsRanks.UPDATE_SUBPALLET_KG_BOX_EDIT)) {
                                        if (!onHandleChangeKgBoxTable) return;
                                        onHandleChangeKgBoxTable({...props});
                                    }
                                }}
                            >
                                {kgBox} Kg
                            </PalletCell>
                        </FlexRow>

                        <Text>{prodpart?.customer.name}</Text>

                        <Text>{currentSubpallet?.packaging_name}</Text>

                        <FlexRow>
                            <TextBold style={{fontSize: '0.7rem'}}>{prodpart?.erp_prodpart_code}</TextBold>
                            <TextBold style={{fontSize: '0.7rem'}}>{prodpart ? prodpart.pallets_count + '/' + prodpart.pallets_total : ''}</TextBold>
                            <TextBold style={{color: 'red', fontSize: '0.7rem'}}>{kgBoxProdpart ? `${kgBoxProdpart}` : '-'}</TextBold>
                            <TextBold style={{fontSize: '0.7rem'}}>{`${boxesCountKg} Kg` || '-'}</TextBold>
                        </FlexRow>
                    </div>
                </Content>
            </CustomTable>
        );
    else return <div>Error en los datos.</div>;
};
