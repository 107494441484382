import {TransferOriginPallet} from './store/liftPalletsControl.types';
import {Button, Card, Grid, Typography} from '@material-ui/core';
import {ListItem} from '@material-ui/core/es';
import EditIcon from '@material-ui/icons/Edit';
import RemoveButton from '@material-ui/icons/Clear';
import styled from 'styled-components';
import React, {useState} from 'react';
import {colorSubpallet, colorSubpalletHover} from '../../../shared/style/Colors';
import {PalletCompleteInfoModal} from '../../global/palletInfo/PalletInfoModal';
import {PalletInfoPallet} from '../../global/palletInfo/types';

type Props = {
    pallet: TransferOriginPallet,
    handleClick: (barcode: string) => void;
    handleRemove: (barcode: string) => void;
    handlePalletUpdated: (barcode: string) => void;
}

export const LiftPalletsOriginPalletListItem = ((props: Props) => {
        const [state, setState] = useState({
            openPalletInfoModal: false,
        });

        const handleOnClosePalletCompleteInfoModal = (pallet?: PalletInfoPallet) => {
            setState({
                ...state,
                openPalletInfoModal: false,
            });
            if (pallet) {
                props.handlePalletUpdated(pallet.barcode);
            }
        };

        return (
            <ListItem style={{width: '100%'}} key={props.pallet.id}>
                <Grid style={{width: '100%', padding: '0px'}} container alignItems='center'>

                    <PalletCompleteInfoModal palletBarcode={props.pallet.palletBarcode}
                                             open={state.openPalletInfoModal}
                                             onClose={handleOnClosePalletCompleteInfoModal} />

                    <Card style={{display: 'flex', flexFlow: 'row', padding: '0px', width: '100%'}}
                          className={'palletListElement'}>
                        <EditBoxes style={{flexGrow: 1, padding: '5px'}}
                                   onClick={() => props.handleClick(props.pallet.palletBarcode)}>
                            <Typography>
                                <b>{props.pallet.palletBarcode}</b> ({props.pallet.current_subpallet.boxes_count - props.pallet.current_subpallet.transfered_boxes}/{props.pallet.current_subpallet.boxes_total})
                            </Typography>
                            <Typography>
                                Artículo: <b>{props.pallet.current_subpallet.merchandise_name}</b>
                            </Typography>
                            <Typography>
                                Cajas a transferir: <b>{props.pallet.current_subpallet.transfered_boxes}</b>
                            </Typography>
                        </EditBoxes>

                        <Button variant={'raised'} color={'primary'} style={{
                            height: '100% !important',
                            width: '15px',
                            borderRadius: '0px',
                        }}
                                onClick={() => setState({...state, openPalletInfoModal: true})}>
                            <EditIcon style={{color: 'white'}} />
                        </Button>
                        <Button variant={'raised'} color={'secondary'} style={{
                            height: '100% !important',
                            width: '15px',
                            borderRadius: '0px',
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}
                                onClick={() => props.handleRemove(props.pallet.palletBarcode)}>
                            <RemoveButton style={{color: 'white'}} />
                        </Button>

                    </Card>

                </Grid>
            </ListItem>
        );
    }
);

export const EditBoxes = styled.div`
    background-color: ${colorSubpallet};

    &:hover {
        background-color: ${colorSubpalletHover};
        cursor: pointer;
    }
`;
