import * as React from 'react';
import {connect, MapStateToProps} from 'react-redux';

import {Dump, LanePtableState} from 'src/app/lanemanager/lane/store/types';
import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {Table} from './Table';
import {WrapTables} from './Wrap';
import {CustomButton} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {changePtableAssign, getAreaAllPtables} from 'src/app/lanemanager/lane/store/thunks';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {PtableWithoutPalletModal} from './PtableWithoutPalletModal';
import {LaneSelectStoreState} from '../../../../laneSelect/store/laneSelect.reducer';
import {CPA} from '../../store/thunks/changePtableAssign';
import {getObjectFromStorage} from '../../../../../utils/localforage';
import {currentDumpInstance} from '../../store/storage';
import {PalletInfoModal} from './PalletsInfoModal';
import {PalletChangeKgBoxModal} from './PalletChangeKgBoxModal';

export interface DProps {
    getPtables(): void;

    changeTable: (config: CPA) => any;
}

export type SProps = {
    ptables: LanePtableState[];
    auto_palletise: boolean;
    area: LaneSelectStoreState;
    prodparts: ReturnType<typeof AreaSelectors.getProdParts>;
    fetchState: ReturnType<typeof AreaSelectors.getCurrentDumpFetchState>;
};

type State = {
    openModal: boolean;
    openModalWithoutPallet: boolean;
    openConfirmationFinishAllPTables: boolean;
    openModalChangeKgBox: boolean;
    currentDump: Dump | null;
    selectedPtable: LanePtableState | null;
};

const initialState: State = {
    openModal: false,
    openModalWithoutPallet: false,
    openConfirmationFinishAllPTables: false,
    openModalChangeKgBox: false,
    currentDump: null,
    selectedPtable: null,
};

export class PalletsInfo extends React.Component<DProps & SProps, State> {
    readonly state = initialState;

    private onHandleClickTable = (selectedPtable: LanePtableState) => {
        if (selectedPtable && selectedPtable.pallet) {
            this.setState({
                ...this.state,
                openModal: true,
                selectedPtable,
            });
        } else if (selectedPtable) {
            this.setState({
                ...this.state,
                openModalWithoutPallet: true,
                selectedPtable,
            });
        }
    };

    private onHandleChangeKgBoxTable = (selectedPtable: LanePtableState) => {
        console.log('selectedPtable', selectedPtable);
        this.setState({
            ...this.state,
            openModalChangeKgBox: true,
            selectedPtable,
        });
        /*if (selectedPtable && selectedPtable.pallet) {
                   this.setState({
                       ...this.state,
                       openModal: true,
                       selectedPtable,
                   });
               } else if (selectedPtable) {
                   this.setState({
                       ...this.state,
                       openModalWithoutPallet: true,
                       selectedPtable,
                   });
               }*/
    };

    private finishAllPallets = async () => {
        const areaId = this.props.area.laneId;
        const resp = await fetchWithFeedback(PalletsServices.finishAllByArea(areaId), {
            errorMessage: 'Error al finalizar pale',
            successMessage: 'Pale finalizado con exito',
        });
        if (resp && resp.length > 0) {
            this.props.getPtables();
        }
        this.setState({...this.state, openConfirmationFinishAllPTables: false});
    };

    private hidePtableWithoutPalletActions = () => {
        this.setState({
            ...this.state,
            openModalWithoutPallet: false,
        });
    };

    private getProdpartByPtableAssignedTo(assigned_to: string) {
        return this.props.prodparts.find((prodpart: any) => prodpart.id === assigned_to);
    }

    private changeTable = (pt: LanePtableState) => () => {
        if (pt.state !== 'ACTIVA')
            this.props.changeTable({
                ptable: pt,
                newAssign: undefined,
                newReserve: undefined,
            });
    };

    private retrieveFromDB = async () => {
        const dumpData = await getObjectFromStorage<Dump>(currentDumpInstance);
        this.setState({...this.state, currentDump: dumpData});
    };

    render() {
        const {ptables, fetchState} = this.props;
        if (ptables === undefined || ptables.length === 0) return <div style={{padding: 10, paddingLeft: 20, fontSize: 14}}>No hay datos sobre las mesas...</div>;

        fetchState !== 'waiting' && fetchState !== 'fail' && this.retrieveFromDB();

        return (
            <>
                {/* FINALIZED ALL PTABLES CONFIRMATION DIALOG */}

                <CustomDialog
                    title={'Confirmación'}
                    contentText={'¿Desea finalizar todas las mesas de paletizado?'}
                    open={this.state.openConfirmationFinishAllPTables}
                    handle={this.finishAllPallets}
                    handleClose={() => this.setState({...this.state, openConfirmationFinishAllPTables: false})}
                />

                {/* PTABLE WITHOUT PALLET MODAL */}

                <PtableWithoutPalletModal open={this.state.openModalWithoutPallet} hide={this.hidePtableWithoutPalletActions} ptableSelected={this.state.selectedPtable} />

                {/*  PALLET INFO MODAL */}

                {this.state.selectedPtable && (
                    <PalletInfoModal
                        open={this.state.openModal}
                        ptable={this.state.selectedPtable}
                        ptables={this.props.ptables}
                        auto_palletise={this.props.auto_palletise}
                        onClose={(isUpdated: boolean) => {
                            this.setState({...this.state, openModal: false});
                            if (isUpdated) {
                                this.props.getPtables();
                            }
                        }}
                    />
                )}

                {/*  CHANGE KG BOX MODAL */}

                {this.state.selectedPtable && (
                    <PalletChangeKgBoxModal
                        open={this.state.openModalChangeKgBox}
                        ptable={this.state.selectedPtable}
                        onClose={(isUpdated: boolean) => {
                            this.setState({...this.state, openModalChangeKgBox: false});
                            if (isUpdated) {
                                this.props.getPtables();
                            }
                        }}
                    />
                )}

                {/* PTABLES */}

                {
                    <WrapTables>
                        {ptables.map((ptable: LanePtableState) => {
                            const pallet = ptable.pallet;

                            return (
                                <Table
                                    {...ptable}
                                    prodpart={this.getProdpartByPtableAssignedTo(ptable.assigned_to ?? '')}
                                    pallet={pallet}
                                    currentDump={this.state.currentDump}
                                    key={ptable.ptable_id}
                                    onHandleClickTable={this.onHandleClickTable}
                                    onHandleChangeKgBoxTable={this.onHandleChangeKgBoxTable}
                                    changeTable={this.changeTable}
                                />
                            );
                        })}

                        {this.props.area.isMasivePalletize && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexFlow: 'row wrap',
                                    justifyContent: 'center',
                                    paddingBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <CustomButton
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            openConfirmationFinishAllPTables: true,
                                        })
                                    }
                                    padding={'5px 20px'}
                                >
                                    FINALIZAR TODAS
                                </CustomButton>
                            </div>
                        )}
                    </WrapTables>
                }
            </>
        );
    }
}

const s2p: MapStateToProps<SProps, any, AppStoreState> = state => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: state.laneSelect.AutoPalletise,
    area: state.laneSelect,
    prodparts: AreaSelectors.getProdParts(state),
    fetchState: AreaSelectors.getCurrentDumpFetchState(state),
});

const d2p = (dispatch: any) => ({
    getPtables: () => dispatch(getAreaAllPtables()),
    changeTable: (payload: CPA) => dispatch(changePtableAssign(payload)),
});

export const PalletsInfoConnected = connect(s2p, d2p)(PalletsInfo);
