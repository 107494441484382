import * as React from 'react';
import {connect} from 'react-redux';

import styled from 'styled-components';
import {FullSizeModal} from 'src/shared/components/FullSizeModal';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {PalletInformationServices} from '../../lanemanager/palletInformation/store/palletInformation.services';
import {Spinner} from '../spinner';
import {Tab, Tabs, Typography} from '@material-ui/core';
import {PalletsServices} from '../../lanemanager/pallets/store/services';
import {TabContainer} from '../../lanemanager/Wrap';
import {SubpalletInfo} from './SubpalletInfo';
import {CustomButton} from '../../../shared/style';
import {PalletInfoPallet, PalletInfoSubpallet} from './types';
import {IsInRuleset} from '../../../global/authorization/grantSet';
import {GrantGroupsRanks} from '../../../global/authorization/ranking';
import {SelectProdpartModal} from './SelectProdpartModal';
import {ProdPartGeneric} from '../../lanemanager/lane/store/types';
import {SelectProdpartDialogConfirmation} from './SelectedProdpartDialogConfirmation';

export interface Props {
    open: boolean;
    palletBarcode: string;
    onClose: (pallet?: PalletInfoPallet) => void;
}

type State = {
    isLoading: boolean;
    openAddSubpalletConfirmationDialog: boolean;
    selectedTabIndex: number;
    openSelectProdpartModal: boolean;
    isUpdated: boolean;
    pallet?: PalletInfoPallet;
    selectedSubpallet?: PalletInfoSubpallet;
    selectedProdpartToAddSubpallet?: ProdPartGeneric;
};

const initialState: State = {
    isLoading: false,
    openAddSubpalletConfirmationDialog: false,
    selectedTabIndex: 0,
    openSelectProdpartModal: false,
    isUpdated: false,
};

export class PalletInfoModalBase extends React.Component<Props, State> {
    state = initialState;

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.open && !this.props.open) {
            this.refreshData();
        }
    }

    componentWillUnmount() {
        this.setState({...initialState});
    }

    refreshData = async () => {
        //Hacer la llamada para obtener la información del palé
        if (this.props.palletBarcode) {
            this.setLoading();
            const palletInfo = await fetchWithFeedback(PalletInformationServices.getCompleteInfo(this.props.palletBarcode), {
                errorMessage: 'No se han podido obtener los datos del palé indicado',
            });
            this.unsetLoading();
            this.updatePalletState(palletInfo, this.state.isUpdated);

            return palletInfo;
        }
        return null;
    };

    private updatePalletState(palletInfo: {pallet: PalletInfoPallet}, isUpdated: boolean) {
        if (palletInfo && palletInfo.pallet) {
            const currentSubpallet = palletInfo.pallet.subpallets.find((subpallet: PalletInfoSubpallet) => subpallet.id === palletInfo.pallet.current_subpallet_id);
            if (currentSubpallet) {
                palletInfo.pallet.current_subpallet = currentSubpallet;
            }
            this.setState({
                ...this.state,
                isUpdated,
                pallet: {...palletInfo.pallet},
                selectedSubpallet: this.getSelectedSubpallet(palletInfo.pallet),
                selectedTabIndex: (palletInfo.pallet.subpallets as PalletInfoSubpallet[]).findIndex(
                    (subpallet: PalletInfoSubpallet) => subpallet.id === palletInfo.pallet.current_subpallet_id,
                ),
            });
        }
    }

    private getSelectedSubpallet(pallet: PalletInfoPallet) {
        if (pallet.current_subpallet_id) {
            return pallet.subpallets.find((subpallet: PalletInfoSubpallet) => subpallet.id === pallet.current_subpallet_id);
        }
        return pallet.subpallets[0] ?? null;
    }

    private setLoading = () => {
        this.setState({
            ...this.state,
            isLoading: true,
        });
    };

    private unsetLoading = () => {
        this.setState({
            ...this.state,
            isLoading: false,
        });
    };

    private close = () => {
        if (this.state.isUpdated) {
            this.props.onClose(this.state.pallet);
        } else {
            this.props.onClose();
        }
    };

    private async handleChangeCurrentSubpallet(subpallet: PalletInfoSubpallet) {
        if (!this.state.pallet) return;
        this.setLoading();
        const palletInfo = await fetchWithFeedback(PalletsServices.updateCurrentSubpallet(this.state.pallet.barcode, subpallet.id), {
            successMessage: 'Se ha actualizado el subpalé principal correctamente',
            errorMessage: 'No se ha podido actualizar el subpalé principal',
        });
        this.unsetLoading();
        this.updatePalletState(palletInfo, true);
    }

    private async addSubpalletByProdpart() {
        if (!this.state.selectedProdpartToAddSubpallet || !this.state.pallet) return;
        this.setLoading();
        const palletInfo = await fetchWithFeedback(PalletsServices.addSubpalletByProdpart(this.state.pallet.barcode, this.state.selectedProdpartToAddSubpallet.id), {
            successMessage: 'Se ha añadido un subpalé',
            errorMessage: 'No se ha podido añadir un subpalé',
        });
        this.unsetLoading();
        this.updatePalletState(palletInfo, true);
    }

    private async handleRemoveSubpallet(subpallet: PalletInfoSubpallet) {
        if (!this.state.pallet) return;
        this.setLoading();
        const palletInfo = await fetchWithFeedback(PalletsServices.deleteSubpallet(this.state.pallet.barcode, subpallet.id), {
            successMessage: 'Se elimino el subpalé',
            errorMessage: 'No se ha podido eliminar el subpalé',
        });
        this.unsetLoading();
        this.updatePalletState(palletInfo, true);
    }

    render() {
        const {isLoading, openAddSubpalletConfirmationDialog, selectedTabIndex, selectedSubpallet, pallet, openSelectProdpartModal, selectedProdpartToAddSubpallet} = this.state;

        return (
            <>
                {pallet && (
                    <SelectProdpartModal
                        open={openSelectProdpartModal}
                        title={'Seleccione un parte para el nuevo subpalé'}
                        newSubpallet={true}
                        subpallets={pallet.subpallets}
                        erpOrderCode={pallet.erp_order_code}
                        areaId={pallet.area_id}
                        handleClose={() =>
                            this.setState({
                                ...this.state,
                                openSelectProdpartModal: false,
                            })
                        }
                        handleSelect={item => {
                            this.setState({
                                ...this.state,
                                selectedProdpartToAddSubpallet: item,
                                openSelectProdpartModal: false,
                                openAddSubpalletConfirmationDialog: true,
                            });
                        }}
                    ></SelectProdpartModal>
                )}

                {selectedProdpartToAddSubpallet && (
                    <SelectProdpartDialogConfirmation
                        open={openAddSubpalletConfirmationDialog}
                        title={'¿Desea añadir el subpalé con los siguientes datos?'}
                        prodpart={selectedProdpartToAddSubpallet}
                        prodpartOrigin={undefined}
                        handleSelect={() => {
                            const {selectedProdpartToAddSubpallet, ...state} = this.state;
                            this.setState(
                                {
                                    ...state,
                                    openAddSubpalletConfirmationDialog: false,
                                },
                                () => this.addSubpalletByProdpart(),
                            );
                        }}
                        handleClose={() => this.setState({...this.state, openAddSubpalletConfirmationDialog: false})}
                    />
                )}

                <FullSizeModal title={'Información Palé'} open={this.props.open} handleClose={this.close} withMargin={true}>
                    <Spinner isLoading={isLoading} position={'relative'} />

                    <WrappingDiv style={{opacity: isLoading ? '0.6' : '1'}}>
                        <Typography
                            component={'div'}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexFlow: 'row wrap',
                                padding: '10px',
                                justifyContent: 'space-evenly',
                                gap: '10px',
                            }}
                        >
                            <FlexRowVerticalCenter>
                                Pedido:&nbsp;
                                <span style={{color: 'darkgreen'}}>{pallet?.erp_order_code}</span>
                            </FlexRowVerticalCenter>
                            <FlexRowVerticalCenter>
                                Cliente:&nbsp;
                                <span style={{color: 'darkgreen'}}>{pallet?.customer_name}</span>
                            </FlexRowVerticalCenter>
                            <FlexRowVerticalCenter>
                                Matrícula:&nbsp;
                                <span style={{color: 'darkgreen'}}>{pallet?.barcode}</span>
                            </FlexRowVerticalCenter>
                            <FlexRowVerticalCenter>
                                <span style={{color: 'darkgreen'}}>{pallet?.boxes_count}</span>
                                &nbsp;/&nbsp;
                                <span style={{color: 'darkgreen'}}>{pallet?.boxes_total}</span>
                            </FlexRowVerticalCenter>
                            <FlexRowVerticalCenter>
                                <span style={{color: 'darkgreen'}}>{pallet?.kg_made}</span>
                                &nbsp;/&nbsp;
                                <span style={{color: 'darkgreen'}}>{pallet?.kg_total}&nbsp;kg</span>
                            </FlexRowVerticalCenter>

                            {IsInRuleset(GrantGroupsRanks.PALLET_SUBPALLET_TOTAL) && (
                                <FlexRowVerticalCenter>
                                    <CustomButton
                                        disabled={isLoading}
                                        onClick={() =>
                                            this.setState({
                                                ...this.state,
                                                openSelectProdpartModal: true,
                                            })
                                        }
                                        color="primary"
                                    >
                                        {pallet && pallet.subpallets.length > 1 ? 'Agregar subpalé' : 'Inicial palé mixto'}
                                    </CustomButton>
                                </FlexRowVerticalCenter>
                            )}
                        </Typography>

                        {pallet && pallet.subpallets && pallet.subpallets.length > 1 && (
                            <>
                                <Tabs
                                    disabled={isLoading}
                                    scrollable={true}
                                    fullWidth={true}
                                    style={{width: '100%'}}
                                    value={selectedTabIndex}
                                    onChange={(event, value) => {
                                        console.log(event);
                                        this.setState({...this.state, selectedTabIndex: value});
                                    }}
                                >
                                    {pallet.subpallets.map((subpallet: PalletInfoSubpallet, index: number) => (
                                        <Tab
                                            key={subpallet.id}
                                            style={{textDecoration: 'inherit', color: 'inherit'}}
                                            label={`Subpalet ${index + 1}${selectedSubpallet && selectedSubpallet.id === subpallet.id ? '(*)' : ''}`}
                                        ></Tab>
                                    ))}
                                </Tabs>
                                {pallet &&
                                    pallet.subpallets &&
                                    pallet.subpallets.map(
                                        (subpallet: PalletInfoSubpallet, index: number) =>
                                            selectedTabIndex === index && (
                                                <TabContainer key={subpallet.id}>
                                                    <SubpalletInfo
                                                        isLoading={isLoading}
                                                        subpallet={subpallet}
                                                        pallet={pallet}
                                                        handleLoading={handleLoading => (handleLoading ? this.setLoading() : this.unsetLoading())}
                                                        updatePalletState={palletInfo => this.updatePalletState(palletInfo, true)}
                                                        refreshData={this.refreshData}
                                                        selecCurrentSubpallet={subpallet => this.handleChangeCurrentSubpallet(subpallet)}
                                                        selectedSubpalletId={selectedSubpallet ? selectedSubpallet.id : ''}
                                                        handleRemoveSubpallet={subpallet => this.handleRemoveSubpallet(subpallet)}
                                                    />
                                                </TabContainer>
                                            ),
                                    )}
                            </>
                        )}

                        {pallet && pallet.current_subpallet && pallet.subpallets.length === 1 && (
                            <SubpalletInfo
                                isLoading={isLoading}
                                subpallet={pallet.current_subpallet}
                                pallet={pallet}
                                handleLoading={handleLoading => (handleLoading ? this.setLoading() : this.unsetLoading())}
                                updatePalletState={palletInfo => this.updatePalletState(palletInfo, true)}
                                refreshData={this.refreshData}
                                selecCurrentSubpallet={subpallet => this.handleChangeCurrentSubpallet(subpallet)}
                                selectedSubpalletId={selectedSubpallet ? selectedSubpallet.id : ''}
                                handleRemoveSubpallet={subpallet => this.handleRemoveSubpallet(subpallet)}
                            />
                        )}
                    </WrappingDiv>
                </FullSizeModal>
            </>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(95%);
    margin: auto;
    margin-top: 8px;
    height: calc(95%);
    padding: 0px;
    margin-bottom: 5px;
    background-color: white;
`;

const FlexRowVerticalCenter = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const PalletCompleteInfoModal = connect()(PalletInfoModalBase);
