import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {notifyConnect, notifyServerError} from 'src/utils/Notification';

// import {ptablesInstance} from '../storage';
import {LanePtableActions} from '../actions';
import {AreaServices} from '../services';
// import {fillStorage} from 'src/utils/localforage';
import {LaneActionInterface} from '../types';
import {areaPtablesResSchemeValidate} from '../validators/AreaPtablesRes';

export const getAreaAllPtables = () => async (dispatch: (action: any) => LaneActionInterface, getState: () => AppStoreState) => {
    try {
        const lane = LaneSelectSelectors.getLane(getState());
        const req = await AreaServices.getAllPtables(lane ? lane.laneId : 'NO_EXISTE_LINEA');
        const {data} = req;
        if (req.status < 400) {
            const validate = await areaPtablesResSchemeValidate;
            const valid = await validate(data);

            if (!valid) {
                console.error('NO HE VALIDADO> ', validate.errors); // ! POR CONSOLA???
            }
            dispatch(LanePtableActions.set(data));
        } else dispatch(notifyServerError());
    } catch (error) {
        dispatch(notifyConnect());
        console.error(error);
        throw new Error(error.message);
    }
};
